import { BaseService } from '../base-service';
import { RecaptchaService } from '../recaptcha-service';

import {
   HEADER_REQUEST,
   BASE_URL,
   STORES,
   TIMESLOT_STORE,
   CHOSEN_STORE,
   RESERVE_TIMESLOT,
   EXTEND_ADDRESSES,
   OLDPLATFORM_PDV_HP,
   CLEAN_ECACCESS,
   RESERVE_TIMESLOT_ON_CART,
   EXTEND_TIMESLOT,
   FIRST_TIMESLOT_BY_STORES
} from "../config";

export class StoreService extends BaseService{

   constructor(private recaptchaService: RecaptchaService) {
      super();
   }

   nkOnInit() { }

   getAddress(success, error, body): void {
      this.post(BASE_URL + STORES, success, error, body, HEADER_REQUEST);
   }

   getAvailableTimeSlot(success, error, body): void{
      this.post(BASE_URL + TIMESLOT_STORE, success, error, body, HEADER_REQUEST);
   }

   async setChosenStore(success, error, body): Promise<void> {
      let recaptchaCode = await this.recaptchaService.gRecaptcha(body?.pointOfServiceId);
      if(recaptchaCode){
         body['gRecaptchaResponse'] = recaptchaCode;
      }
      
      this.post(BASE_URL + CHOSEN_STORE, success, async (axiosError) => {
         if (axiosError.response.status == 403) {
            let recaptchaCodeV2 = await this.recaptchaService.gRecaptchaV2();
            body['gRecaptchaResponse'] = recaptchaCodeV2;
            body['gRecaptchaV2'] = "true";
            this.post(BASE_URL + CHOSEN_STORE, success, error, body, HEADER_REQUEST);
         } else {
            error(axiosError);
         }
      }, body, HEADER_REQUEST)
   }

   async reserveTimeSlot(success, error, body): Promise<void> {
      let recaptchaCode = await this.recaptchaService.gRecaptcha(body?.pointOfServiceId);
      if(recaptchaCode){
         body['gRecaptchaResponse'] = recaptchaCode;
      }
      
      this.postAxios(BASE_URL + RESERVE_TIMESLOT, success, async (axiosError) => {
         if (axiosError.response.status == 403) {
            let recaptchaCodeV2 = await this.recaptchaService.gRecaptchaV2();
            body['gRecaptchaResponse'] = recaptchaCodeV2;
            body['gRecaptchaV2'] = "true";
            this.postAxios(BASE_URL + RESERVE_TIMESLOT, success, error, body, HEADER_REQUEST);
         } else {
            error(axiosError);
         }
      }, body, HEADER_REQUEST)
   }

   extendAddresses(success, error, body): void {
      this.post(BASE_URL + EXTEND_ADDRESSES, success, error, body, HEADER_REQUEST)
   }

   getOldPdv(success, error, body): void {
      this.post(BASE_URL + OLDPLATFORM_PDV_HP, success, error, body, HEADER_REQUEST);
   }

   cleanEcaccess(success, error, body): void {
      this.post(BASE_URL + CLEAN_ECACCESS, success, error, body, HEADER_REQUEST);
   }

   reserveTimeSlotOnCart(success, error, body): void {
      this.postAxios(BASE_URL + RESERVE_TIMESLOT_ON_CART, success, error, body, HEADER_REQUEST);
   }

   extendTimeslot(success, error, body): void {
      this.postAxios(BASE_URL + EXTEND_TIMESLOT, success, error, body, HEADER_REQUEST);
   }

   getFirstAvailableTimeslot(success, error, body): void{
      this.post(BASE_URL + FIRST_TIMESLOT_BY_STORES, success, error, body, HEADER_REQUEST);
   }

   extractStoreIds(stores): Array<string>{
      return this.extractStoreValue(stores, 'name');
   }

   extractDeliveryAreas(stores): Array<string>{
      return this.extractStoreValue(stores, 'selectedDeliveryId')
   }

   extractStoreValue(stores, value){
      let values = [];
      for(let i = 0; i < stores.length; i++){
         if(stores[i][value]){
            values.push(stores[i][value]);
         }else{
            values.push(null);
         }
         
      }
      return values;
   }
}
