import { Options, Splide } from '@splidejs/splide';
import Alpine from 'alpinejs';
import { DefaultService, ProductCardType } from '../../../../../../api';
import { BASE_ECOMMERCE_DATA } from '../../../../../../constants';
import { formatC, fromResponseToCards, getStandardContentRedirectDirective, i18n, renderHrefWithAdditionalParams, throttle } from '../../../../../../libs/base-utils';
import { addAllWishlistProductsToCartFlow } from '../../../../../../libs/common-flows';
import { sendAnalytics } from '../../../../../../libs/tracking-utils';
import { checkAddWishlistProductsToCart, DEFAULT_WISHLIST_NAME, getWishlist, getWishlistNames } from '../../../../../../libs/wishlists-utils';

const WISHLIST_PAGE_SIZE = 24;
const SPLIDE_WISH_ID = '#splide-wishlists';
const SPLIDE_WISH_OPT: Options = {
    drag: 'free',
    arrows: false,
    pagination: false,
    autoWidth: true,
};
const SPLIDE_PRODUCTS_ID = '#splide-wishlist-products-preview';
const SPLIDE_PRODUCTS_OPT: Options = {
    drag: 'free',
    pagination: false,
    autoWidth: true,
};

Alpine.data('ec11x8xPreviewWishlists', () => {
    const parser = new DOMParser();
    let splideWish;
    let splideProducts;

    return {
        initialized: false as boolean,
        existentWishlistsNamesWithDim: new Map() as Map<string, number>,
        selectedWishlist: DEFAULT_WISHLIST_NAME,
        showCardsSkeleton: true,
        showCardsWrapper: false,
        wishlistNominalSize: 0,
        wishlistCards: [] as Node[],
        currentLoadedPage: 0,
        init() {
            this._loadExistentWishlistsNames();
        },
        // consts
        DEFAULT_WISHLIST_NAME,
        // utils
        i18n,
        formatC,
        getWishlist,
        renderHrefWithAdditionalParams,
        checkAddWishlistProductsToCart,
        // internal functions
        async _loadExistentWishlistsNames() {
            this.existentWishlistsNamesWithDim = new Map();
            (await getWishlistNames(true))?.forEach(async (wishName) => {
                const wishDim = (await getWishlist(wishName))?.entries?.size;
                this.existentWishlistsNamesWithDim.set(wishName, wishDim);
            });
            this._refreshWishlistCards();
            this.$nextTick(() => {
                splideWish = new Splide(SPLIDE_WISH_ID, SPLIDE_WISH_OPT).mount();
            });
            this.initialized = true;
        },
        async _getWishlistCards(): Promise<Node[]> {
            // console.log(`Load wishlist product cards of '${this.selectedWishlist}', filter: '${this.wishlistFilter}', page: '${this.currentLoadedPage}'`);
            try {
                const response = (await DefaultService.getWishlistJcrContentRootWishlistListHtml(
                    getStandardContentRedirectDirective(),
                    (this.selectedWishlist && this.selectedWishlist !== DEFAULT_WISHLIST_NAME) ?
                        (await getWishlist(this.selectedWishlist)).wishlistId : undefined, // wishlistId
                    ['incr', 'decr'].includes(this.wishlistFilter) ? this.wishlistFilter : undefined, // price
                    WISHLIST_PAGE_SIZE, // pageSize
                    this.currentLoadedPage, // page 
                    ProductCardType.CARD_MINI, // cardType
                    true // errorsAsServlet
                ));
                return fromResponseToCards(response, parser);
            } catch (e) {
                this.$dispatch('toast', { id: 'code-error', customText: i18n('Error retrieving wishlist cards') });
                console.error(e);
                return [];
            }
        },
        _refreshWishlistCards: throttle(async function () {
            this.currentLoadedPage = 0;
            this.wishlistNominalSize = (await getWishlist(this.selectedWishlist))?.entries?.size || 0;
            if (this.wishlistNominalSize == 0) {
                this.wishlistCards = [];
                this.showCardsSkeleton = false;
                return;
            }
            this.showCardsSkeleton = true;
            this.showCardsWrapper = false;
            this.wishlistCards = (await this._getWishlistCards());
            this.$nextTick(() => {
                if (splideProducts) splideProducts.destroy();
                splideProducts = new Splide(SPLIDE_PRODUCTS_ID, SPLIDE_PRODUCTS_OPT).mount();
                window.Utils.checkCartStatus(); // old-fe call
            });
            this.showCardsWrapper = true;
            this.showCardsSkeleton = false;
        }, 500),
        // main component functions
        async selectWishlist(wishlistName: string) {
            this.selectedWishlist = wishlistName;
            sendAnalytics((() => {
                let ecommerceData = BASE_ECOMMERCE_DATA.CLICK_PA_WISHLISTS_CHANGE_LIST;
                ecommerceData.funnel.listaVisualizzata = DEFAULT_WISHLIST_NAME;
                ecommerceData.funnel.listaSelezionata = this.selectedWishlist;
                return ecommerceData;
            })());
            this._refreshWishlistCards();
        },
        async addAllToCart() {
            if (!this.$store.user || !this.$store.user.utenzaId || !this.$store.cart) return;
            if (!this.selectedWishlist || this.wishlistCards?.length == 0) return;
            sendAnalytics((() => {
                let ecommerceData = BASE_ECOMMERCE_DATA.CLICK_PA_WISHLISTS_ADD_ALL_TO_CART;
                ecommerceData.funnel.listaVisualizzata = DEFAULT_WISHLIST_NAME;
                return ecommerceData;
            })());
            const flowOutputData = addAllWishlistProductsToCartFlow(this);
            // if (flowOutputData?.result?.state === 'confirmed') {}*/
        },
        // only analytics functions
        trackShowAll() {
            let ecommerceData = BASE_ECOMMERCE_DATA.CLICK_PA_PROFILE_SHOW_ALL;
            ecommerceData.funnel.stepFunnel = 'Liste Prodotti';
            sendAnalytics(ecommerceData);
        }
    }
});
