import { Alpine } from 'alpinejs';
import { FlowStore } from '../types';

export const cFlowElement = (Alpine: Alpine) => {
    Alpine.directive('cflowelement', (el: HTMLElement, { expression }, { cleanup, effect }) => {
        const cScope = Alpine.reactive({ $ccurrent: false });
        Alpine.addScopeToNode(el, cScope);

        effect(() => {
            const flowStore = <FlowStore>window.Alpine.store('cFlow');
            if (flowStore.currentStep?.name == expression) cScope.$ccurrent = true;
            else cScope.$ccurrent = false;
        });
    }).before('bind');
};
