
export const trackingDictionary = {
    FUNNEL_DATA_EP_STEP1: { funnel: { nomeFunnel: 'EntryPage', stepFunnel: 'Step 1 - Start' } },
    FUNNEL_DATA_EP_STEP2: { funnel: { nomeFunnel: 'EntryPage', stepFunnel: 'Step 2 - SceltaNegozio' } },
    FUNNEL_DATA_EP_STEP3: { funnel: { nomeFunnel: 'EntryPage', stepFunnel: 'Step 3 - SceltaSlot' } },
    FUNNEL_DATA_EP_BOOKING_MANAGE: { funnel: { nomeFunnel: 'EntryPage', stepFunnel: 'Gestisci prenotazione' } },
    FUNNEL_DATA_EP_SERVICE_CHOICE: { funnel: { nomeFunnel: 'EntryPage', stepFunnel: 'Scelta servizio' } },
    FUNNEL_DATA_EP_NO_STEP: { funnel: { nomeFunnel: 'EntryPage' } },
    FUNNEL_DATA_MEGAMENU: { funnel: { nomeFunnel: 'Scelta prodotto', stepFunnel: 'Menu categorie' } },
    FUNNEL_DATA_SEARCH: { funnel: { nomeFunnel: 'Ricerca', stepFunnel: 'Risultati ricerca' } },
    FUNNEL_DATA_HEADER_PRESEARCH: { funnel: { nomeFunnel: 'Ricerca', stepFunnel: 'Start' } },
    FUNNEL_DATA_HEADER_POSTSEARCH: { funnel: { nomeFunnel: 'Ricerca', stepFunnel: 'Suggerimento' } },
    FUNNEL_DATA_LISTINGPAGE: { funnel: { nomeFunnel: 'Scelta prodotto', stepFunnel: 'Listing page' } },
    FUNNEL_DATA_PRODUCT_CARD_ADD_TO_CART_SEARCH: { funnel: { nomeFunnel: 'Ricerca', stepFunnel: 'Aggiunta al carrello', creazioneCarrello: '' } },
    FUNNEL_DATA_PRODUCT_CARD_ADD_TO_CART_CHOOSE: { funnel: { nomeFunnel: 'Scelta prodotto', stepFunnel: 'Aggiunta al carrello', creazioneCarrello: '' } },
    FUNNEL_DATA_PRODUCT_CARD_REMOVE_FROM_CART_SEARCH: { funnel: { nomeFunnel: 'Ricerca', stepFunnel: 'Rimozione dal carrello' } },
    FUNNEL_DATA_PRODUCT_CARD_REMOVE_FROM_CART_CHOOSE: { funnel: { nomeFunnel: 'Scelta prodotto', stepFunnel: 'Rimozione dal carrello' } },
    FUNNEL_DATA_PRODUCT_CARD_ADD_FAVOURITE_SEARCH: { funnel: { nomeFunnel: 'Ricerca', stepFunnel: 'Aggiunta ai preferiti' } },
    FUNNEL_DATA_PRODUCT_CARD_REMOVE_FAVOURITE_SEARCH: { funnel: { nomeFunnel: 'Ricerca', stepFunnel: 'Rimozione dai preferiti' } },
    FUNNEL_DATA_PRODUCT_CARD_ADD_FAVOURITE_CHOOSE: { funnel: { nomeFunnel: 'Scelta prodotto', stepFunnel: 'Aggiunta ai preferiti' } },
    FUNNEL_DATA_PRODUCT_CARD_REMOVE_FAVOURITE_CHOOSE: { funnel: { nomeFunnel: 'Scelta prodotto', stepFunnel: 'Rimozione dai preferiti' } },
    FUNNEL_DATA_PRODUCT_CARD_CLICK_PRODUCT_SEARCH: { funnel: { nomeFunnel: 'Ricerca', stepFunnel: 'Click su prodotto' } },
    FUNNEL_DATA_PRODUCT_CARD_CLICK_PRODUCT_CHOOSE: { funnel: { nomeFunnel: 'Scelta prodotto', stepFunnel: 'Click su prodotto' } },
    FUNNEL_DATA_EST_SES_INFOBAR_EST: { funnel: { estendiSessione: { componente: 'Infobar', azione: 'Estendi' } } },
    FUNNEL_DATA_EST_SES_INFOBAR_CLOSE: { funnel: { estendiSessione: { componente: 'Infobar', azione: 'Chiudi' } } },
    FUNNEL_DATA_EST_SES_POPUP_EST: { funnel: { estendiSessione: { componente: 'Popup richiesta estensione', azione: 'Estendi' } } },
    FUNNEL_DATA_EST_SES_POPUP_CLOSE: { funnel: { estendiSessione: { componente: 'Popup richiesta estensione', azione: 'Chiudi' } } },
    FUNNEL_DATA_EST_SES_POPUP_CONF: { funnel: { estendiSessione: { componente: 'Popup conferma estensione', azione: 'Continua' } } },
    FUNNEL_DATA_EST_SES_POPUP_CONF_CLOSE: { funnel: { estendiSessione: { componente: 'Popup conferma estensione', azione: 'Chiudi' } } },
    FUNNEL_DATA_EST_SES_POPUP_NEW_SESSION: { funnel: { estendiSessione: { componente: 'Popup sessione scaduta', azione: 'Nuova prenotazione' } } },
    FUNNEL_DATA_EST_SES_POPUP_NEW_SESSION_IGNORE: { funnel: { estendiSessione: { componente: 'Popup sessione scaduta', azione: 'Chiudi' } } },
    FUNNEL_DATA_MINICART_VIEW: { funnel: { nomeFunnel: 'Minicart', stepFunnel: 'Visualizzazione carrello' } },
    FUNNEL_DATA_MINICART_CLOSE: { funnel: { nomeFunnel: 'Minicart', stepFunnel: 'Chiusura minicart' } },
    FUNNEL_DATA_MINICART_REMOVE_ITEM: { funnel: { nomeFunnel: 'Minicart', stepFunnel: 'Eliminazione prodotto' } },
    FUNNEL_DATA_MINICART_CLICK_ITEM: { funnel: { nomeFunnel: 'Minicart', stepFunnel: 'Click su prodotto' } },
    FUNNEL_DATA_MINICART_TO_CART: { funnel: { nomeFunnel: 'Minicart', stepFunnel: 'CTA dettaglio carrello' } },
    FUNNEL_DATA_CART_VIEW: { funnel: { nomeFunnel: 'Carrello', stepFunnel: 'Visualizzazione carrello' } },
    FUNNEL_DATA_CART_CHECKOUT: { funnel: { nomeFunnel: 'Carrello', stepFunnel: 'Click vai al checkout' } },
    FUNNEL_DATA_CART_TO_EMPTY: { funnel: { nomeFunnel: 'Carrello', stepFunnel: 'Conferma svuota carrello' } },
    FUNNEL_DATA_CART_CLOSE_MODAL: { funnel: { nomeFunnel: 'Carrello', stepFunnel: 'Chiusura modale svuota carrello' } },
    FUNNEL_DATA_CART_APPLY_PEZZ: { funnel: { nomeFunnel: 'Carrello', stepFunnel: 'Applica pezzatura' } },
    FUNNEL_DATA_CART_APPLY_NOTE: { funnel: { nomeFunnel: 'Carrello', stepFunnel: 'Applica nota di preparazione' } },
    FUNNEL_DATA_CART_REMOVE_PROD: { funnel: { nomeFunnel: 'Carrello', stepFunnel: 'Eliminazione prodotto' } },
    FUNNEL_DATA_CART_DELETE_REMOVE: { funnel: { nomeFunnel: 'Carrello', stepFunnel: 'Annullamento eliminazione prodotto' } },
    FUNNEL_DATA_CART_REPLACE_MODE: { funnel: { nomeFunnel: 'Carrello', stepFunnel: 'Applica modalità sostituzione' } },
    FUNNEL_DATA_CART_REPLACE_APPLY: { funnel: { nomeFunnel: 'Carrello', stepFunnel: 'Applica prodotto sostitutivo' } },
    FUNNEL_DATA_CART_REPLACE_ACTIVE: { funnel: { nomeFunnel: 'Carrello', stepFunnel: 'Attiva sostituzione' } },
    FUNNEL_DATA_CART_REPLACE_DETAIL: { funnel: { nomeFunnel: 'Carrello', stepFunnel: 'Dettaglio prodotto sostitutivo' } },
    FUNNEL_DATA_CART_REPLACE_CHANGE: { funnel: { nomeFunnel: 'Carrello', stepFunnel: 'Cambia prodotto sostitutivo' } },
    FUNNEL_DATA_CART_PRODUCT_CARD_ADD_TO_CART: { funnel: { nomeFunnel: 'Carrello', stepFunnel: 'Aggiunta al carrello', creazioneCarrello: '' } },
    FUNNEL_DATA_CART_PRODUCT_CARD_REMOVE_FROM_CART: { funnel: { nomeFunnel: 'Carrello', stepFunnel: 'Rimozione dal carrello' } },
    FUNNEL_DATA_CART_PRODUCT_CARD_ADD_FAVOURITE: { funnel: { nomeFunnel: 'Carrello', stepFunnel: 'Aggiunta ai preferiti' } },
    FUNNEL_DATA_CART_PRODUCT_CARD_REMOVE_FAVOURITE: { funnel: { nomeFunnel: 'Carrello', stepFunnel: 'Rimozione dai preferiti' } },
    FUNNEL_DATA_CART_PRODUCT_CARD_CLICK_PRODUCT: { funnel: { nomeFunnel: 'Carrello', stepFunnel: 'Click su prodotto' } },
    FUNNEL_DATA_CART_MODALITA_SOSTITUZIONE: { funnel: { nomeFunnel: 'Carrello', stepFunnel: 'CTA Scegli modalita di sostituzione' } },
    FUNNEL_DATA_CART_POPUP_MODALITA_SOSTITUZIONE: { funnel: { nomeFunnel: 'Carrello', stepFunnel: 'Popup modalita di sostituzione' } },
    FUNNEL_DATA_CART_CTA_MODIFICA_MODALITA_SOSTITUZIONE: { funnel: { nomeFunnel: 'Carrello', stepFunnel: 'CTA Modifica modalita di sostituzione' } },
    FUNNEL_DATA_HOME: { funnel: { nomeFunnel: 'Homepage', stepFunnel: 'Homepage' } },
    FUNNEL_DATA_HOME_ADD_TO_CART: { funnel: { nomeFunnel: 'Homepage', stepFunnel: 'Aggiunta al carrello', creazioneCarrello: '' } },
    FUNNEL_DATA_HOME_REMOVE_FROM_CART: { funnel: { nomeFunnel: 'Homepage', stepFunnel: 'Rimozione dal carrello' } },
    FUNNEL_DATA_HOME_ADD_FAVOUR: { funnel: { nomeFunnel: 'Homepage', stepFunnel: 'Aggiunta ai preferiti' } },
    FUNNEL_DATA_HOME_REMOVE_FAVOUR: { funnel: { nomeFunnel: 'Homepage', stepFunnel: 'Rimozione dai preferiti' } },
    FUNNEL_DATA_HOME_CLICK_PRODUCT: { funnel: { nomeFunnel: 'Homepage', stepFunnel: 'Click su prodotto' } },
    FUNNEL_DATA_OFFERS: { funnel: { nomeFunnel: 'Offerte e promozioni', stepFunnel: '<Indice | Bassi e fissi | Carte Conad | Sotto costo>' } },
    FUNNEL_DATA_CHECKOUT: { funnel: { nomeFunnel: 'Checkout', stepFunnel: 'Accesso checkout utente guest' } },
    FUNNEL_DATA_CHECKOUT_CLICK_PAGA_ONLINE: { funnel: { nomeFunnel: 'Checkout', stepFunnel: 'Click paga online' } },
    FUNNEL_DATA_CHECKOUT_CLICK_PAGA_IN_NEGOZIO: { funnel: { nomeFunnel: 'Checkout', stepFunnel: 'Click paga in negozio' } },
    FUNNEL_DATA_CHECKOUT_SEND_CODE: { funnel: { nomeFunnel: 'Checkout', stepFunnel: 'Invia codice OTP' } },
    FUNNEL_DATA_CHECKOUT_FORM_ERROR: { funnel: { nomeFunnel: 'Checkout', stepFunnel: 'Accesso checkout utente guest' } },
    FUNNEL_DATA_CHECKOUT_RESEND_CODE: { funnel: { nomeFunnel: 'Checkout', stepFunnel: 'Invia nuovo codice OTP' } },
    FUNNEL_DATA_CHECKOUT_CHECK_CODE: { funnel: { nomeFunnel: 'Checkout', stepFunnel: 'Verifica codice OTP' } },
    FUNNEL_DATA_CHECKOUT_PAGEVIEW: { funnel: { nomeFunnel: 'Checkout', stepFunnel: 'Visualizzazione checkout' } },
    FUNNEL_DATA_CHECKOUT_PAGEVIEW_STEP2: { funnel: { nomeFunnel: 'Checkout', stepFunnel: 'Visualizzazione checkout - Step 2 pagamento' } },
    FUNNEL_DATA_CHECKOUT_PAGEVIEW_HD: { funnel: { nomeFunnel: 'Checkout', stepFunnel: 'Visualizzazione checkout - Step 1 dati consegna' } },
    FUNNEL_DATA_CHECKOUT_PAGEVIEW_OC: { funnel: { nomeFunnel: 'Checkout', stepFunnel: 'Visualizzazione checkout - Step 1 dati ritiro' } },
    FUNNEL_DATA_CHECKOUT_SAVE_ADDRESS: { funnel: { nomeFunnel: 'Checkout', stepFunnel: 'Salva indirizzo di consegna' } },
    FUNNEL_DATA_CHECKOUT_SAVE_CONTACT_DETAILS: { funnel: { nomeFunnel: 'Checkout', stepFunnel: 'Salva dati di contatto' } },
    FUNNEL_DATA_CHECKOUT_BOOK_SLOT: { funnel: { nomeFunnel: 'Checkout', stepFunnel: 'Prenota slot' } },
    FUNNEL_DATA_CHECKOUT_SELECT_PAYMENT: { funnel: { nomeFunnel: 'Checkout', stepFunnel: 'Seleziona metodo di pagamento' } },
    FUNNEL_DATA_CHECKOUT_SELECT_PAYMENT_PAGEVIEW: { funnel: { nomeFunnel: 'Checkout', stepFunnel: 'Selezione metodo di pagamento' } },
    FUNNEL_DATA_CHECKOUT_CLOSE_MODAL: { funnel: { nomeFunnel: 'Checkout', stepFunnel: 'Modale lista indirizzi' } },
    FUNNEL_DATA_CHECKOUT_CHANGE_ADDRESS: { funnel: { nomeFunnel: 'Checkout', stepFunnel: 'CTA modifica indirizzo di consegna' } },
    FUNNEL_DATA_CHECKOUT_CHANGE_FROM_ADDRESS_BOOK: { funnel: { nomeFunnel: 'Checkout', stepFunnel: 'CTA modalità modifica indirizzo e anagrafica' } },
    FUNNEL_DATA_CHECKOUT_CHANGE_SLOT: { funnel: { nomeFunnel: 'Checkout', stepFunnel: 'CTA modifica slot di consegna' } },
    FUNNEL_DATA_CHECKOUT_CHANGE_BILLING_ADDRESS: { funnel: { nomeFunnel: 'Checkout', stepFunnel: 'CTA modifica indirizzo di fatturazione' } },
    FUNNEL_DATA_CHECKOUT_MODAL_ADDRESSES_LIST: { funnel: { nomeFunnel: 'Checkout', stepFunnel: 'Modale lista indirizzi' } },
    FUNNEL_DATA_CHECKOUT_ADD_ADDRESS: { funnel: { nomeFunnel: 'Checkout', stepFunnel: 'CTA aggiungi indirizzo' } },
    FUNNEL_DATA_CHECKOUT_SELECTED_ADDRESS: { funnel: { nomeFunnel: 'Checkout', stepFunnel: 'CTA scegli indirizzo selezionato' } },
    FUNNEL_DATA_CHECKOUT_MODAL_CHANGE_ADDRESS: { funnel: { nomeFunnel: 'Checkout', stepFunnel: 'Modale modifica indirizzo' } },
    FUNNEL_DATA_CHECKOUT_CTA_SAVE_ADDRESS: { funnel: { nomeFunnel: 'Checkout', stepFunnel: 'CTA salva indirizzo' } },
    FUNNEL_DATA_CHECKOUT_MODAL_ADD_ADDRESS: { funnel: { nomeFunnel: 'Checkout', stepFunnel: 'Modale aggiunta indirizzo' } },
    FUNNEL_DATA_CHECKOUT_SELECT_ADDRESS: { funnel: { nomeFunnel: 'Checkout', stepFunnel: 'CTA seleziona indirizzo' } },
    FUNNEL_DATA_CHECKOUT_MODAL_SELECT_SLOT: { funnel: { nomeFunnel: 'Checkout', stepFunnel: 'Modale scelta slot' } },
    FUNNEL_DATA_CHECKOUT_SELECT_SLOT: { funnel: { nomeFunnel: 'Checkout', stepFunnel: 'CTA seleziona slot' } },
    FUNNEL_DATA_CHECKOUT_MODAL_FATT_ELETTR: { funnel: { nomeFunnel: 'Checkout', stepFunnel: 'Modale fattura elettronica' }},
    FUNNEL_DATA_CHECKOUT_MODAL_FATT_ELETTR_SAVE: { funnel: { nomeFunnel: 'Checkout', stepFunnel: 'CTA salva dati fattura elettronica'}},
    FUNNEL_DATA_CHECKOUT_FLAG_FATT_ELETTR: { funnel: { nomeFunnel: 'Checkout', stepFunnel: 'Flag richiesta fattura elettronica'}},
    FUNNEL_DATA_CHECKOUT_COUPON_APPLY: { funnel: { nomeFunnel: 'Checkout', stepFunnel: 'CTA applica coupon'}},
    FUNNEL_DATA_CHECKOUT_COUPON_REMOVE: { funnel: { nomeFunnel: 'Checkout', stepFunnel: 'CTA rimuovi coupon'}},
    FUNNEL_DATA_CHECKOUT_FORM_CONSEGNA_RITIRO_ERROR: {funnel: { nomeFunnel: 'Checkout', stepFunnel: 'Inserimento dati di consegna'}},
    FUNNEL_DATA_CHECKOUT_CONFERMA_PAGA_IN_NEGOZIO: {funnel: { nomeFunnel: 'Checkout', stepFunnel: 'Conferma pagamento in negozio'}},
    FUNNEL_DATA_CHECKOUT_POPUP_LISTA_PRODOTTI: {funnel: { nomeFunnel: 'Checkout', stepFunnel: 'Popup lista prodotti'}},
    FUNNEL_DATA_CHECKOUT_POPUP_PRENOTAZIONE_SCADUTA: {funnel: { nomeFunnel: 'Checkout', stepFunnel: 'Popup prenotazione scaduta'}},
    FUNNEL_DATA_CHECKOUT_ALERT_COUPON: { funnel: { nomeFunnel: 'Checkout', stepFunnel: 'Visualizzazione alert coupon' } },
    FUNNEL_DATA_CHECKOUT_SECTION_COUPON_ESPANDI: {funnel: { nomeFunnel: 'Checkout', stepFunnel: 'Espandi coupon'}},
    FUNNEL_DATA_CHECKOUT_POPUP_QUANTITA_AGGIORNATA: {funnel: {nomeFunnel: 'Checkout', stepFunnel: 'Popup quantità prodotti aggiornata'}},
    FUNNEL_DATA_CHECKOUT_POPUP_QUANTITA_AGGIORNATA_BACK_CARRELLO: {funnel: {nomeFunnel: 'Checkout', stepFunnel: 'Torna al carrello per aggiornamento prodotti'}},
    FUNNEL_DATA_CHECKOUT_POPUP_QUANTITA_AGGIORNATA_CHIUDI: {funnel: {nomeFunnel: 'Checkout', stepFunnel: 'Chiudi Popup aggiornamento prodotti'}},
    FUNNEL_DATA_DET_PROD_ADD_TO_CART: { funnel: { nomeFunnel: 'Scheda prodotto', stepFunnel: 'Aggiunta al carrello', creazioneCarrello: ''}},
    FUNNEL_DATA_DET_PROD_REMOVE_FROM_CART: { funnel: { nomeFunnel: 'Scheda prodotto', stepFunnel: 'Rimozione dal carrello'}},
    FUNNEL_DATA_DET_PROD_ADD_FAVOURITE: { funnel: { nomeFunnel: 'Scheda prodotto', stepFunnel: 'Aggiunta ai preferiti'}},
    FUNNEL_DATA_DET_PROD_REMOVE_FAVOURITE: { funnel: { nomeFunnel: 'Scheda prodotto', stepFunnel: 'Rimozione dai preferiti'}},
    FUNNEL_DATA_DET_PROD_CLICK_PRODUCT: { funnel: { nomeFunnel: 'Scheda prodotto', stepFunnel: 'Click su prodotto'}},
    FUNNEL_DATA_404 : { funnel: { nomeFunnel: 'Pagina di errore', stepFunnel: 'Pagina non trovata'}},
    FUNNEL_DATA_PROD_PREF_ADD_TO_CART: { funnel: { nomeFunnel: 'Prodotti preferiti', stepFunnel: 'Aggiunta al carrello', creazioneCarrello: ''}},
    FUNNEL_DATA_CTA_ACCEDI : { funnel: { nomeFunnel: 'Accesso', stepFunnel: 'CTA Accedi'}},
    FUNNEL_DATA_CTA_REGISTRATI : { funnel: { nomeFunnel: 'Accesso', stepFunnel: 'CTA Registrati'}},
    FUNNEL_DATA_CLICK_PROFILO : { funnel: { nomeFunnel: 'Menu Personale', stepFunnel: 'Apertura'}},
    FUNNEL_DATA_PAGINA_SUPPORTO_PAGEVIEW : { funnel: { nomeFunnel: 'Supporto', stepFunnel: 'Atterraggio pagina richiesta informazioni'}},
    FUNNEL_DATA_PAGINA_SUPPORTO_CLICK_RICHIESTA_INFO : { funnel: { nomeFunnel: 'Supporto', stepFunnel: 'Click selezione richiesta informazioni'}},
    FUNNEL_DATA_MODALE_RICHIESTA_INFORMAZIONI : { funnel: { nomeFunnel: 'Supporto', stepFunnel: 'Modale richiesta informazioni'}},
    FUNNEL_DATA_MODALE_RICHIESTA_INFORMAZIONI_INVIO_RICHIESTA : { funnel: { nomeFunnel: 'Supporto', stepFunnel: 'Click invia richiesta informazioni'}},
    FUNNEL_DATA_MODALE_SUCCESS_REQUEST : { funnel: { nomeFunnel: 'Supporto', stepFunnel: 'Richiesta inviata con successo'}},
    FUNNEL_DATA_MODALE_SUCCESS_CHIUSURA : { funnel: { nomeFunnel: 'Supporto', stepFunnel: 'Chiusura banner richiesta inviata con successo'}},
    FUNNEL_DATA_RISOLUZIONE_PROBLEMI : { funnel: { nomeFunnel: 'Supporto', stepFunnel: 'Atterraggio pagina aiuto risoluzione problemi'}},
    FUNNEL_DATA_RISOLUZIONE_PROBLEMI_RICERCA : { funnel: { nomeFunnel: 'Supporto', stepFunnel: 'Click ricerca problema'}},
    FUNNEL_DATA_RISOLUZIONE_PROBLEMI_RISPOSTA_CATEGORIA : { funnel: { nomeFunnel: 'Supporto', stepFunnel: 'Click risposta categoria'}},
    FUNNEL_DATA_RISOLUZIONE_PROBLEMI_CLICK_BANNER : { funnel: { nomeFunnel: 'Supporto', stepFunnel: 'Click banner contatto'}},
    FUNNEL_DATA_BANNER_BLACKLIST : { funnel: { nomeFunnel: 'Blacklist', stepFunnel: 'Comparsa banner Blacklist'}},
    FUNNEL_DATA_BANNER_BLACKLIST_CLICK_CONTATTACI : { funnel: { nomeFunnel: 'Blacklist', stepFunnel: 'Click contattaci su banner blacklist'}},
    FUNNEL_DATA_MODALE_RICHIESTA_INFORMAZIONI_GENERICA : { funnel: { nomeFunnel: 'Form Generico', stepFunnel: 'Comparsa form generico'}},
    FUNNEL_DATA_MODALE_RICHIESTA_INFORMAZIONI_GENERICA_INVIO_RICHIESTA : { funnel: { nomeFunnel: 'Form Generico', stepFunnel: 'Invia richiesta form generico'}},
    FUNNEL_DATA_CLICK_ACCORDION_INFO_PRENOTAZIONE_SLOT: { funnel: { nomeFunnel: 'Accordion prenotazione slot', stepFunnel: 'Click accordion info prenotazione slot' } },
    FUNNEL_DATA_MODALE_RICHIESTA_AGEVOLAZIONE : { funnel: { nomeFunnel: 'Agevolazione over 70', stepFunnel: 'Apertura form agevolazione over 70'}},
    FUNNEL_DATA_MODALE_RICHIESTA_AGEVOLAZIONE_CLICK_INVIA_RICHIESTA : { funnel: { nomeFunnel: 'Agevolazione over 70', stepFunnel: 'Click invia richiesta agevolazione over 70'}},
    FUNNEL_DATA_MODALE_RICHIESTA_AGEVOLAZIONE_SUCCESS_REQUEST : { funnel: { nomeFunnel: 'Agevolazione over 70', stepFunnel: 'Banner richiesta over 70 OK'}},
    FUNNEL_DATA_MODALE_RICHIESTA_AGEVOLAZIONE_SUCCESS_CHIUSURA : { funnel: { nomeFunnel: 'Agevolazione over 70', stepFunnel: 'Click chiudi banner richiesta over 70 OK'}},
    FUNNEL_DATA_MODALE_RICHIESTA_AGEVOLAZIONE_KO_REQUEST : { funnel: { nomeFunnel: 'Agevolazione over 70', stepFunnel: 'Banner richiesta over 70 KO'}},
    FUNNEL_DATA_MODALE_RICHIESTA_AGEVOLAZIONE_KO_CHIUSURA : { funnel: { nomeFunnel: 'Agevolazione over 70', stepFunnel: 'Click chiudi banner richiesta over 70 KO'}},
    FUNNEL_DATA_MODALE_RICHIESTA_AGEVOLAZIONE_ERRORE_FORM : { funnel: { nomeFunnel: 'Agevolazione over 70', stepFunnel: 'Errore compilazione form over 70'}},
    FUNNEL_DATA_CONFERMA_IDENTITA : { funnel: { nomeFunnel: 'Conferma Identita', stepFunnel: 'Banner conferma identita'}},
    FUNNEL_DATA_CLICK_CONFERMA_IDENTITA : { funnel: { nomeFunnel: 'Conferma Identita', stepFunnel: 'Click conferma identita'}},
    FUNNEL_DATA_CLICK_OPZIONI_FOOTER : { funnel: { nomeFunnel: 'Footer', stepFunnel: 'Click opzioni footer'}},
    FUNNEL_DATA_SELEZIONE_CATEGORIA : { funnel: { nomeFunnel: 'Risultati ricerca', stepFunnel: 'Selezione categoria'}},
    FUNNEL_DATA_STEP_1B_SCELTA_SERVIZIO : { funnel: { nomeFunnel: 'EntryPage', stepFunnel: 'Step 1B - Scelta servizio'}},
    FUNNEL_DATA_FORM_ESTENSIONE_SERVIZIO : { funnel: { nomeFunnel: 'EntryPage', stepFunnel: 'Form richiesta estensione servizio'}},
    FUNNEL_DATA_PRENOTA_SLOT_PREHEADER : {funnel: { nomeFunnel: 'EntryPage', stepFunnel: 'CTA prenota slot di consegna preheader'}},
    FUNNEL_DATA_MODIFICA_SLOT_PREHEADER : {funnel: { nomeFunnel: 'EntryPage', stepFunnel: 'CTA modifica slot di consegna preheader'}},
    FUNNEL_DATA_MODIFICA_SERVIZIO_PREHEADER : {funnel: { nomeFunnel: 'EntryPage', stepFunnel: 'CTA modifica servizio preheader'}},
    FUNNEL_DATA_MODIFICA_INDIRIZZO_PREHEADER : {funnel: { nomeFunnel: 'EntryPage', stepFunnel: 'CTA modifica indirizzo preheader'}},
    FUNNEL_DATA_CTA_ACCEDI_CENTRALE : { funnel: { nomeFunnel: 'Accesso', stepFunnel: 'CTA Accedi Centrale'}},
    FUNNEL_DATA_STEP_1C_COMPLETA_INDIRIZZO : { funnel: { nomeFunnel: 'EntryPage', stepFunnel: 'Step 1C - Completa indirizzo'}},
    FUNNEL_DATA_LISTA_PROVINCE : { funnel: { nomeFunnel: 'Copertura Servizio', stepFunnel: 'Lista regioni'}},
    FUNNEL_LANDING_IDM: { funnel: { nomeFunnel: 'Landing IDM', stepFunnel: 'Landing IDM'}},
    FUNNEL_PAGINA_PARTNER: { funnel: { nomeFunnel: 'Partner', stepFunnel: 'Partner'}},
    FUNNEL_PAGINA_MARCHI: { funnel: { nomeFunnel: 'Marchi', stepFunnel: 'Marchi'}},
    FUNNEL_LANDING_PROMO_PER_TE: { funnel: { nomeFunnel: 'Promo per te', stepFunnel: 'Promo per te'}}
}
