import { _$, _Uikit, Window } from '../../main';
import { LOADER_POSITION, Utils } from '../../services/utils/utils';
import { CartService } from "src/services/cart-service/cart-service";
import Swiper, { SwiperOptions } from 'swiper';
import { Analytics } from '../../services/analytics/analytics';
import { GoogleUtils } from '../../services/utils/google-utils';
import { BOOKING_SLOT_STATE, BookUtils } from '../../services/utils/book-utils';
import { ModalDetailProduct } from '../../components/modal-detail-product/modal-detail-product';
import { LocalStorage } from 'src/services/local-storage/local-storage';
import { LockerUtils } from '../../services/utils/locker-utils';
import { SwiperConfigInitializer } from '../../services/utils/swiper-config-initializer';

export class DetailCart {
    swiperSuggestedProductsOptions: SwiperOptions = {
        slidesPerView: 1.01,
        spaceBetween: 15,
        freeMode: true,
        scrollbar: {
            el: '.scrollbar-suggested',
            draggable: true,
            hide: false,
        },
       /*  navigation: {
            nextEl: '.next-suggested',
            prevEl: '.prev-suggested',
        }, */
        pagination: {
            el: '.pagination-suggested',
            type: 'custom',
            renderCustom: function (swiper, current, total) {
                let slideView;
                if (_$(window).width() < 768) {
                    slideView = swiper.params.breakpoints['375'].slidesPerView;
                } else if (_$(window).width() < 1024){
                    slideView = swiper.params.breakpoints['768'].slidesPerView;
                } else if (_$(window).width() < 1440){
                    slideView = swiper.params.breakpoints['1024'].slidesPerView;
                } else {
                    slideView = swiper.params.breakpoints['1440'].slidesPerView;
                }
                return Math.ceil((current+slideView-1)/slideView) + ' di ' + Math.ceil((total+slideView-1)/slideView);
            },
        },
        breakpointsInverse: true,
        breakpoints: {
            375: {
                slidesPerView: 1.21,
                spaceBetween: 15,
            },
            768: {
                slidesPerView: 3.1,
                spaceBetween: 15,
                /* slidesPerGroup: 3, */
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 16,
                /* slidesPerGroup: 4, */
            },
            1440: {
                slidesPerView: 4,
                spaceBetween: 24,
                /* slidesPerGroup: 4, */
            },
        },
    };
    swiperSuggestedProducts = new Swiper('.swiper-suggested-products', this.swiperSuggestedProductsOptions);

    swiperFavoritesOptions: SwiperOptions = {
        slidesPerView: 1.01,
        spaceBetween: 15,
        freeMode: true,
        scrollbar: {
            el: '.scrollbar-favorites',
            draggable: true,
            hide: false,
        },
        /* navigation: {
            nextEl: '.next-favorites',
            prevEl: '.prev-favorites',
        }, */
        pagination: {
            el: '.pagination-favorites',
            type: 'custom',
            renderCustom: function (swiper, current, total) {
                let slideView;
                if (_$(window).width() < 768) {
                    slideView = swiper.params.breakpoints['375'].slidesPerView;
                } else if (_$(window).width() < 1024){
                    slideView = swiper.params.breakpoints['768'].slidesPerView;
                } else if (_$(window).width() < 1440){
                    slideView = swiper.params.breakpoints['1024'].slidesPerView;
                } else {
                    slideView = swiper.params.breakpoints['1440'].slidesPerView;
                }
                return Math.ceil((current+slideView-1)/slideView) + ' di ' + Math.ceil((total+slideView-1)/slideView);
            },
        },
        breakpointsInverse: true,
        breakpoints: {
            375: {
                slidesPerView: 1.21,
                spaceBetween: 15,
            },
            768: {
                slidesPerView: 3.1,
                spaceBetween: 15,
                /* slidesPerGroup: 3, */
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 16,
                /* slidesPerGroup: 4, */
            },
            1440: {
                slidesPerView: 4,
                spaceBetween: 24,
                /* slidesPerGroup: 4, */
            },
        },
    };
    swiperFavorites = new Swiper('.swiper-favorites', this.swiperFavoritesOptions);

    swiperLastViewsOptions: SwiperOptions = {
        slidesPerView: 1.01,
        spaceBetween: 15,
        freeMode: true,
        scrollbar: {
            el: '.scrollbar-last',
            draggable: true,
            hide: false,
        },
        /* navigation: {
            nextEl: '.next-last',
            prevEl: '.prev-last',
        }, */
        pagination: {
            el: '.pagination-last',
            type: 'custom',
            renderCustom: function (swiper, current, total) {
                let slideView;
                if (_$(window).width() < 768) {
                    slideView = swiper.params.breakpoints['375'].slidesPerView;
                } else if (_$(window).width() < 1024){
                    slideView = swiper.params.breakpoints['768'].slidesPerView;
                } else if (_$(window).width() < 1440){
                    slideView = swiper.params.breakpoints['1024'].slidesPerView;
                } else {
                    slideView = swiper.params.breakpoints['1440'].slidesPerView;
                }
                return Math.ceil((current+slideView-1)/slideView) + ' di ' + Math.ceil((total+slideView-1)/slideView);
            },
        },
        breakpointsInverse: true,
        breakpoints: {
            375: {
                slidesPerView: 1.21,
                spaceBetween: 15,
            },
            768: {
                slidesPerView: 2.4,
                spaceBetween: 15,
               /*  slidesPerGroup: 2, */
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 16,
                /* slidesPerGroup: 3, */
            },
            1440: {
                slidesPerView: 3,
                spaceBetween: 24,
                /* slidesPerGroup: 3, */
            },
        },
    };
    swiperLastViews = new Swiper('.swiper-last-views', this.swiperLastViewsOptions);

    swiperSostitutiOptions: SwiperOptions = {
        slidesPerView: 'auto',
        spaceBetween: 15,
        freeMode: true,
        scrollbar: {
            el: '.scrollbar-sostituti',
            draggable: true,
            hide: false,
        },
        navigation: {
            nextEl: '.next-sostituti',
            prevEl: '.prev-sostituti',
        },
        breakpointsInverse: true,
        breakpoints: {
            1024: {
                spaceBetween: 16,
            },
        },
        observer: true,
        observeParents: true,
    };

    swiperPromoListOptions: SwiperOptions = {
        slidesPerView: 'auto',
        spaceBetween: 9,
        freeMode: true,
        navigation: {
            nextEl: '.next-promo-list',
            prevEl: '.prev-promo-list',
        },
        breakpointsInverse: true,
        observer: true,
        observeParents: true,
    };
    swiperPromoList = new Swiper('.swiper-promo-list', this.swiperPromoListOptions);

    swiperCardConsegnaGratuitaOption: SwiperOptions = {
        mousewheel: {
            invert: true,
            releaseOnEdges: true,
            forceToAxis: true,
        },
        slidesPerView: 1,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        on: {
            init: function () {
                if (_$('.swiper-card-consegna-gratuita .swiper-slide').length == 1) {
                    _$('.swiper-card-consegna-gratuita .swiper-pagination').addClass('uk-hidden');
                }
            }
        }
    };

    private currentElement: Object;
    private _urlEdit: boolean;
    private _typeOfService;
    private _pdvBusinessName;
    private _pdvAddress;
    private preselectedSub;
    private lockerOversizeProducts;

    constructor(
        private _utils: Utils,
        private _cartService: CartService,
        private _analytics: Analytics,
        private _google: GoogleUtils,
        private _modalDetailProduct: ModalDetailProduct,
        private localStorage: LocalStorage,
        private _bookUtils: BookUtils,
        private _lockerUtils: LockerUtils,
        private _swiperConfigInitializer: SwiperConfigInitializer
    ) {}

    nkOnInit() {

        if(_$('.swiper-card-consegna-gratuita').length){
            let swiperCardConsegnaGratuita = new Swiper('.swiper-card-consegna-gratuita', this._swiperConfigInitializer.initConfig(['autoplay'],_$('.swiper-card-consegna-gratuita'), this.swiperCardConsegnaGratuitaOption));
        }
        this.showSostitutiListener();
        this.updateBadges();
        this._utils.detectQuantityChange();
        this.sendAnalytics('pageview');
        if(this.getUpdatedCartValue() === 'true' && _$('.component-ProductCardDetailCart')){
            if(_$('.component-ProductCardDetailCart').length){
                _Uikit.modal('#ricalcolo-carrello-cart').show();
            }
        }

        if(_$('.primary-panel').attr('data-replacement-cart')){
            this.applyReplaceMode(_$('.primary-panel'), _$('.primary-panel').attr('data-replacement-cart'));
        }

        if(Window.pointOfService && Window.pointOfService.substitutionModes != null && Window.pointOfService.substitutionModes.length){
            if(Window.pointOfService.substitutionModes.indexOf('PUNCTUAL_SUBSTITUTION') < 0)
                _$('#modal-opzioni-sostituzione').find('.substitution-option[data-sub-value="PUNCTUAL_SUBSTITUTION"]').addClass('uk-hidden');

            if(Window.pointOfService.substitutionModes.indexOf('AUTOMATIC_SUBSTITUTION') < 0)
                _$('#modal-opzioni-sostituzione').find('.substitution-option[data-sub-value="AUTOMATIC_SUBSTITUTION"]').addClass('uk-hidden');

            if(Window.pointOfService.substitutionModes.indexOf('SEMI_AUTOMATIC_SUBSTITUTION') < 0)
                _$('#modal-opzioni-sostituzione').find('.substitution-option[data-sub-value="SEMI_AUTOMATIC_SUBSTITUTION"]').addClass('uk-hidden'); 
        }

        /* Custom trigger per ricaricare il carrello */
        _$('.primary-panel').on('reloadCart', () => {
            this.reloadCart();
        });

        /* Check alert pesanti al caricamento*/
        if(Window.volume){
            if(Window.volume.cartVolume >= Window.volume.maxVolume){
                if(_$('#heavy-limit-cart-mobile') && _$('#heavy-limit-cart-desktop')){
                    _$('#heavy-limit-cart-mobile, #heavy-limit-cart-desktop').removeClass('uk-hidden');
                }
            }else if(Window.volume.cartVolume >= Window.volume.maxVolumeNoSurcharge){
                if(_$('#heavy-surcharge-cart-mobile') && _$('#heavy-surcharge-cart-desktop')){
                    _$('#heavy-surcharge-cart-mobile, #heavy-surcharge-cart-desktop').removeClass('uk-hidden');
                }
            }
        }
        _$('#offcanvas-repl-mode').on('show', () => { 
            _$('#offcanvas-repl-mode .uk-button-primary').attr('disabled','');
            this.preselectedSub = _$('#offcanvas-repl-mode .uk-radio:checked').attr('value').toString();
        });
        for (let i = 0; i < _$('.next-suggested').length; i++) {
            _$('.next-suggested')[i].setAttribute('onclick','DetailCart.mySlideNext("suggested",this)');
            _$('.prev-suggested')[i].setAttribute('onclick','DetailCart.mySlidePrev("suggested",this)');            
        }
        for (let i = 0; i < _$('.next-favorites').length; i++) {
            _$('.next-favorites')[i].setAttribute('onclick','DetailCart.mySlideNext("favorite",this)');
            _$('.prev-favorites')[i].setAttribute('onclick','DetailCart.mySlidePrev("favorite",this)');            
        }
        for (let i = 0; i < _$('.next-last').length; i++) {
            _$('.next-last')[i].setAttribute('onclick','DetailCart.mySlideNext("last",this)');
            _$('.prev-last')[i].setAttribute('onclick','DetailCart.mySlidePrev("last",this)');            
        }

        /* Se siamo nel flusso di modifica non è necessario verificare le disponibilità dei timeslot */
        if(Window.cart && !Window.cart.parentOrder){
            this.checkDisponibilitaTimeslot();
        }

        _$('.component-ProductCardDetailCart').find('.quantity .select-native-ui select').on('touchstart focus', (event) => {
            this.initProductCardSelects(_$(event.target).closest('.quantity').find('.select-native-ui'));
        });

        _$(() => {
            if(_$('.quantity-format').length){
                this._utils.initSingleCustomScrollbar(_$('.quantity-format'));
            }
        });

        if(_$('#alert-scelta-carrello-myconad').length){
            setTimeout(() => {
                _$('#alert-scelta-carrello-myconad').addClass('uk-hidden');
            }, 15000)
        }

        _$('#modal-opzioni-sostituzione').on('beforeshow', () => {
            _$('#modal-opzioni-sostituzione').find(`.substitution-option[data-sub-value="${_$('.primary-panel').attr('data-replacement-cart')}"]`).trigger('click');
        });

        this.setReplacementModeAnalytics();

        if(_$('#modal-opzioni-sostituzione').length){
            _$('#modal-opzioni-sostituzione').on('show', () => {
                this.sendAnalytics('pageviewPopupModalitaSostituzione');
            });

            _$('#modal-opzioni-sostituzione').find('.active-text a').on('click', () => {
                this.sendAnalytics('annullaModalitaSostituzione');
            });

            _$('#modal-opzioni-sostituzione').on('hide', () => {
                this.sendAnalytics('chiusuraModaleModalitaSostituzione');
            })
        }

        if(_$('.warning-aggiungi-prodotti').length){
            setTimeout(() => {
                _$('.warning-aggiungi-prodotti .tooltiptext-aggiungi-prodotti').prev().trigger('click');
            }, 100);
        }

        /* check volume in eccesso al caricamento */
        let exceedingVolume = Window.volume.cartVolume - Window.volume.maxVolume;
        if(exceedingVolume > 0){
            _$('#alert-limit-exceeding-liters').removeClass('uk-hidden');
            _$('#exceeding-liters-data').html(exceedingVolume.toString());
            _$('.card-scontrino-bottom-footer .uk-button').replaceWith('<button type="button" class="uk-button uk-button-primary" disabled>Vai alla cassa - Non disponibile</button>');
        }
    }

    async managePreferiteProductSendAnalytics (e) {
        try {
            //await this._utils.managePreferiteProduct(e, 'offcanvas-detail');
        } catch (error) {
            console.log(error);
        } 
    }    

    checkDisponibilitaTimeslot(){
        if(Window.pointOfService){
            if(!Window.pointOfService.firstAvailableTimeSlot && Window.selectedTimeslot == null) {
                console.log('no available timeslot');
                this._typeOfService = Window.typeOfService == "HOME_DELIVERY" ? 'la consegna' : 'il ritiro';
                this._pdvBusinessName = Window.pointOfService.businessName ? Window.pointOfService.businessName : '';
                this._pdvAddress = Window.pointOfService.address.formattedAddress ? Window.pointOfService.address.formattedAddress : '';
                _$('#alert-disponibilità-servizio').find('span[nkModel="DetailCart._typeOfService"]').text(this._typeOfService);
                _$('#alert-disponibilità-servizio').find('span[nkModel="DetailCart._pdvBusinessName"]').text(this._pdvBusinessName);
                _$('#alert-disponibilità-servizio').find('span[nkModel="DetailCart._pdvAddress"]').text(this._pdvAddress);
                _$('#alert-disponibilità-servizio').removeClass('uk-hidden');
                _$('.card-scontrino-bottom-footer .uk-button').replaceWith('<button type="button" class="uk-button uk-button-primary" disabled>Vai alla cassa - Non disponibile</button>')
            } else {
                console.log('available timeslot');
            }
            
        }
    }

    getUpdatedCartValue() {
        var results = new RegExp('[?&]updatedCart=([^&#]*)').exec(window.location.href);
        if (results == null) {
            return null;
        } else {
            return decodeURI(results[1]) || 0;
        }
    }

    updateBadges() {
        if(_$('.cart .component-ProductCardDetailCart:visible').length){
            _$('.selected-products .number b').html(_$('.cart .component-ProductCardDetailCart:visible').length.toString());
            if (_$('.selected-products .number b').html() == '1') {
                _$('.selected-products .text').html('<b>prodotto</b> aggiunto');
            } else {
                _$('.selected-products .text').html('<b>prodotti</b> aggiunti');
            }
        }
        
    }

    /* applica la nota di preparazione */
    applyOption(e: object, reset?: boolean) {
        let choice;
        let dataProduct = JSON.parse(_$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-product'));
        /* mobile */
        if (_$(e).closest('.filter').hasClass('extra-option')) {
            choice = _$(e).closest('.filter').find('input:checked').siblings('span').html();
        
        /* desktop */
        } else {

            if(_$(e).closest('.filter').find('input:checked').siblings('span').length){
                choice = _$(e).closest('.filter').find('input:checked').next().text();
            }else{
                choice = _$(e).closest('.filter').find('input:checked').next().prop('value');
            }
        }

        if(reset){
            choice = '';
        }

        let success = (res) => {
            if (_$(e).closest('.filter').hasClass('formato')) {
                _$(e).closest('.filter').find('.choice').html(choice);
                _$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-formato', choice);
                this.toggleOpenAccordion();
            } else if (_$(e).closest('.filter').hasClass('extra-option')) {
                _$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-extra-option', choice);
                this.toggleOpenAccordion();

            } else {
                if(_$(e).closest('.filter').find('input:checked').siblings('span').length){
                    _$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-extra-option', choice);
                }else{
                    _$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-extra-option', choice);
                }
                if (_$(this.currentElement).is('a.placeholder')) {
                    _$(this.currentElement).addClass('uk-hidden');
                    this.currentElement = _$(this.currentElement).siblings('.extra-option');
                    _$(this.currentElement).find('span').html(choice);
                    _$(this.currentElement).removeClass('uk-hidden');
                }
                _$(this.currentElement).find('span').html(choice);
            }

            _$(e).closest('.filter').addClass('enabled-filter');
            _Uikit.offcanvas('#note-preparazione-offcanvas').hide();
            _Uikit.modal('#note-preparazione-modal').hide();
            this.reloadCart();
            this.sendAnalytics('applicaNotePreparazione', _$(this.currentElement).closest('.component-ProductCardDetailCart'), choice);
        }

        let error = (err) => {
            console.log(err);
        }

        let body = {
            "productId": _$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-code'),
            "qty": _$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-quantity'),
            "entryNumber": _$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-entryNumber'),
            "preparationNote" : choice
        }

        if(_$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-scale')){
            body['scale'] = _$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-scale');
            body['weight'] = body['scale'];
        }else{
            if(dataProduct.netQuantityUm && dataProduct.netQuantityUm.toUpperCase() != "PZ"){
            body['weight'] = dataProduct.netQuantity ? dataProduct.netQuantity : 0;
            }
        }

        console.log(body);

        this._cartService.addRemoveItemToCart(success, error, body);
    }

    applyFormato(e: object){
        let choice = _$(e).closest('.filter').find('input:checked').siblings('span').html();
        this.updateScale(this.currentElement, choice);
    }

    loadOptions(e: object) {
        this.getPreparationNotes(JSON.parse(_$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-product')).entries[0].entryNumber);
    }

    openOffcanvasDetail(e: object, prodottoOmaggio?: boolean) {
        let link;
        if(prodottoOmaggio){
            link = _$('.primary-panel').attr('data-product-modal').toString()+ '?product=' + _$(e).attr('data-code');
        }else{
            link =  _$('.primary-panel').attr('data-product-modal').toString()+ '?product=' + _$(e).attr('data-code');
        }
        if(_$('#offcanvas-detail-product')){
            _$('#offcanvas-detail-product').remove();
        }
        if(prodottoOmaggio){
            link = link + '&giftedProduct=true'
        }
        let success = (res) => {
            this._utils.showLoader(_$('.main-content'), false, LOADER_POSITION.OFFCANVAS_BODY);
            res = res.replace(/>\s+</g,'><');
            let offcanvasDetail = _$.trim(res);
            _$('.main-content').after(offcanvasDetail);
            _Uikit.util.on('#offcanvas-detail-product', 'show', () => {
                //@ts-ignore
                this._modalDetailProduct.swiperModalDetailProduct = new Swiper('#offcanvas-detail-product .modal-swiper-product-detail-image', this._modalDetailProduct.swiperModalDetailProductParams);
                this._utils.initZoom('NZoomContainer', 'NZoomImg');
            });
            _Uikit.offcanvas('#offcanvas-detail-product').show();
            /* Appendo il data-code all'offcanvas per usarla nel managepreferiteproduct */
            _$('#offcanvas-detail-product').attr('data-code', _$(e).attr('data-code'));
            this.sendAnalytics('dettaglioSostituzione',_$('#offcanvas-detail-product'));
            this.sendAnalytics('pageviewProdReplace',_$('#offcanvas-detail-product'));
        }

        let error = (err) => {
            this._utils.showLoader(_$('.main-content'), false, LOADER_POSITION.OFFCANVAS_BODY);
            console.log(err);
            
        }
  
        this._utils.showLoader(_$('.main-content'), true, LOADER_POSITION.OFFCANVAS_BODY);
        this._cartService.getPageUpdate(success, error, link);
    }

    getImageZoomMobileModal(e: object){
        let productId;
        if(_$(e).closest('#offcanvas-detail-product').attr('data-product')){
            productId = JSON.parse(_$(e).closest('#offcanvas-detail-product').attr('data-product')).code;
        }
        let link =  _$('.primary-panel').attr('data-product-images-modal').toString()+ '?product=' + productId;

        if(_$('#modalProductImages')){
            _$('#modalProductImages').remove();
        }
        let success = (res) => {
            res = res.replace(/>\s+</g,'><');
            let modalProductImages = _$.trim(res);
            _$('.main-content').after(modalProductImages);
            _Uikit.util.on('#modalProductImages', 'show', () => {
                //@ts-ignore
                this._modalDetailProduct.swiperModalDetailProductImageMobile = new Swiper('#modalProductImages .swiper-product-detail-image-modal', this._modalDetailProduct.swiperModalDetailProductImageMobileParams);
                this._utils.initZoom('NZoomContainerMobile', 'NZoomImgMobile');
            });
            _Uikit.modal('#modalProductImages').show();
        }

        let error = (err) => {
            console.log('error loading detail images');
        }

        this._cartService.getPageUpdate(success, error, link);
    }

    activeElement(e: object) {
        this.currentElement = _$(e);
    }

    resetSingleFilter(e: object) {
        _$(e).closest('.enabled-filter').removeClass('enabled-filter');
        _$(e).closest('.filter').find('input:checked.uk-radio').prop('checked', false);
        _$(e).parent().parent().find(".free-note").val('');
        _$(e).parent().parent().find(".free-note").addClass('blank');
        this.disableSingleFilterButtons(_$(e).parent());
        this.applyOption(e, true);
    }

    disableSingleFilterButtons(e: object) {
        _$(e).find('.single-filter-reset').prop('disabled', true);
        _$(e).find('.single-filter-apply').prop('disabled', true);
    }

    enableSingleFilterButtons(e: object) {
        _$(e).find('.single-filter-reset').prop('disabled', false);
        _$(e).find('.single-filter-apply').prop('disabled', false);
        if(_$(event.target).next().hasClass('free-note')){
            //@ts-ignore
            if(_$(e).parent().find('.free-note').val().length < 1){
                this.disableSingleFilterButtons(_$(e).closest('.product-preparation-notes'));
                this.disableSingleFilterButtons(_$(e));
            }
        }
    }

    resetExtraOption(e: object) {
        _$(this.currentElement).siblings('.extra-option').html('');
        this.toggleOpenAccordion();
    }

    resetModal(e: object) {
        if (_$(this.currentElement).hasClass('extra-option')) {
            _$(this.currentElement).addClass('uk-hidden');
            _$(this.currentElement).find('span').html('');
            _$(this.currentElement).siblings('.placeholder').removeClass('uk-hidden');
            _$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-extra-option', '');
        }
    }

    toggleOpenAccordion() {
        let index = -1;
        _$('#note-preparazione-offcanvas li').each(function () {
            index++;
            if (_$(this).hasClass('uk-open')) {
                _Uikit.accordion(_$('#note-preparazione-offcanvas .list-accordion')).toggle(index, true);
            }
        });
    }

    switchDeleteButton(e: object) {
        if (_$(e).find('.checkmark').hasClass('selected')) {
            _$(e).parent().find('.delete-cart').addClass('uk-hidden');
            _$(e).parent().find('.delete-items').removeClass('uk-hidden');
        } else {
            _$(e).parent().find('.delete-cart').removeClass('uk-hidden');
            _$(e).parent().find('.delete-items').addClass('uk-hidden');
        }
    }

    updateSelectAll(e: object) {
        let currentSelected;
        let itemsTotal = _$('.cart .component-ProductCardDetailCart').length;

        if (_$(window).width() > 1023) {
            currentSelected = _$('.category-desktop-product').parent().find('.checkmark.selected').length;
        } else {
            currentSelected = _$('.category-mobile-product').parent().find('.checkmark.selected').length;
        }

        if (currentSelected == 1) {
            _$('.selected-products').html(
                `<div class="number"><b>${currentSelected.toString()}</b>&nbsp;</div><div class="text"><b>prodotto</b> selezionato</div>`
            );
        } else if (currentSelected == itemsTotal) {
            _$('.selected-products').html('<b>Tutti i prodotti</b>&nbsp;selezionati');
        } else {
            _$('.selected-products').html(
                `<div class="number"><b>${currentSelected.toString()}</b>&nbsp;</div><div class="text"><b>prodotti</b> selezionati</div>`
            );
        }
    }

    toggleDelete(e: object) {
        if (_$(e).find('.checkmark.selected').length) {
            _$('.cart:visible .uk-checkbox-custom.uk-hidden').removeClass('uk-hidden');
            _$('.cart .component-ProductCardDetailCart .uk-checkbox-custom input').prop('checked', true);
            _$('.selected-products').html('<b>Tutti i prodotti</b>&nbsp;selezionati');
        } else {
            let itemsTotal = _$('.cart:visible .component-ProductCardDetailCart').length;
            _$('.cart .component-ProductCardDetailCart .uk-checkbox-custom input').prop('checked', false);
            _$('.cart .component-ProductCardDetailCart .uk-checkbox-custom').addClass('uk-hidden');
            if (itemsTotal == 1){
                _$('.selected-products').html(
                    `<div class="number"><b>${itemsTotal.toString()}</b>&nbsp;</div><div class="text"><b>prodotto</b> aggiunto</div>`
                );
            }else{
                _$('.selected-products').html(
                    `<div class="number"><b>${itemsTotal.toString()}</b>&nbsp;</div><div class="text"><b>prodotti</b> aggiunti</div>`
                );
            }
        }

        this._utils.checkBox(_$('.cart:visible .component-ProductCardDetailCart'));
    }

    resetChoosenSub() {
        _$('#offcanvas-repl-mode .uk-radio.'+this.preselectedSub).prop('checked',true);
    }

    //SOSTITUZIONE
    applyReplaceMode(e: object, onloadChoice?: string) {
        
        if(!onloadChoice){
            /* Se applicata durante la navigazione in pagina */
            let success = async (res) => {
                let choice = _$('#modal-opzioni-sostituzione').find('.substitution-option.selected').attr('data-sub-value');
                if(['AUTOMATIC_SUBSTITUTION', 'PUNCTUAL_SUBSTITUTION', 'SEMI_AUTOMATIC_SUBSTITUTION'].indexOf(choice) > -1){
                    await this.reloadCart();
                }

                _$('.component-ReplacementMode .replacement-content-info').removeClass('replacement-no-substitution replacement-scelgo-io replacement-chiamami replacement-sceglie-conad');
                
                let productFooter = _$('.cart').find('.component-ProductCardDetailCart .subs');
                _$(productFooter).removeClass('automatic-substitution semi-automatic-substitution punctual-substitution no-substitute');
                if (choice === 'NO_SUBSTITUTION') {
                    productFooter.addClass('uk-hidden');
                    this.preselectedSub = 'no-replace';
                    _$('.component-ReplacementMode .replacement-content-info').addClass('replacement-no-substitution');
                } else {
                    productFooter.removeClass('uk-hidden');
                    if (choice == 'PUNCTUAL_SUBSTITUTION') {
                        _Uikit.modal(_$('#modal-sostituzione-scelgo-io')).show();
                        _$(productFooter).addClass('punctual-substitution');
                        _$('.component-ReplacementMode .replacement-content-info').addClass('replacement-scelgo-io');
                        this.preselectedSub = 'scelgo-io';
                    } else if (choice == 'AUTOMATIC_SUBSTITUTION') {
                        _Uikit.modal(_$('#modal-sostituzione-sceglie-conad')).show();
                        _$(productFooter).addClass('automatic-substitution');
                        _$('.component-ReplacementMode .replacement-content-info').addClass('replacement-sceglie-conad')
                        this.preselectedSub = 'sceglie-conad';
                    } else if (choice == 'SEMI_AUTOMATIC_SUBSTITUTION') {
                        _Uikit.modal(_$('#modal-sostituzione-chiamami')).show();
                        _$(productFooter).addClass('semi-automatic-substitution');
                        _$('.component-ReplacementMode .replacement-content-info').addClass('replacement-chiamami')
                        this.preselectedSub = 'chiamami';
                    }
                }
                
                this.sendAnalytics('modalitaSostituzione');
                _$('.primary-panel').attr('data-replacement-cart', choice);
                this._utils.showLoader('', false);
                _$(e).prop('disable', false);
                this.initSubstitutionToggles(_$('.primary-panel').attr('data-replacement-cart'));
            }
    
            let error = (err) => {
                console.error(err);
                this._utils.showLoader('', false);
                _$(e).prop('disable', false);
            }
    
            let params = {
                "replacementMode": _$('#modal-opzioni-sostituzione').find('.substitution-option.selected').attr('data-sub-value')
            }

            this._utils.showLoader('', true);
            _$(e).prop('disable', true);
            
            this._cartService.addRemoveItemToCart(success, error, params);
        }else{
            /* Se applicata al caricamento della pagina */
            let productFooter = _$('.cart').find('.component-ProductCardDetailCart .subs');
            _$(productFooter).removeClass('automatic-substitution semi-automatic-substitution punctual-substitution no-substitute');
            _$('.component-ReplacementMode .replacement-content-info').removeClass('replacement-no-substitution replacement-scelgo-io replacement-chiamami replacement-sceglie-conad');
            if (onloadChoice == 'NO_SUBSTITUTION') {
                productFooter.addClass('uk-hidden');
                _$('.component-ReplacementMode .replacement-content-info').addClass('replacement-no-substitution');
            } else {
                productFooter.removeClass('uk-hidden');
                if (onloadChoice == 'PUNCTUAL_SUBSTITUTION') {
                    _$(productFooter).addClass('punctual-substitution');
                    _$('.component-ReplacementMode .replacement-content-info').addClass('replacement-scelgo-io');
                } else if (onloadChoice == 'AUTOMATIC_SUBSTITUTION') {
                    _$(productFooter).addClass('automatic-substitution');
                    _$('.component-ReplacementMode .replacement-content-info').addClass('replacement-sceglie-conad');
                } else if (onloadChoice == 'SEMI_AUTOMATIC_SUBSTITUTION') {
                    _$(productFooter).addClass('semi-automatic-substitution');
                    _$('.component-ReplacementMode .replacement-content-info').addClass('replacement-chiamami');
                }
            }
            this.initSubstitutionToggles(onloadChoice);
        }
    }

    initSubstitutionToggles(replacementMode?: string){
        /* Settaggio delle checkbox nel caso di sostituzione gia selezionata in precedenza - allowSubstitution nel data-product */
        if(_$('.component-ProductCardDetailCart').length){
            let productCards = _$('.component-ProductCardDetailCart');
            productCards.each((index) => {
                let allowSubstitution = JSON.parse(_$(productCards[index]).attr('data-product')).allowSubstitution;
                if(allowSubstitution){
                    if(replacementMode){
                        if(replacementMode == "PUNCTUAL_SUBSTITUTION" && !_$(productCards[index]).find('.subs-item.scelgo-io a').attr('data-code')){
                            _$(productCards[index]).find('.subs').addClass('deselected').addClass('no-substitute').removeClass('punctual-substitution');
                            _$(productCards[index]).find('.subs .toggle input').prop('checked', false);
                        } else if (replacementMode == "AUTOMATIC_SUBSTITUTION" && !_$(productCards[index]).find('.subs-item.scelgo-io a').attr('data-code')) {
                            _$(productCards[index]).find('.subs').addClass('deselected').addClass('no-substitute').removeClass('automatic-substitution');
                            _$(productCards[index]).find('.subs .toggle input').prop('checked', false);
                        } else if (replacementMode == "SEMI_AUTOMATIC_SUBSTITUTION" && !_$(productCards[index]).find('.subs-item.scelgo-io a').attr('data-code')){
                            _$(productCards[index]).find('.subs').addClass('deselected').addClass('no-substitute').removeClass('semi-automatic-substitution');
                            _$(productCards[index]).find('.subs .toggle input').prop('checked', false);
                        }
                    }else{
                        _$(productCards[index]).find('.subs').removeClass('deselected');
                        _$(productCards[index]).find('.subs .toggle input').prop('checked', true);
                    }
                }else{
                    
                    _$(productCards[index]).find('.subs').addClass('deselected');
                    _$(productCards[index]).find('.subs .toggle input').prop('checked', false);

                    if(!_$(productCards[index]).find('.subs-item.scelgo-io a').attr('data-code')){
                        _$(productCards[index]).find('.subs').removeClass('automatic-substitution semi-automatic-substitution punctual-substitution no-substitute');
                        _$(productCards[index]).find('.subs').addClass('no-substitute');
                    }
                }
            });
        }
    }

    removeReplaceMode(){
        let choice = 'NO_SUBSTITUTION'
        let success = async (res) => {

            _$('.component-ReplacementMode .replacement-content-info').removeClass('replacement-no-substitution replacement-scelgo-io replacement-chiamami replacement-sceglie-conad');
            
            let productFooter = _$('.cart').find('.component-ProductCardDetailCart .subs');
            _$(productFooter).removeClass('automatic-substitution semi-automatic-substitution punctual-substitution no-substitute');
            productFooter.addClass('uk-hidden');
            this.preselectedSub = 'no-replace';
            _$('.component-ReplacementMode .replacement-content-info').addClass('replacement-no-substitution');
            this.sendAnalytics('disattivaModalitaSostituzione');
            _$('.primary-panel').attr('data-replacement-cart', choice);
            this._utils.showLoader('', false);
        }

        let error = (err) => {
            console.error(err);
            this._utils.showLoader('', false);
        }

        let params = {
            "replacementMode": choice
        }

        this._utils.showLoader('', true);
        
        this._cartService.addRemoveItemToCart(success, error, params);

        this.initSubstitutionToggles('NO_SUBSTITUTION');
    }

    toggleReplace(e: object) {

        let isWeight = false;
        let allowSubstitution;

        if(_$(e).closest('.component-ProductCardDetailCart').attr('data-product')){
            let dataProduct = JSON.parse(_$(e).closest('.component-ProductCardDetailCart').attr('data-product'));
            if(dataProduct.variations || dataProduct.increment){
                isWeight = true;
            }
        }

        if (_$(e).find('input').is(':checked')) {
            _$(e).removeClass('deselected');
            allowSubstitution = true;
            this.sendAnalytics('attivaSostituzione', _$(e).closest('.component-ProductCardDetailCart'));
        } else {
            _$(e).addClass('deselected');
            allowSubstitution = false;
        }

        let success = (res) => {
        }

        let error = (err) => {
            if(allowSubstitution){
                _$(e).addClass('deselected');
                _$(e).find('input').prop('checked', false);
            }else{
                _$(e).removeClass('deselected');
                _$(e).find('input').prop('checked', true);
            }
        }

        let params = {
            "productId": JSON.parse(_$(e).closest('.component-ProductCardDetailCart').attr('data-product')).code,
            "entryNumber": JSON.parse(_$(e).closest('.component-ProductCardDetailCart').attr('data-product')).entries[0].entryNumber,
            "allowSubstitution" : allowSubstitution
        }

        if(isWeight){
            params['scale'] = _$(e).closest('.component-ProductCardDetailCart').attr('data-scale');
            params['weight'] = params['scale'];
        }else{
            if(JSON.parse(_$(e).closest('.component-ProductCardDetailCart').attr('data-product')).netQuantityUm && JSON.parse(_$(e).closest('.component-ProductCardDetailCart').attr('data-product')).netQuantityUm.toUpperCase() != "PZ"){
                params['weight'] = JSON.parse(_$(e).closest('.component-ProductCardDetailCart').attr('data-product')).netQuantity ? 
                                    JSON.parse(_$(e).closest('.component-ProductCardDetailCart').attr('data-product')).netQuantity : 0;
            }
        }

        this._cartService.addRemoveItemToCart(success, error, params);
    }

    applyOrangeOverlay(e: object) {
        if (!_$(e).hasClass('active')) {
            _$(e).closest('.replace-list').find('.orange-overlay').removeClass('active');
            _$(e).closest('.replace-list').find('.uk-badge').addClass('uk-hidden');
            _$(e).toggleClass('active');
            _$(e).siblings('.uk-badge').toggleClass('uk-hidden');
            this.replaceChangeCta(e);
        }
    }

    setReplacementMode(e: any){

    }

    showSostitutiListener() {
        let functions = this;
        _$('#offcanvas-sostituti, #modal-sostituti').on('show', function () {
            functions.loadSostituti(this);
        });

        _$('#offcanvas-sostituti').on('shown', () => {
            _$('#offcanvas-sostituti').find('.js-scroll').scrollTop(0);
        })
    }

    loadSostituti(e: object) {
        let entryNumber = JSON.parse(_$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-product')).entries[0].entryNumber;
        if (_$(e).attr('id') == 'offcanvas-sostituti') {
            let mobileProduct = _$(this.currentElement).closest('.category-mobile-product');
            _$(e).find('.current-element').find('.img').html(mobileProduct.find('.top-row').next().find('.img').html());
            if(mobileProduct.find('.logos').length > 0){
                _$(e).find('.logos').html(mobileProduct.find('.logos').html());
            }
            _$(e).find('.product-desc').html(mobileProduct.find('.desc').html());
            _$(e).find('.unit-price').html(_$(e).closest('.component-ProductCardDetailCart').find('.category-desktop-product').find('.unit-price').html());
        } else if (_$(e).attr('id') == 'modal-sostituti') {
            let desktopProduct = _$(this.currentElement).closest('.category-desktop-product');
            if(desktopProduct.find('.logos').length > 0){
                let logos = '';
                if(desktopProduct.find('.logos .top').length > 0){
                    logos.concat(desktopProduct.find('.logos .top').html());
                }
                if(desktopProduct.find('.logos .bottom').length > 0){
                    logos.concat(desktopProduct.find('.logos .bottom').html());
                }
                _$(e).find('.logos').html(logos);
            }
            _$(e).find('.current-element').find('.img').html(desktopProduct.find('.info-grid .product-img').html());

            //marchio conad60
            if(_$(desktopProduct).find('img.conad-60').length > 0) {
                let marchio_conad60 = _$(desktopProduct).find('.conad-60').clone();
                _$('#modal-sostituti').find('.current-element').removeClass('uk-flex-middle').addClass('uk-flex-top');
                _$('#modal-sostituti').find('.current-element').find('.img').append(marchio_conad60);
            } else {
                _$(e).find('.current-element').addClass('uk-flex-middle').removeClass('uk-flex-top');
            }
            _$(e).find('.product-desc').html(desktopProduct.find('.product-desc-text span').html());
            _$(e).find('.unit-price').html(desktopProduct.find('.unit-price').html());
            _$(e).find('.price-qty').html(desktopProduct.find('.price-qty').html());
        }

        _$('#offcanvas-sostituti').find('.single-filter-apply').prop('disabled', true);
        _$('#modal-sostituti').find('.single-filter-apply').prop('disabled', true);

        let scaleReplacement = JSON.parse(_$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-product')).entries &&
                                JSON.parse(_$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-product')).entries[0] && 
                                JSON.parse(_$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-product')).entries[0].scaleReplacement ? 
                                JSON.parse(_$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-product')).entries[0].scaleReplacement : null;
        this.fetchSostituti(entryNumber, scaleReplacement);
    }

    fetchSostituti(entryNumber: string, scaleReplacement?){
        let link = _$('.primary-panel').attr('data-replacement-modal').toString() + `?entry=${entryNumber}`;
        if(Window.cart && Window.cart.userGroup){
            link = link + '&customer_type='+ Window.cart.userGroup;
        }

        if(Window.pointOfService && Window.pointOfService.type){
            link = link + '&isLocker=' + (Window.pointOfService.type == "LOCKER" ? 'true' : 'false');
        }

        let dataProduct = _$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-product') && JSON.parse(_$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-product')) ? JSON.parse(_$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-product')) : null;

        if(_$(this.currentElement).closest('.component-ProductCardDetailCart').length && _$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-scale') != null){
            link = link + '&weight=' + _$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-scale');
        }else if(dataProduct && dataProduct.netQuantityUm && dataProduct.netQuantityUm.toUpperCase() != "PZ"){
            link = link + '&weight=' + (dataProduct.netQuantity ? dataProduct.netQuantity : 0);
        }
        
        let success = (res) => {
            this._utils.showLoader(_$('#modal-sostituti .replace-list'), false, LOADER_POSITION.SUBSTITUTIONS);
            this._utils.showLoader(_$('#offcanvas-sostituti .box-overflow-container'), false, LOADER_POSITION.SUBSTITUTIONS);
            _$('#modal-sostituti').find('.replace-list').append(`
                    <div class="swiper-carousel swiper-sostituti swiper-container">
                        <div class="swiper-wrapper uk-flex uk-flex-bottom"></div>
                        <div class="swiper-scrollbar scrollbar-sostituti"></div>
                        <div role="button" aria-label="Scorri in avanti" class="swiper-button-next next-sostituti"></div>
                        <div role="button" aria-label="Scorri indietro" class="swiper-button-prev prev-sostituti"></div>
                    </div>`);

            _$('#offcanvas-sostituti').find('.box-overflow-container').append(res);
            res = res.replace(/>\s+</g,'><');
            let listaProdotti = _$(_$.parseHTML(_$.trim(res)));

            listaProdotti.each((i) => {
                _$('#modal-sostituti').find('.replace-list .swiper-wrapper').append(
                    `<div class="swiper-slide">${_$(listaProdotti[i]).prop('outerHTML').toString()}</div>`
                );
            });

            _$('#modal-sostituti').find('.replace-list').find('.orange-overlay.active').addClass('pre-selected');
            _$('#offcanvas-sostituti').find('.replace-list').find('.orange-overlay.active').addClass('pre-selected');

            this._utils.customScrollbar();
            const swiperSostituti = new Swiper('.swiper-sostituti', this.swiperSostitutiOptions);

            if(_$('#modal-sostituti').find('.replace-list .component-ProductCard .manage-preferite-product')){
                _$('#modal-sostituti').find('.replace-list .component-ProductCard .manage-preferite-product').click(function(event){
                    event.stopPropagation();
                });
            }
            
            if(_$('#offcanvas-sostituti').find('.replace-list .component-ProductCard .manage-preferite-product')){
                _$('#offcanvas-sostituti').find('.replace-list .component-ProductCard .manage-preferite-product').click(function(event){
                    event.stopPropagation();
                });
            }

            if (_$(window).width() > 1023) {
                this.sendAnalytics('cambiaSostituzione', _$('#modal-sostituti').find('.active .component-ProductCard'));
            } else {
                this.sendAnalytics('cambiaSostituzione', _$('#offcanvas-sostituti').find('.active .component-ProductCard'));
            }

            if(scaleReplacement && _$(window).width() > 1023 && _$('#modal-sostituti').find('.orange-overlay.active').find('.box-scale-sostitutivi').length){
                _$('#modal-sostituti').find('.orange-overlay.active').find('.box-scale-sostitutivi').find(`.uk-tag[data-scale="${scaleReplacement}"]`).addClass('active');
            }else if(scaleReplacement && _$(window).width() < 1024 && _$('#offcanvas-sostituti').find('.orange-overlay.active').find('.box-scale-sostitutivi').length){
                _$('#offcanvas-sostituti').find('.orange-overlay.active').find('.box-scale-sostitutivi').find(`.uk-tag[data-scale="${scaleReplacement}"]`).addClass('active');
            }
        }

        let error = (err) => {
            console.log(err);
            this._utils.showLoader(_$('#modal-sostituti .replace-list'), false, LOADER_POSITION.SUBSTITUTIONS);
            this._utils.showLoader(_$('#offcanvas-sostituti .box-overflow-container'), false, LOADER_POSITION.SUBSTITUTIONS);
        }
        _$('#modal-sostituti').find('.replace-list').html('<div class="scegli">Scegli il sostituto:</div>');
        _$('#offcanvas-sostituti').find('.box-overflow-container').html(`<div class="scegli">Scegli il sostituto:</div>`);
        this._utils.showLoader(_$('#modal-sostituti .replace-list'), true, LOADER_POSITION.SUBSTITUTIONS);
        this._utils.showLoader(_$('#offcanvas-sostituti .box-overflow-container'), true, LOADER_POSITION.SUBSTITUTIONS);
        this._cartService.getPageUpdate(success, error, link );
    }

    replaceChangeCta(e: object) {
        if (_$(e).find('.orange-overlay.active').hasClass('pre-selected')) {
            if(_$(e).find('.orange-overlay.active').find('.box-scale-sostitutivi').length){
                if(_$(e).find('.orange-overlay.active').find('.box-scale-sostitutivi').find('.uk-tag.active').length && 
                    _$(e).find('.orange-overlay.active').find('.box-scale-sostitutivi').find('.uk-tag.active').attr('data-scale')){
                    let selectedScaleSub = _$(e).find('.orange-overlay.active').find('.box-scale-sostitutivi').find('.uk-tag.active').attr('data-scale');
                    let scaleReplacement = JSON.parse(_$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-product')).entries &&
                                JSON.parse(_$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-product')).entries[0] && 
                                JSON.parse(_$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-product')).entries[0].scaleReplacement ? 
                                JSON.parse(_$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-product')).entries[0].scaleReplacement : null;
                    if(scaleReplacement && selectedScaleSub && scaleReplacement == selectedScaleSub){
                        this.disableSingleFilterButtons(_$(e).closest('.container-sostituti'));
                    }else{
                        this.enableSingleFilterButtons(_$(e).closest('.container-sostituti'));
                    }
                }
            }else{
                this.disableSingleFilterButtons(_$(e).closest('.container-sostituti'));
            }
        }else{
            if(_$(e).find('.orange-overlay.active').find('.box-scale-sostitutivi').length){
                if(!_$(e).find('.orange-overlay.active').find('.box-scale-sostitutivi').find('.uk-tag.active').length){
                    _$(e).find('.orange-overlay.active').find('.box-scale-sostitutivi').find('.uk-tag').first().addClass('active');
                }
            }
            this.enableSingleFilterButtons(_$(e).closest('.container-sostituti'));
        }
    }

    replaceChange(e: object){
        let newReplace = _$(e).closest('.container-sostituti').find('.orange-overlay.active .component-ProductCard');
        let code = JSON.parse(newReplace.attr('data-product')).code;
        let description = newReplace.find('.product-description h3').html();

        let success = (res) => {
            _$(this.currentElement).closest('.component-ProductCardDetailCart').find('.subs .scelgo-io a').html(description);
            _$(this.currentElement).closest('.component-ProductCardDetailCart').find('.subs .scelgo-io a').attr('data-code', code);
            if(_$(newReplace).find('.weight-list-sostitutivi .uk-tag.active').length){
                let dataProduct = JSON.parse(_$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-product'));
                if(dataProduct && dataProduct.entries && dataProduct.entries[0]){
                    dataProduct.entries[0].scaleReplacement = body['replacementProductScale'];
                    _$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-product', JSON.stringify(dataProduct));
                }
            }
            this._utils.showLoader(_$(e), false, LOADER_POSITION.BUTTON);
        }

        let error = (err) => {
            this._utils.showLoader(_$(e), false, LOADER_POSITION.BUTTON);
            console.log(err);
        }

        let body = {
            "entryNumber": _$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-entryNumber'),
            "substitutionId": code,
            "productId": _$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-code')
        }

        if(_$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-scale')){
            body['scale'] = parseFloat(_$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-scale')) ? parseFloat(_$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-scale')) : _$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-scale');
            body['weight'] = body['scale'];
        }else{
            if(_$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-product') && 
                JSON.parse(_$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-product')) && 
                JSON.parse(_$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-product')).netQuantityUm && 
                JSON.parse(_$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-product')).netQuantityUm.toUpperCase() != "PZ"){
                body['weight'] = _$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-product') && JSON.parse(_$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-product')) ? 
                    JSON.parse(_$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-product')).netQuantity ?  JSON.parse(_$(this.currentElement).closest('.component-ProductCardDetailCart').attr('data-product')).netQuantity : 0 : 0;
            }
        }

        let replaceDataProduct = JSON.parse(_$(newReplace).attr('data-product'));

        if(replaceDataProduct?.increment?.minWeight){
            body['replacementProductScale'] = replaceDataProduct?.increment?.minWeight;
        }else if(replaceDataProduct?.variations && _$(newReplace).find('.weight-list-sostitutivi .uk-tag.active').length){
            body['replacementProductScale'] = _$(newReplace).find('.weight-list-sostitutivi .uk-tag.active').attr('data-scale') ? _$(newReplace).find('.weight-list-sostitutivi .uk-tag.active').attr('data-scale') : null;
        }

        this._utils.showLoader(_$(e), true, LOADER_POSITION.BUTTON);
        this._cartService.addRemoveItemToCart(success, error, body);
    }

    getScaleOptions(){
        /* _$('#note-preparazione-offcanvas').find('.formato').addClass('uk-hidden');
        _$('#note-preparazione-offcanvas').find('.formato .single-choice-radio').html('');
        
        if(_$(this.currentElement).closest('.component-ProductCardDetailCart').find('.quantity-format').length > 0){
            
            _$(this.currentElement).closest('.component-ProductCardDetailCart').find('.quantity-format').find('select option').each(function() {
                let checked = _$(this).is(':selected') ? 'checked' : '';
                _$('#note-preparazione-offcanvas').find('.formato .single-choice-radio').append(`<label>
                        <input class="uk-radio default-ordering" type="radio" name="formato" ${checked}>
                        <span>${_$(this).html()}</span>
                    </label>`);
                if(checked === 'checked'){
                    _$('#note-preparazione-offcanvas').find('.formato .filter-title .choice').html(_$(this).html());
                }
            });

            _$('#note-preparazione-offcanvas').find('.formato').removeClass('uk-hidden');
        } */
    }

    getModifiedScalePreparationNote(entryNumber: any): any{
        return new Promise((resolve, reject) => {
            let link = `?entry=${entryNumber}`;

            let success = (res) => {
                let selectedNote = '';
                if(res.data){
                    let preparationNotes = res.data.preparationNotes;
                    for(const note in preparationNotes){
                        if(preparationNotes[note].active){
                            selectedNote = preparationNotes[note].value;
                        }
                    }

                    if(res.data.otherPreparation && res.data.otherPreparation.active){
                        selectedNote = res.data.otherPreparation.value;
                    }
                }
                resolve(selectedNote)
            }

            let error = (err) => {
                console.log(err);
                reject('');
            }

            this._cartService.getPreparationNotes(success, error, link);
        });
    }

    /* Anche il formato per mobile */
    getPreparationNotes(entryNumber: any){

        let link = `?entry=${entryNumber}`;
        _$('#note-preparazione-offcanvas').find('.extra-option').addClass('uk-hidden');
        _$('#note-preparazione-offcanvas').find('.formato').addClass('uk-hidden');
        _$('#note-preparazione-modal').find('.single-choice-radio').html('');
        _$('#note-preparazione-offcanvas').find('.extra-option .single-choice-radio').html('');
        let success = (res) => {
            this._utils.showLoader(_$('#note-preparazione-modal .modal-content .single-choice-radio'), false, LOADER_POSITION.SUBSTITUTIONS);
            if(res.data){
                let preparationNotes = res.data.preparationNotes;
                if(preparationNotes.length > 0){
                    _$('#note-preparazione-offcanvas').find('.extra-option').removeClass('uk-hidden');
                    
                    for(const note in preparationNotes){

                        if(preparationNotes[note].active){
                            _$('#note-preparazione-modal').find('.single-choice-radio').append(`
                                <label><input class="uk-radio selected" type="radio" name="preparationNotes" checked><span>${preparationNotes[note].value}</span></label>`);

                            _$('#note-preparazione-offcanvas').find('.product-preparation-notes .single-choice-radio').append(`
                            <label>
                                <input class="uk-radio selected" type="radio" name="extra-option" checked>
                                <span>${preparationNotes[note].value}</span>
                            </label>`);
                        }else{
                            _$('#note-preparazione-modal').find('.single-choice-radio').append(`
                                <label><input class="uk-radio" type="radio" name="preparationNotes"><span>${preparationNotes[note].value}</span></label>`);

                            _$('#note-preparazione-offcanvas').find('.product-preparation-notes .single-choice-radio').append(`
                            <label>
                                <input class="uk-radio" type="radio" name="extra-option">
                                <span>${preparationNotes[note].value}</span>
                            </label>`);
                        }
                    }
                    if(res.data.otherPreparation){
                        if(res.data.otherPreparation.active){
                            _$('#note-preparazione-modal').find('.single-choice-radio').append(`
                                    <label>
                                        <input onclick="DetailProduct.checkRadio(this);" class="uk-radio selected" type="radio" name="preparationNotes" checked>
                                        <input onclick="DetailProduct.checkRadio(this);" onkeyup="DetailProduct.freeNoteStyle(this);" type="text" placeholder="Altro" class="uk-input yellow-bottom-border free-note free-note-detail-cart blank">
                                    </label>
                            `);

                            _$('#note-preparazione-offcanvas').find('.product-preparation-notes .single-choice-radio').append(`
                                    <label>
                                        <input onclick="DetailProduct.checkRadio(this);" class="uk-radio selected" type="radio" name="extra-option" checked>
                                        <input onclick="DetailProduct.checkRadio(this);" onkeyup="DetailProduct.freeNoteStyle(this);" type="text" placeholder="Altro" class="uk-input yellow-bottom-border free-note free-note-detail-cart blank">
                                    </label>
                            `);
                        }else{
                            _$('#note-preparazione-modal').find('.single-choice-radio').append(`
                                    <label>
                                        <input onclick="DetailProduct.checkRadio(this);" class="uk-radio" type="radio" name="preparationNotes">
                                        <input onclick="DetailProduct.checkRadio(this);" onkeyup="DetailProduct.freeNoteStyle(this);" type="text" placeholder="Altro" class="uk-input yellow-bottom-border free-note free-note-detail-cart blank">
                                    </label>
                            `);

                            _$('#note-preparazione-offcanvas').find('.product-preparation-notes .single-choice-radio').append(`
                                    <label>
                                        <input onclick="DetailProduct.checkRadio(this);" class="uk-radio" type="radio" name="extra-option">
                                        <input onclick="DetailProduct.checkRadio(this);" onkeyup="DetailProduct.freeNoteStyle(this);" type="text" placeholder="Altro" class="uk-input yellow-bottom-border free-note free-note-detail-cart blank">
                                    </label>
                            `);
                        }

                        if(res.data.otherPreparation['value'] != undefined){
                            _$('#note-preparazione-modal').find('.single-choice-radio .free-note').prop('value', res.data.otherPreparation['value']);
                            _$('#note-preparazione-offcanvas').find('.product-preparation-notes .single-choice-radio .free-note').prop('value', res.data.otherPreparation['value']);
                        }
                    }
                }else{
                    _$('#note-preparazione-offcanvas').find('.extra-option').addClass('uk-hidden');
                }
                if(res.data.weights.length > 0){
                    _$('#note-preparazione-offcanvas').find('.formato .single-choice-radio').html('');
                    res.data.weights.forEach((i)=>{
                        if(i.active){
                            _$('#note-preparazione-offcanvas').find('.formato .single-choice-radio').append(`
                            <label>
                                <input class="uk-radio selected" type="radio" name="formato" checked>
                                <span>${i.value}</span>
                            </label>`);
                            
                        }else{
                            _$('#note-preparazione-offcanvas').find('.formato .single-choice-radio').append(`
                            <label>
                                <input class="uk-radio" type="radio" name="formato">
                                <span>${i.value}</span>
                            </label>`);
                        }
                    });
                    _$('#note-preparazione-offcanvas').find('.formato').removeClass('uk-hidden');
                }else{
                    _$('#note-preparazione-offcanvas').find('.formato').addClass('uk-hidden');
                }
            }else{
                console.log('no preparation notes');
            }
            
        }

        let error = (err) => {
            this._utils.showLoader(_$('#note-preparazione-modal .modal-content .single-choice-radio'), false, LOADER_POSITION.SUBSTITUTIONS);
        }
        this._utils.showLoader(_$('#note-preparazione-modal .modal-content .single-choice-radio'), true, LOADER_POSITION.SUBSTITUTIONS);
        this._cartService.getPreparationNotes(success, error, link);
    }

    emptyCart(){
        let success = (res) => {
            window.location.reload();
        }

        let error = (err) => {
            console.error(err);
        }

        let body = {
            "clean": "true"
        }

        this._cartService.addRemoveItemToCart(success, error, body);
        this.sendAnalytics('svuotaCarrello');
    }

    emptyPartialCart(){
        let entriesToDelete = [];
        if (_$(window).width() > 1023) {
            if(_$('.category-desktop-product').parent().find('.checkmark.selected').length == _$('.component-ProductCardDetailCart').length){
                this.emptyCart();
            }else{
                _$('.component-ProductCardDetailCart > div').has('.category-desktop-product').has('.checkmark.selected').each((index, element) => {
                   entriesToDelete.push(_$(element).parent().attr('data-entrynumber'));
               });

               let success = (res) => {
                this.reloadCart();
                }
        
                let error = (err) => {
                    console.error(err);
                }
        
                let body = {
                    "clean": "true",
                    "entriesToDelete": entriesToDelete.toString().replace('[', '').replace(']', '')
                }
                
                console.log(body);
        
                this._cartService.addRemoveItemToCart(success, error, body);
            }
        } else {
            if(_$('.category-mobile-product').parent().find('.checkmark.selected').length == _$('.component-ProductCardDetailCart').length){
                this.emptyCart();
            }else{
                _$('.component-ProductCardDetailCart > div').has('.category-mobile-product').has('.checkmark.selected').each((index, element) => {
                   entriesToDelete.push(_$(element).parent().attr('data-entrynumber'));
               });

               let success = (res) => {
                    this.reloadCart();
                }
        
                let error = (err) => {
                    console.error(err);
                }
        
                let body = {
                    "clean": "true",
                    "entriesToDelete": entriesToDelete.toString().replace('[', '').replace(']', '')
                }
                
                console.log(body);
        
                this._cartService.addRemoveItemToCart(success, error, body);
            }
        }
    }

    reloadCartHTML():Promise<void>{
        return new Promise((resolve, reject) => {
            let link = _$('.primary-panel').attr('data-update-cart');
            let success = (res) => {
                this._utils.showLoader('', false);
                _$('.primary-panel').closest('.content-grid').replaceWith(res);
                resolve(); 
            }
            let error = (err) => {
                this._utils.showLoader('', false);
                reject();
            }
            this._cartService.getPageUpdate(success, error, link);
            this._utils.showLoader('', true);
        });
        
    }

    async reloadCart(){
        try{
            await this.reloadCartHTML();
            if(Window.cart && !Window.cart.parentOrder){
                this.checkDisponibilitaTimeslot();
            }
            this._utils.initCustomSelect2();
            this._utils.customScrollbar();
            _$('.component-ProductCardDetailCart').find('.quantity .select-native-ui select').on('focus', (event) => {
                this.initProductCardSelects(_$(event.target).closest('.quantity').find('.select-native-ui'));
            });
            this._utils.detectQuantityChange();
            this.setAttributesOnCards();
            this.updateBadges();
            if(_$('.primary-panel').attr('data-replacement-cart')){
                this.applyReplaceMode(_$('.primary-panel'), _$('.primary-panel').attr('data-replacement-cart'));
            }
            this.setReplacementModeAnalytics();
        } catch (err){
            console.log(err);
        }
    }

    /**
     * Resetta gli attributi data-code, data-quantity, data-entrynumber e data-scale sulle card del carrello
     *  
     * */ 
     setAttributesOnCards(){
        let heavyCartLimit = false;
        let heavyCartSurcharge = false;

        if(_$('.component-ProductCardDetailCart').length) {
            _$('.component-ProductCardDetailCart').each(function(){
                let dataProduct = JSON.parse(_$(this).attr('data-product'));
                let dataQuantity = parseFloat(_$(this).attr('data-quantity'));
                
                _$(this).attr('data-code', dataProduct.code);
                if (dataProduct.entries) {
                    let entry = dataProduct.entries[0];
                    _$(this).attr("data-quantity", entry.qty);
                    _$(this).attr("data-entryNumber", entry.entryNumber);
                    if(dataProduct.increment || dataProduct.variations){
                        _$(this).attr('data-scale', entry.scale);
                    }
                }

                if(dataProduct.volume && Window.volume){
                    if(Window.volume.maxVolume && Window.volume.cartVolume){
                        let volumeDelta = Window.volume.maxVolume - Window.volume.cartVolume;
                        let maxNewQuantity = Math.floor(volumeDelta / dataProduct.volume);
                        if(maxNewQuantity >= 1){
                            let maxOptions = dataQuantity + maxNewQuantity;
                            _$(this).find(`.quantity .select-native-ui`).each(function(){
                                _$(this).find(`option:lt(${maxOptions + 1})`).removeClass('uk-hidden');
                                _$(this).find(`option:gt(${maxOptions})`).addClass('uk-hidden');
                            });
                        }
                    }

                    if(Window.volume.cartVolume >= Window.volume.maxVolume){
                        heavyCartLimit = true;
                    }else if(Window.volume.cartVolume >= Window.volume.maxVolumeNoSurcharge){
                        heavyCartSurcharge = true;
                    }

                    if(_$('body').attr('nkPage') == 'DetailCart'){
                        if(heavyCartLimit){
                            _$('#heavy-limit-cart-mobile, #heavy-limit-cart-desktop').removeClass('uk-hidden');
                            _$('#heavy-surcharge-cart-mobile, #heavy-surcharge-cart-desktop').addClass('uk-hidden');
                        }else if(heavyCartSurcharge){
                            _$('#heavy-limit-cart-mobile, #heavy-limit-cart-desktop').addClass('uk-hidden');
                            _$('#heavy-surcharge-cart-mobile, #heavy-surcharge-cart-desktop').removeClass('uk-hidden');
                        }else{
                            _$('#heavy-limit-cart-mobile, #heavy-limit-cart-desktop').addClass('uk-hidden');
                            _$('#heavy-surcharge-cart-mobile, #heavy-surcharge-cart-desktop').addClass('uk-hidden')
                        }
                    }
                }
            });
        }

        if(_$('.component-CardProdottoOmaggio').length){
            _$('.component-CardProdottoOmaggio').each((index, value) => {
                if(_$(value).attr('data-product') != null && _$(value).attr('data-product').toString() != null && JSON.parse(_$(value).attr('data-product').toString()) != null){
                    let dataProduct = JSON.parse(_$(value).attr('data-product').toString());
                    _$(value).find('.a-wrapper').attr('data-code', dataProduct.code != null ? dataProduct.code : '');
                    _$(value).find('.omaggio-checkbox').attr('data-active', dataProduct.active != null ? dataProduct.active : false);
                }
            });
        }
    }

    async updateScale(e: object, scaleMobile?: string){
        
        let newScale;
        if(scaleMobile){
            newScale = scaleMobile;
        }else{
            newScale = _$(e).children('option').filter(':selected').attr('data-scale');
        }
        let newQuantity = _$(e).closest('.component-ProductCardDetailCart').attr('data-quantity');
        let newQuantityInt = parseFloat(newQuantity);
        let productCard = _$(e).closest('.component-ProductCardDetailCart');
        let dataProduct = JSON.parse(productCard.attr('data-product'));
        let productCode = dataProduct.code;
        let entryNumber = productCard.attr('data-entryNumber');
        let preparationNote =  await this.getModifiedScalePreparationNote(entryNumber);
        let success = (res) => {
            this.reloadCart();

            /* Controllo se:
                - Se la quantityAdded > 0 allora sono state unite due entry
                - se la quantityAdded = 0 allora è stata cambiata semplicemente la scale
            */
            _Uikit.offcanvas('#note-preparazione-offcanvas').hide();
            if(res.data.data.modifications.cartModifications[0].quantityAdded > 0){
                let newPreparationNote = res.data.data.modifications.cartModifications[0].entry.preparationNote;
                if(newPreparationNote && preparationNote.length > 0){
                    if(newPreparationNote != preparationNote){
                        /* Aprire scelta nota di preparazione */
                        if (_$(window).width() <= 1023) {
                            setTimeout(() => {
                                _$('.component-ProductCardDetailCart[data-entrynumber='+res.data.data.modifications.cartModifications[0].entry.entryNumber+']').find('.category-mobile-product .button-more').trigger('click');
                                _Uikit.accordion('#note-preparazione-offcanvas .list-filters').toggle(1);
                            }, 3000);
                        }else{
                            setTimeout(() => {
                                _Uikit.modal('#note-preparazione-modal').show();
                                _$('.component-ProductCardDetailCart[data-entrynumber='+res.data.data.modifications.cartModifications[0].entry.entryNumber+']').find('.category-desktop-product .extra a:not(.uk-hidden)').trigger('click');
                            }, 3000);
                        }
                    }
                }else if((newPreparationNote && preparationNote.length < 1) || (!newPreparationNote && preparationNote.length > 0)){
                    /* Se manca solo una o l'altra */
                    
                    if(newPreparationNote != preparationNote){
                        /* Aprire scelta nota di preparazione */
                        if (_$(window).width() <= 1023) {
                            _$('.component-ProductCardDetailCart[data-entrynumber='+res.data.data.modifications.cartModifications[0].entry.entryNumber+']').find('.category-mobile-product .button-more').trigger('click');
                            _Uikit.accordion('#note-preparazione-offcanvas .list-filters').toggle(1);
                        }else{
                            _$('.component-ProductCardDetailCart[data-entrynumber='+res.data.data.modifications.cartModifications[0].entry.entryNumber+']').find('.category-desktop-product .extra a:not(.uk-hidden)').trigger('click');
                        }
                    }
                }
            }
            this.sendAnalytics('applicaPezzatura', e);
        }

        let error = (err) => {
            console.log(err);
            this.reloadCart();
            if(this._lockerUtils.parseLockerErrorMessageAddToCart(err.response.data.message) != null){
                let jsonError = this._lockerUtils.parseLockerErrorMessageAddToCart(err.response.data.message);
                if(jsonError['maxPiecesLk'] != null){
                    this._lockerUtils.manageLockerContingencyModal(jsonError['maxPiecesLk'], true, false);
                }else if(jsonError['maxKgLk'] != null){
                    this._lockerUtils.manageLockerContingencyModal(jsonError['maxKgLk'], false, true);
                }
            }
        }

        let body = {
            "productId": productCode,
            "entryNumber": entryNumber,
            "qty": newQuantityInt,
            "scale": parseFloat(newScale),
            "weight": parseFloat(newScale)
        }

        console.log(body);
        
        this._cartService.addRemoveItemToCart(success, error, body);
    }

    /* funzione per annullare le modifiche ad un ordine e cancellare il carrello creato in modifica */
    deleteCart(){
        let success = (res) => {
            if(Window.user){
                /* redirect allo storico ordini myconad */
                Window.location.href = Window.urlSite.myConadOrderHistory;
            }else{
                /* redirect al dettaglio ordine guest */
                let cartId = Window.cart ? Window.cart.guid : '';
                let bEcommerce = Window.pointOfService ? Window.pointOfService.becommerce : '';
                let orderDetail = Window.urlSite.orderDetail ? Window.urlSite.orderDetail : '/order-detail';
                
                if(cartId && bEcommerce && orderDetail){
                    console.log('cart id && becommerce found');
                    Window.location.href = orderDetail + '?orderId='+cartId+'&'+'bEcommerce='+bEcommerce;
                }else{
                    console.log('problembs with cartId || bEcommerce || orderDetail urlsite');
                }
            }
        }

        let error = (err) => {
            console.log('error deleting cart');
            
        }

        let body = {}

        this._cartService.deleteCart(success, error, body);
    }
    
    async sendAnalytics(eventType, e?, note?) {
        try {
            let analyticsData;
            let dataPromoArray = [];
            let step;
            let spesaMin = _$('.card-scontrino-top .products-costs .uk-alert') ? 'NO' : 'SI';
            let importoCarr = Window.cart && Window.cart.subTotal && Window.cart.subTotal.value ? Window.cart.subTotal.value : 0;
            let importoCons;
            let oldSostituzione = _$('.primary-panel').attr('data-replacement-cart');
            let oldSostituzioneMapping;
            if(_$('.card-scontrino-top').find('.delivery-costs').length > 0){
                importoCons = _$('.card-scontrino-top').find('.delivery-costs').find('.value').length ? parseFloat(_$('.card-scontrino-top').find('.delivery-costs').find('.value').html().replace(/\s+/g, '').replace('€','').replace(',','.')) : 0;
            }else{
                importoCons = 0;
            }
            let consegnaGrat = parseInt(_$('.card-scontrino-top').find('.delivery-costs-progress-bar').find('.progress-bar-custom').attr('value')) == 90 ? 'SI' : 'NO';
            let modSostituzione = _$('.replace-choice').find('input:checked').attr('value'); /* da rivedere come funziona check */
            
            let prodotti = [];
            let prodottiCarrello = _$('.component-ProductCardDetailCart');
            for (var i = 0; i < prodottiCarrello.length; i++) {
                let prodotto = (await this._analytics.getProductData(prodottiCarrello.eq(i)));
                prodotti.push(prodotto);
            }

            let partners = [];
            let partnersConsegna = _$('.card-consegna-gratuita');
            for (var i = 0; i < partnersConsegna.length; i++) {
                let partner = partnersConsegna.eq(i).find('.text div').text();
                partners.push(partner);
            }

            switch (eventType) {
                case 'pageview':
                    // 4.2.1
                    var pageviewCard = _$('.swiper-slide .card-consegna-gratuita-v3');
                    let sponsorAdv = [];
                    if(_$(pageviewCard).length){
                        for(let i = 0; i < _$(pageviewCard).length; i++){
                            sponsorAdv.push({
                                brand: _$(pageviewCard).eq(i).find('.text div').html(),
                                campagna: _$(pageviewCard).eq(i).find('.text p').html(),
                                formato: 'Banner promo',
                                landing: _$(pageviewCard).eq(i).find('a').attr('href'),
                                posizioneSlide:  _$(pageviewCard).eq(i).closest('.swiper-wrapper').find('.swiper-slide').length > 1 ? _$(pageviewCard).eq(i).closest('.swiper-slide').index() + 1 : 0,
                            })

                            if(_$(pageviewCard).eq(i).attr('data-promo')){
                                dataPromoArray.push(JSON.parse(_$(pageviewCard).eq(i).attr('data-promo')))
                            }else{
                                dataPromoArray.push("");
                            }
                        }
                    }

                    analyticsData = {
                        event: eventType,
                        IDpromozioni: this._analytics.getIDpromozioni(),
                        carrello: {
                            importo: importoCarr,
                            consegnaGratuita: consegnaGrat,
                            importoConsegna: importoCons,
                            prodotti,
                            partnerConsegnaOmaggio: partners
                        },
                        sponsor: sponsorAdv ? sponsorAdv : ''
                    }

                    step = 'FUNNEL_DATA_CART_VIEW';
                    break;
                case 'clickCheckout':
                    //4.2.2
                    analyticsData = {
                        event: eventType,
                        IDpromozioni: this._analytics.getIDpromozioni(),
                        carrello: {
                            importo: importoCarr,
                            consegnaGratuita: consegnaGrat,
                            importoConsegna: importoCons,
                            prodotti
                        }
                    }
                    step = 'FUNNEL_DATA_CART_CHECKOUT';
                    break;
                case 'svuotaCarrello':
                    //4.2.3
                    analyticsData = {
                        event: eventType,
                        IDpromozioni: this._analytics.getIDpromozioni(),
                        carrello: {
                            spesaMinimaRaggiunta: spesaMin,
                            importo: importoCarr,
                            consegnaGratuita: consegnaGrat,
                            importoConsegna: importoCons,
                            prodotti
                        }
                    }
                    step = 'FUNNEL_DATA_CART_TO_EMPTY';
                    break;
                case 'chiusuraModale':
                    //4.2.4
                    analyticsData = {
                        event: eventType,
                        IDpromozioni: this._analytics.getIDpromozioni(),
                        carrello: {
                            spesaMinimaRaggiunta: spesaMin,
                            importo: importoCarr,
                            consegnaGratuita: consegnaGrat,
                            importoConsegna: importoCons,
                            prodotti
                        }
                    }
                    step = 'FUNNEL_DATA_CART_CLOSE_MODAL';
                    break;
                case 'applicaPezzatura':
                    //4.2.5
                    let scale = "";
                    if (_$(e).closest('.filter').find('input:checked').siblings('span').html()) {
                        scale = _$(e).closest('.filter').find('input:checked').siblings('span').html();
                    } else {
                        scale = _$(e).children('option').filter(':selected').attr('data-scale');
                    }
                    let product = _$(e).closest('.component-ProductCardDetailCart');
                    if (product && product.length < 1) {
                        product = _$(this.currentElement).closest('.component-ProductCardDetailCart');
                    }
                    analyticsData = {
                        event: eventType,
                        IDpromozioni: this._analytics.getIDpromozioni(),
                        prodotto: (await this._analytics.getProductData(product, false, eventType, scale))
                    }
                    step = 'FUNNEL_DATA_CART_APPLY_PEZZ';
                    break;
                case 'applicaNotePreparazione':
                    //4.2.6
                    analyticsData = {
                        event: eventType,
                        prodotto: (await this._analytics.getProductData(e))
                    }
                    step = 'FUNNEL_DATA_CART_APPLY_NOTE';
                    analyticsData.prodotto['notePreparazione'] = note;
                    break;
                case 'eliminazioneProdotto':
                    //4.2.7
                    analyticsData = {
                        event: eventType,
                        IDpromozioni: this._analytics.getIDpromozioni(),
                        prodotto: (await this._analytics.getProductData(e))
                    }
                    step = 'FUNNEL_DATA_CART_REMOVE_PROD';
                    break;
                case 'annullaEliminazione':
                    //4.2.8
                    analyticsData = {
                        event: eventType,
                        IDpromozioni: this._analytics.getIDpromozioni(),
                        prodotto: (await this._analytics.getProductData(e))
                    }
                    step = 'FUNNEL_DATA_CART_DELETE_REMOVE';
                    break;
                case 'modalitaSostituzione':
                    //4.2.9
                    analyticsData = {
                        event: eventType,
                        IDpromozioni: this._analytics.getIDpromozioni(),
                        carrello: {
                            spesaMinimaRaggiunta: spesaMin,
                            modalitaSostituzione: modSostituzione ? modSostituzione.replace("-"," ") : '',
                            prodotti
                        }
                    }
                    step = 'FUNNEL_DATA_CART_REPLACE_MODE';
                    break;
                case 'applicaSostituzione':
                    //4.2.10
                    e = _$(e).parent().parent().find('.replace-list .swiper-carousel .orange-overlay.active .component-ProductCard');
                    analyticsData = {
                        event: eventType,
                        IDpromozioni: this._analytics.getIDpromozioni(),
                        prodotto: (await this._analytics.getProductData(e))
                    }
                    step = 'FUNNEL_DATA_CART_REPLACE_APPLY';
                    break;
                case 'attivaSostituzione':
                    //4.2.11
                        analyticsData = {
                            event: eventType,
                            IDpromozioni: this._analytics.getIDpromozioni(),
                            prodotto: (await this._analytics.getProductData(e))
                        }
                        step = 'FUNNEL_DATA_CART_REPLACE_ACTIVE';
                        break;
                    case 'dettaglioSostituzione':  /* dati prodotto sostitutivo */
                    //4.2.12
                        analyticsData = {
                            event: eventType,
                            IDpromozioni: this._analytics.getIDpromozioni(),
                            prodotto: (await this._analytics.getProductData(e))
                        }
                        step = 'FUNNEL_DATA_CART_REPLACE_DETAIL';
                        break;
                    case 'cambiaSostituzione':  /* dati prodotto sostitutivo */
                    //4.2.13
                        analyticsData = {
                            event: eventType,
                            IDpromozioni: this._analytics.getIDpromozioni(),
                            prodotto: (await this._analytics.getProductData(e))
                        }
                        step = 'FUNNEL_DATA_CART_REPLACE_CHANGE';
                        break;
                    case 'pageviewProdReplace':  /* dati prodotto sostitutivo */
                        //4.2.14
                        analyticsData = {
                            event: 'pageview',
                            IDpromozioni: this._analytics.getIDpromozioni(),
                            prodotto: (await this._analytics.getProductData(e))
                        }
                        step = 'FUNNEL_DATA_CART_REPLACE_CHANGE';
                        break;
                    case 'advClick':
                        //6.9.2
                        var card = _$(e);

                        analyticsData = {
                            event: eventType,
                            carrello: {
                                importo: importoCarr,
                                consegnaGratuita: consegnaGrat,
                                importoConsegna: importoCons,
                                prodotti
                            },
                            sponsor: [{
                                brand: card.find('.text div').html(),
                                campagna: card.find('.text p').html(),
                                formato: 'Banner promo',
                                landing: card.find('a').attr('href'),
                                posizioneSlide: card.closest('.swiper-wrapper').find('.swiper-slide').length > 1 ? card.closest('.swiper-slide').index() + 1 : 0,
                            }]
                        }
                        step = 'FUNNEL_DATA_CART_CHECKOUT';
                        
                        if(_$(card).attr('data-promo')){
                            dataPromoArray.push(JSON.parse(_$(card).attr('data-promo')))
                        }else{
                            dataPromoArray.push("");
                        }
    
                        this._saveAnalyticsCart();  // why????
                        break;
                        case 'infoConsegna':
                            //4.3.36
                            analyticsData = {
                                event: eventType,
                                funnel: {
                                    nomeFunnel: 'Carrello',
                                    stepFunnel: 'Tooltip info consegna',
                                },
                                carrello: {
                                    importo: importoCarr,
                                    consegnaGratuita: consegnaGrat,
                                    importoConsegna: importoCons,
                                    prodotti
                                }
                            };
                            this._analytics.sendAnalytics(analyticsData, '', false);
                            return;
                        //1.3.1
                        case 'scegliModalitaSostituzione':
                            analyticsData = {
                                event: eventType,
                                carrello: {
                                    spesaMinimaRaggiunta: spesaMin,
                                    prodotti
                                }
                            }
                            step = 'FUNNEL_DATA_CART_MODALITA_SOSTITUZIONE';
                            break;
                        //1.3.2
                        case 'pageviewPopupModalitaSostituzione':
                            analyticsData = {
                                event : 'pageview',
                                carrello: {
                                    spesaMinimaRaggiunta: spesaMin,
                                    prodotti
                                }
                            }

                            step = 'FUNNEL_DATA_CART_POPUP_MODALITA_SOSTITUZIONE';
                            break;
                        //1.3.3
                        case 'annullaModalitaSostituzione':
                            analyticsData = {
                                event : eventType,
                                carrello: {
                                    spesaMinimaRaggiunta: spesaMin,
                                    prodotti
                                }
                            }

                            step = 'FUNNEL_DATA_CART_POPUP_MODALITA_SOSTITUZIONE';
                            break;
                        //1.3.4
                        case 'chiusuraModaleModalitaSostituzione':

                            analyticsData = {
                                event : 'chiusuraModale',
                                carrello: {
                                    spesaMinimaRaggiunta: spesaMin,
                                    prodotti
                                }
                            }

                            step = 'FUNNEL_DATA_CART_POPUP_MODALITA_SOSTITUZIONE';
                            break;
                        //1.3.5
                        case 'disattivaModalitaSostituzione':
                            switch(oldSostituzione){
                                case 'AUTOMATIC_SUBSTITUTION':
                                    oldSostituzioneMapping = 'Sceglie Conad'
                                    break;
                                case 'SEMI_AUTOMATIC_SUBSTITUTION':
                                    oldSostituzioneMapping = 'Chiamami';
                                    break;
                                case 'PUNCTUAL_SUBSTITUTION':
                                    oldSostituzioneMapping = 'Scelgo io';
                                    break;
                            }

                            analyticsData = {
                                event : eventType,
                                carrello: {
                                    spesaMinimaRaggiunta: spesaMin,
                                    prodotti,
                                    modalitaSostituzione: oldSostituzioneMapping
                                }
                            }

                            step = 'FUNNEL_DATA_CART_POPUP_MODALITA_SOSTITUZIONE';
                            break;
                        //1.3.6
                        case 'modificaModalitaSostituzione':
                            console.log('modifica modalita sostituzione');
                            switch(oldSostituzione){
                                case 'AUTOMATIC_SUBSTITUTION':
                                    oldSostituzioneMapping = 'Sceglie Conad'
                                    break;
                                case 'SEMI_AUTOMATIC_SUBSTITUTION':
                                    oldSostituzioneMapping = 'Chiamami';
                                    break;
                                case 'PUNCTUAL_SUBSTITUTION':
                                    oldSostituzioneMapping = 'Scelgo io';
                                    break;
                            }

                            analyticsData = {
                                event : eventType,
                                carrello: {
                                    spesaMinimaRaggiunta: spesaMin,
                                    prodotti,
                                    modalitaSostituzione: oldSostituzioneMapping
                                }
                            }

                            analyticsData = {
                                event: eventType
                            }

                            step = 'FUNNEL_DATA_CART_CTA_MODIFICA_MODALITA_SOSTITUZIONE';
                            break;
            }

            if (dataPromoArray.length) {
                this._analytics.sendAnalytics(analyticsData, step, true, true, dataPromoArray);
            } else {
                this._analytics.sendAnalytics(analyticsData, step);
            }
        } catch (error) {
            console.log(eventType + ' : ' + error);
        }
    }

    mySlideNext(swiperType, item) {
        switch (swiperType) {
            case "suggested":
                this._utils.mySlideNext(this.swiperSuggestedProductsOptions, _$(item).closest('.swiper-suggested-products')[0].swiper);
            break;
            case "favorite":
                this._utils.mySlideNext(this.swiperFavoritesOptions, _$(item).closest('.swiper-favorites')[0].swiper);
            break;
            case "last":
                this._utils.mySlideNext(this.swiperLastViewsOptions, _$(item).closest('.swiper-last-views')[0].swiper);
            break;
        }
    }

    mySlidePrev(swiperType, item) {
        switch (swiperType) {
            case "suggested":
                this._utils.mySlidePrev(this.swiperSuggestedProductsOptions, _$(item).closest('.swiper-suggested-products')[0].swiper);
            break;
            case "favorite":
                this._utils.mySlidePrev(this.swiperFavoritesOptions, _$(item).closest('.swiper-favorites')[0].swiper);
            break;
            case "last":
                this._utils.mySlidePrev(this.swiperLastViewsOptions, _$(item).closest('.swiper-last-views')[0].swiper);
            break;
        }
    }

    _saveAnalyticsCart() {

        let importoCarr = Window.cart && Window.cart.subTotal && Window.cart.subTotal.value ? Window.cart.subTotal.value : 0;
        let importoCons;
        if(_$('.card-scontrino-top').find('.delivery-costs').length > 0){
            importoCons = _$('.card-scontrino-top').find('.delivery-costs').find('.value').length ? parseFloat(_$('.card-scontrino-top').find('.delivery-costs').find('.value').html().replace(/\s+/g, '').replace('€','').replace(',','.')) : 0;
        }else{
            importoCons = 0;
        }
        let consegnaGrat = parseInt(_$('.card-scontrino-top').find('.delivery-costs-progress-bar').find('.progress-bar-custom').attr('value')) == 90 ? 'SI' : 'NO';
        
        let prodotti = [];
        let prodottiCarrello = _$('.component-ProductCardDetailCart');
        for (var i = 0; i < prodottiCarrello.length; i++) {
            let prodotto = this._analytics.getProductData(prodottiCarrello.eq(i));
            prodotti.push(prodotto);
        }

        this.localStorage.setItem('carrelloAnalytics', JSON.stringify({
            importo: importoCarr,
            consegnaGratuita: consegnaGrat,
            importoConsegna: importoCons,
            prodotti
        }));
    }

    async manageRedirectCheckout(buttonCTA: object){

        /* save analytics cart */
        this._saveAnalyticsCart();

        /* al success */
        let link = _$(buttonCTA).attr('data-href');
        this._utils.showLoader('', true);
        if(Window.pointOfService && Window.pointOfService.type && Window.pointOfService.type == "LOCKER"){
            if(Window.selectedTimeslot && Window.selectedTimeslotExpiration){
                /* se c'è il timeslot si chiama la nuova api */
                let success = (res) => {
                    if(link){
                        window.location.href = link;
                        this.sendAnalytics('clickCheckout');
                    }else{
                        this._utils.showLoader('', false);
                        console.error('no link found in data-href');
                    }
                }
    
                let error = (err) => {
                    this._utils.showLoader('', false);
                    if(err && err.response && err.response.data && err.response.data.message &&
                        this._lockerUtils.parseLockerErrorMessageAddToCart(err.response.data.message) && this._lockerUtils.parseLockerErrorMessageAddToCart(err.response.data.message)['invalidUnitObjects'] && this._lockerUtils.parseLockerErrorMessageAddToCart(err.response.data.message)['invalidUnitObjects'].length){
                        /* Gestione prodotti indicizzati male */
                        this.lockerOversizeProducts = [];
                        _$('#modalLockerOversizeDimensions .product-remove-list').html('');
                        let errorProducts = this._lockerUtils.parseLockerErrorMessageAddToCart(err.response.data.message)['invalidUnitObjects'];

                        for(let i = 0; i < errorProducts.length; i++){
                            _$('#modalLockerOversizeDimensions .product-remove-list').append(this._lockerUtils.createCustomCardLockerError(errorProducts[i]));
                        }
                        _Uikit.modal('#modalLockerOversizeDimensions').show();
                        _$('#modalLockerOversizeDimensions .btn-group .uk-button').attr('onclick', 'DetailCart.removeOversizeLocker()');
                        this.lockerOversizeProducts = errorProducts;
                    }else if(this._lockerUtils.checkTooManyUnitsPresence(err)){
                        _Uikit.modal('#modal-timeslot').show();
                        _$('#modal-timeslot').addClass('locker-error');
                    }else{
                        /* senza timeslots perchè il carrello non va bene */
                        _Uikit.modal('#modalSlotPenguinInsufficienti').show();
                    }
                }
    
                this._cartService.checkCartValidityForLocker(success, error);
            }else{
                /* Se non c'è il timeslot si chiama la get timeslots */
                let checkTimeslotsObject = await this._bookUtils.checkTimeslotsOnGoToCheckout();
                
                if(checkTimeslotsObject && checkTimeslotsObject['timeslots']){
                    this._utils.showLoader('', false);
                    this._bookUtils.modifyStep2('ritira');
                    this._bookUtils.bookingSlotState = BOOKING_SLOT_STATE.TO_CHECKOUT;
                    _$('#modal-timeslot .js-button-conferma-timeslot').attr('onclick', 'BookUtils.bookingSlot()');
                }else{
                    this._utils.showLoader('', false);
                    if(checkTimeslotsObject != null && !checkTimeslotsObject['timeslots'] && checkTimeslotsObject['invalidUnitObjects']){
                        this.lockerOversizeProducts = [];
                        /* senza timeslots perchè alcuni prodotti non stanno fisicamente dentro i locker */
                        /* caso di prodotti indicizzati male */
                        _$('#modalLockerOversizeDimensions .product-remove-list').html('');
                        let errorProducts = checkTimeslotsObject['invalidUnitObjects'];
                        for(let i = 0; i < errorProducts.length; i++){
                            _$('#modalLockerOversizeDimensions .product-remove-list').append(this._lockerUtils.createCustomCardLockerError(errorProducts[i]));
                        }
                        _Uikit.modal('#modalLockerOversizeDimensions').show();
                        _$('#modalLockerOversizeDimensions .btn-group .uk-button').attr('onclick', 'DetailCart.removeOversizeLocker()');
                        this.lockerOversizeProducts = errorProducts;
                    }else if(checkTimeslotsObject != null && checkTimeslotsObject['ConadLockerTooManyUnitsError']){
                        _Uikit.modal('#modal-timeslot').show();
                        _$('#modal-timeslot').addClass('locker-error');
                    }else{
                        /* Senza timeslots perchè il carrello non va bene */
                        _Uikit.modal('#modalSlotPenguinInsufficienti').show();
                    }
                }
            }
        }else{
            if(link){
                if(Window.selectedTimeslot && Window.selectedTimeslotExpiration){
                    window.location.href = link;
                    this.sendAnalytics('clickCheckout');
                }else{
                    this._bookUtils.modifyStep2(Window.typeOfService == "ORDER_AND_COLLECT" ? 'ritira' : 'ricevi');
                    this._bookUtils.bookingSlotState = BOOKING_SLOT_STATE.TO_CHECKOUT;
                    _$('#modal-timeslot .js-button-conferma-timeslot').attr('onclick', 'BookUtils.bookingSlot()');
                }
            }else{
                this._utils.showLoader('', false);
                console.error('no link found in data-href');
            }
        }
    }

    /* rimuove i prodotti oversize per il locker */
    removeOversizeLocker(){
        if(this.lockerOversizeProducts && this.lockerOversizeProducts.length){
            this._utils.showLoader('', true);
            let entriesToDelete = [];
            for(let i = 0; i < this.lockerOversizeProducts.length; i++){
                entriesToDelete.push(this.lockerOversizeProducts[i].entryId);
            }
            
            let success = (res) => {
                _Uikit.modal('#modalLockerOversizeDimensions').hide();
                window.location.reload();
            }

            let error = (err) => {
                console.error(err);
                _Uikit.modal('#modalLockerOversizeDimensions').hide();
                this.reloadCart();
                this._utils.showLoader('', false);
            }

            let body = {
                "clean": "true",
                "entriesToDelete": entriesToDelete.toString().replace('[', '').replace(']', '')
            }

            this._cartService.addRemoveItemToCart(success, error, body);
        }else{
            _Uikit.modal('#modalLockerOversizeDimensions').hide();
        }
    }

    initProductCardSelects(e: object){
        if(!_$(e).hasClass('select-native-ui-loaded')){
            let _this = this;
            let customSelect = _$(e).closest('.select-native-ui');
            let customOptionText = '';
            let dataProduct = _$(e).closest('.component-ProductCardDetailCart').length && _$(e).closest('.component-ProductCardDetailCart').attr('data-product') ? JSON.parse(_$(e).closest('.component-ProductCardDetailCart').attr('data-product')) : '';
            let maxQty;
            
            if(dataProduct && dataProduct.increment){
                maxQty = _$(e).closest('.component-ProductCardDetailCart').length && _$(e).closest('.component-ProductCardDetailCart').attr('data-maxQuantity') ? parseInt(_$(e).closest('.component-ProductCardDetailCart').attr('data-maxQuantity')) : 20;
                let maxOpts = maxQty;
                let currentQuantity = _$(customSelect).find('select option.selection').val() ? _$(customSelect).find('select option.selection').val() : 0;
                _$(customSelect).find('option').remove();
                _$(customSelect).append(_$(('<option>'), {
                    value: 0,
                    text: '0 g'
                }));
                
                if(dataProduct.increment.minWeight == currentQuantity){

                    if(dataProduct.increment.unitOfMeasure == "KG"){
                        customOptionText = dataProduct.increment.minWeight < 1 ? dataProduct.increment.minWeight * 1000 +' g' : dataProduct.increment.minWeight +' kg';
                    }else if(dataProduct.increment.unitOfMeasure == "GR"){
                        customOptionText = dataProduct.increment.minWeight > 999 ? dataProduct.increment.minWeight/1000 +' kg' : dataProduct.increment.minWeight +' g';
                    }else{
                        customOptionText = dataProduct.increment.minWeight > 999 ? dataProduct.increment.minWeight/1000 +' kg' : dataProduct.increment.minWeight +' g';
                    }

                    _$(customSelect).find('select').append(_$(('<option>'), {
                        value: dataProduct.increment.minWeight,
                        text: customOptionText,
                        class: 'selection',
                        selected: 'selected'
                    }));
                }else{
                    if(dataProduct.increment.unitOfMeasure == "KG"){
                        customOptionText = dataProduct.increment.minWeight < 1 ? dataProduct.increment.minWeight * 1000 +' g': dataProduct.increment.minWeight + ' kg';
                    }else if(dataProduct.increment.unitOfMeasure == "GR"){
                        customOptionText = dataProduct.increment.minWeight > 999 ? dataProduct.increment.minWeight/1000 +' kg': dataProduct.increment.minWeight + ' g';
                    }else{
                        customOptionText = dataProduct.increment.minWeight > 999 ? dataProduct.increment.minWeight/1000 +' kg': dataProduct.increment.minWeight + ' g';
                    }

                    _$(customSelect).find('select').append(_$(('<option>'), {
                        value: dataProduct.increment.minWeight,
                        text: customOptionText
                    }));
                }

                for(let i = dataProduct.increment.minWeight + dataProduct.increment.increment, x = 0; x <= maxOpts; i+= dataProduct.increment.increment, x++){
                    i = parseFloat(i.toFixed(4));
                    if(i != currentQuantity){
                        if(dataProduct.increment.unitOfMeasure == "KG"){
                            customOptionText = i < 1 ? i * 1000 + ' g' : i + ' kg';
                        }else if(dataProduct.increment.unitOfMeasure == "GR"){
                            customOptionText = i > 999 ? i/1000 + ' kg' : i + ' g';
                        }else{
                            customOptionText = i > 999 ? i/1000 + ' kg' : i + ' g';
                        }

                        _$(customSelect).find('select').append(_$(('<option>'), {
                            value: i,
                            text: customOptionText
                        }));
                        
                    }else{
                        if(dataProduct.increment.unitOfMeasure == "KG"){
                            customOptionText = i < 1 ? i * 1000 +' g' : i+' kg';
                        }else if(dataProduct.increment.unitOfMeasure == "GR"){
                            customOptionText = i > 999 ? i/1000 +' kg' : i+' g';
                        }else{
                            customOptionText = i > 999 ? i/1000 +' kg' : i+' g';
                        }

                        _$(customSelect).find('select').append(_$(('<option>'), {
                            value: i,
                            text: customOptionText,
                            class: 'selection',
                            selected: 'selected'
                        }));
                    }
                }
            }else{
                maxQty = _$(e).closest('.component-ProductCardDetailCart').length && _$(e).closest('.component-ProductCardDetailCart').attr('data-maxQuantity') ? parseInt(_$(e).closest('.component-ProductCardDetailCart').attr('data-maxQuantity')) : 99;
                let maxOpts = maxQty;
                let currentQuantity = _$(customSelect).find('select option.selection').text() ? parseInt(_$(customSelect).find('select option.selection').text()) : 0;
                _$(customSelect).find('option').remove();
                
                for(let i = 0; i <= maxOpts; i++){
                    if(i != currentQuantity){
                        _$(customSelect).find('select').append(_$(('<option>'), {
                            value: i,
                            text: i
                        }));
                    }else{
                        _$(customSelect).find('select').append(_$(('<option>'), {
                            value: i,
                            text: i,
                            class: 'selection',
                            selected: 'selected'
                        }));
                    }
                }
            }

            let currentQuantity = _$(customSelect).find('select option.selection').text() ? parseInt(_$(customSelect).find('select option.selection').text()) : 0;
            if(dataProduct && dataProduct.volume && Window.volume && Window.volume.cartVolume > -1 && Window.volume.maxVolume){
                let maxHeavyOpts = currentQuantity + (Window.volume.cartVolume > Window.volume.maxVolume ? 
                                        -Math.ceil((Window.volume.cartVolume - Window.volume.maxVolume)/dataProduct.volume) : 
                                        Math.floor((Window.volume.maxVolume - Window.volume.cartVolume) / dataProduct.volume));
                maxHeavyOpts = Math.max(0, maxHeavyOpts);
                
                if(maxHeavyOpts < maxQty){
                    _$(customSelect).find('option').removeClass('uk-hidden');
                    _$(customSelect).find('option').filter(function() {
                        return parseFloat(this.value) > maxHeavyOpts;
                    }).addClass('uk-hidden');
                }
            }
            _$(customSelect).addClass('select-native-ui-loaded');
        }
    }

    setReplacementModeAnalytics(){
        if(_$('.component-ReplacementMode').length){
            _$('.component-ReplacementMode').find('.no-replace a.edit').on('click', () => {
                this.sendAnalytics('scegliModalitaSostituzione');
            });

            _$('.component-ReplacementMode').find('.replacement-active a.edit').on('click', () => {
                this.sendAnalytics('modificaModalitaSostituzione');
            });
        }
    }
}
