import { Analytics } from "../../services/analytics/analytics";
import { ValidationUtils } from "../../services/utils/validation-utils";
import {LOADER_POSITION, Utils } from "../../services/utils/utils";
import { BOOKING_SLOT_STATE, BookUtils } from "../../services/utils/book-utils";
import { _$, _Uikit, Window } from '../../main';
import { LocalStorage } from '../../services/local-storage/local-storage';
import { StoreService } from '../../services/store-service/store-service';
import { GoogleAddressUtils } from './google-address-utils';

export enum MODAL_ONBOARDING_STATUS {
    DEFAULT,
    INCOMPLETE_ADDRESS
}

export class OnboardingManager{

    public addressLoadedOnStep0;
    public addressExtendService = '';
    public selectedService;
    public modalStatus;
    _modalOnboarding = _$('#modal-onboarding');


    constructor(private utils: Utils,
                private validationUtils: ValidationUtils,
                private bookUtils: BookUtils,
                private localStorage: LocalStorage,
                private storeService: StoreService,
                private _analytics: Analytics,
                private googleAddressUtils: GoogleAddressUtils
                ){
        this.setModalOnboardingOnClickEvents();
        if(Window.typeOfService){
            this.initAddressOnModal(Window.typeOfService)
        }

        _$('#modal-onboarding').attr('data-status', MODAL_ONBOARDING_STATUS.DEFAULT);

        _$('#modal-onboarding').on('shown', () => {
            this.utils.showLoader('', false);
            _$('#modal-onboarding').attr('data-status', MODAL_ONBOARDING_STATUS.DEFAULT);
        })
    }

    setModalOnboardingOnClickEvents(){

        _$('.component-card-spesa-a-casa .delivery-button.incomplete').on('click', (event) => {
            this.showSectionAddressEditIncompleteAddress();
        });

        _$('#modal-onboarding .btn-annulla, .btn-annulla-mobile').on('click', () => {
            if(this.checkDataStatusIncompleteAddress()){
                this.sendAnalytics('1.4.4','ctaAnnulla');
            }else{
                this.sendAnalytics('1.2.7','ctaAnnulla');
            }
            
            this.showSectionAddressComplete();
            _$('#modal-onboarding').attr('data-status', MODAL_ONBOARDING_STATUS.DEFAULT);
        });

        _$('#modal-onboarding').on('hide', (event) => {
            if(_$(event.target).attr('id') == 'modal-onboarding'){
                _$('#modal-onboarding').attr('data-status', MODAL_ONBOARDING_STATUS.DEFAULT);
            }
        })

        _$('#modal-onboarding #section-estensione-servizio .to-back').on('click', () => {
            this.showStep1(this.addressLoadedOnStep0['formattedAddress']);
            this.sendAnalytics('1.2.15','tornaIndietro');

        });
        _$('#modal-onboarding .to-back').on('click', () => {
            this.showStep1(this.addressLoadedOnStep0['formattedAddress']);

        });

        _$('.component-card-ordina-e-ritira').find('a.richiedi-estensione').on('click', (val) => {
            this.addressExtendService = 'ORDER_AND_COLLECT';
            this.showStepExtend();
            this.sendAnalytics('1.2.9','formEstensioneServizio');
            this.sendAnalytics('1.2.10','pageview');
        });
        
        _$('.component-card-spesa-a-casa').find('a.richiedi-estensione').on('click', () => {
            this.addressExtendService = 'HOME_DELIVERY';
            this.showStepExtend();
            this.sendAnalytics('1.2.9','formEstensioneServizio');
            this.sendAnalytics('1.2.10','pageview');
        });

        _$('#section-estensione-servizio .send-request').on('click', () => {
            this.extendAddresses(this.addressExtendService);
            this.sendAnalytics('1.2.11','inviaRichiestaEstensione');
        });

        _$('#ordina-ritira-scelta-pdv .toList').on('click', () => {
            _$('#ordina-ritira-scelta-pdv').find('.lista-negozi-section').show();
            _$('#ordina-ritira-scelta-pdv').find('.lista-negozi-section').removeClass('lista-negozi-section-hidden');
            _$('#ordina-ritira-scelta-pdv').find('.toList').hide();
            _$('#ordina-ritira-scelta-pdv').find('.toMap').fadeIn();
        })

        _$('#ordina-ritira-scelta-pdv .toMap').on('click', () => {
            _$('#ordina-ritira-scelta-pdv').find('.lista-negozi-section').hide();
            _$('#ordina-ritira-scelta-pdv').find('.lista-negozi-section').addClass('lista-negozi-section-hidden');
            _$('#ordina-ritira-scelta-pdv').find('.toList').fadeIn();
            _$('#ordina-ritira-scelta-pdv').find('.toMap').hide();
        });

        _$('#ordina-ritira-scelta-pdv .vedi_lista').on('click', () => {
            this.initMobileListSection();
        })

        _$('#ordina-ritira-scelta-pdv .vedi_mappa').on('click', () => {
            this.initMobileMapSection();
        })

        _$('#modal-onboarding').find('.btn-conferma-pdv button').on('click', () => {
            this.utils.showLoader(_$('#modal-onboarding').find('.btn-conferma-pdv button'), true, LOADER_POSITION.BUTTON);
            this.sendAnalytics('1.3.2','selezioneNegozio');
            this.selectStore();
        })
    }

    /* Gestione steps modale
        - step0: scelta servizio
        - step1: scelta store
        - estensione: estensione servizio
    */
    manageModalSteps(step, service?: string, address?: object, addNewAddress?: boolean){
        /* settaggio di parametri necessari allo scegli dopo */
        this.hideSteps();
        
        switch(step){
            case 'step0':
                this.modalStatus = step;
                break;
            case 'step1':
                this.addressLoadedOnStep0 = address;
                this.modalStatus = step;
                this.showStep1(address['formattedAddress']);
                _Uikit.modal('#modal-onboarding').show();
                break;
            case 'step2':
                
                this.showStep2(service);
                this.modalStatus = step;
                _Uikit.modal('#modal-onboarding').show();
                break;
            case 'extend':
                this.modalStatus = step;
                this.showStepExtend();
                break;
            case 'step2Checkout':
                this.showStep2Checkout(address);
                this.modalStatus = step;
                _Uikit.modal('#modal-onboarding').show();
                break;
            case 'step1Checkout':
                this.addressLoadedOnStep0 = address;
                this.modalStatus = step;
                this.showStep1Checkout();
                _Uikit.modal('#modal-onboarding').show();
                if(Window.typeOfService == "HOME_DELIVERY"){
                    if(addNewAddress){
                        _$('#modal-onboarding').addClass('hide-card-scegli-spesa');
                    }else{
                        _$('#modal-onboarding').removeClass('hide-card-scegli-spesa');
                    }   
                }
                break;
        }
    }

    initAddressOnModal(service){
        if(service == "HOME_DELIVERY"){
            this.addressLoadedOnStep0 = Window.cart && Window.cart.deliveryAddress && Window.cart.deliveryAddress ? Window.cart.deliveryAddress : {};
            _$('#modal-onboarding .section-address .section-address-complete').find('span').html(
                Window.cart && Window.cart.deliveryAddress && Window.cart.deliveryAddress && Window.cart.deliveryAddress.formattedAddress ?
                    Window.cart.deliveryAddress.formattedAddress : ''
            );
        }else if(service == "ORDER_AND_COLLECT"){
            this.addressLoadedOnStep0 = Window.pointOfService && Window.pointOfService.address && Window.pointOfService.address ? Window.pointOfService.address : {};
            _$('#modal-onboarding .section-address .section-address-complete').find('span').html(
                Window.pointOfService && Window.pointOfService.address && Window.pointOfService.address && Window.pointOfService.address.formattedAddress ?
                    Window.pointOfService.address.formattedAddress : ''
            );
        }
    }

    loading(status: boolean) {
        this.utils.showLoader(_$('#modal-onboarding > div'), status, LOADER_POSITION.FULLPAGE_ORANGE);
    }
    
    showSectionAddressComplete(){
        if(this.modalStatus == 'step2Checkout'){
            _$('#modal-onboarding').removeClass('address-edit on-loading');
            this.manageModalSteps('step2Checkout', null, this.addressLoadedOnStep0);
        }else{
            _$('#modal-onboarding').addClass('address-complete').removeClass('address-edit');
        }
    }

    showSectionAddressEdit(googleInput?){
        _$('#modal-onboarding').removeClass('address-complete').addClass('address-edit');
        let address = this.googleAddressUtils.googleAddressSearched;
        let input = googleInput ? googleInput : _$('#modal-onboarding').find('.google-input:visible');
        if(address){
            _$(input).find('.google-input-line1').val(address.formatted_address_no_street_number ? address.formatted_address_no_street_number : '');
            if(address.isStreetAddress){
                _$(input).find('.google-input-line2').val(address.street_number ? address.street_number : '');
                _$(input).find('.google-input-line2').removeClass('uk-hidden');
                _$(input).find('.google-input-line2').next().removeClass('uk-hidden');
            }
        }
    }

    showSectionAddressEditCheckout(){
        _$('#modal-onboarding').removeClass('address-complete, address-edit');
        _$('#modal-onboarding').find('.section-address-checkout .section-address-complete').hide();
        _$('#modal-onboarding').find('.section-address-checkout .section-address-edit').fadeIn();
    }

    showSectionAddressEditIncompleteAddress(){
        this.showSectionAddressEdit();
        _$('#modal-onboarding').attr('data-status', MODAL_ONBOARDING_STATUS.INCOMPLETE_ADDRESS);
        let address = this.googleAddressUtils.googleAddressSearched;
        if(address && address.isStreetAddress && !address.street_number){
            _$('#modal-onboarding').find('.google-input:visible .google-input-line2').trigger('focus');
        }
    }

    showStep1(address){
        _$('#modal-onboarding .section-address .section-address-complete .address').attr('onclick', 'OnboardingManager.showSectionAddressEdit()');
        _$('#modal-onboarding .section-address .section-address-complete').find('span').html(address);
        this.hideStep2();
        this.hideStepExtend();
        this.hideStep1Checkout();
        if(Window.typeOfService){
            this.hideStep2Checkout(Window.typeOfService);
        }
        this.fadeInStep1();
        this.showSectionAddressComplete();
    }

    showStep1Checkout(){
        this.hideStep1();
        this.hideStep2();
        this.hideStepExtend();
        if(Window.typeOfService){
            this.hideStep2Checkout(Window.typeOfService);
        }
        this.fadeInStep1Checkout();
        this.showSectionAddressEditCheckout();
    }

    fadeInStep1(){
        _$('#modal-onboarding .modal-top-bar').fadeIn();
        _$('#modal-onboarding #modal-onboarding-content').fadeIn();
        _$('#modal-onboarding').find('.section-scelta-spesa').fadeIn();
        _$('#modal-onboarding').find('.section-address').fadeIn();
    }

    fadeInStep1Checkout(){
        _$('#modal-onboarding .modal-top-bar').fadeIn();
        _$('#modal-onboarding #modal-onboarding-content').fadeIn();
        _$('#modal-onboarding').addClass('add-delivery-address type-checkout address-edit-no-btn-annulla');
        _$('#modal-onboarding .to-back').fadeIn();
        _$('#modal-onboarding').find('.section-scelta-spesa').fadeIn();
        _$('#modal-onboarding').find('.section-address-checkout').fadeIn();
    }

    hideStep1(){
        _$('#modal-onboarding .modal-top-bar').hide();
        _$('#modal-onboarding #modal-onboarding-content').hide();
        _$('#modal-onboarding').find('.section-scelta-spesa').hide();
        _$('#modal-onboarding').find('.section-address').hide();
    }

    hideStep1Checkout(){
        _$('#modal-onboarding .modal-top-bar').hide();
        _$('#modal-onboarding #modal-onboarding-content').hide();
        _$('#modal-onboarding').removeClass('add-delivery-address type-checkout address-edit-no-btn-annulla');
        _$('#modal-onboarding .to-back').hide();
        _$('#modal-onboarding').find('.section-scelta-spesa').hide();
        _$('#modal-onboarding').find('.section-address-checkout').hide();
    }

    showStep2(service: string){
        _$('#modal-onboarding .section-address .section-address-complete .address').attr('onclick', "OnboardingManager.showStep1(OnboardingManager.addressLoadedOnStep0['formattedAddress']); OnboardingManager.showSectionAddressEdit();");
        this.showSectionAddressComplete();
        this.hideStepExtend();
        this.hideStep1();
        this.hideStep1Checkout();
        if(Window.typeOfService){
            this.hideStep2Checkout(Window.typeOfService);
        }
        this.fadeInStep2(service);
        this.initMobileListSection();
    }

    fadeInStep2(type?: string) {
        _$('#modal-onboarding .modal-top-bar').fadeIn();
        _$('#modal-onboarding #modal-onboarding-content').fadeIn();
        _$('#modal-onboarding .to-back').fadeIn();
        _$('#modal-onboarding').find('.section-scelta-spesa').hide();
        _$('#modal-onboarding').addClass('step-2');
        _$('#modal-onboarding').find('.section-address').fadeIn();
        if(type == 'HOME_DELIVERY') {
            _$('#modal-onboarding').find('.section-spesa-a-casa').fadeIn();

        } else if(type == 'ORDER_AND_COLLECT') {
            _$('#modal-onboarding').find('.section-ordina-e-ritira').fadeIn();
        }
    }

    hideStep2(){
        _$('#modal-onboarding').find('.section-address').hide();
        _$('#modal-onboarding').find('.section-spesa-a-casa').hide();
        _$('#modal-onboarding').find('.section-ordina-e-ritira').hide();
        _$('#modal-onboarding').find('.component-card-negozio').removeClass('selected');
        _$('#modal-onboarding').removeClass('selected-pdv');
        _$('#modal-onboarding .to-back').hide();
        _$('#modal-onboarding').removeClass('step-2');
    }

    showStep2Checkout(address){
        this.hideStepExtend();
        this.hideStep1();
        this.hideStep1Checkout();
        this.hideStep2CheckoutOnloading();
        this.hideStep2();
        this.initMobileListSection();
        this.fadeInStep2Checkout(Window.typeOfService, address);
        this.showSectionAddressEditCheckout();
    }

    fadeInStep2Checkout(type, address?){
        _$('#modal-onboarding').find('.section-address-checkout').fadeIn();
        _$('#modal-onboarding .modal-top-bar').fadeIn();
        _$('#modal-onboarding #modal-onboarding-content').fadeIn();
        _$('#modal-onboarding').addClass('step-2');
        _$('#modal-onboarding').addClass('type-checkout');
        if(type == 'HOME_DELIVERY') {
            _$('#modal-onboarding').find('.section-spesa-a-casa').fadeIn();
            if(address){
                _$('#modal-onboarding').find('#googleInputOnboardingCheckoutLine1').val(address.line1 ? address.line1 : '');
                if(address.line2){
                    _$('#modal-onboarding').find('#googleInputOnboardingCheckoutLine2').val(address.line2 ? address.line2 : '');
                    _$('#modal-onboarding').find('#googleInputOnboardingCheckoutLine2').removeClass('uk-hidden');
                    _$('#modal-onboarding').find('#googleInputOnboardingCheckoutLine2').next().removeClass('uk-hidden');
                }
            }else{
                _$('#modal-onboarding').find('#googleInputOnboardingCheckoutLine1').val(Window.cart && Window.cart.deliveryAddress && Window.cart.deliveryAddress.line1 ? Window.cart.deliveryAddress.line1 : '');
                if(Window.cart && Window.cart.deliveryAddress && Window.cart.deliveryAddress.line2){
                    _$('#modal-onboarding').find('#googleInputOnboardingCheckoutLine2').val(Window.cart && Window.cart.deliveryAddress && Window.cart.deliveryAddress.line2 ? Window.cart.deliveryAddress.line2 : '');
                    _$('#modal-onboarding').find('#googleInputOnboardingCheckoutLine2').removeClass('uk-hidden');
                    _$('#modal-onboarding').find('#googleInputOnboardingCheckoutLine2').next().removeClass('uk-hidden');
                }
            }
            
        } else if(type == 'ORDER_AND_COLLECT') {
            _$('#modal-onboarding').find('.section-ordina-e-ritira').fadeIn();
            if(address){
                if(address.notCompleted){
                    _$('#modal-onboarding').find('#googleInputOnboardingCheckoutLine1').val(address.formattedAddress ? address.formattedAddress : '');
                }else{
                    _$('#modal-onboarding').find('#googleInputOnboardingCheckoutLine1').val(address.line1 ? address.line1 : '');
                    if(address.line2){
                        _$('#modal-onboarding').find('#googleInputOnboardingCheckoutLine2').val(address.line2 ? address.line2 : '');
                        _$('#modal-onboarding').find('#googleInputOnboardingCheckoutLine2').removeClass('uk-hidden');
                        _$('#modal-onboarding').find('#googleInputOnboardingCheckoutLine2').next().removeClass('uk-hidden');
                    }
                }
                
            }else{
                _$('#modal-onboarding').find('#googleInputOnboardingCheckoutLine1').val(Window.pointOfService && Window.pointOfService.address && Window.pointOfService.address.line1 ? Window.pointOfService.address.line1 : '');
                if(Window.pointOfService && Window.pointOfService.address && Window.pointOfService.address.line2){
                    _$('#modal-onboarding').find('#googleInputOnboardingCheckoutLine2').val(Window.pointOfService && Window.pointOfService.address && Window.pointOfService.address.line2 ? Window.pointOfService.address.line2 : '');
                    _$('#modal-onboarding').find('#googleInputOnboardingCheckoutLine2').removeClass('uk-hidden');
                    _$('#modal-onboarding').find('#googleInputOnboardingCheckoutLine2').next().removeClass('uk-hidden');
                }
            }
        }
    }

    hideStep2Checkout(type){
        _$('#modal-onboarding').find('.section-address-checkout').hide();
        _$('#modal-onboarding .modal-top-bar').hide();
        _$('#modal-onboarding #modal-onboarding-content').hide();
        _$('#modal-onboarding').removeClass('step-2');
        _$('#modal-onboarding').removeClass('type-checkout');
        if(type == 'HOME_DELIVERY') {
            _$('#modal-onboarding').find('.section-spesa-a-casa').hide();
        } else if(type == 'ORDER_AND_COLLECT') {
            _$('#modal-onboarding').find('.section-ordina-e-ritira').hide();
        }
    }

    hideStep2CheckoutOnloading(){
        _$('#modal-onboarding').removeClass('address-edit on-loading');
    }

    /**
     * Funzione unica che nasconde gli steps
     */
    hideSteps() {
        _$('#modal-onboarding').find('#modal-onboarding-wrapper-googleuser').hide();
        _$('#modal-onboarding').find('#modal-onboarding-wrapper').show();
    }

    showStepExtend(){
        _$('#modal-onboarding .section-address-complete .address').attr('onclick', "OnboardingManager.showStep1(OnboardingManager.addressLoadedOnStep0['formattedAddress'])");
        _$('#section-estensione-servizio #field_nome').val(Window.user && Window.user.nome ? Window.user.nome : '');
        _$('#section-estensione-servizio #field_cognome').val(Window.user && Window.user.cognome ? Window.user.cognome : '');
        _$('#section-estensione-servizio #email').val(Window.user && Window.user.email ? Window.user.email : '');
        if(Window.user){
            this.isValidForm();
        }
        this.hideStep1Checkout();
        this.hideStep1();
        this.hideStep2();
        if(Window.typeOfService){
            this.hideStep2Checkout(Window.typeOfService);
        }
        this.fadeInStepExtend();
    }

    fadeInStepExtend(){
        _$('#modal-onboarding .modal-top-bar').hide();
        _$('#modal-onboarding #modal-onboarding-content').hide();
        _$('#modal-onboarding .to-back').fadeIn();
        _$('#modal-onboarding').find('#section-estensione-servizio').fadeIn();
    }

    hideStepExtend(){
        _$('#modal-onboarding').find('#section-estensione-servizio').hide();
    }

    showPdv(service: string, stores, stepCheckout: boolean = false, address?){
        this.selectedService = service;
        switch(service){
            case 'HOME_DELIVERY':
                this.generateStoresList(service, stores);
                _$('#modal-onboarding').addClass('service-type-consegna').removeClass('service-type-ritiro');
                this.loading(false);
                break;
            case 'ORDER_AND_COLLECT':
                this.generateStoresList(service, stores);
                _$('#modal-onboarding').addClass('service-type-ritiro').removeClass('service-type-consegna');
                this.utils.initSingleCustomScrollbar(_$('#modal-onboarding .section-ordina-e-ritira .lista-negozi-section'));
                this.loading(false);
                break;
        }

        setTimeout(() => {
            if(stepCheckout){
                this.manageModalSteps('step2Checkout', service, address);
            }else{
                this.manageModalSteps('step2', service);
            }
            
        }, 500)
    }

    initMobileMapSection(){
        if(this.utils.isMobile || this.utils.isTablet()){
            _$('#ordina-ritira-scelta-pdv').find('.lista-negozi-section').hide();
            _$('#ordina-ritira-scelta-pdv').find('.map-pdv-container').fadeIn();
            _$('#ordina-ritira-scelta-pdv .vedi_mappa').hide();
            _$('#ordina-ritira-scelta-pdv .vedi_lista').fadeIn().css('display', 'block');
        }
    }

    initMobileListSection(){
        if(this.utils.isMobile() || this.utils.isTablet()){
            _$('#ordina-ritira-scelta-pdv').find('.lista-negozi-section').fadeIn();
            _$('#ordina-ritira-scelta-pdv').find('.map-pdv-container').hide();
            _$('#ordina-ritira-scelta-pdv .vedi_mappa').fadeIn().css('display', 'block');
            _$('#ordina-ritira-scelta-pdv .vedi_lista').hide();
        }
    }

    createStoreCard(service, store, index?): string{
        let day, month, hour, deliveryNote;
        let badgeColor = '';
        let labelService = service && service == "HOME_DELIVERY" ? 'Consegna' : 'Ritiro'

        let cartaInsieme = store.cartaInsieme ? '' : `<div class="uk-flex-row uk-flex-inline programma-carta-insieme">
                                                            <span class="icon-fidelity-double"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span></span>
                                                            <span class="SmallPar">Non aderisce al programma Carta Insieme</span>
                                                        </div>`

        let serviceHours = '';
        let serviceHoursHTML = '';
        if(store.serviceHours){
            for(let i = 0; i < store.serviceHours.length; i++){
                serviceHours = serviceHours + `<span class="SmallPar">${store.serviceHours[i]}</span><br>`;
            }
            serviceHoursHTML = `<div class="orari">
                                    <hr>
                                    <div class="uk-flex-row title-row">
                                        <span class="Paragraph uk-text-bold">Orari ${service == "HOME_DELIVERY" ? 'Spesa a casa' : 'Ordina e ritira'}</span>
                                    </div>
                                    <div class="uk-flex-row par-row" style="column-count: 2;">
                                        ${serviceHours}
                                    </div>
                                </div>`
        }

        let action = `GoogleUtils.clickStoreToList(this, ${index}, '${service}')`;
        let disabled = '';
        if (store.enabled == false){
            action = '';
            disabled = 'disabled';
        }
        
        let storeHTML = `<li>
                            <div nkPage="Card-negozio" class="card-negozio-skeleton component-card-negozio ${disabled}" type="button" aria-label="Negozio" onclick="OnboardingManager.confirmStore(this); ${action}" data-pos-id="${store.name}">
                                <div class="uk-card uk-card-default uk-flex">
                                    <div class="uk-card-body uk-text-left">
                                        <div class="uk-flex-row uk-flex uk-flex-between availability">
                                            <i class="icon-${this.setIconStore(store.type)}"></i>
                                            <div class="card-skeleton-availability loading"></div>
                                        </div>
                                        <div class="uk-flex-row title-row store-type">
                                            <span class="card-title">${store.storeType}</span>
                                        </div>
                                        <div class="uk-flex-row par-row store-address">
                                            <span class="card-text">${store.address.formattedAddress} |</span>
                                            <span class="card-text uk-text-bold">${store.formattedDistance} da te</span>
                                        </div>
                                        ${cartaInsieme}
                                        ${service == 'ORDER_AND_COLLECT' ? serviceHoursHTML : ''}
                                    </div>
                                </div>
                            </div>
                        </li>`;
        return storeHTML;
    }

    generateStoresList(service: string, stores){
        switch(service){
            case 'HOME_DELIVERY':
                if(stores && stores.length){
                    _$('#modal-onboarding-content').find('.section-spesa-a-casa .lista-negozi-section').html('');
                    for(let i = 0; i < stores.length; i++){
                        _$('#modal-onboarding-content').find('.section-spesa-a-casa').find('.lista-negozi-section').append(this.createStoreCard(service, stores[i], i));
                    }
                    this.fetchFirstAvailableTimeslots('HOME_DELIVERY', stores);  
                }
                break;
            case 'ORDER_AND_COLLECT':
                if(stores && stores.length){
                    _$('#modal-onboarding-content').find('.section-ordina-e-ritira .lista-negozi-section .uk-list').html('');
                    for(let i = 0; i < stores.length; i++){
                        _$('#modal-onboarding-content').find('.section-ordina-e-ritira').find('.lista-negozi-section').find('.uk-list').append(this.createStoreCard(service, stores[i], i));
                    }
                    this.fetchFirstAvailableTimeslots('ORDER_AND_COLLECT', stores);             
                }
                break;
        }
    }

    async fetchFirstAvailableTimeslots(service, stores){
        try{
            let storeIds = this.storeService.extractStoreIds(stores);
            let deliveryAreas = this.storeService.extractDeliveryAreas(stores);
            const firstAvailableTimeSlots = await this.bookUtils.fetchFirstAvailableTimeslots(service, storeIds, deliveryAreas);
            for (let i = 0; i < stores.length; i ++){
                let currentStore;
                currentStore = service == "ORDER_AND_COLLECT" ? 
                                                _$('#modal-onboarding-content').find('.section-ordina-e-ritira').find('.lista-negozi-section').find('.uk-list').find('.component-card-negozio').eq(i) : 
                                                _$('#modal-onboarding-content').find('.section-spesa-a-casa').find('.lista-negozi-section').find('.component-card-negozio').eq(i)

                let currentStoreId = _$(currentStore).attr('data-pos-id');
                let day, month, hour, deliveryNote;
                let store = stores[i];
                let badgeColor;
                let labelService = service && service == "HOME_DELIVERY" ? 'Consegna' : 'Ritiro';
                
                let firstAvailableTimeslot = firstAvailableTimeSlots[currentStoreId] != null && firstAvailableTimeSlots[currentStoreId].firstAvailableTimeSlot != null ? firstAvailableTimeSlots[currentStoreId].firstAvailableTimeSlot : null;
                
                if(firstAvailableTimeslot){
                    day = new Date(firstAvailableTimeslot.date).getDate();
                    month = new Date(firstAvailableTimeslot.date).getMonth() + 1;
                    hour = firstAvailableTimeslot['startTime']['formattedHour'];
                    deliveryNote = `Prima disponibilità: ${day}/${month} dalle ${hour}`
                }else{
                    if (!store.newPlatform) {
                        deliveryNote = `<i class="icon-alert"></i> Esperienza in aggiornamento`;
                        badgeColor = `uk-invisible`;
                    }
                    deliveryNote = 'Disponibilità terminata';
                }

                if (store.type == "LOCKER" && Window.cart && store.newPlatform) {
                    deliveryNote = `Verifica disponibilità`;
                    badgeColor = 'uk-invisible';
                }

                if (store.enabled == false){
                    deliveryNote = `${labelService}: servizio non disponibile`;
                }

                if(!firstAvailableTimeslot && _$('body').hasClass('page-CheckoutNew')){
                    _$(currentStore).addClass('disabled');
                }

                if(firstAvailableTimeslot){
                    _$(currentStore).addClass('available')
                }else{
                    _$(currentStore).addClass('not-available');
                }

                let html = `<div class="uk-margin-auto-left">
                                <span class="uk-badge ${badgeColor}">${deliveryNote}</span>
                            </div>`

                _$(currentStore).find('.availability').find('.card-skeleton-availability').remove();
                _$(currentStore).find('.availability').append(html);
                
            }
        }catch(error){
            console.log(error);
        }
    }

    showEstensione(){
        _$('#modal-onboarding-content').addClass('uk-hidden');
        _$('#modal-estensione').removeClass('uk-hidden');
    }


    /* gestione stato grafico della card */
    manageCardSpesaOnline(status){
        switch(status){
            case 'serviceActive':
                _$('#modal-onboarding').find('.component-card-spesa-a-casa').removeClass('disabled').removeClass('incomplete').removeClass('inactive');
                break;
            case 'serviceInactive':
                _$('#modal-onboarding').find('.component-card-spesa-a-casa').addClass('disabled').removeClass('incomplete').removeClass('inactive');
                break;
            case 'addressIncomplete':
                _$('#modal-onboarding').find('.component-card-spesa-a-casa').removeClass('disabled').addClass('incomplete').removeClass('inactive');
                break;
            case 'cardInactive':
                _$('#modal-onboarding').find('.component-card-spesa-a-casa').removeClass('disabled').removeClass('incomplete').addClass('inactive');
                break;
        }

    }

    /* gestione stato grafico della card */
    manageCardOrdinaRitira(status){
        switch(status){
            case 'serviceActive':
                _$('#modal-onboarding').find('.component-card-ordina-e-ritira').removeClass('disabled').removeClass('inactive');
                break;
            case 'serviceInactive':
                _$('#modal-onboarding').find('.component-card-ordina-e-ritira').addClass('disabled').removeClass('inactive');
                break;
            case 'cardInactive':
                _$('#modal-onboarding').find('.component-card-ordina-e-ritira').removeClass('disabled').addClass('inactive');
                break;
        }
    }

    manageSceltaSpesaDiv(hdStores, ocStores, notCompleted?: boolean){
        if(_$('body').hasClass('page-CheckoutNew')){
            if(Window.typeOfService == 'HOME_DELIVERY'){
                if(hdStores == null){
                    _$('#modal-onboarding').find('.section-scelta-spesa .title-section').removeClass('consegna-ritiro-disabled consegna-disabled ritiro-disabled').addClass('checkout-indirizzo-disabled');
                }else{
                    _$('#modal-onboarding').find('.section-scelta-spesa .title-section').removeClass('consegna-ritiro-disabled consegna-disabled ritiro-disabled checkout-indirizzo-disabled');
                }
            }else if(Window.typeOfService == 'ORDER_AND_COLLECT'){
                if(ocStores == null){
                    _$('#modal-onboarding').find('.section-scelta-spesa .title-section').removeClass('consegna-ritiro-disabled consegna-disabled ritiro-disabled').addClass('checkout-indirizzo-disabled');
                }else{
                    _$('#modal-onboarding').find('.section-scelta-spesa .title-section').removeClass('consegna-ritiro-disabled consegna-disabled ritiro-disabled checkout-indirizzo-disabled');
                }
            }
        }else{
            if(notCompleted){
                _$('#modal-onboarding').find('.section-scelta-spesa .title-section').removeClass('consegna-ritiro-disabled consegna-disabled ritiro-disabled')
            }else{
                if(hdStores == null && ocStores == null){
                    _$('#modal-onboarding').find('.section-scelta-spesa .title-section').removeClass('consegna-disabled ritiro-disabled consegna-ritiro-disabled');
                    _$('#modal-onboarding').find('.section-scelta-spesa .title-section').addClass('consegna-ritiro-disabled');
                }else if(hdStores == null){
                    _$('#modal-onboarding').find('.section-scelta-spesa .title-section').removeClass('consegna-ritiro-disabled ritiro-disabled consegna-disabled');
                    if(this.checkDataStatusIncompleteAddress()){
                        _$('#modal-onboarding').find('.section-scelta-spesa .title-section').addClass('consegna-disabled');
                    }
                }else if(ocStores == null){
                    _$('#modal-onboarding').find('.section-scelta-spesa .title-section').removeClass('consegna-ritiro-disabled consegna-disabled ritiro-disabled');
                }else{
                    _$('#modal-onboarding').find('.section-scelta-spesa .title-section').removeClass('consegna-ritiro-disabled consegna-disabled ritiro-disabled');
                }
            }
        }
    }

    /*
    * Setta l'icona dello store sulla lista
    * valori possibili dal BE:
    * "DELIVERY" - "STORE" - "PICKUP" - "WAREHOUSE" - "LOCKER" - "POS" - "DARK_STORE" - "DARK_ROOM"
    */
    setIconStore(storeType){
        let icon: string = 'shop-3';
        switch (storeType) {
            case 'DELIVERY':
                icon = 'drive';
                break;
            case 'STORE':
                icon = 'shop-3';
                break;
            case 'PICKUP':
                icon = 'shop-3';
                break;
            case 'WAREHOUSE':
                icon = 'shop-3';
                break;
            case 'LOCKER':
                icon = 'locker-2';
                break;
            case 'POS':
                icon = 'shop-3';
                break;
            case 'DARK_STORE':
                icon = 'shop-3';
                break;
            case 'DARK_ROOM':
                icon = 'shop-3';
                break;
        }
        return icon;
    }

    confirmStore(e: object) {
        _$('#modal-onboarding').find('.component-card-negozio').removeClass('selected');
        _$(e).addClass('selected');
        _$('#modal-onboarding').addClass('selected-pdv');
        this.sendAnalytics('1.3.6','selezioneNegozioDaLista');
    }

    extendAddresses(service: string) {
        if (this.isValidForm()) {
            this.bookUtils.setExtendAddressInfo(_$('#form-estensione-servizio').find('#email').val(), 
                                                _$('#form-estensione-servizio').find('#field_nome').val(), 
                                                _$('#form-estensione-servizio').find('#field_cognome').val())
            this.bookUtils.extendAddresses(service, this.addressLoadedOnStep0);
            this.manageModalSteps('step1', service, this.addressLoadedOnStep0);
            this.sendAnalytics('1.2.13','richiestaEstensioneInviata');
        }else{
            this.sendAnalytics('1.2.12','erroreForm');

        }
    }

    isValidForm() {
        let $form = _$('#form-estensione-servizio');
        let $allInputs = $form.find('input');
        let validInputs = [];

        _$($allInputs).each((index, singleInput) => {
            let $input = _$(singleInput);
            this.validationUtils.newValidInput($input);
            if ($input.attr('data-valid') == 'false') {
                validInputs.push(false);
            } else {
                validInputs.push(true);
            }
        });

        return validInputs.indexOf(false) < 0;
    }

    /**
     * Checks value
     * @param e
     * @param type
     */
    checkValue(e: any, type: string): void {
        //this.bookUtils.checkValue(e, type);
        //this.bookUtils.allowButtonExtAddress('extAddressBtnSL');
        this.bookUtils.setValue(e,type);
        this.validationUtils.newValidInput(e);
    }

    async selectStore(){
        if(_$('body').hasClass('page-CheckoutNew')){
            this.bookUtils.bookingSlotState = BOOKING_SLOT_STATE.MODIFY_ADDRESS;
            _Uikit.modal('#modal-onboarding').hide();
            if(this.localStorage.getFromStorage('storeSelected') && this.localStorage.getFromStorage('storeSelected').name && Window.pointOfService && Window.pointOfService.name){
                if(this.localStorage.getFromStorage('storeSelected').name != Window.pointOfService.name || (Window.typeOfService == 'HOME_DELIVERY' ? (Window.deliveryArea != this.localStorage.getFromStorage('storeSelected').selectedDeliveryId) : false)){
                    this.bookUtils.loadStep2(Window.typeOfService == 'ORDER_AND_COLLECT' ? 'ritira' : 'ricevi');
                }     
            }else{
                this.bookUtils.loadStep2(Window.typeOfService == 'ORDER_AND_COLLECT' ? 'ritira' : 'ricevi');
            }
            this.utils.showLoader(_$('#modal-onboarding').find('.btn-conferma-pdv button'), false, LOADER_POSITION.BUTTON);
        }else{
            if (Window.pointOfService) {
                if (this.bookUtils.checkPdvMigrato()) {
                    let needBackup = await this.needBackupPos();
                    if (this.bookUtils.checkSwitchPiattaforma() && this.bookUtils.checkCartPresence()) {
                        if(this.bookUtils._serviceType === 'HOME_DELIVERY' && needBackup){
                            _$('#modalTransizionePdvMigratoSwitchPiattaforma .btn-group button').on('click', () => {
                                this.bookUtils.bookingSlotState = BOOKING_SLOT_STATE.DEFAULT;
                                //this.bookUtils.choosePdvBackup();
                                this.bookUtils.showBackupPdvModal();
                            });
                        }else{
                            _$('#modalTransizionePdvMigratoSwitchPiattaforma .btn-group button').on('click', () => {
                                this.bookUtils.bookingSlotState = BOOKING_SLOT_STATE.DEFAULT;
                                this.bookUtils.chosenStore();
                            });
                        }
                        _Uikit.modal('#modalTransizionePdvMigratoSwitchPiattaforma').show();
                    } else {
                        this.bookUtils.bookingSlotState = BOOKING_SLOT_STATE.DEFAULT;
                        if(this.bookUtils._serviceType === 'HOME_DELIVERY' && needBackup){
                            //this.bookUtils.choosePdvBackup();
                            this.bookUtils.showBackupPdvModal();
                        }else{
                            this.bookUtils.chosenStore();
                        }
                    }
                } else {
                    this.utils.showLoader(_$('#modal-onboarding').find('.btn-conferma-pdv button'), false, LOADER_POSITION.BUTTON);
                    _Uikit.modal('#modalTransizionePdvNonMigrato').show();
                }
            } else {
                let needBackup = (await this.needBackupPos());
                if (this.bookUtils.checkPdvMigrato()) {
                    this.bookUtils.bookingSlotState = BOOKING_SLOT_STATE.DEFAULT;
                    if (this.bookUtils._serviceType === 'HOME_DELIVERY' && needBackup) {
                        // this.bookUtils.choosePdvBackup();
                        this.bookUtils.showBackupPdvModal();
                    } else {
                        this.bookUtils.chosenStore();
                    }
                } else {
                    this.utils.showLoader(_$('#modal-onboarding').find('.btn-conferma-pdv button'), false, LOADER_POSITION.BUTTON);
                    _Uikit.modal('#modalTransizionePdvNonMigrato').show();
                }
            }
        }
    }

    needBackupPos(): Promise<boolean> {
        return new Promise(async (resolve) => {
            let selectedStore = this.bookUtils._storeSelected;
            let selectedStoreBackup = this.bookUtils._storeSelected && this.bookUtils._storeSelected.backupPos ? this.bookUtils._storeSelected.backupPos : null;
            if (this.bookUtils._serviceType == 'ORDER_AND_COLLECT') {
                resolve(false);
            } else if (selectedStore && selectedStoreBackup) {
                let storeIds = [selectedStore.name, selectedStoreBackup.name];
                let deliveryAreas = [selectedStore.selectedDeliveryId, selectedStore.backupPointOfServiceDeliveryId];
                let availableTimeslots = await this.bookUtils.fetchFirstAvailableTimeslots(this.bookUtils._serviceType, storeIds, deliveryAreas);
                const need = (availableTimeslots[selectedStore.name] == null || Object.keys(availableTimeslots[selectedStore.name])?.length == 0) &&
                    availableTimeslots[selectedStoreBackup.name] != null && Object.keys(availableTimeslots[selectedStoreBackup.name])?.length > 0;
                resolve(need);
            } else {
                resolve(false);
            }
        });
    }

    /* 
        Preseleziona nella lista dei pdv il pdv correntemente selezionato in pagina
    */
    togglePreselectedPdv(service, storeName){
        setTimeout(() => {
            if(service == 'HOME_DELIVERY'){
                _$('#modal-onboarding').find('.section-spesa-a-casa .lista-negozi-section').find(`.component-card-negozio[data-pos-id=${storeName}]`).trigger('click');
            }else{
                _$('#modal-onboarding').find('.section-ordina-e-ritira .lista-negozi-section').find(`.component-card-negozio[data-pos-id=${storeName}]`).trigger('click');
                _$('#ordina-ritira-scelta-pdv').find('.component-card-store-map').hide();
            }
        }, 1000)
        
    }

    checkDataStatusIncompleteAddress(): boolean{
        return parseInt(_$('#modal-onboarding').attr('data-status')) == MODAL_ONBOARDING_STATUS.INCOMPLETE_ADDRESS
    }

    /* Export della funzionel dalla modal recap order receive */
    changeModal(type, event?, element?) {
        let modifica;
        switch (type) {
            case 'timeslot':
                modifica = 'Slot';
                this.utils.showLoader(_$(element), true, LOADER_POSITION.BUTTON);
                Window.typeOfService === 'HOME_DELIVERY'
                    ? this.bookUtils.modifyStep2('ricevi')
                    : this.bookUtils.modifyStep2('ritira');
                break;
            case 'lockerTimeslot':
                modifica = 'Slot';
                this.utils.showLoader(_$(element), true, LOADER_POSITION.BUTTON);
                let showLockerAlert = true;
                let forceLockerSelectTimeslot = true;
                Window.typeOfService === 'HOME_DELIVERY'
                    ? this.bookUtils.modifyStep2('ricevi')
                    : this.bookUtils.modifyStep2('ritira', showLockerAlert, forceLockerSelectTimeslot);
                this.utils.showLoader(_$(element), false, LOADER_POSITION.BUTTON);
                break;
        }
        this.sendAnalytics('modifica', modifica)
    }

    sendAnalytics(analyticsType: string, eventType, search?) {
        try {
            let analyticsData;
            let tsdEnabled = false;
            if(Window.pointOfService && Window.pointOfService.tsdEnabled){
                tsdEnabled = true;
            }
            let step = '';
            let link = document.location.pathname.toString().split('/');
            let linkProvenienza = _$('body').attr('nkpage') == 'DetailProduct' ? link[link.length - 2].split('?')[0] : link[link.length - 1].split('?')[0];
            let provenienza = this._analytics.getProvenienza(linkProvenienza);
            let selectedDay, selectedTimeSlot, giornoProposto;
            let slotDinamico, slotDinamicoScontato;
            selectedDay = _$('.component-card-timeslot.selected').attr('data-date') ? _$('.component-card-timeslot.selected').attr('data-date') : '';
            giornoProposto = _$('.component-card-timeslot.available').first().attr('data-date') == selectedDay ? 'SI' : 'NO';
            if(tsdEnabled){
                let timeslotData;
                if(Window.tsdUseSlider){
                    selectedTimeSlot = _$('.component-SliderTimeslotTsd.active').attr('data-hour') ? _$('.component-SliderTimeslotTsd.active').attr('data-hour') : '';
                    timeslotData = _$('.component-SliderTimeslotTsd.active').attr('data-timeslot') != null ? JSON.parse(_$('.component-SliderTimeslotTsd.active').attr('data-timeslot')) : null;   
                }else{
                    timeslotData = _$('.chip-hour.selected').attr('data-timeslot') != null ? JSON.parse(_$('.chip-hour.selected').attr('data-timeslot')) : null;
                    selectedTimeSlot = _$('.chip-hour.selected').attr('data-hour') ? _$('.chip-hour.selected').attr('data-hour') : '';
                }
                if(timeslotData){
                    slotDinamico = timeslotData['tipologiaFascia'];
                    slotDinamicoScontato = timeslotData['deliveryCost'] != null && timeslotData['discountedDeliveryCost'] != null && timeslotData['deliveryCost'] > timeslotData['discountedDeliveryCost'] ? 'SI' : 'NO';
                }
            }else{
                selectedTimeSlot = _$('.chip-hour.selected').attr('data-hour') ? _$('.chip-hour.selected').attr('data-hour') : '';
            }

            let consegna = '';
            let ritiro = '';
            let card_spesa_a_casa = _$('#spesa-a-casa');
            let card_ordina_ritira = _$('#ordina-e-ritira');

            if(card_spesa_a_casa.hasClass("incomplete")){
                consegna = "verifica indirizzo";
            }else if(card_spesa_a_casa.hasClass("disabled")){
                consegna = "non disponibile";
            }else{
                consegna = "disponibile";
            }

            if(card_ordina_ritira.hasClass("incomplete")){
                ritiro = "verifica indirizzo";
            }else if(card_ordina_ritira.hasClass("disabled")){
                ritiro = "non disponibile";
            }else{
                ritiro = "disponibile";
            }

            let tipo_servizio = Window.typeOfService == 'ORDER_AND_COLLECT' ? 'Ritiro' : 'Consegna';
            let disponibilita_negozio = _$('.component-card-negozio.selected').hasClass('available') ? "Disponibile" : "Esaurita";

            //CALCOLO NUMERO RISULTATI
            let num = _$('.section-view-pdv-content .lista-negozi-section ul')[0].children.length;

            //let storeSelected = this.localStorage.getFromStorage('storeSelected') ? this.localStorage.getFromStorage('storeSelected') : '';
            //if (!search) {
            //    search = this._search;
            //}
            switch (analyticsType) {
                case '1.1.2':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            ricerca: this.localStorage.getFromStorage('ricercaAnalytics') ? this.localStorage.getFromStorage('ricercaAnalytics') : '',
                        },
                    };

                    step = 'FUNNEL_DATA_EP_STEP1';
                    break;
                case '1.2.4':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            servizioConsegna: consegna,
                            servizioRitira: ritiro,
                            provenienza: provenienza,
                            ricerca: this.localStorage.getFromStorage('ricercaAnalytics') ? this.localStorage.getFromStorage('ricercaAnalytics') : '',
                        },
                    };

                    step = 'FUNNEL_DATA_STEP_1B_SCELTA_SERVIZIO';
                    break;
               //SCELTA NEGOZIO
                case '1.2.7':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            servizioConsegna: consegna,
                            servizioRitira: ritiro,
                            provenienza: provenienza,
                            ricerca: this.localStorage.getFromStorage('ricercaAnalytics') ? this.localStorage.getFromStorage('ricercaAnalytics') : '',
                        },
                    };

                    step = 'FUNNEL_DATA_STEP_1B_SCELTA_SERVIZIO';
                    break;
                case '1.2.8':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            servizioConsegna: consegna,
                            servizioRitira: ritiro,
                            provenienza: provenienza,
                            ricerca: this.localStorage.getFromStorage('ricercaAnalytics') ? this.localStorage.getFromStorage('ricercaAnalytics') : '',
                        },
                    };

                    step = 'FUNNEL_DATA_STEP_1B_SCELTA_SERVIZIO';
                    break;
                case '1.2.9':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            tipoServizio: this.localStorage.getFromStorage('typeOfServiceRedirectOldPdv') == 'ORDER_AND_COLLECT' ? 'Ritiro' : 'Consegna',
                            servizioConsegna: consegna,
                            servizioRitira: ritiro,
                            provenienza: provenienza,
                            ricerca: this.localStorage.getFromStorage('ricercaAnalytics') ? this.localStorage.getFromStorage('ricercaAnalytics') : '',
                        },
                    };

                    step = 'FUNNEL_DATA_STEP_1B_SCELTA_SERVIZIO';
                    break;
                case '1.2.10':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            tipoServizio: this.localStorage.getFromStorage('typeOfServiceRedirectOldPdv') == 'ORDER_AND_COLLECT' ? 'Ritiro' : 'Consegna',
                            servizioConsegna: consegna,
                            servizioRitira: ritiro,
                            provenienza: provenienza,
                            ricerca: this.localStorage.getFromStorage('ricercaAnalytics') ? this.localStorage.getFromStorage('ricercaAnalytics') : '',
                        },
                    };

                    step = 'FUNNEL_DATA_FORM_ESTENSIONE_SERVIZIO';
                    break;
                case '1.2.11':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            tipoServizio: this.localStorage.getFromStorage('typeOfServiceRedirectOldPdv') == 'ORDER_AND_COLLECT' ? 'Ritiro' : 'Consegna',
                            servizioConsegna: consegna,
                            servizioRitira: ritiro,
                            provenienza: provenienza,
                            ricerca: this.localStorage.getFromStorage('ricercaAnalytics') ? this.localStorage.getFromStorage('ricercaAnalytics') : '',
                        },
                    };

                    step = 'FUNNEL_DATA_FORM_ESTENSIONE_SERVIZIO';
                    break;
                case '1.2.12':
                    let listaCampiErrore = [];
                    let form = _$('#form-estensione-servizio');
                    let inputError =_$(form).find('input.uk-form-danger');
                    for(let i = 0; i < _$(inputError).length; i++){
                        listaCampiErrore.push(
                            _$(inputError).eq(i).siblings('label').html()
                        );
                    }

                    analyticsData = {
                        event: eventType,
                        funnel: {
                            servizioConsegna: consegna,
                            servizioRitira: ritiro,
                            tipoServizio: this.localStorage.getFromStorage('typeOfServiceRedirectOldPdv') == 'ORDER_AND_COLLECT' ? 'Ritiro' : 'Consegna',
                            provenienza: provenienza,
                            ricerca: this.localStorage.getFromStorage('ricercaAnalytics') ? this.localStorage.getFromStorage('ricercaAnalytics') : '',
                        },
                        form: {
                            campoErrore : listaCampiErrore
                        }
                    };

                    step = 'FUNNEL_DATA_FORM_ESTENSIONE_SERVIZIO';
                    break;
                case '1.2.13':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            tipoServizio: this.localStorage.getFromStorage('typeOfServiceRedirectOldPdv') == 'ORDER_AND_COLLECT' ? 'Ritiro' : 'Consegna',
                            servizioConsegna: consegna,
                            servizioRitira: ritiro,
                            provenienza: provenienza,
                            ricerca: this.localStorage.getFromStorage('ricercaAnalytics') ? this.localStorage.getFromStorage('ricercaAnalytics') : '',
                        },
                    };

                    step = 'FUNNEL_DATA_FORM_ESTENSIONE_SERVIZIO';
                    break;
                case '1.2.15':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            servizioConsegna: consegna,
                            servizioRitira: ritiro,
                            provenienza: provenienza,
                            ricerca: this.localStorage.getFromStorage('ricercaAnalytics') ? this.localStorage.getFromStorage('ricercaAnalytics') : '',
                        },
                    };

                    step = 'FUNNEL_DATA_FORM_ESTENSIONE_SERVIZIO';
                    break;

                //SCELTA NEGOZIO
                case '1.3.2':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            tipoServizio: tipo_servizio,
                            servizioConsegna: consegna,
                            servizioRitira: ritiro,
                            provenienza: provenienza,
                            ricerca: this.localStorage.getFromStorage('ricercaAnalytics') ? this.localStorage.getFromStorage('ricercaAnalytics') : '',
                            sceltaNegozio : {
                                idNegozio: this.localStorage.getFromStorage('storeSelected') ? this.localStorage.getFromStorage('storeSelected').name : '',
                                cooperativaNegozio: this.localStorage.getFromStorage('storeSelected') ? this.localStorage.getFromStorage('storeSelected').cooperativeId : '',
                                disponibilitaNegozio: window['typeOfService'] && this.localStorage.getFromStorage('storeSelected') ?
                                    (window['typeOfService'] == 'HOME_DELIVERY' ?
                                        (window['pointOfService'].hdTemporaryUnavailable == false ? 'Disponibile' : 'Esaurita')
                                        :
                                        (window['pointOfService'].ocTemporaryUnavailable == false ? 'Disponibile' : 'Esaurita'))
                                    : '',
                                numeroRisultati: num
                            }
                        },
                    };

                    step = 'FUNNEL_DATA_EP_STEP2';
                    break;
                case '1.3.6':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            tipoServizio: this.localStorage.getFromStorage('typeOfServiceRedirectOldPdv') == 'ORDER_AND_COLLECT' ? 'Ritiro' : 'Consegna',
                            servizioConsegna: consegna,
                            servizioRitira: ritiro,
                            provenienza: provenienza,
                            ricerca: this.localStorage.getFromStorage('ricercaAnalytics') ? this.localStorage.getFromStorage('ricercaAnalytics') : '',
                            sceltaNegozio : {
                                numeroRisultati: num,
                                idNegozio: this.localStorage.getFromStorage('storeSelected') ? this.localStorage.getFromStorage('storeSelected').name : '',
                                cooperativaNegozio: this.localStorage.getFromStorage('storeSelected') ? this.localStorage.getFromStorage('storeSelected').cooperativeId : '',
                                disponibilitaNegozio: window['typeOfService'] && this.localStorage.getFromStorage('storeSelected') ?
                                    (window['typeOfService'] == 'HOME_DELIVERY' ?
                                        (window['pointOfService'].hdTemporaryUnavailable == false ? 'Disponibile' : 'Esaurita')
                                        :
                                        (window['pointOfService'].ocTemporaryUnavailable == false ? 'Disponibile' : 'Esaurita'))
                                    : ''
                            }
                        },
                    };

                    step = 'FUNNEL_DATA_EP_STEP2';
                    break;
                case '1.3.7':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            tipoServizio: this.localStorage.getFromStorage('typeOfServiceRedirectOldPdv') == 'ORDER_AND_COLLECT' ? 'Ritiro' : 'Consegna',
                            servizioConsegna: consegna,
                            servizioRitira: ritiro,
                            provenienza: provenienza,
                            ricerca: this.localStorage.getFromStorage('ricercaAnalytics') ? this.localStorage.getFromStorage('ricercaAnalytics') : '',
                            sceltaNegozio : {
                                numeroRisultati: num,
                                idNegozio: this.localStorage.getFromStorage('storeSelected') ? this.localStorage.getFromStorage('storeSelected').name : '',
                                cooperativaNegozio: this.localStorage.getFromStorage('storeSelected') ? this.localStorage.getFromStorage('storeSelected').cooperativeId : '',
                                disponibilitaNegozio: window['typeOfService'] && this.localStorage.getFromStorage('storeSelected') ?
                                    (window['typeOfService'] == 'HOME_DELIVERY' ?
                                        (window['pointOfService'].hdTemporaryUnavailable == false ? 'Disponibile' : 'Esaurita')
                                        :
                                        (window['pointOfService'].ocTemporaryUnavailable == false ? 'Disponibile' : 'Esaurita'))
                                    : ''
                            }
                        },
                    };

                    step = 'FUNNEL_DATA_EP_STEP2';
                    break;
                case '1.4.2':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            servizioConsegna: consegna,
                            servizioRitira: ritiro,
                            provenienza: provenienza,
                            ricerca: this.localStorage.getFromStorage('ricercaAnalytics') ? this.localStorage.getFromStorage('ricercaAnalytics') : '',
                        },
                    };

                    step = 'FUNNEL_DATA_STEP_1C_COMPLETA_INDIRIZZO';
                    break;

                case '1.4.4':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            servizioConsegna: consegna,
                            servizioRitira: ritiro,
                            provenienza: provenienza,
                            ricerca: this.localStorage.getFromStorage('ricercaAnalytics') ? this.localStorage.getFromStorage('ricercaAnalytics') : '',
                        },
                    };

                    step = 'FUNNEL_DATA_STEP_1C_COMPLETA_INDIRIZZO';
                    break;

                //PRE-HEADER
                case '1.5.1':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            tipoServizio: tipo_servizio,
                            provenienza: provenienza
                        }
                    };

                    step = 'FUNNEL_DATA_PRENOTA_SLOT_PREHEADER';
                    break;
                case '1.5.2':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            tipoServizio: tipo_servizio,
                            provenienza: provenienza
                        }
                    };

                    step = 'FUNNEL_DATA_MODIFICA_SLOT_PREHEADER';
                    break;
                case '1.5.3':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            tipoServizio: tipo_servizio,
                            provenienza: provenienza
                        },
                    };

                    step = 'FUNNEL_DATA_MODIFICA_SERVIZIO_PREHEADER';
                    break;
                case '1.5.4':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            tipoServizio: tipo_servizio,
                            provenienza: provenienza
                        },
                    };

                    step = 'FUNNEL_DATA_MODIFICA_INDIRIZZO_PREHEADER';
                    break;
                case '1.5.5':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            tipoServizio: tipo_servizio,
                            provenienza: provenienza,
                            sceltaSlot: {
                                giorno: selectedDay,
                                fasciaOraria: selectedTimeSlot
                            },
                            carrello: this.localStorage.getFromStorage('carrelloAnalytics')
                        }
                    };

                    if(tsdEnabled){
                        analyticsData.funnel.sceltaSlot['slotDinamico'] = slotDinamico;
                        analyticsData.funnel.sceltaSlot['slotDinamicoScontato'] = slotDinamicoScontato;
                    }

                    step = 'FUNNEL_DATA_EP_STEP3';
                    break;
                case '1.5.6':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            tipoServizio: tipo_servizio,
                            provenienza: provenienza,
                            sceltaSlot: {
                                giorno: selectedDay,
                                giornoProposto: giornoProposto,
                                fasciaOraria: selectedTimeSlot,
                            },
                            carrello: this.localStorage.getFromStorage('carrelloAnalytics')
                        },
                    };

                    if(tsdEnabled){
                        analyticsData.funnel.sceltaSlot['slotDinamico'] = slotDinamico;
                        analyticsData.funnel.sceltaSlot['slotDinamicoScontato'] = slotDinamicoScontato;
                    }

                    step = 'FUNNEL_DATA_EP_STEP3';
                    break;
                case '1.5.7':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            tipoServizio: tipo_servizio,
                            provenienza: provenienza,
                            sceltaSlot: {
                                giorno: selectedDay,
                                fasciaOraria: selectedTimeSlot,
                            },
                            carrello: this.localStorage.getFromStorage('carrelloAnalytics')
                        }
                    };

                    if(tsdEnabled){
                        analyticsData.funnel.sceltaSlot['slotDinamico'] = slotDinamico;
                        analyticsData.funnel.sceltaSlot['slotDinamicoScontato'] = slotDinamicoScontato;
                    }

                    step = 'FUNNEL_DATA_EP_STEP3';
                    break;
                case '1.5.8':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            tipoServizio: tipo_servizio,
                            provenienza: provenienza,
                            sceltaSlot: {
                                giorno: selectedDay,
                                fasciaOraria: selectedTimeSlot,
                            }
                        }
                    };

                    if(tsdEnabled){
                        analyticsData.funnel.sceltaSlot['slotDinamico'] = slotDinamico;
                        analyticsData.funnel.sceltaSlot['slotDinamicoScontato'] = slotDinamicoScontato;
                    }

                    step = 'FUNNEL_DATA_CLICK_ACCORDION_INFO_PRENOTAZIONE_SLOT';
                    break;
                //RICERCA IN LANDINGPAGE
                case '1.6.1':
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            ricerca: this.localStorage.getFromStorage('ricercaAnalytics') ? this.localStorage.getFromStorage('ricercaAnalytics') : '',
                        },

                    };

                    step = 'FUNNEL_DATA_LISTA_PROVINCE';
                    break;
                case '1.7.3':
                    analyticsData = {
                        event: eventType,
                        funnel: {

                            provenienza: provenienza,
                            //ricerca: this.localStorage.getFromStorage('ricercaAnalytics') ? this.localStorage.getFromStorage('ricercaAnalytics') : '',
                        },
                    };

                    step = 'FUNNEL_DATA_EP_STEP1';
                    break;
                case '1.8.10':
                    let importoConsegna, consegnaGratuita;
                    let importo = window['cart'] && window['cart'].subTotal ? window['cart'].subTotal.value : '0';
                    if(_$('.box-scontrino').find('.total-delivery-costs-value').length && tipo_servizio == 'Ordina e ricevi'){
                        importoConsegna = parseFloat(_$('.box-scontrino').find('.total-delivery-costs-value').html().replace('€','').replace(',','.'));
                        if(importoConsegna != null && importoConsegna > 0){
                            consegnaGratuita = 'NO'
                        }else{
                            consegnaGratuita = 'SI'
                        };
                    }else{
                        importoConsegna = 0;
                        consegnaGratuita = 'SI';
                    }
                    //1.8.10
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            nomeFunnel: 'EntryPage',
                            stepFunnel: 'Step 2 - Scelta Negozio',
                            tipoServizio: tipo_servizio,
                            provenienza: 'Checkout',
                        },
                        carrello: {
                            importo,
                            consegnaGratuita,
                            importoConsegna,
                            prodotti: this.localStorage.getFromStorage('carrelloAnalytics') ? this.localStorage.getFromStorage('carrelloAnalytics')['prodotti'] : ''
                        }
                    };
                    this._analytics.sendAnalytics(analyticsData, '', false, true);
                    return;

            }
            if (analyticsType != 'chiusuraModale' && analyticsData) {
                if(['1.5.1', '1.5.2', '1.5.3', '1.5.4'].indexOf(analyticsType) > 0){
                    this._analytics.sendAnalytics(analyticsData, step, true, true);
                }
                else if(['1.5.5','1.5.6', '1.5.7', '1.5.8'].indexOf(analyticsType) > 0){
                    this._analytics.sendAnalytics(analyticsData, step, true, false);
                }else{
                    this._analytics.sendAnalytics(analyticsData, step, true, false);
                }
            }

        }catch (error) {
            console.log(eventType + ' : ' + error);
        }
    }
    

}