import Alpine from 'alpinejs';
import { DefaultService, Order, TypeOfService } from '../../../../../../api';
import type { GetOrderHistoryRequest } from '../../../../../../api/models/GetOrderHistoryRequest';
import { BASE_ECOMMERCE_DATA } from '../../../../../../constants';
import { formatC, i18n } from '../../../../../../libs/base-utils';
import { sendAnalytics } from '../../../../../../libs/tracking-utils';

const ORDERS_PAGE_SIZE = 5;

Alpine.data('ec8x1xOrders', () => ({
    openTab: 'OR', // 'OR' || 'OC'
    ordersORTotalResults: 0,
    ordersORNextPage: 0,
    ordersOR: [] as Order[],
    ordersOCTotalResults: 0,
    ordersOCNextPage: 0,
    ordersOC: [] as Order[],
    timeFilter: 'ALL',
    firstTimeSwitchTab: true,
    init() {
        sendAnalytics(BASE_ECOMMERCE_DATA.PAGE_VIEW_PA_ORDERS);
    },
    // utils
    formatC,
    i18n,
    // main component functions
    async addMoreOrders(serviceType: TypeOfService) {
        try {
            let requestBody: GetOrderHistoryRequest = {
                serviceType: serviceType,
                pageSize: ORDERS_PAGE_SIZE,
                currentPage: serviceType === TypeOfService.HOME_DELIVERY ? this.ordersORNextPage : this.ordersOCNextPage,
                timeFilter: this.timeFilter
            };
            const response = await DefaultService.postApiEcommerceItItGetOrderHistoryJson(requestBody);
            if (serviceType === TypeOfService.HOME_DELIVERY) {
                this.ordersOR.push(...response?.data?.orders || []);
                this.ordersORTotalResults = response?.data?.pagination?.totalResults || 0;
                this.ordersORNextPage++;
            } else {
                this.ordersOC.push(...response?.data?.orders || []);
                this.ordersOCTotalResults = response?.data?.pagination?.totalResults || 0;
                this.ordersOCNextPage++;
            }
        } catch (e) {
            console.warn(e);
        }
    },
    async changeTimeFilter(event) {
        this.timeFilter = event?.detail?.value || 'ALL';
        console.log("Clean and load orders: ", this.timeFilter);
        this.ordersORNextPage = 0;
        this.ordersOR = [];
        await this.addMoreOrders(TypeOfService.HOME_DELIVERY);
        this.ordersOCNextPage = 0;
        this.ordersOC = [];
        await this.addMoreOrders(TypeOfService.ORDER_AND_COLLECT);
        if (this.firstTimeSwitchTab) { // TODO change from first change trigger to init()
            this.swithToMostRecentOrderTab();
            this.firstTimeSwitchTab = false;
        }
    },
    loadMore() {
        console.log("Load more orders");
        const serviceType = this.openTab === 'OR' ? TypeOfService.HOME_DELIVERY : TypeOfService.ORDER_AND_COLLECT;
        this.addMoreOrders(serviceType);
    },
    swithToMostRecentOrderTab() {
        if (this.ordersOC.length == 0 || this.ordersOR.length == 0) {
            if (this.ordersOR.length == 0) {
                this.openTab = 'OC';
            }
            return;
        }
        const firstOC: Order = this.ordersOC[0];
        const firstOR: Order = this.ordersOR[0];
        if (new Date(firstOC?.created) > new Date(firstOR?.created))
            this.openTab = 'OC';
    },
    replacePlaceholderLinkDiscoverMorePopup(text) {
        if (!text) return '';
        return text.replace('{', '<a x-on:click="openOrderStatesPopup();">').replace('}', '</a>');
    },
    openOrderStatesPopup: async () => {
        window.cFlowManager.startAsyncFlow({
            name: 'display-order-states-flow',
            steps: [{ name: 'display-order-states' }]
        });
    },
    // only analytics functions
    trackOrdersChangeService(service: string) {
        let ecommerceData = BASE_ECOMMERCE_DATA.CLICK_PA_ORDERS_CHANGE_SERVICE;
        ecommerceData.funnel.stepFunnel = service;
        sendAnalytics(ecommerceData);
    }
}));
