import { _$, _Uikit, Window, _dropzone, _moment } from "../../main";
import { LOADER_POSITION, Utils } from "../../services/utils/utils";
import { UserService } from "../../services/user-service/user-service";
import { ValidationUtils } from "../../services/utils/validation-utils";
import { Analytics } from "src/services/analytics/analytics";

export class ModalAgevolazione {
    private overForm = _$('#agevolazioneOverForm');
    private invaliditaForm = _$('#agevolazioneInvaliditaForm');
    private listInputFiles = [];

    constructor(
        private _utils: Utils,
        private _userService: UserService,
        private _validationUtils: ValidationUtils,
        private _analytics: Analytics
    ) {
        _Uikit.util.on('#offcanvasAgevolazioneOver', 'show', () => {
            this.setUserLogged('agevolazioneOverForm', 'submitAgOverForm');
            this.sendAnalytics('pageview_modale_agevolazioneOver');
        });

        _$('#submitAgOverForm').on('click', () => { 
                this.sendAnalytics('ClickInviaRichiestaAgevolazioneOver70');   
        });

        _$('#modalFormUploadSuccess_Agevolazione').on('show', () => { this.sendAnalytics('pageview_modale_success'); });
        _$('#modalFormUploadSuccess_Agevolazione').find('.uk-button').on('click', () => { this.sendAnalytics('clickChiudiBannerRichiestaOver70OK'); })
        _$('#modalFormUploadSuccess_Agevolazione').find('.uk-modal-close-default').on('click', () => { this.sendAnalytics('clickChiudiBannerRichiestaOver70OK'); })

        _$('#modalFormUploadError_Agevolazione').on('show', () => { this.sendAnalytics('pageview_modale_KO'); });
        _$('#modalFormUploadError_Agevolazione').find('.uk-button').on('click', () => { this.sendAnalytics('clickChiudiBannerRichiestaOver70KO'); })
        _$('#modalFormUploadError_Agevolazione').find('.uk-modal-close-default').on('click', () => { this.sendAnalytics('clickChiudiBannerRichiestaOver70KO'); })

        _Uikit.util.on('#offcanvasAgevolazioneInvalidita', 'show', () => {
            this.setUserLogged('agevolazioneInvaliditaForm', 'submitAgInvaliditaForm');
            //this._utils.checkFormValidator('submitAgInvaliditaForm', 'agevolazioneInvaliditaForm');
        });
        let myUtils = this._utils;
        //change input minorenne agevolazione invalidità form
        _$('#agevolazioneInvaliditaForm input[name=richiestaAgevolazioneMinorenne]').on('change', function(e) {
            if(_$(this).val() === 'true') {
                _$('.js-ag-doc-legale').removeClass('uk-hidden');
                _$('.js-ag-doc-legale').find('input').attr('required','');
                //myUtils.checkFormValidator('submitAgInvaliditaForm','agevolazioneInvaliditaForm');
            } else {
                _$('.js-ag-doc-legale').addClass('uk-hidden');
                _$('.js-ag-doc-legale').find('input').removeAttr('required');
                //myUtils.checkFormValidator('submitAgInvaliditaForm','agevolazioneInvaliditaForm');
            }
        });

        _$('.component-modal-agevolazione .check-container').find('.checkmark, .label').on('click', () => {
            _$('.component-modal-agevolazione').find('.check-container .message-error').addClass('uk-hidden');
        })

        _$('#agevolazioneOverForm input').on( "focusout", () => {
            setTimeout(() => {
                this.sendAnalytics('erroreForm', _$('#agevolazioneOverForm').find('.uk-form-danger'), _$('#agevolazioneOverForm'));
            }, 100);
        } );
    }
    
    nkOnInit() {
    }

    setUserLogged(idForm, submitButtonID?) {
        if(Window.user){
            if(Window.user.nome){
                _$('#'+idForm).find('.js-input-nome').val(Window.user.nome).prop('readonly', true);
                _$('#'+idForm).find('.js-input-nome').parent().addClass('blocked');
                //this.controlValueFieldInput(_$('.js-input-nome'), idForm, submitButtonID);
            }
            if(Window.user.cognome){
                _$('#'+idForm).find('.js-input-cognome').val(Window.user.cognome).prop('readonly', true);
                _$('#'+idForm).find('.js-input-cognome').parent().addClass('blocked');
                //this.controlValueFieldInput(_$('.js-input-cognome'), idForm, submitButtonID);
            }
            if(Window.user.email){
                _$('#'+idForm).find('.js-input-email').val(Window.user.email).prop('readonly', true);
                _$('#'+idForm).find('.js-input-email').parent().addClass('blocked');
                //this.controlValueFieldInput(_$('.js-input-email'), idForm, submitButtonID);
            }
            if(Window.user.cartaFedelta){
                _$('#'+idForm).find('.js-ag-numero-carta-no-carta').addClass('uk-hidden');
                _$('#'+idForm).find('.js-ag-numero-carta').removeClass('uk-hidden');
                _$('#'+idForm).find('.js-input-numeroCarta').val(Window.user.cartaFedelta).prop('readonly', true);
                _$('#'+idForm).find('.js-input-numeroCarta').parent().addClass('blocked');
                //this.controlValueFieldInput(_$('.js-input-numeroCarta'), idForm, submitButtonID);
            } else {
                _$('#'+idForm).find('.js-ag-numero-carta').addClass('uk-hidden');
                _$('#'+idForm).find('.js-ag-numero-carta-no-carta').removeClass('uk-hidden');
            }
            if(Window.user.telefonoCellulare){
                _$('#'+idForm).find('.js-input-telefono').val(Window.user.telefonoCellulare);
                //this.controlValueFieldInput(_$('.js-input-telefono'), idForm, submitButtonID);
            }
            if(Window.user.dataNascita){
                _$('#'+idForm).find('.js-input-data').val(Window.user.dataNascita).prop('readonly', true);
                _$('#'+idForm).find('.js-input-data').parent().addClass('blocked');
                this.checkDateUnder70(_$('.js-input-data'));
            }
        }
    }

    clearForm(idForm){
        _$(idForm + ' input,' + idForm + ' textarea').val('').trigger('keyup');
    }

    sendForm(e: object, idForm){
        let typeForm = idForm == 'agevolazioneOverForm' ? this.overForm : this.invaliditaForm;

        //@ts-ignore
        let form = new FormData(typeForm.get(0));

        this._utils.showLoader(_$(e), true, LOADER_POSITION.BUTTON);

        let success = (res) => {
            console.log(res);
            this._utils.showLoader(_$(e), false, LOADER_POSITION.BUTTON);
            _Uikit.modal('#modalFormUploadSuccess_Agevolazione').show();
            this.clearForm(idForm);
            this.setUserLogged(idForm);
        }

        let error = (err) => {
            console.log(err);
            this._utils.showLoader(_$(e), false, LOADER_POSITION.BUTTON);
            _Uikit.modal('#modalFormUploadError_Agevolazione').show();
        }

        let tipo = typeForm == this.overForm ? 'facilitationOverAge' : 'facilitationDisability';
        
        form.append('tipo',tipo);
        form.delete('privacy');
        
        if(form.get('dataNascita')){
            let format = "%02d"
            let dataNascita = form.get('dataNascita');
            if(dataNascita.toString().split(/[-\/]+/)){
                let splitDataNascita = dataNascita.toString().split(/[-\/]+/);
                splitDataNascita[0] = splitDataNascita[0].padStart(2, '0');
                splitDataNascita[1] = splitDataNascita[1].padStart(2, '0');
                let finalDataNascita = splitDataNascita.join('/');
                form.set('dataNascita', finalDataNascita);
            }
        }

        this.listInputFiles.forEach((inputFiles) => {
            let inputFormFiles = form.get(inputFiles.name);

            if(inputFormFiles) {
                form.delete(inputFiles.name);
                inputFiles.files.forEach((singleFile) => {
                    form.append(inputFiles.name, singleFile);
                });
            }
        });

        let body = form;

        this._userService.sendFaqForm(success, error, body);
    }

    /**
     * Evento upload all'input type file
     * @param el
     * @param e
     */
    fileSelected(el, e){
        let parent = _$(el).parents('.js-ag-doc');
        let size = this._utils.formatBytes(e.target.files[0].size);
        let totalFilesInputSize = 0;
        console.log('attachment size: ', e.target.files[0].size); //max 5242880

        let allowedExtensions = /(\.pdf|\.jpg|\.jpeg|\.png|\.doc|\.docx)$/i;

        this.listInputFiles.forEach((input) => {
            if(input.name == e.target.name) {
                input.files.forEach((file) => {
                    totalFilesInputSize += file.size;
                });
            }
        });

        if(e.target.files[0].size >= 5242880 || !allowedExtensions.exec(e.target.value) || (totalFilesInputSize + e.target.files[0].size) > 5242880) {
            //_$(parent).find('.js-ag-doc-showfile .details i').removeClass('icon-file-text').addClass('icon-alert uk-border-circle');

            let fileExtensionNotAllowed = false;
            let fileMaxSizeExceed = false;
            let filesTotalMaxSizeExceed = false;
            let nameFile = e.target.files[0].name;

            if(!allowedExtensions.exec(e.target.value)) {
                fileExtensionNotAllowed = true;
            }

            if(e.target.files[0].size >= 5242880) {
                fileMaxSizeExceed = true;
            }

            if(totalFilesInputSize + e.target.files[0].size) {
                filesTotalMaxSizeExceed = true;
            }

            _$(parent).find('input[type=file]').val('');

            if(fileExtensionNotAllowed && fileMaxSizeExceed) {
                _$(parent).append(`<div class="banner-error js-ag-doc-error js-ag-doc-error-size">Il file <b>${nameFile}</b> è troppo grande (${size}).<br/> Il formato del documento selezionato non è valido.</div>`);
            } else {
                if(fileMaxSizeExceed) {
                    _$(parent).append(`<div class="banner-error js-ag-doc-error js-ag-doc-error-size">Il file <b>${nameFile}</b> è troppo grande (${size}).</div>`);
                } else if (filesTotalMaxSizeExceed) {
                    _$(parent).append(`<div class="banner-error banner-error-info js-ag-doc-error js-ag-doc-error-size"><i class="icon-info uk-border-circle tooltip-icon"></i>Hai raggiunto il limite di 5MB totali</div>`);
                } else if(fileExtensionNotAllowed) {
                    _$(parent).append(`<div class="banner-error js-ag-doc-error js-ag-doc-error-formato">Il formato del documento <b>${nameFile}</b> selezionato non è valido.</div>`);
                }
            }

            let bannerError = _$(parent).find('.banner-error');
            bannerError.addClass('show');

            setTimeout(() => {
                bannerError.removeClass('show');
                setTimeout(() => {
                    bannerError.remove();
                }, 600);
            }, 7000);
        } else {
            if(parent.hasClass('multiple')) {
                let inputObj = this.listInputFiles.find(input => {
                  return input.name == e.target.name
                });

                if(!inputObj) {
                    this.listInputFiles.push({
                        name: e.target.name,
                        files: []
                    });
                }

                this.listInputFiles.filter(input => {
                    if(input.name == e.target.name) {
                        let filesList = input.files;
                        filesList.push(e.target.files[0]);
                        input.files = filesList;
                    }
                });

                let emptyShowFile = _$(parent).find('.js-ag-doc-showfile-empty').clone();
                _$(emptyShowFile).find('.js-ag-doc-nomeallegato').html(e.target.files[0].name);
                _$(emptyShowFile).find('.dimensioni-allegato').html(size);
                _$(emptyShowFile).removeClass('js-ag-doc-showfile-empty');
                _$(emptyShowFile).addClass('js-ag-doc-showfile');
                _$(emptyShowFile).removeClass('uk-hidden');
                const jsAgDocAddMoved = _$(parent).find('.js-ag-doc-add-moved');
                emptyShowFile.insertBefore(jsAgDocAddMoved);
                jsAgDocAddMoved.show();

                if (jsAgDocAddMoved[0] && !jsAgDocAddMoved[0].hasAttribute('inputAlreadyMoved')) {
                    jsAgDocAddMoved.append(_$(parent).find('.uk-form-custom'));
                    jsAgDocAddMoved[0].setAttribute('inputAlreadyMoved', '');
                }

                console.log(this.listInputFiles);
            } else {
                _$(parent).find('.js-ag-doc-nomeallegato').html(e.target.files[0].name);
                _$(parent).find('.dimensioni-allegato').html(size);
                _$(parent).find('.uk-form-custom').addClass('uk-hidden');
                _$(parent).children('.js-ag-doc-showfile').removeClass('uk-hidden');
            }

            _$(el).attr('data-valid', 'true');
        }

        this.multipleFileToggleEl(parent);
        
        let form = _$(el).closest('form').attr('id');
        let submitButton = _$(el).closest('form').find('.button-submit').attr('id');

        //this._utils.checkFormValidator(submitButton,form);
    }

    /**
     * Resetto l'input type file
     * @param el
     */
    removeAttachment(el) {
        let parent = _$(el).parents('.js-ag-doc');
        /* _$(parent).find('.js-ag-doc-showfile .details i').removeClass('icon-alert uk-border-circle').addClass('icon-file-text'); */

        if(parent.hasClass('multiple')) {
            let docToRemove = _$(el).parents('.js-ag-doc-showfile').find('.js-ag-doc-nomeallegato').text();
            this.listInputFiles.filter(input => {
                let newListFiles = input.files.filter(file => {
                    if(file.name == docToRemove) {
                        _$(el).parents('.js-ag-doc-showfile').remove();
                        return false;
                    }

                    return true;
                });

                input.files = newListFiles;
                if(input.files.length == 0) {
                    return true;
                }
                return false;
            });

            if(_$(parent).find('.js-ag-doc-showfile').length == 0) {
                const jsAgDocAddMoved = _$(parent).find('.js-ag-doc-add-moved');
                if (jsAgDocAddMoved[0] && jsAgDocAddMoved[0].hasAttribute('inputAlreadyMoved')) {
                    jsAgDocAddMoved[0].removeAttribute('inputAlreadyMoved');
                }
                _$(parent).find('.uk-form-custom').insertAfter(_$(parent).find('.desc'));
                jsAgDocAddMoved.hide();
            }
        } else {
            _$(parent).children('.js-ag-doc-showfile').addClass('uk-hidden');
            _$(parent).find('input[type=file]').val('');
            _$(parent).find('.js-ag-doc-nomeallegato').html('');
            _$(parent).find('.uk-form-custom').removeClass('uk-hidden');
            _$(parent).find('.js-ag-doc-error-size').remove();
            _$(parent).find('.js-ag-doc-error-formato').remove();

            //_$(parent).find('.details .icon-alert').addClass('uk-hidden');
            _$(parent).find('.details .icon-file-text').removeClass('uk-hidden');
            _$(el).closest('.content').find('input').attr('data-valid', 'false');
        }

        this.multipleFileToggleEl(parent);
        
        let form = _$(el).closest('form').attr('id');
        let submitButton = _$(el).closest('form').find('.button-submit').attr('id');

        //this._utils.checkFormValidator(submitButton,form);
    }

    multipleFileToggleEl(parent) {
        if(_$(parent).data('max-files')) {
            let maxFiles = parseInt(_$(parent).data('max-files'));
            let labelNotMaxFile = _$(parent).find('.max-file-to-upload-message');
            let labelMaxFileReached = _$(parent).find('.max-file-uploaded-message');
            let percentageMaxFile = (_$(parent).find('.js-ag-doc-showfile').length / maxFiles) * 100;

            if(_$(parent).find('.js-ag-doc-showfile').length == maxFiles) {
                labelNotMaxFile.hide();
                labelMaxFileReached.show();
                _$(parent).find('input').prop('disabled', true);
                _$(parent).find('.link-carica').addClass('link-carica--disabled');
            } else {
                labelNotMaxFile.show();
                labelMaxFileReached.hide();
                _$(parent).find('input').prop('disabled', false);
                _$(parent).find('.link-carica').removeClass('link-carica--disabled');
            }

            _$(parent).find('.progress-bar-total-file-draw').css('width', percentageMaxFile + '%');
        }
    }

    /**
     * Controllo se la data di nascita è inferiore a 70 anni
     * @param e
     */
    checkDateUnder70(e) {
        if (_$(e).attr('data-valid') == 'true') {
            let dateInput = _$('#agOverData').val().toString().split(/[-\/]+/);
        
            _$('#agOverData').removeClass('uk-form-danger');
            let pattern = _$('#agOverData').attr("pattern");
            
            if(_$('#agOverData').val()) {
                let today = _moment([
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate()
                ]);
                let date = _moment([
                    parseInt(dateInput[2]),
                    parseInt(dateInput[1]) - 1,
                    parseInt(dateInput[0])
                ]);
    
                //@ts-ignore
                
                if (date.isValid()){
                    if (today.diff(date, 'years') >= 70) {
                        _$('#agOverData').attr('data-valid', 'true');
                        _$('#agOverData').removeClass('uk-form-danger');
                        _$('#agOverData').parent().find('.message-error').addClass('uk-hidden');
                        _$('#agevolazioneOverForm').find('.input-container').eq(1).css('margin-top','0px');
                    }  else {
                        _$('#agOverData').attr('data-valid', 'false');
                        _$('#agOverData').addClass('uk-form-danger');
                        _$('#agOverData').parent().find('.message-error').text("Ci dispiace ma l'agevolazione è riservata a persone con almeno 70 anni di età.")
                        _$('#agOverData').parent().find('.message-error').removeClass('uk-hidden');
                        _$('#agevolazioneOverForm').find('.input-container').eq(1).css('margin-top','27px');
                    }
                } else {
                    _$('#agOverData').attr('data-valid', 'false');
                    _$('#agOverData').addClass('uk-form-danger');
                    _$('#agOverData').parent().find('.message-error').text("Inserisci una data valida.")
                    _$('#agOverData').parent().find('.message-error').removeClass('uk-hidden');
                    _$('#agevolazioneOverForm').find('.input-container').eq(1).css('margin-top','27px');
                }
            } else {
                _$('#agOverData').attr('data-valid', 'false');
                _$('#agOverData').addClass('uk-form-danger');
                _$('#agOverData').parent().find('.message-error').text("Campo obbligatorio.")
                _$('#agOverData').parent().find('.message-error').removeClass('uk-hidden');
                _$('#agevolazioneOverForm').find('.input-container').eq(1).css('margin-top','27px');
            }
        }
        
        //this._utils.checkFormValidator('submitAgOverForm','agevolazioneOverForm');
    }

    controlValueFieldInput(element,  formId?: string, submitButtonID?: string) {
        _$('#'+ formId).find(element).removeClass('uk-form-danger');
        let pattern = _$('#'+ formId).find(element).attr("pattern");
        if(_$('#'+ formId).find(element).val()) {
            _$('#'+ formId).find(element).parent().find('.message-error-req').addClass('uk-hidden');
            //@ts-ignore
            if (_$('#'+ formId).find(element).val().match(pattern)) {
                _$('#'+ formId).find(element).attr('data-valid', 'true');
                _$('#'+ formId).find(element).removeClass('uk-form-danger');
                _$('#'+ formId).find(element).parent().find('.message-error').addClass('uk-hidden');
                _$(element).closest('.uk-label-float').css('margin-bottom','');
            } else {
                _$('#'+ formId).find(element).attr('data-valid', 'false');
                _$('#'+ formId).find(element).addClass('uk-form-danger');
                _$('#'+ formId).find(element).parent().find('.message-error-format').removeClass('uk-hidden');
                _$(element).closest('.uk-label-float').css('margin-bottom','28px');
            }
        } else {
            _$('#'+ formId).find(element).parent().find('.message-error').addClass('uk-hidden');
            _$('#'+ formId).find(element).attr('data-valid', 'false');
            if(_$('#'+ formId).find(element).prop('required')){
                _$('#'+ formId).find(element).addClass('uk-form-danger');
                _$('#'+ formId).find(element).parent().find('.message-error-req').removeClass('uk-hidden');
                _$(element).closest('.uk-label-float').css('margin-bottom','28px');
            }
        }
        //this._utils.checkFormValidator(submitButtonID, formId);
    }

    validSingleField(element) {
        this._validationUtils.newValidInput(element);
    }

    validForm(submitButton) {
        let $form = _$(submitButton).closest('form');
        let $allInputs = $form.find('input');
        let validInputs = [];

        _$($allInputs).each((index, singleInput) => {
            let $input = _$(singleInput);
            this.validSingleField($input);
            if ($input.attr('data-valid') == 'false') {
                validInputs.push(false);
            } else {
                validInputs.push(true);
            }
        });
        if ($form.find('.check-container .checkmark').hasClass('selected')) {
            validInputs.push(true);
        } else {
            $form.find('.check-container .message-error').text('Campo obbligatorio.');
            $form.find('.check-container .message-error').removeClass('uk-hidden');
            validInputs.push(false);
        }

        if (!this.checkFileStatus(_$(submitButton).closest('form'))) {
            validInputs.push(false);
        }

        return validInputs.indexOf(false) < 0;
    }

    checkForm(element, idForm) {
        if (this.validForm(element)) {
            this.sendForm(element,idForm);
        }
    }

    checkFileStatus(form) {
        let files = _$(form).find('.js-ag-doc');
        files.find('.message-error').addClass('uk-hidden');
        let validFiles = [];
        _$(files).each((index, file) => {
            if (!_$(file).hasClass('uk-hidden')) {
                let upFile = _$(file).find('.js-ag-doc-showfile');
                if (!upFile.hasClass('uk-hidden')) {
                    if (upFile.find('.js-ag-doc-error').length == 0 || upFile.find('.js-ag-doc-error').hasClass('uk-hidden')) {
                        validFiles.push(true);
                    } else {
                        validFiles.push(false);
                    }
                } else {
                    validFiles.push(false);
                    _$(file).find('.message-error').text('File obbligatorio.');
                    _$(file).find('.message-error').removeClass('uk-hidden');
                }
            }
            
        })

        return validFiles.indexOf(false) < 0;
    }
            
    sendAnalytics(eventType: string, e?: object, extra?){
        try {
            let analyticsData;
            let funnelDict;
    
            switch (eventType) {
                case 'pageview_modale_agevolazioneOver':
                    //7.1.1
                    analyticsData = {
                        event: 'pageview'
                    }
            
                    funnelDict = 'FUNNEL_DATA_MODALE_RICHIESTA_AGEVOLAZIONE';
                    break;   
                case 'ClickInviaRichiestaAgevolazioneOver70':
                    //7.1.2
                    analyticsData = {
                        event: eventType,
                    }
            
                    funnelDict = 'FUNNEL_DATA_MODALE_RICHIESTA_AGEVOLAZIONE_CLICK_INVIA_RICHIESTA';
                    break;  
                case 'erroreForm':
                    //7.1.3
                    let labels = [];
                    _$(e).each(function() {
                        if(_$(this).parent().find('label').text()){
                            labels.push(_$(this).parent().find('label').text().trim());
                        }
                    });

                    let filesError = this._utils.formFindErrorFiles('agevolazione', _$(extra));
                    labels = labels.concat(filesError);
                    analyticsData = {
                        event: eventType,
                        form: {
                            campoErrore: labels
                        }
                    }
            
                    funnelDict = 'FUNNEL_DATA_MODALE_RICHIESTA_AGEVOLAZIONE_ERRORE_FORM';
                    if (labels.length) {
                        this._analytics.sendAnalytics(analyticsData, funnelDict, true, false);
                    }
                    return;  
                case 'pageview_modale_success':
                        //7.1.4
                        analyticsData = {
                            event: 'pageview',
                        }
            
                        funnelDict = 'FUNNEL_DATA_MODALE_RICHIESTA_AGEVOLAZIONE_SUCCESS_REQUEST';
                        break; 
                case 'pageview_modale_KO':
                    //7.1.5
                    analyticsData = {
                        event: 'pageview',
                    }
                
                    funnelDict = 'FUNNEL_DATA_MODALE_RICHIESTA_AGEVOLAZIONE_KO_REQUEST';
                    break;  
                case 'clickChiudiBannerRichiestaOver70OK':
                    //7.1.6
                    analyticsData = {
                        event: eventType,
                    }
                
                    funnelDict = 'FUNNEL_DATA_MODALE_RICHIESTA_AGEVOLAZIONE_SUCCESS_CHIUSURA';
                    break;     
                case 'clickChiudiBannerRichiestaOver70KO':
                    //7.1.7
                    analyticsData = {
                        event: eventType,
                    }
                
                    funnelDict = 'FUNNEL_DATA_MODALE_RICHIESTA_AGEVOLAZIONE_KO_CHIUSURA';
                    break;                                   
            }
    
            this._analytics.sendAnalytics(analyticsData, funnelDict, true, false);
        } catch (error) {
            console.log(eventType + ' : ' + error);
        }
    }            
}