import { EcommerceData } from '../services/analytics/analytics-interface';

function _populateServiceInfo(ecommerceData: EcommerceData) {
    if (!ecommerceData?.funnel) ecommerceData.funnel = {};
    ecommerceData.funnel.tipoServizio = window['typeOfService'] ?
        (window['typeOfService'] == 'HOME_DELIVERY' ? 'Ordina e ricevi' : 'Ordina e ritira') : '';
}

function _populateSlotInfo(ecommerceData: EcommerceData) {
    if (!ecommerceData?.funnel) ecommerceData.funnel = {};
    if (window['selectedTimeslotExpiration']) {
        ecommerceData.funnel.sceltaSlot = { 
            giorno: window['selectedTimeslot'] ? new Date(window['selectedTimeslot'].date).toString() : '',
            fasciaOraria: window['pointOfService'] && window['pointOfService'].firstAvailableTimeSlot && window['selectedTimeslot'] ?
                (window['pointOfService'].firstAvailableTimeSlot.date == window['selectedTimeslot'].date ? 'SI' : 'NO') : '',
            giornoProposto: window['selectedTimeslot'] ?
                `${window['selectedTimeslot'].startTime?.formattedHour} - ${window['selectedTimeslot'].endTime?.formattedHour}` : ''
        };
    } else {
        ecommerceData.funnel.sceltaSlot = {
            giorno: '',
            fasciaOraria: '',
            giornoProposto: ''
        };
    }
}

function _populateStoreInfo(ecommerceData: EcommerceData) {
    if (!ecommerceData?.funnel) ecommerceData.funnel = {};
    ecommerceData.funnel.sceltaNegozio = {
        idNegozio: window['pointOfService'] ? window['pointOfService'].name : '',
        cooperativaNegozio: window['pointOfService'] ? window['pointOfService'].cooperativeId : '',
        disponibilitaNegozio: window['typeOfService'] && window['pointOfService'] ?
            (window['typeOfService'] == 'HOME_DELIVERY' ?
                (window['pointOfService']?.hdTemporaryUnavailable == false ? 'Disponibile' : 'Esaurita') :
                (window['pointOfService']?.ocTemporaryUnavailable == false ? 'Disponibile' : 'Esaurita')) : ''
    };
}

function _populateUserInfo(ecommerceData: EcommerceData) {
    const user = window.user;
    if (!user) return;
    ecommerceData.userInfo = {
        abitudiniAlimentariFamiglia: user.abitudiniAlimentariFamiglia ? user.abitudiniAlimentariFamiglia : '',
        accessoMinisito: user.accessoMinisito ? user.accessoMinisito : '',
        acquistiOnline: user.acquistiOnline ? user.acquistiOnline : '',
        animaliDomestici: user.animaliDomestici ? user.animaliDomestici : '',
        canaliDiContatto: (() => { // !!!!!!!!!!!!!!!!!! TODO check this
            if (!user.canaliDiContatto) return {};
            return user.canaliDiContatto.reduce((result, canale) => {
                result[canale.codice] = canale.consenso;
                return result;
            }, {});
        })(),
        cap: user.cap ? user.cap : '',
        cardistaAnteCPlus: user.cardistaAnteCPlus ? user.cardistaAnteCPlus : '',
        cartaDematerializzata: user.cartaDematerializzata ? user.cartaDematerializzata : '',
        cartaFedelta: user.cartaFedelta ? user.cartaFedelta : '',
        citta: user.citta ? user.citta : '',
        codiceCitta: user.codiceCitta ? user.codiceCitta : '',
        codiceComuneDiNascita: user.codiceComuneDiNascita ? user.codiceComuneDiNascita : '',
        codiceCooperativa: user.codiceCooperativa ? user.codiceCooperativa : '',
        codiceNazione: user.codiceNazione ? user.codiceNazione : '',
        codiceProvincia: user.codiceProvincia ? user.codiceProvincia : '',
        comuneNascita: user.comuneNascita ? user.comuneNascita : '',
        conadCard: user.conadCard ? user.conadCard : '',
        dataNascita: user.dataNascita ? user.dataNascita : '',
        dataNascitaConiuge: user.dataNascitaConiuge ? user.dataNascitaConiuge : '',
        dataNascitaFiglio1: user.dataNascitaFiglio1 ? user.dataNascitaFiglio1 : '',
        dataNascitaFiglio2: user.dataNascitaFiglio2 ? user.dataNascitaFiglio2 : '',
        dataNascitaFiglio3: user.dataNascitaFiglio3 ? user.dataNascitaFiglio3 : '',
        email: user.email ? user.email : '',
        hobbyFamiglia: user.hobbyFamiglia ? user.hobbyFamiglia : '',
        negozioPreferito: user.negozioPreferito ? user.negozioPreferito : '',
        negozioPreferitoCooperativa: user.negozioPreferitoCooperativa ? user.negozioPreferitoCooperativa : '',
        numeroComponentiNucleoFamiliare: user.numeroComponentiNucleoFamiliare ? user.numeroComponentiNucleoFamiliare : '',
        privacy1: user.privacy1 ? user.privacy1 : '',
        privacy2: user.privacy2 ? user.privacy2 : '',
        privacy3: user.privacy3 ? user.privacy3 : '',
        provenienza: user.provenienza ? user.provenienza : '',
        saldoPunti: user.saldoPunti ? user.saldoPunti : '',
        saldoPuntiCollectionPrecedente: user.saldoPuntiCollectionPrecedente ? user.saldoPuntiCollectionPrecedente : '',
        sesso: user.sesso ? user.sesso : '',
        socialRegistration: user.socialRegistration ? user.socialRegistration : '',
        stato: user.stato ? user.stato : '',
        totCoupons: user.totCoupons ? user.totCoupons : '',
        unreadMessages: user.unreadMessages ? user.unreadMessages : '',
        utenzaId: user.utenzaId ? user.utenzaId : ''
    };
}

////////////////////////////////////////////////////////////////
///////////////////////// exported
////////////////////////////////////////////////////////////////

export function sendAnalytics(ecommerceData: EcommerceData, populateStoreAndTimeslot: boolean = true/*, analyticsType: string, needFunnelDict = true, needSlot = true, dataPromo?: any*/) {
    try {
        // populate funnel
        // ... TODO
        // populate user
        if (!ecommerceData.userInfo) // not already set
            _populateUserInfo(ecommerceData);
        // populate service, store and timeslot 
        if (populateStoreAndTimeslot) {
            if (!ecommerceData?.funnel?.tipoServizio) // not already set
                _populateServiceInfo(ecommerceData);
            if (!ecommerceData?.funnel?.sceltaNegozio) // not already set
                _populateStoreInfo(ecommerceData);
            if (!ecommerceData?.funnel?.sceltaSlot) // not already set
                _populateSlotInfo(ecommerceData);
        }
        // send analytics
        if (window.adobeDataLayer) {
            console.log('⚐ Tracking: ', ecommerceData);
            window.adobeDataLayer.push(ecommerceData);
        } else if (location?.hostname === 'localhost') {
            console.log('⚐ Tracking (local): ', ecommerceData);
        }
    } catch (e) {
        console.error('sendAnalytics error: ', e);
    }
}