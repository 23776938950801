import Alpine from 'alpinejs';
import { BASE_ECOMMERCE_DATA } from '../../../../../../constants';
import { formatC, i18n } from '../../../../../../libs/base-utils';
import { sendAnalytics } from '../../../../../../libs/tracking-utils';

Alpine.data('ec8x4xAssistance', () => ({
    init() {
        sendAnalytics(BASE_ECOMMERCE_DATA.PAGE_VIEW_PA_ASSISTANCE);
    },
    // consts
    // utils
    i18n,
    formatC,
    // internal functions
    // main component functions
}));
