import Alpine from 'alpinejs';
import PerfectScrollbar from 'perfect-scrollbar';

Alpine.data('popup12x1xDisplayOrderStates', () => ({
    ps: null,
    init() {
        const scrollbarOptions = {
            swipeEasing: true,
            suppressScrollX: true,
        };
        this.ps = new PerfectScrollbar(this.$refs.scrollable, scrollbarOptions);
    },
}));
