import { EcommerceData } from "./services/analytics/analytics-interface";

export const ORDER_STATUS = {
    'CONFIRMED': {
        label: 'Confirmed',
        description: 'The order is confirmed. If you wish to make changes or cancel the order you will be able to do it within the time indicated on the order detail.'
    },
    'IN_PREPARATION': {
        label: 'In preparation',
        description: 'We are preparing your order. We will inform you if there are any changes on the availability of the products purchased.'
    },
    'READY_FOR_DELIVERY': {
        label: 'In preparation',
        description: 'We are preparing your order. We will inform you if there are any changes on the availability of the products purchased.'
    },
    'READY_FOR_PREPARATION': {
        label: 'In preparation',
        description: 'We are preparing your order. We will inform you if there are any changes on the availability of the products purchased.'
    },
    'PICKER_DELAYED': {
        label: 'Delayed',
        description: 'The order has yet to be entrusted to the delivery guy, who appears to be late with the collection of the order.'
    },
    'DELIVERY_DELAYED': {
        label: 'Delayed',
        description: 'The order has yet to be entrusted to the delivery guy, who appears to be late with the collection of the order.'
    },
    'OUT_FOR_DELIVERY': {
        label: 'Out for delivery',
        description: 'The order has been taken into the delivery guy\'s care and is about to be delivered to the delivery address and within the time window you provided.'
    },
    'OUT_FOR_LOCKER': {
        label: 'Out for delivery',
        description: 'The order has been taken into the delivery guy\'s care and is about to be delivered to the delivery address and within the time window you provided.'
    },
    'READY_FOR_COLLECTION': {
        label: 'Ready for collection',
        description: 'The order can be collected at the selected point of service. On the order detail you can find the address of the collection point.'
    },
    'CUSTOMER_DELAYED': {
        label: 'Ready for collection',
        description: 'The order can be collected at the selected point of service. On the order detail you can find the address of the collection point.'
    },
    'DELIVERED_PICKED_HD': {
        label: 'Delivered',
        description: 'The order has been delivered to the provided delivery address and the payment has been carried out successfully.'
    },
    'DELIVERED_PICKED_OC': {
        label: 'Collected',
        description: 'The order has been collected at the point of service and the payment has been carried out successfully.'
    },
    'DELIVERY_FAILED': {
        label: 'Delivery failed',
        description: 'The order has not been delivered to the provided delivery address.'
    },
    'NOT_DELIVERED': {
        label: 'Delivery failed',
        description: 'The order has not been delivered to the provided delivery address.'
    },
    'CANCELLED_BY_CP': {
        label: 'Cancelled',
        description: 'The order has been cancelled successfully.'
    },
    'CANCELLED_BY_OMS': {
        label: 'Cancelled',
        description: 'The order has been cancelled successfully.'
    },
    'CANCELLED_BY_USER': {
        label: 'Cancelled',
        description: 'The order has been cancelled successfully.'
    },
    'CANCELLED_BY_CUSTOMER_CARE': {
        label: 'Cancelled',
        description: 'The order has been cancelled successfully.'
    },
};

export const TRANSACTION_STATUS = {
    'ACCEPTED': 'Accepted',
    'REJECTED': 'Rejected'
};

export const PAYMENT_METHOD = {
    '1': 'Bancomat',
    '13': 'Conad Card',
    '20': 'Credit Card',
    '25': 'Credit Card',
    '26': 'Credit Card',
    '27': 'Credit Card',
    '28': 'Credit Card',
    '33': 'Maestro',
    '34': 'Visa',
    '36': 'MasterCard',
    '32': 'Amex',
    '35': 'Diners',
    '37': 'JCB',
    '38': 'Unionpay',
    '14': 'Conad Card (traditional)',
    '15': 'Conad Card (prepaid)',
    '16': 'Conad Card (rechargeable)',
    '44': 'Conad Pay (app)',
    '11': 'Satispay',
    '12': 'Paypal',
    'default': 'Other'
};

export const BASE_ECOMMERCE_DATA: { [id: string]: EcommerceData; } = {
    PAGE_VIEW_PA_PROFILE: {
        event: 'pageview',
        funnel: {
            nomeFunnel: 'Area personale - Profilo',
            stepFunnel: 'Profilo'
        }
    },
    PAGE_VIEW_PA_ORDERS: {
        event: 'pageview',
        funnel: {
            nomeFunnel: 'Area personale - I miei Ordini',
            stepFunnel: 'I miei ordini'
        }
    },
    PAGE_VIEW_PA_WISHLISTS: {
        event: 'pageview',
        funnel: {
            nomeFunnel: 'Area personale - Preferiti',
            stepFunnel: 'Liste prodotti preferiti',
            listaVisualizzata: '<Valorizzato con la lista visualizzata>'
        }
    },
    PAGE_VIEW_PA_COUPONS: {
        event: 'pageview',
        funnel: {
            nomeFunnel: 'Area personale - Preferiti',
            stepFunnel: 'Offerte e buoni'
        }
    },
    PAGE_VIEW_PA_ASSISTANCE: {
        event: 'pageview',
        funnel: {
            nomeFunnel: 'Area personale - Assistenza',
            stepFunnel: 'Assistenza'
        }
    },
    CLICK_USER_MENU: {
        event: 'clickProfilo',
        funnel: {
            nomeFunnel: 'Area personale',
            stepFunnel: 'Apertura'
        }
    },
    CLICK_USER_MENU_ITEM: {
        event: 'clickVoceArea',
        funnel: {
            nomeFunnel: 'Area personale',
            stepFunnel: '<Nome della voce di Area>'
        }
    },
    CLICK_PA_PROFILE_LEFT_SIDE: {
        event: 'pulsantiSpallaSinistra',
        funnel: {
            nomeFunnel: 'Area personale - Profilo',
            stepFunnel: '<Modifica Dati|Contattaci|Logout>'
        }
    },
    CLICK_PA_PROFILE_SHOW_ALL: {
        event: 'vediTutti',
        funnel: {
            nomeFunnel: 'Area personale - Profilo',
            stepFunnel: '<I miei ordini|Liste Prodotti|Offerte e buoni>'
        }
    },
    CLICK_PA_ORDERS_MANAGE: {
        event: 'clickGestioneOrdine',
        funnel: {
            nomeFunnel: 'Area personale - I miei Ordini',
            stepFunnel: '<Modifica|Aggiungi|Annulla|Ordina di nuovo>'
        }
    },
    CLICK_PA_ORDERS_CHANGE_SERVICE: {
        event: 'clickServizioOrdine',
        funnel: {
            nomeFunnel: 'Area personale - I miei Ordini',
            stepFunnel: '<Spesa a casa|Ordina e ritira>'
        }
    },
    CLICK_PA_WISHLISTS_ADD_ALL_TO_CART: {
        event: 'aggiungiTuttiProdotti',
        funnel: {
            nomeFunnel: 'Area personale - Preferiti',
            stepFunnel: 'Liste prodotti preferiti',
            listaVisualizzata: '<Valorizzato con la lista visualizzata al momento del caricamento della pagina>'
        }
    },
    CLICK_PA_WISHLISTS_MANAGE: {
        event: 'ctaListePreferiti',
        funnel: {
            nomeFunnel: 'Area personale - Preferiti',
            stepFunnel: '<Elimina Lista|Modifica Lista|Nuova lista>',
            listaVisualizzata: '<Valorizzato con la lista visualizzata al momento del caricamento della pagina>'
        }
    },
    CLICK_PA_WISHLISTS_CHANGE_LIST: {
        event: 'ctaselezioneListaPreferiti',
        funnel: {
            nomeFunnel: 'Area personale - Preferiti',
            stepFunnel: 'Cambia Lista',
            listaVisualizzata: '<Valorizzato con la lista visualizzata al momento del caricamento della pagina>',
            listaSelezionata: '<Valorizzato con la lista selezionata>'
        }
    }
}
