import { ManageWishlistsServletRequest, Wishlist, WishlistEntry, WishlistProduct } from '../api';
import { CacheService } from '../api.utils/services/CacheService';
import { DefaultService } from '../api/services/DefaultService';
import Semaphore from '../libs/semaphore';
import { Cart } from '../types/cart';

export type SmartWishlist = {
    wishlistId: string;
    name?: string;
    isDefault?: boolean;
    entries?: Map<string, WishlistEntry>; // <-- difference with Wishlist is here
};

type SmartWishlistWrapper = {
    smartWishlists: Map<string, SmartWishlist>;
    userId: string
};

interface VirtualSmartWishlist extends SmartWishlist { };

export const DEFAULT_WISHLIST_NAME: string = 'default';
const WISHLISTS_CUSTOM_CACHE_NAME: string = 'cached-wishlists';
const WISHLISTS_CUSTOM_CACHE_TTL: number = 300000;
let _swWrap: SmartWishlistWrapper = {
    smartWishlists: new Map(),
    userId: undefined
};
const unique = new Semaphore();

async function _forceResetCache() {
    _swWrap = {
        smartWishlists: new Map(),
        userId: undefined
    };
    CacheService.resetCacheCustomData(WISHLISTS_CUSTOM_CACHE_NAME);
}

async function _retrieveSmartWishlists(forceCacheRefresh: boolean = false): Promise<Map<string, SmartWishlist>> {
    return unique.callFunction(__retrieveSmartWishlists, forceCacheRefresh);
};

async function __retrieveSmartWishlists(forceCacheRefresh: boolean = false): Promise<Map<string, SmartWishlist>> {
    if (!window.user?.utenzaId) return new Map();

    // CACHE LAYER 1: only at page load (not a real cache)
    if (!forceCacheRefresh && _swWrap.smartWishlists && _swWrap.smartWishlists?.size > 0 && _swWrap.userId === window.user.utenzaId) {
        return _swWrap.smartWishlists;
    }

    // CACHE LAYER 2: sessionStorage cache
    // warning!! Wishlists use a custom cache (the intermediate rapresentation is directly cached)
    if (!forceCacheRefresh) {
        const cachedWrap: SmartWishlistWrapper = CacheService.getFromCacheCustomData(WISHLISTS_CUSTOM_CACHE_NAME, WISHLISTS_CUSTOM_CACHE_TTL);
        if (cachedWrap) {
            if (cachedWrap.userId === window.user.utenzaId) {
                _swWrap = cachedWrap;
                return cachedWrap.smartWishlists;
            } else {
                console.warn('wrong userId on cache match -> reset wishlist cache');
                _forceResetCache();
            }
        }
    }

    // NO CACHE: BACKEND CALL
    try {
        const response = (await DefaultService.postApiEcommerceItItGetWishlistsJson());
        const wishlists: Wishlist[] = response?.data;
        if (!wishlists) return new Map();

        _swWrap.userId = window.user.utenzaId;
        _swWrap.smartWishlists = new Map();
        wishlists.forEach((wishlist, index) => {
            if (!wishlist?.name || !wishlist?.wishlistId) return;
            // manage the possibility of multiple names
            const wishlistName = !_swWrap.smartWishlists.has(wishlist?.name) ? wishlist?.name : `${wishlist?.name} (${index})`;
            _swWrap.smartWishlists.set(wishlistName, _convertToSmartWishlist(wishlist));
        });

        if (_swWrap && _swWrap.smartWishlists && _swWrap.smartWishlists?.size > 0) { // save into cache
            CacheService.putIntoCacheCustomData(WISHLISTS_CUSTOM_CACHE_NAME, WISHLISTS_CUSTOM_CACHE_TTL, _swWrap);
        }

        return _swWrap.smartWishlists;
    } catch (e) {
        console.warn('impossible to retrieve wishlists');
        throw e;
    }
};

async function _menageWishlist(wishlistName: string, action: ManageWishlistsServletRequest.action, newName: string = undefined): Promise<string> {
    if (!wishlistName || !action) {
        throw new Error('invalid menageWishlist params');
    }
    if (wishlistName == DEFAULT_WISHLIST_NAME) {
        throw new Error('operation non permitted on default wishlist');
    }

    const smartWishlists = await _retrieveSmartWishlists();
    if (!smartWishlists) throw new Error('invalid wishlists state');

    let targetWishlist = undefined;
    if (action == ManageWishlistsServletRequest.action.DELETE_WISHLIST || action == ManageWishlistsServletRequest.action.RENAME_WISHLIST) {
        targetWishlist = smartWishlists.get(wishlistName);
        if (!targetWishlist || !targetWishlist.wishlistId) throw new Error('invalid target wishlist');
    }

    if (action == ManageWishlistsServletRequest.action.CREATE_WISHLIST) {
        if (smartWishlists?.has(wishlistName)) throw new Error('a wishlist with this name already exists');
    }

    if (action == ManageWishlistsServletRequest.action.RENAME_WISHLIST) {
        if (smartWishlists?.has(newName)) throw new Error('a wishlist with this name already exists');
    }

    try {
        const request: ManageWishlistsServletRequest = {
            action: action,
            wishlistId: (() => {
                if (action == ManageWishlistsServletRequest.action.DELETE_WISHLIST) return targetWishlist.wishlistId;
                if (action == ManageWishlistsServletRequest.action.RENAME_WISHLIST) return targetWishlist.wishlistId;
                return undefined;
            })(),
            wishlistName: (() => {
                if (action == ManageWishlistsServletRequest.action.CREATE_WISHLIST) return wishlistName;
                if (action == ManageWishlistsServletRequest.action.RENAME_WISHLIST) return newName;
                return undefined;
            })()
        };
        const response = await DefaultService.postApiEcommerceItItManageWishlistsJson(request);
        return (response)?.data;
    } catch (e) {
        throw new Error('backend api error');
    }
};

async function _menageWishlistProducts(productCode: string, wishlistName: string, action: ManageWishlistsServletRequest.action): Promise<string> {
    if (!productCode || !wishlistName || !action) {
        throw new Error('invalid menageWishlist params');
    }

    const smartWishlists = await _retrieveSmartWishlists();
    if (!smartWishlists) return 'invalid wishlists state';
    const targetWishlist = smartWishlists.get(wishlistName);
    if (!targetWishlist || !targetWishlist.wishlistId) throw new Error('invalid target wishlist');

    if (action == ManageWishlistsServletRequest.action.ADD_PRODUCT) {
        if (targetWishlist.entries.has(productCode)) throw new Error('the product is already in the wishlist');
    }

    if (action == ManageWishlistsServletRequest.action.REMOVE_PRODUCT) {
        if (!targetWishlist.entries.has(productCode)) throw new Error('the product is not in the wishlist');
    }

    try {
        const request: ManageWishlistsServletRequest = {
            action: action,
            productId: productCode,
            wishlistId: targetWishlist.wishlistId,
        };
        const response = await DefaultService.postApiEcommerceItItManageWishlistsJson(request);
        return (response)?.data;
    } catch (e) {
        throw new Error('backend api error');
    }
};

async function _addWishlistProductsToCart(wishlistName: string): Promise<any> {
    if (!wishlistName) {
        throw new Error('invalid param: wishlistName');
    }

    const smartWishlists = await _retrieveSmartWishlists();
    if (!smartWishlists) return 'invalid wishlists state';
    const targetWishlist = smartWishlists.get(wishlistName);
    if (!targetWishlist || !targetWishlist.wishlistId) throw new Error('invalid target wishlist');

    try {
        const request: ManageWishlistsServletRequest = {
            action: ManageWishlistsServletRequest.action.ADD_WISHLIST_TO_CART,
            wishlistId: targetWishlist.wishlistId,
        };
        const response = await DefaultService.postApiEcommerceItItManageWishlistsJson(request);
        return (response)?.data;
    } catch (e) {
        throw new Error('backend api error');
    }
};

function _convertToSmartWishlist(wishlist: Wishlist): SmartWishlist {
    if (!wishlist) return undefined;
    return {
        wishlistId: wishlist?.wishlistId,
        name: wishlist?.name,
        isDefault: wishlist?.isDefault,
        entries: !wishlist?.entries ? {} : new Map<string, WishlistEntry>(wishlist.entries.map(entry => [entry?.product?.code, entry]))
    } as SmartWishlist;
};

function _enforce_cacheRefresh() {
    if (_swWrap && _swWrap.smartWishlists && _swWrap.smartWishlists?.size > 0) {
        CacheService.updateCacheCustomData(WISHLISTS_CUSTOM_CACHE_NAME, WISHLISTS_CUSTOM_CACHE_TTL, _swWrap);
    }
}

function _emulate_createWishlist(wishlistName: string, wishlistId: string) {
    const newWishlist: SmartWishlist = {
        wishlistId: wishlistId,
        name: wishlistName,
        isDefault: false,
        entries: new Map<string, WishlistEntry>()
    };
    _swWrap.smartWishlists.set(wishlistName, newWishlist);
    _enforce_cacheRefresh();
}

function _emulate_deleteWishlist(wishlistName: string) {
    _swWrap.smartWishlists.delete(wishlistName);
    _enforce_cacheRefresh();
}

function _emulate_renameWishlist(wishlistName: string, newName: string) {
    const currentWishlist: SmartWishlist = _swWrap.smartWishlists?.get(wishlistName);
    if (!currentWishlist) return;
    const renamedWishlist: SmartWishlist = {
        wishlistId: currentWishlist.wishlistId,
        name: newName,
        isDefault: currentWishlist.isDefault, // ever false
        entries: currentWishlist.entries
    };
    _swWrap.smartWishlists.set(newName, renamedWishlist);
    _swWrap.smartWishlists.delete(wishlistName);
    _enforce_cacheRefresh();
}

function _emulate_addProductIntoWishlist(productCode: string, wishlistName: string, productPlaceholderName: string = '') {
    const updatingWishlist: SmartWishlist = _swWrap.smartWishlists?.get(wishlistName);
    if (!updatingWishlist) return;
    const newProduct: WishlistProduct = {
        code: productCode,
        name: productPlaceholderName
    };
    const newEntry: WishlistEntry = {
        addedDate: Date.now(),
        product: newProduct
    };
    updatingWishlist.entries.set(productCode, newEntry);
    _enforce_cacheRefresh();
}

function _emulate_removeProductFromWishlist(productCode: string, wishlistName: string) {
    const updatingWishlist: SmartWishlist = _swWrap.smartWishlists?.get(wishlistName);
    if (!updatingWishlist) return;
    updatingWishlist.entries.delete(productCode);
    _enforce_cacheRefresh();
}

////////////////////////////////////////////////////////////////
///////////////////////// exported
////////////////////////////////////////////////////////////////

/**
 * Returns the list of user wishlists names. E.g. ['default', 'colazione', 'pranzo']
 * @param [ordered=false] if true wishlists names will be ordered
 * @returns the list of user wishlists names
 */
export async function getWishlistNames(ordered: boolean = false): Promise<string[]> {
    let wNames: string[] = Array.from((await _retrieveSmartWishlists())?.keys());
    if (!wNames) return [];
    if (!ordered || !wNames.includes(DEFAULT_WISHLIST_NAME)) return wNames;
    wNames.splice(wNames.indexOf(DEFAULT_WISHLIST_NAME), 1);
    wNames.sort();
    return ['default'].concat(wNames);
}

/**
 * Returns the number of wishlists (the wishlists list size)
 * @returns the number of wishlists
 */
export async function getNumberOfWishlists(): Promise<number> {
    return (await _retrieveSmartWishlists()).size;
}

/**
 * Returns the 'default' wishlist (already in "merge" view)
 * @returns the default wishlist
 */
export async function getDefaultWishlist(): Promise<VirtualSmartWishlist> {
    const smartWishlists = await _retrieveSmartWishlists();
    if (!smartWishlists || smartWishlists.size < 0) return undefined;
    const vanillaDefaultWishlist = smartWishlists ? smartWishlists.get(DEFAULT_WISHLIST_NAME) : undefined;
    if (!vanillaDefaultWishlist || !vanillaDefaultWishlist.entries) return undefined;
    let mergeWishlist = { ...vanillaDefaultWishlist };
    Array.from(smartWishlists?.keys()).filter(wName => wName != DEFAULT_WISHLIST_NAME).forEach(wName => {
        if (!wName || !smartWishlists.get(wName).entries) return;
        mergeWishlist.entries = new Map<string, WishlistEntry>([...mergeWishlist.entries, ...smartWishlists.get(wName).entries]);
    });
    return mergeWishlist;
}

/**
 * Returns the $wishlistName wishlist
 * @param wishlistName name of the wishlist to return
 * @returns the $wishlistName wishlist
 */
export async function getWishlist(wishlistName: string): Promise<VirtualSmartWishlist> {
    if (!wishlistName) return;
    if (wishlistName == DEFAULT_WISHLIST_NAME) return getDefaultWishlist();
    const smartWishlists = await _retrieveSmartWishlists();
    return smartWishlists ? smartWishlists.get(wishlistName) : undefined;
}

/**
 * Returns a list of wishlist that contains the $productCode. E.g. ['default', 'colazione', 'pranzo']
 * @param productCode the product code
 * @returns a list of wishlist that contains the $productCode
 */
export async function getWishlistsContainingProduct(productCode: string): Promise<string[]> {
    const smartWishlists = await _retrieveSmartWishlists();
    if (!smartWishlists) return [];
    const containing: string[] = Array.from(smartWishlists?.keys()).filter(wName => smartWishlists.get(wName)?.entries?.has(productCode));
    return (containing.length > 0 && !containing?.includes(DEFAULT_WISHLIST_NAME)) ? [...[DEFAULT_WISHLIST_NAME], ...containing] : containing;
}

/**
 * Returns true if the $productCode is in at least one wishlist
 * @param productCode the product code 
 * @returns true if the $productCode is in at least one wishlist 
 */
export async function isWishListProduct(productCode: string): Promise<boolean> {
    return (await getWishlistsContainingProduct(productCode))?.length > 0;
}

/**
 * Create a wishlist with a given $wishlistName
 * @param wishlistName the wishlist name
 * @returns true if no errors
 */
export async function createWishlist(wishlistName: string): Promise<boolean> {
    try {
        const createdWishlistId: string = (await _menageWishlist(wishlistName, ManageWishlistsServletRequest.action.CREATE_WISHLIST));
        if (createdWishlistId) {
            _emulate_createWishlist(wishlistName, createdWishlistId); // emulation
        } else {
            await _retrieveSmartWishlists(true); // force refresh wishlists
        }
        return true;
    } catch (e) {
        console.warn(e?.message);
        return false;
    }
}

/**
 * Delete a wishlist with a given $wishlistName
 * @param wishlistName the wishlist name
 * @returns true if no errors
 */
export async function deleteWishlist(wishlistName: string): Promise<boolean> {
    try {
        await _menageWishlist(wishlistName, ManageWishlistsServletRequest.action.DELETE_WISHLIST);
        _emulate_deleteWishlist(wishlistName); // emulation
        return true;
    } catch (e) {
        console.warn(e?.message);
        return false;
    }
}

/**
 * Rename a wishlist $wishlistName with a new $newName
 * @param wishlistName the current wishlist name
 * @param newName the new wishlist name
 * @returns true if no errors
 */
export async function renameWishlist(wishlistName: string, newName: string): Promise<boolean> {
    try {
        await _menageWishlist(wishlistName, ManageWishlistsServletRequest.action.RENAME_WISHLIST, newName);
        _emulate_renameWishlist(wishlistName, newName); // emulation
        return true;
    } catch (e) {
        console.warn(e?.message);
        return false;
    }
}

/**
 * Add a $productCode in a wishlist with a given $wishlistName
 * @param productCode the product code 
 * @param wishlistName the wishlist name 
 * @param productPlaceholderName the placeholder name for the priduct added (temporary, held until the first cache update)
 * @returns true if no errors 
 */
export async function addProductIntoWishlist(productCode: string, wishlistName: string, productPlaceholderName: string = ''): Promise<boolean> {
    try {
        await _menageWishlistProducts(productCode, wishlistName, ManageWishlistsServletRequest.action.ADD_PRODUCT);
        _emulate_addProductIntoWishlist(productCode, wishlistName, productPlaceholderName); // emulation
        return true;
    } catch (e) {
        console.warn(e?.message);
        return false;
    }
}

/**
 * Remove a $productCode from a wishlist with a given $wishlistName
 * @param productCode the product code
 * @param wishlistName the wishlist name 
 * @returns true if no errors 
 */
export async function removeProductFromWishlist(productCode: string, wishlistName: string): Promise<boolean> {
    try {
        await _menageWishlistProducts(productCode, wishlistName, ManageWishlistsServletRequest.action.REMOVE_PRODUCT);
        _emulate_removeProductFromWishlist(productCode, wishlistName); // emulation
        return true;
    } catch (e) {
        console.warn(e?.message);
        return false;
    }
}

/**
 * Update wishlists that have (or do not have) a certain product, based on $wishlistsMustHaveProduct
 * Steps:
 * - if a wishlist IN $wishlistsMustHaveProduct DOES NOT HAVE the product --> the product will be added to the products in the wishlist
 * - if a wishlist NOT IN $wishlistsMustHaveProduct HAS the product --> the product will be removed from the products in the wishlist
 * NB! if there is a wishlist in $wishlistsMustHaveProduct that does not exist, the addition will be skipped
 * @param productCode the product code 
 * @param wishlistsMustHaveProduct a Set of wishlist names, 'the only wishlists that must have the product'
 * @param productPlaceholderName the placeholder name for the product added (temporary, held until the first cache update)
 * @returns number of actions performed
 */
export async function updateWishlistsContainingProduct(productCode: string, wishlistsMustHaveProduct: Set<string>, productPlaceholderName: string = ''): Promise<number> {
    if (!wishlistsMustHaveProduct) return;
    const smartWishlists = await _retrieveSmartWishlists();
    if (!smartWishlists) return;

    let actions = 0;
    await Promise.all(Array.from(smartWishlists.entries()).map(async ([existingWishName, existingWish]) => {
        if (wishlistsMustHaveProduct.has(existingWishName)) { // => existingWish MUST HAVE the product
            if (!existingWish.entries?.has(productCode)) { // => add the product into existingWish
                await addProductIntoWishlist(productCode, existingWishName, productPlaceholderName);
                actions++;
            }
        } else { // => existingWish MUST NOT HAVE the product
            if (existingWish.entries?.has(productCode)) { // => remove the product from existingWish
                await removeProductFromWishlist(productCode, existingWishName);
                actions++;
            }
        }
    }));
    return actions;
}

/**
 * Add $wishlistName products into user cart
 * @param wishlistName the wishlist name
 * @returns true if no errors 
 */
export async function addWishlistProductsToCart(wishlistName: string): Promise<any> {
    try {
        return await _addWishlistProductsToCart(wishlistName);
    } catch (e) {
        console.warn(e?.message);
        return undefined;
    }
}

/**
 * Check if $wishlistName products can be added to the current $cart, false if all products are already in the $cart
 * @param wishlistName the wishlist name
 * @param cart the cart
 * @returns true if is possible, false otherwise
 */
export async function checkAddWishlistProductsToCart(wishlistName: string, cart: Cart): Promise<any> {
    if (!cart || !cart.entries) return true;
    const cartProducts: Set<string> = new Set(cart.entries.map(entry => entry.productCode));
    if (!cartProducts || cartProducts.size == 0) return true;
    const wish = (await getWishlist(wishlistName));
    if (!wish || !wish.entries || wish.entries.size == 0) return false;
    const wishProducts: Set<string> = new Set(wish.entries.keys());
    const diff = new Set<string>();
    wishProducts.forEach((item) => {
        if (!cartProducts.has(item)) { diff.add(item); }
    });
    if (diff?.size == 0) { console.log('All wishlist products already added'); }
    return (diff?.size > 0);
}
