import Alpine from 'alpinejs';
import Splide from '@splidejs/splide';

Alpine.data('ec11x1xPersonalAreaMenu', () => {
    let splide;

    return {
        currentPage: window.location.href as string,
        init() {
            splide = new Splide('#splide-personal-area-menu', {
                drag: 'free',
                arrows: false,
                pagination: false,
                autoWidth: true,
                focus: 'center',
            }).mount();
        },
        // consts
        // utils
        // internal functions
        // main component functions
        goToSlide(index) {
            if (!splide) return;
            this.$nextTick(() => {splide.go(index)});
        }
    }
});