//@ts-nocheck
import { BaseService } from "./base-service";
import { Window } from '../main';

export class RecaptchaService extends BaseService {
    constructor() {
        super();
    }

    public valueGrecaptcha;
    public siteKey = Window.recaptchaSiteKey ? Window.recaptchaSiteKey : '6LeX2lQaAAAAAKDBeCJJrCx2rqMJMEt0gbbX657F';
    public siteKeyV2 = Window.recaptchaSiteKeyV2 ? Window.recaptchaSiteKeyV2 : '6LeX2lQaAAAAAKDBeCJJrCx2rqMJMEt0gbbX657F';
    public v2Id;

    gRecaptcha(pointOfServiceId): Promise<string> {
        return new Promise((resolve, reject) => {
            try {

                if (Window.user || ( Window.pointOfService?.name == pointOfServiceId) ) {
                    resolve('');
                } else {
                    grecaptcha.ready(() => {
                        grecaptcha.execute(this.siteKey, { action: 'entryaccess' }).then((token) => {
                            console.log('token grecaptcha: ', token);
                            this.valueGrecaptcha = token;
                            resolve(this.valueGrecaptcha);
                        });
                    });
                }

            } catch (e) {
                console.log('google recaptcha error: ', e);
                reject('');
            }
        });
    }

    gRecaptchaV2(): Promise<string> {
        return new Promise((resolve, reject) => {
            try {

                grecaptcha.ready(() => {

                    if (this.v2Id) {
                        grecaptcha.reset(this.v2Id);
                    }

                    this.v2Id = grecaptcha.render(document.getElementById("captcha-v2-container"), {
                        sitekey: this.siteKeyV2,
                        size: "invisible",
                        callback: function (token) {
                            console.log('token grecaptcha v2: ', token);
                            resolve(token);
                        },
                        "expired-callback": function () {
                            reject('');
                        }
                    });

                    grecaptcha.execute(this.v2Id);

                });
            } catch (e) {
                console.log('google recaptcha v2 error: ', e);
                reject('');
            }
        });

    }

}