import Alpine from 'alpinejs';
import { formatC, i18n, renderHrefWithAdditionalParams } from '../../../../../../libs/base-utils';
import { sendAnalytics } from '../../../../../../libs/tracking-utils';
import { BASE_ECOMMERCE_DATA } from '../../../../../../constants';

Alpine.data('ec11x9xPreviewCoupons', () => ({
    init() {
    },
    // consts
    // utils
    i18n,
    formatC,
    renderHrefWithAdditionalParams,
    // internal functions
    // main component functions
    // only analytics functions
    trackShowAll() {
        let ecommerceData = BASE_ECOMMERCE_DATA.CLICK_PA_PROFILE_SHOW_ALL;
        ecommerceData.funnel.stepFunnel = 'Offerte e buoni';
        sendAnalytics(ecommerceData);
    }
}));