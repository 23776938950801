import { _$, _Uikit, _moment, Window } from '../../main';
import { StoreService } from '../../services/store-service/store-service';
import { CartService } from '../../services/cart-service/cart-service';
import { LOADER_POSITION, Utils } from '../../services/utils/utils';
import { LockerUtils } from '../../services/utils/locker-utils';
import { LocalStorage } from '../../services/local-storage/local-storage';
import { DynamicTsManager } from './dynamic-ts-manager/dynamic-ts-manager';
import Swiper, { SwiperOptions } from 'swiper';

export enum BOOKING_SLOT_STATE {
    DEFAULT,
    TO_CART,
    MODIFY_ADDRESS,
    TO_CHECKOUT,
    TO_CHECKOUT_EDIT,
    GOOGLE_PAGE
}

export const months = ['gen', 'feb', 'mar', 'apr', 'mag', 'giu', 'lug', 'ago', 'set', 'ott', 'nov', 'dic'];
export class BookUtils {
    _storeSelected: any;
    _slotSelected: any;
    _addressStoreSelected: string = '';
    _nameStoreSelected: string = '';
    _availabilityResponse: any;
    _addressDeliverySelected: string = '';
    _serviceType: string = '';
    /* Serve per differenziare il flusso di modifica quando il form di delivery è già compilato o meno nel checkout */
    public _changePdvAndDeliveryAddress = false;
    /* Conta il numero di indirizzi salvati dell'utente */
    public _deliveryAddressesCount;

    /* Indica se siamo nel flusso di checkout e se l'utente sta aggiungendo un nuovo indirizzo */
    public checkoutAddingNewAddress;

    public accordionReservationLength = '';
    public accordionMinimoSpesaHD = '';
    public accordionMinimoSpesaOC = '';
    public accordionSingleCostHD = '';
    public accordionSingleCostOC = '';
    

    public name: string = '';
    public surname: string = '';
    public email: string = '';

    public lockerOversizeProducts = [];

    /* Serve a fare la redirect al checkout in caso di locker e scelta slot durante il "Vai alla cassa" */
    public _lockerBookingSlot = false;
    private _showLockerAlert = false;
    private _forceLockerTimeslot = false;

    public _currentDeliveryFormattedAddress = Window.selectedAddress && Window.selectedAddress.completeAddress && Window.selectedAddress.completeAddress.formattedAddress ? Window.selectedAddress.completeAddress.formattedAddress : undefined;
    public bookingSlotState = Window.interactionCondition === 'REQUIRE_SERVICE_CHOICE' && !Window.typeOfService ? BOOKING_SLOT_STATE.GOOGLE_PAGE : BOOKING_SLOT_STATE.DEFAULT;

    swiperParams: SwiperOptions = {
        slidesPerView: 'auto',
        spaceBetween: 16,
        init: false,
        freeMode: true,
        simulateTouch: false,
        observer: true,
        scrollbar: {
            el: '.swiper-scrollbar',
            draggable: true,
            hide: false,
            snapOnRelease: false
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpointsInverse: true,
       /*  breakpoints: {
            375: {
                slidesPerView: 2.5
            },
            425: {
                slidesPerView: 2.8
            },
            550: {
                slidesPerView: 3.5
            },
            768: {
                slidesPerView: 4.5
            },
            1024: {
                slidesPerView: 3.5
            },
            1440: {
                slidesPerView: 5.5
            }
        }, */
    };

    swiperParamsCheckout: SwiperOptions = {
        slidesPerView: 'auto',
        spaceBetween: 16,
        freeMode: true,
        simulateTouch: true,
        observer: true,
        scrollbar: {
            el: '.swiper-scrollbar',
            draggable: true,
            hide: false,
            snapOnRelease: false
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        /* breakpoints: {
            375: {
                slidesPerView: 2.5
            },
            425: {
                slidesPerView: 2.8
            },
            550: {
                slidesPerView: 3.5
            },
            768: {
                slidesPerView: 4.5
            },
            1024: {
                slidesPerView: 3.5
            },
            1440: {
                slidesPerView: 5.5
            }
        }, */
        breakpointsInverse: true
    };

    constructor(
        private storeService: StoreService,
        public utils: Utils,
        public localStorage: LocalStorage,
        private lockerUtils: LockerUtils,
        private cartService: CartService,
        private dynamicTsManager: DynamicTsManager
    ) {
    }

    nkOnInit() {
    }

    storeWithPdvBackup() {
        return !this._storeSelected.firstAvailableTimeSlot && this._storeSelected.backupPos;
    }
    /**
     * Loads step2 in fase di entrypage
     * @param serviceType
     */
    loadStep2(serviceType: string) {
        this._lockerBookingSlot = false;
        this._showLockerAlert = false;
        this._forceLockerTimeslot = false;
        this.localStorage.setItem('step2Flow', JSON.stringify('loadStep2'));
        this.utils.showLoader(_$('#modal-onboarding').find('.btn-conferma-pdv button'), true, LOADER_POSITION.BUTTON);
        _$('#progress-steps >*').removeClass('complete');
        _$('#modal-timeslot').removeClass('edit-mode');

        this._serviceType = serviceType == 'ritira' ? 'ORDER_AND_COLLECT' : 'HOME_DELIVERY';
        this._storeSelected = this.localStorage.getFromStorage('storeSelected') ? this.localStorage.getFromStorage('storeSelected') : Window.pointOfService;
        let labelService = serviceType == 'ritira' ? 'Ritiro' : 'Consegna';
        
        if(this.requireTimeslotSelection(this._storeSelected)){
            if (this.storeWithPdvBackup() && serviceType == 'ricevi') {
                this.utils.enableAllGoogleInput();
                let backupPos = this._storeSelected.backupPos;
                _$('#modalSlotTerminati .text-box .subtitle').html('ci dispiace ma il negozio <b>' + this._storeSelected.storeType + '</b> al momento ha esaurito le disponibilità per le consegne.');
                _$('#modalSlotTerminati .text-box .desc.address').html('<b>' + backupPos.storeType + '</b> <br>' + backupPos.address.formattedAddress);
                if (backupPos.firstAvailableTimeSlot) {
                    const day = new Date(backupPos.firstAvailableTimeSlot.date).getDate();
                    const month = new Date(backupPos.firstAvailableTimeSlot.date).getMonth() + 1;
                    const hour = backupPos.firstAvailableTimeSlot.startTime.formattedHour;
                    const deliveryNote = `${labelService}: dal ${day}/${month} dalle ${hour}`;
                    _$('#modalSlotTerminati .text-box .uk-badge').html(deliveryNote);
                }
                _Uikit.modal('#modalSlotTerminati').show();
            } else {
                this._addressStoreSelected = this._storeSelected && this._storeSelected.address.formattedAddress ? this._storeSelected.address.formattedAddress : '';
                this._nameStoreSelected = this._storeSelected ? this._storeSelected.storeType : '';
                this._addressDeliverySelected = this.localStorage.getFromStorage('selectedAddress') ? this.localStorage.getFromStorage('selectedAddress').formatted_address : this._currentDeliveryFormattedAddress;
                let success = (res) => {
                    this.utils.showLoader(_$('#modal-onboarding').find('.btn-conferma-pdv button'), false, LOADER_POSITION.BUTTON);
                    if (res && res.data && res.data.results) {
                        this._availabilityResponse = res.data.results;
                        let cardDays = this.createCardDaysModalOnboarding(this._availabilityResponse, this.utils.isTsdEnabledTemporaryPdv(this._storeSelected) ? true : false);
                        _$('#modal-timeslot').find('.list-delivery-days').html('');
                        _$('#modal-timeslot').find('.list-delivery-days').html(cardDays);
                        this.utils.customScrollbar();
                        this.utils.callUpdateScrollbar('scrollable-list-opzioni-consegna');
                        this.utils.callUpdateScrollbar('scrollable-list-giorni');
                    }
                    setTimeout(() => {
                        this.utils.enableAllGoogleInput();
                    }, 3000);
                };
                let error = (err) => {
                    
                    this.utils.enableAllGoogleInput();
                    this.utils.showLoader(_$('#modal-onboarding').find('.btn-conferma-pdv button'), false, LOADER_POSITION.BUTTON);
                    if(this.lockerUtils.checkInvalidUnitObjectsPresence(err)){
                        _Uikit.modal('#modal-timeslot').hide();
                        let result = {};
                        result['invalidUnitObjects'] = this.lockerUtils.getInvalidUnitObjects(err);
                        _$('#modalLockerOversizeDimensions .product-remove-list').html('');
                        let errorProducts = result['invalidUnitObjects'];
                        for(let i = 0; i < errorProducts.length; i++){
                            _$('#modalLockerOversizeDimensions .product-remove-list').append(this.lockerUtils.createCustomCardLockerError(errorProducts[i]));
                        }
                        _Uikit.modal('#modalLockerOversizeDimensions').show();
                        this.lockerOversizeProducts = errorProducts;
                        _$('#modalLockerOversizeDimensions .btn-group .uk-button').attr('onclick', 'BookUtils.manageDeleteLockerEntriesLoadStep2("'+serviceType+'")');
                        
                    }else if(this.lockerUtils.checkTooManyUnitsPresence(err)){
                        _$('#modal-timeslot').addClass('locker-error');
                    }else{
                        _Uikit.modal('#modal-timeslot').hide();
                    }
                };

                let currentTime = _moment().valueOf();
                const deliveryArea = this._storeSelected ? this._storeSelected.selectedDeliveryId : null;

                let params = {
                    dateUTC: currentTime,
                    pointOfServiceId: this._storeSelected.name,
                    becommerce: this._storeSelected.becommerce, // il backend di riferimento del punto vendita
                    typeOfService: this._serviceType
                };

                if(this._serviceType == "HOME_DELIVERY"){
                    params['deliveryArea'] = deliveryArea;
                }

                _Uikit.modal('#modal-timeslot').show();
                if(_$('body').hasClass('page-CheckoutNew')){
                    _$('#modal-timeslot').addClass('edit-mode');
                }

                this.storeService.getAvailableTimeSlot(success, error, params);
            }
        }
    }

    /**
     * Loads step2 in fase di checkout
     * @param serviceType
     */
    loadStep2checkout(serviceType: string, preselectTimeslot?: boolean) {
        this._serviceType = serviceType == 'ritira' ? 'ORDER_AND_COLLECT' : 'HOME_DELIVERY';
        this._storeSelected = Window.pointOfService ? Window.pointOfService : '';
        this.localStorage.setItem('step2Flow', JSON.stringify('modifyStep2'));
        _$('#modal-timeslot').removeClass('edit-mode');
        let success = (res) => {
            if (res && res.data && res.data.results) {
                this.utils.showLoader('',false);
                this._availabilityResponse = res.data.results;
                let cardDays = this.createCardDaysModalOnboarding(this._availabilityResponse, this.utils.isTsdEnabled() ? true : false);
                _$('#modal-timeslot').find('.list-delivery-days').html('');
                _$('#modal-timeslot').find('.list-delivery-days').html(cardDays);
                this.utils.customScrollbar();
                this.utils.callUpdateScrollbar('scrollable-list-opzioni-consegna');
                this.utils.callUpdateScrollbar('scrollable-list-giorni');

                if(preselectTimeslot){
                    setTimeout(() => {
                        if(this.utils.isTsdEnabled() && Window.selectedTimeslot != null){
                            this.preselectTimeslotTsd(this._availabilityResponse);
                        }else{
                            this.preselectTimeslot(this._availabilityResponse);
                        }
                    }, 1)

                    
                }
            }
        };
        let error = (err) => {
            _Uikit.modal('#modal-timeslot').hide();
            this.utils.showLoader('',false);
            console.error(err);
            this._availabilityResponse = [];
        };
        let currentTime = _moment().valueOf();
        let deliveryArea;
        if(serviceType == 'ricevi'){
            deliveryArea = this._storeSelected && this._storeSelected.selectedDeliveryId ? this._storeSelected.selectedDeliveryId : Window.deliveryArea;
        }else{
            deliveryArea = null;
        }
        let params = {
            dateUTC: currentTime,
            pointOfServiceId: this._storeSelected?.name,
            becommerce: this._storeSelected?.becommerce, // il backend di riferimento del punto vendita
            typeOfService: this._serviceType
        };

        if(this._serviceType == 'HOME_DELIVERY'){
            params['deliveryArea'] = deliveryArea;
        }

        _Uikit.modal('#modal-timeslot').show();
        this.storeService.getAvailableTimeSlot(success, error, params);
    }

    changePdvAndAddress(choice: boolean){
        this._changePdvAndDeliveryAddress = choice;
    }

    setModalLabels(serviceType: string) {
        if (serviceType == 'ritira') {
            _$('.step-2 .addresses-container .info-order-receive').addClass('uk-hidden');
            _$('.step-2 .addresses-container .box-without-info-servizio').addClass('uk-hidden');
            _$('.step-2 .section-subtitle').text('Giorno di ritiro');
            _$('.step-2 .hours-container .section-subtitle').text('Orario di ritiro');
            _$('.step-2 .addresses-container .info-store .label').text('Il punto di ritiro:');
            _$('.step-2 .addresses-container hr').remove();
        } else {
            _$('.step-2 .addresses-container .info-order-receive').removeClass('uk-hidden');
        }
    }
    /**
     * Loads step2 in fase di modifica della prenotazione
     * @param serviceType
     */
    modifyStep2(serviceType: string, showLockerAlert?: boolean, forceLockerSelectTimeslot?: boolean) {
        this._lockerBookingSlot = false;
        this._showLockerAlert = showLockerAlert ? true : false;
        this._forceLockerTimeslot = forceLockerSelectTimeslot ? true : false;
        this.localStorage.setItem('step2Flow', JSON.stringify('modifyStep2'));
        this._serviceType = serviceType == 'ritira' ? 'ORDER_AND_COLLECT' : 'HOME_DELIVERY';
        this.setModalLabels(serviceType);

        if(forceLockerSelectTimeslot){
            this._lockerBookingSlot = true;
        }else{
            this._lockerBookingSlot = false;
        }
        
        if (Window.pointOfService) {
            this._storeSelected = Window.pointOfService;
        } else {
            this._storeSelected = ''
        }

        this._addressStoreSelected =
            this._storeSelected && this._storeSelected.address.formattedAddress
                ? this._storeSelected.address.formattedAddress
                : '';
        this._nameStoreSelected = this._storeSelected ? this._storeSelected.storeType : '';

        this._addressDeliverySelected =  this._currentDeliveryFormattedAddress;

        if(this.requireTimeslotSelection(this._storeSelected)){

            let success = (res) => {
                this.utils.showLoader(_$('.js-change-modal'), false, LOADER_POSITION.BUTTON);
                if (res && res.data && res.data.results) {
                    this._availabilityResponse = res.data.results;
                    let cardDays = this.createCardDaysModalOnboarding(this._availabilityResponse, this.utils.isTsdEnabled() ? true : false);
                    _$('#modal-timeslot').find('.list-delivery-days').html('');
                    _$('#modal-timeslot').find('.list-delivery-days').html(cardDays);
                    this.utils.customScrollbar();
                    this.utils.callUpdateScrollbar('scrollable-list-opzioni-consegna');
                    this.utils.callUpdateScrollbar('scrollable-list-giorni');
                    setTimeout(() => {
                        if(this.utils.isTsdEnabled() && Window.selectedTimeslot != null){
                            this.preselectTimeslotTsd(this._availabilityResponse);
                        }else{
                            this.preselectTimeslot(this._availabilityResponse);
                        }
                    }, 1);
                }
            };
            let error = (err) => {
                this.utils.showLoader(_$('.js-change-modal'), false, LOADER_POSITION.BUTTON);
                if(this.lockerUtils.checkInvalidUnitObjectsPresence(err)){
                    let result = {};
                    result['invalidUnitObjects'] = this.lockerUtils.getInvalidUnitObjects(err);
                    _$('#modalLockerOversizeDimensions .product-remove-list').html('');
                    let errorProducts = result['invalidUnitObjects'];
                    for(let i = 0; i < errorProducts.length; i++){
                        _$('#modalLockerOversizeDimensions .product-remove-list').append(this.lockerUtils.createCustomCardLockerError(errorProducts[i]));
                    }
                    _Uikit.modal('#modalLockerOversizeDimensions').show();
                    this.lockerOversizeProducts = errorProducts;
                    _$('#modalLockerOversizeDimensions .btn-group .uk-button').attr('onclick', 'BookUtils.manageDeleteLockerEntriesModifyStep2("'+serviceType+'")');
                    
                }else if(this.lockerUtils.checkTooManyUnitsPresence(err)){
                    _$('#modal-timeslot').addClass('locker-error');
                }else{
                    _Uikit.modal('#modal-timeslot').hide();
                }
            };
            let currentTime = _moment().valueOf();
            const deliveryArea = Window.deliveryArea;
            
            let params = {
                dateUTC: currentTime,
                pointOfServiceId: this._storeSelected?.name,
                becommerce: this._storeSelected?.becommerce, // il backend di riferimento del punto vendita
                typeOfService: this._serviceType
            };
            
            if(Window.typeOfService == "HOME_DELIVERY"){
                params['deliveryArea'] = deliveryArea;
            }
            _Uikit.modal('#modal-timeslot').show();
            this.storeService.getAvailableTimeSlot(success, error, params);
        }
    }

    /**
     * Loads step2 in fase di modifica del timeslot post chiusura ordine
     * @param serviceType
     */
     modifyStep2GuestOrder(serviceType: string, pointOfServiceId: string, becommerce: string, deliveryArea: string, tsdEnabled: boolean) {
        this._serviceType = serviceType == 'ORDER_AND_COLLECT' ? 'ORDER_AND_COLLECT' : 'HOME_DELIVERY';

        let success = (res) => {
            this.utils.showLoader(_$('.js-change-modal'), false, LOADER_POSITION.BUTTON);
            if (res && res.data && res.data.results) {
                let address = _$('.delivery-collect-address').html();
                _$('#modal-timeslot').find('.section-address-complete .address .value').text(address);
                this._availabilityResponse = res.data.results;
                let cardDays = this.createCardDaysModalOnboarding(this._availabilityResponse, tsdEnabled);
                _$('#modal-timeslot').find('.list-delivery-days').html('');
                _$('#modal-timeslot').find('.list-delivery-days').html(cardDays);
                this.utils.customScrollbar();
                this.utils.callUpdateScrollbar('scrollable-list-opzioni-consegna');
                this.utils.callUpdateScrollbar('scrollable-list-giorni');
            }
        };
        let error = (err) => {
            _Uikit.modal('#modal-timeslot').hide();
            console.log(err);
        };
        
        let currentTime = _moment().valueOf();

        let params = {
            dateUTC: currentTime,
            pointOfServiceId: pointOfServiceId,
            becommerce: becommerce,
            typeOfService: serviceType
        };

        if(this._serviceType == "HOME_DELIVERY"){
            params['deliveryArea'] = deliveryArea;
        }

        _Uikit.modal('#modal-timeslot').show();
        this.storeService.getAvailableTimeSlot(success, error, params);
    }

    reInitSection() {
        _$('.js-accordion-timeslot-info').addClass('uk-hidden');
        _$('.js-warning-disponibilita-container').addClass('uk-hidden');
    }

    createCardDaysModalOnboarding(arraySlots: any[], tsdEnabled:boolean = false){
        let items = '';
        let firstDayAvailable = true;

        if(tsdEnabled){
            if(Window.tsdUseSlider){
                _$('#modal-timeslot').addClass('modal-tsd type-a');
                _$('#modal-timeslot').removeClass('type-b');
            }else{
                _$('#modal-timeslot').addClass('modal-tsd type-b');
                _$('#modal-timeslot').removeClass('type-a');
            }
            this.resetModalTimeslotTSD();
        }
        for (let i = 0; i < arraySlots.length; i++) {
            let dynamicTimeslots = [];
            const element = arraySlots[i];
            const fullDate = _moment(element.date).utc();
            const date = _moment(element.date).utc().format('DD MMM');
            const dayOfWeek = _moment(element.date).utc().format('dddd');
            let checkStatus;
            if(tsdEnabled){
                
                let keys = Object.keys(element.deliveryBandMap);
                keys.forEach((index) => {
                    dynamicTimeslots.push(...element.deliveryBandMap[index].timeSlots);
                });
                checkStatus = this.checkStatus(dynamicTimeslots);
            }else{
                checkStatus = this.checkStatus(element.timeSlots);
            }
            let classStatus;
            switch (checkStatus) {
                case 'out':
                    classStatus = 'not-available';
                    break;
                case 'warning':
                    classStatus = 'limited';
                    break;
                default:
                    classStatus = 'available';
                    break;
            }

            let itemSelected = ''

            if (firstDayAvailable && checkStatus != 'out') {
                firstDayAvailable = false;
                itemSelected = 'selected';
                if(tsdEnabled){
                    this.createCardOpzioniConsegna(i);
                }else{
                    this.createHourSlotsModalOnboarding(i);
                }
            }
            
            let eventClick = '';

            if(tsdEnabled){
                eventClick = !(checkStatus == 'out') ? 'onclick="BookUtils.createCardOpzioniConsegna('+i+')"' : '';
            }else{
                eventClick = !(checkStatus == 'out') ? 'onclick="BookUtils.createHourSlotsModalOnboarding('+i+')"' : '';
            }

            items += `<li><div nkPage="Card-timeslot" class="component-card-timeslot ${classStatus} ${itemSelected}" role="button" aria-label="Timeslot Disponibile il 22 Settembre" data-date="${fullDate}" data-index-day=${i} ${eventClick}>
                        <div class="uk-card uk-card-default uk-flex">
                            <div class="uk-card-body uk-text-left">
                                <div class="availability uk-flex-row title-row store-type">
                                    <span class="available">Disponibile</span>
                                    <span class="not-available">Esaurita</span>
                                    <span class="limited">Limitata</span>
                                </div>
                                <div class="day-month uk-flex-row store-address">
                                    <span class="card-text">${date}</span>
                                </div>
                                <div class="letter-day uk-flex-row">
                                    <span class="">${dayOfWeek}</span>
                                </div>
                            </div>
                        </div>
                    </div></li>`
        }

        _$('#modal-timeslot').removeClass('showing-skeleton');
        return items;
    }

    /**
     * Checks status
     * @param timeslot
     * @returns
     */
    checkStatus(timeslot: any[]) {
        let status = '';
        let availabilityCount = 0;
        for (let i = 0; i < timeslot.length; i++) {
            if (timeslot[i].available) {
                availabilityCount++;
            }
        }
        if (availabilityCount == 0) {
            status = 'out';
        } else if (availabilityCount < timeslot.length / 2) {
            status = 'warning';
        }
        return status;
    }

    createHourSlotsModalOnboarding(item, tsd?){
        let availability;
        if(tsd != null){
            availability = tsd.timeSlots;
        }else{
            _$('#modal-timeslot').addClass('selected-timeslot');
            _$('#modal-timeslot').find('.component-card-timeslot').removeClass('selected');
            _$('#modal-timeslot').find('.component-card-timeslot').eq(item).addClass('selected');
            availability = this._availabilityResponse[item].timeSlots;
        }
        
        let dataIndexDay = item;
        let items = '';
        
        for (let i = 0; i < availability.length; i++) {
            let item = availability[i];
            let checkTimeContinuativo;
            let startTimeText;
            let endTimeText;
            if(tsd != null){
                startTimeText = item.startTime.formattedHour;
                endTimeText = item.endTime.formattedHour;
            }else{
                checkTimeContinuativo = this.checkTimeslotContinuativo(item, this._availabilityResponse[dataIndexDay]);
                startTimeText = checkTimeContinuativo['startTimeText'];
                endTimeText = checkTimeContinuativo['endTimeText'];
            }
            
            let startTime = item.startTime.formattedHour;
            let endTime = item.endTime.formattedHour;
            
            const disabledHour = !item.available ? ' disabled' : '';
            const timeSlotId = item.slotId ? 'slotId="' + item.slotId + '"' : '';

            let priceRanges;
            let surchargeThresholds;
            let deliveryThresholds;

            if((item.deliveryThresholds && item.deliveryThresholds.length) || (item.serviceSurchargeThresholds && item.serviceSurchargeThresholds.length)){
                priceRanges =  'priceRanges="true"';
            }else{
                priceRanges = 'priceRanges="false"';
            }

            let surchargeThresholdsAttr;
            let deliveryThresholdsAttr;

            if(item.deliveryThresholds){
                deliveryThresholds = encodeURI(JSON.stringify(item.deliveryThresholds));
                deliveryThresholdsAttr = 'deliveryThresholds='+ deliveryThresholds;
            }

            if(item.serviceSurchargeThresholds){
                surchargeThresholds = encodeURI(JSON.stringify(item.serviceSurchargeThresholds));
                surchargeThresholdsAttr = 'serviceSurchargeThresholds=' + surchargeThresholds;
            }

            let reservationLength = item.reservationLength ? item.reservationLength : '';
            let reservationLengthAttr = 'reservationLength=' + reservationLength;
            let timeslot = tsd != null ? `<li>
                                            <div>
                                                ${item.discountedDeliveryCost != null ? 
                                                `<div class="promo-tag">
                                                    <i class="icon-tag"></i>
                                                    <span class="text">${item.discountedDeliveryCost > 0 ? item.discountedDeliveryCost + '€' : 'Gratuita'}</span>
                                                </div>` : ``}
                                                <button type="button" 
                                                    onclick="BookUtils.setCostModalOnboarding(this)" 
                                                    class="chip-hour uk-flex uk-flex-center uk-flex-middle ${!item.available ? 'not-available' : ''}"
                                                    ${timeSlotId}
                                                    ${priceRanges ? priceRanges : ''}
                                                    ${deliveryThresholdsAttr ? deliveryThresholdsAttr : ''}
                                                    ${surchargeThresholdsAttr ? surchargeThresholdsAttr : ''}
                                                    ${reservationLengthAttr ? reservationLengthAttr : ''}
                                                    data-hour = ${startTime+'-'+endTime}
                                                    data-index-day = ${dataIndexDay}
                                                    data-timeslot = '${JSON.stringify(item)}'
                                                    ${disabledHour}
                                                    >${startTimeText} - ${endTimeText}
                                                </button>
                                            </div>
                                        </li>`
                                        : 
                                        `<li>
                                            <button type="button" 
                                                onclick="BookUtils.setCostModalOnboarding(this)" 
                                                class="chip-hour uk-flex uk-flex-center uk-flex-middle ${!item.available ? 'not-available' : ''}"
                                                ${timeSlotId}
                                                ${priceRanges ? priceRanges : ''}
                                                ${deliveryThresholdsAttr ? deliveryThresholdsAttr : ''}
                                                ${surchargeThresholdsAttr ? surchargeThresholdsAttr : ''}
                                                ${reservationLengthAttr ? reservationLengthAttr : ''}
                                                data-hour = ${startTime+'-'+endTime}
                                                data-index-day = ${dataIndexDay}
                                                ${disabledHour}
                                                >${startTimeText} - ${endTimeText}</button>
                                        </li>`
            
            if(tsd){
                items += timeslot;
            }else{
                if(!item.isFull){
                    items += timeslot;
                }
            }
            
        }

        if(tsd != null){
            _$('#modal-timeslot').find('.ts-dinamico-hours.type-b').html(items);
            const atLeastOnePromotion = availability.some(({ discountedDeliveryCost, deliveryCost }) => discountedDeliveryCost != null && deliveryCost != null && discountedDeliveryCost != deliveryCost);
            const allPromotion = availability.every(({discountedDeliveryCost, deliveryCost}) => discountedDeliveryCost != null && deliveryCost != null && discountedDeliveryCost != deliveryCost);
            let firstPromoValue = availability[0].discountedDeliveryCost;
            const allSamePromotion = availability.every(({discountedDeliveryCost}) => discountedDeliveryCost == firstPromoValue);
            if(atLeastOnePromotion && !allPromotion){
                _$('#modal-timeslot').find('.ts-dinamico-hours.type-b').addClass('visible-promotions');
            }else if(allPromotion && !allSamePromotion){
                _$('#modal-timeslot').find('.ts-dinamico-hours.type-b').addClass('visible-promotions');
            }else{
                _$('#modal-timeslot').find('.ts-dinamico-hours.type-b').removeClass('visible-promotions');
            }
        }else{
            _$('#modal-timeslot').find('.list-delivery-hours').html(items);
        }

        this.setCostModalOnboarding(_$('#modal-timeslot').find('.chip-hour:not(.not-available):first'));
    }

    createCardOpzioniConsegna(item){
        _$('.component-ModalTimeslot .alert-fascia-non-disponibile').removeClass('unavailable-timeslot-selected');
        _$('#modal-timeslot').find('.component-card-timeslot').removeClass('selected');
        _$('#modal-timeslot').find('.component-card-timeslot').eq(item).addClass('selected');
        _$('#modal-timeslot').removeClass('selected-timeslot');
        let bands = this._availabilityResponse[item];
        _$('.component-CardOpzioneConsegna').addClass('uk-hidden');
        _$('.component-CardOpzioneConsegna').attr('data-day-id', item);
        let activeBands = Object.keys(bands.deliveryBandMap);
        activeBands.forEach((index, element) => {
            _$('.component-CardOpzioneConsegna[data-band-id='+index+']').removeClass('uk-hidden');
        });
        _$('.tsd-opzione-consegna').removeClass('selected');
        _$('.component-CardOpzioneConsegna').removeClass('selected');
        this.dynamicTsManager.manageCardOpzioniConsegnaPromotions(bands);
        if(bands.deliveryBandMap != null && Object.keys(bands.deliveryBandMap) != null){
            Object.keys(bands.deliveryBandMap).forEach((value) => {
                if(value == 'TYPE4'){
                    let timeslotArray = bands.deliveryBandMap[value].timeSlots;
                    let firstFormattedHour = timeslotArray[0] != null && timeslotArray[0].startTime != null && timeslotArray[0].startTime.formattedHour != null ? timeslotArray[0].startTime.formattedHour : '8:00';
                    let lastFormattedHour = timeslotArray.slice(-1)[0] != null && timeslotArray.slice(-1)[0].endTime != null && timeslotArray.slice(-1)[0].endTime.formattedHour != null ? timeslotArray.slice(-1)[0].endTime.formattedHour : '22:00';
                    _$(`.component-CardOpzioneConsegna[data-band-id=TYPE4]`).find('.start-time').html(firstFormattedHour);
                    _$(`.component-CardOpzioneConsegna[data-band-id=TYPE4]`).find('.end-time').html(lastFormattedHour);
                }else{
                    let duration = Math.ceil(bands.deliveryBandMap[value].durationMinutes / 60);
                    _$(`.component-CardOpzioneConsegna[data-band-id=${value}]`).find('.band-width').html(`${duration} ${duration > 1 ? 'ore' : 'ora'}`);
                }
            })
        }
        if(bands && bands.deliveryBandMap != null && bands.deliveryBandMap.TYPE4 != null){
            
        }
    }

    createHourSlotsSlider(eventTarget, findPreselected: boolean = false){
        _$('.component-CardOpzioneConsegna').removeClass('selected');
        _$(eventTarget).closest('.component-CardOpzioneConsegna').addClass('selected');
        _$('.ts-dinamico-hours-mobile .timeline-tsd').css('left', '0px');
        _$('.tsd-opzione-consegna').addClass('selected');
        let index = _$(eventTarget).closest('.component-CardOpzioneConsegna').attr('data-day-id');
        let band = _$(eventTarget).closest('.component-CardOpzioneConsegna').attr('data-band-id')
        let timeslotResponse = this._availabilityResponse;
        _$('#modal-timeslot').addClass('selected-timeslot');
        if(Window.tsdUseSlider){
            this.dynamicTsManager.createDraggableTSD(timeslotResponse[index].deliveryBandMap[band], findPreselected);
            this.setCostModalOnboarding(_$(window).width() >= 1024 ? JSON.parse(_$('.component-SliderTimeslotTsd.active').attr('data-timeslot')) : JSON.parse(_$('.component-SliderTimeslotTsd.active').attr('data-timeslot')), true);
            _$('#modal-timeslot').removeClass('type-b').addClass('type-a');
        }else{
            this.createHourSlotsModalOnboarding(0, timeslotResponse[index].deliveryBandMap[band])
            _$('#modal-timeslot').removeClass('type-a').addClass('type-b');
        }

        if(band == 'TYPE4'){
            _$('.title-section-tsd').addClass('show-all-day');
        }else{
            _$('.title-section-tsd').removeClass('show-all-day');
        }
        
        _$('#modal-timeslot').find('.list-delivery-hours').html('');
        this.utils.customScrollbar();
    }

    resetModalTimeslotTSD(){
        _$('.ts-dinamico-hours-desktop .list-tsd-desktop').html('');
        _$('.ts-dinamico-hours-mobile .container-timeslots').html('');
        _$('.component-CardOpzioneConsegna.selected').removeClass('selected');
        _$('.tsd-opzione-consegna').removeClass('selected');
    }

    setCostModalOnboarding(item, tsdTypeA?){

        if(tsdTypeA){
            this._slotSelected = item['slotId'];
            _$('#modal-timeslot').find('.chip-hour').removeClass('selected');
            _$(item).addClass('selected');

            if(_$('.component-AccordionTimeslotInfo').length) {
                if(item['deliveryThresholds'] != null || item['serviceSurchargeThresholds'] != null){
                    this.manageAccordion2(_$('#modal-timeslot'), encodeURI(JSON.stringify(item.deliveryThresholds)), encodeURI(JSON.stringify(item.serviceSurchargeThresholds)));
                }else{
                    _$('#modal-timeslot .component-AccordionTimeslotInfo .accordion-second').addClass('uk-hidden');
                    _$('#modal-timeslot .component-AccordionTimeslotInfo .accordion-second').siblings('hr').addClass('uk-hidden');
                }
            }

            if(item['reservationLength'] != null){
                if(_$('#modal-timeslot .component-AccordionTimeslotInfo .reservation-length').length){
                    this.accordionReservationLength = item['reservationLength'];
                    _$('#modal-timeslot .component-AccordionTimeslotInfo .reservation-length').html(this.accordionReservationLength);
                }
            }
        }else{
            this._slotSelected = _$(item)[0].getAttribute('slotId');
            _$('#modal-timeslot').find('.chip-hour').removeClass('selected');
            _$(item).addClass('selected');

            if(_$('.component-AccordionTimeslotInfo').length) {
                if(_$(item)[0].getAttribute('priceRanges') == "true"){
                    this.manageAccordion2(_$('#modal-timeslot'), _$(item)[0].getAttribute('deliveryThresholds'), _$(item)[0].getAttribute('serviceSurchargeThresholds'));
                }else{
                    _$('#modal-timeslot .component-AccordionTimeslotInfo .accordion-second').addClass('uk-hidden');
                    _$('#modal-timeslot .component-AccordionTimeslotInfo .accordion-second').siblings('hr').addClass('uk-hidden');
                }
            }

            if(_$(item)[0].getAttribute('reservationLength')){
                if(_$('#modal-timeslot .component-AccordionTimeslotInfo .reservation-length').length){
                    this.accordionReservationLength = _$(item)[0].getAttribute('reservationLength');
                    _$('#modal-timeslot .component-AccordionTimeslotInfo .reservation-length').html(this.accordionReservationLength);
                }
            }
        }
    }

    setCostCheckout(item) {
        this._slotSelected = _$(item)[0].getAttribute('slotId');
        _$('.js-hours-container .uk-tag.active')[0] ? _$('.js-hours-container .uk-tag.active')[0].classList.remove('active') : '';
        _$(item)[0].classList.add('active');
        const priceValue = _$(item)[0].getAttribute('price');
        
        _$('.section-giorno-orario .button-prenota').removeAttr('disabled');

        if(_$('.section-giorno-orario .info-costs') && _$('.section-giorno-orario .info-costs').length) {
            _$('.section-giorno-orario .info-costs').find('.delivery-costs').html(priceValue);
        }

        _$(item).parent('.js-hours-container').children('button').removeAttr('aria-selected');
        _$(item).attr('aria-selected', 'true');
    }

    bookingSlot(checkPdvName?: boolean) {
        this.utils.showLoader(_$('.js-button-conferma, #modal-timeslot .js-button-conferma-timeslot'), true, LOADER_POSITION.BUTTON);
        const infoStore = this.manageInfoStore();
        let selectedAddressSAP;
        let notCompleted;
        if(this.localStorage.getFromStorage('step2Flow') == 'loadStep2'){
            selectedAddressSAP = this.localStorage.getFromStorage('selectedAddress_SAP_Format') ? this.localStorage.getFromStorage('selectedAddress_SAP_Format') : '';
            notCompleted = this.localStorage.getFromStorage('selectedAddress') ? this.localStorage.getFromStorage('selectedAddress').notCompleted : '';
        }else if(this.localStorage.getFromStorage('step2Flow') == 'modifyStep2'){
            selectedAddressSAP = Window.selectedAddress.completeAddress;
            notCompleted = Window.selectedAddress.notCompleted;
        }
        const deliveryArea = this._storeSelected ? this._storeSelected.selectedDeliveryId : Window.deliveryArea;
        
        if(notCompleted){
            selectedAddressSAP['notCompleted'] = notCompleted;
        }

        let body;
        
        if(this.localStorage.getFromStorage('step2Flow') == 'loadStep2'){
            body = {
                timeSlotId: Window.tsdUseSlider && this.utils.isTsdEnabledTemporaryPdv(this._storeSelected) ? this.fetchTimeslotIdFromSlider() : this._slotSelected,
                pointOfServiceId: this._storeSelected.name,
                becommerce: this._storeSelected.becommerce,
                typeOfService: this._serviceType,
                deliveryAddress: this._addressDeliverySelected ? this._addressDeliverySelected : this._currentDeliveryFormattedAddress,
                completeAddress: selectedAddressSAP,
                latitudine: infoStore.latitude,
                longitudine: infoStore.longitude,
                nStoresFound: infoStore.nStore
            };
    
            if(this._serviceType == 'HOME_DELIVERY'){
                body['deliveryArea'] = deliveryArea ? deliveryArea : Window.deliveryArea
            }
        }else{
            body = {
                timeSlotId: Window.tsdUseSlider && this.utils.isTsdEnabled() ? this.fetchTimeslotIdFromSlider() : this._slotSelected,
                oldTimeslotId: Window.selectedTimeslot != null && Window.selectedTimeslot.slotId != null ? Window.selectedTimeslot.slotId : ''
            };
        }

        let success = (res) => {
            if (res) {
                //viene fatto un cambio pagina, non togliere il loader
                if(_$('body').hasClass('page-CheckoutNew')){
                    this.localStorage.setItem('selectedAddressOrderAndCollect', JSON.stringify(selectedAddressSAP));
                }
                this.utils.showLoader(_$('.js-button-conferma, #modal-timeslot .js-button-conferma-timeslot'), false, LOADER_POSITION.BUTTON);
                let params = Window.location.search;
                let queryParams = new URLSearchParams(params);
                if(queryParams && queryParams.get('cb')){
                    Window.location.href = decodeURIComponent(queryParams.get('cb'));
                }else if(this.bookingSlotState == BOOKING_SLOT_STATE.TO_CART){
                    if(checkPdvName){
                        if(this._serviceType == "ORDER_AND_COLLECT"){
                            if(this._lockerBookingSlot){
                                Window.location.href = Window.urlSite.checkout;
                            }else{
                                Window.location.href = Window.urlSite.home;
                            }
                        }else{
                            if(Window.pointOfService && Window.pointOfService.name == this._storeSelected.name){
                                Window.location.reload();
                            }else{
                                Window.location.href = Window.urlSite.home;
                            }
                        }
                    }else{
                        Window.location.href = Window.urlSite.detailCart + '?updatedCart=true';
                    }
                }else if(this.bookingSlotState == BOOKING_SLOT_STATE.MODIFY_ADDRESS){
                    if(Window.pointOfService && Window.pointOfService.name == this._storeSelected.name){
                        Window.location.reload();
                    }else{
                        Window.location.href = Window.urlSite.detailCart;
                    }
                }else if(this.bookingSlotState == BOOKING_SLOT_STATE.TO_CHECKOUT){
                    Window.location.href = Window.urlSite.checkout;
                }else if(this.bookingSlotState == BOOKING_SLOT_STATE.TO_CHECKOUT_EDIT){
                    Window.location.href = Window.urlSite.checkout + '?edit';
                }else if(this.bookingSlotState == BOOKING_SLOT_STATE.DEFAULT){
                    if(this._lockerBookingSlot){
                        Window.location.href = Window.urlSite.checkout;
                    }else{
                        Window.location.reload();
                    }
                }

                this.localStorage.setItem('noMoreExtend', 'false');
            }
        };

        let error = (err) => {
            this.utils.showLoader(_$('.js-button-conferma, #modal-timeslot .js-button-conferma-timeslot'), false, LOADER_POSITION.BUTTON);
            if(this._serviceType == "HOME_DELIVERY"){
                /* TODO: onboarding checkout - verificare il loadstep2 se viene piu chiamato */
                if(this.localStorage.getFromStorage('step2Flow') == 'loadStep2'){
                    this.loadStep2('ricevi');
                }else{
                    this.modifyStep2('ricevi');
                }
                
            }else if(this._serviceType == "ORDER_AND_COLLECT"){
                /* TODO: onboarding checkout - verificare il loadstep2 se viene piu chiamato */
                if(this.localStorage.getFromStorage('step2Flow') == 'loadStep2'){
                    this.loadStep2('ritira');
                }else{
                    this.modifyStep2('ritira', this._showLockerAlert, this._forceLockerTimeslot);
                }
                
            }
        };

        if((this.localStorage.getFromStorage('step2Flow') == 'loadStep2' ? (Window.tsdUseSlider && this.utils.isTsdEnabledTemporaryPdv(this._storeSelected)) : (Window.tsdUseSlider && this.utils.isTsdEnabled()) ) && ((_$('.balloon-timeslot').length && _$('.balloon-timeslot').hasClass('not-available')) || (_$('.component-SliderTimeslotTsd.active').length && _$('.component-SliderTimeslotTsd.active').hasClass('not-available')))){
            this.showModalTsdUnavailableAlert();
        }else{
            if(this.localStorage.getFromStorage('step2Flow') == 'loadStep2'){
                this.storeService.reserveTimeSlot(success, error, body);
            }else{
                this.storeService.reserveTimeSlotOnCart(success, error, body);
            }
        }
        
    }

    bookingSlotCheckout(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.utils.showLoader('', true);

            let body = {
                timeSlotId: Window.tsdUseSlider && this.utils.isTsdEnabled() ? this.fetchTimeslotIdFromSlider() : this._slotSelected,
                oldTimeslotId: Window.selectedTimeslot != null && Window.selectedTimeslot.slotId != null ? Window.selectedTimeslot.slotId : ''
            };

            let success = (res) => {
                if (res && res.data && res.data.data && res.data.data.timeSlot && res.data.data.timeSlot.date) {
                    this.utils.showLoader('', false);
                    let date = new Date(res.data.data.timeSlot.date);
                    _Uikit.countdown('.banner-timer-container .countdown-timer', {
                        date: new Date(res.data.data.expiration)
                    });
                    Window.selectedTimeslotExpiration = res.data.data.expiration;
                    _$('.box-data').find('.data-ora .data-ora-info').html(this.bookingSlotCheckoutText(res.data.data.timeSlot));
                    resolve();
                    if(Window.selectedTimeslot != null && Object.keys(Window.selectedTimeslot) != null && res.data.data.timeSlot != null){
                        Object.keys(Window.selectedTimeslot).forEach((value) => {
                            Window.selectedTimeslot[value] = res.data.data.timeSlot[value];
                        });
                    }
                }
                this.localStorage.setItem('noMoreExtend', 'false');
                _Uikit.modal('#modal-timeslot').hide();
            };
            let error = (err) => {
                this.utils.showLoader('', false);
                if(this._serviceType == "HOME_DELIVERY"){
                    this.loadStep2checkout('ricevi');
                }else if(this._serviceType == "ORDER_AND_COLLECT"){
                    this.loadStep2checkout('ritira');
                }
                reject();
            };
            

            if(Window.tsdUseSlider && this.utils.isTsdEnabled() && ((_$('.balloon-timeslot').length && _$('.balloon-timeslot').hasClass('not-available')) || (_$('.component-SliderTimeslotTsd.active').length && _$('.component-SliderTimeslotTsd.active').hasClass('not-available')))){
                this.showModalTsdUnavailableAlert();
            }else{
                this.storeService.reserveTimeSlotOnCart(success, error, body);
            }
        });
    }

    /* prenotazione timeslot su un ordine guest riaperto */
    bookingSlotOnCart(){
        let success = (res) => {
            Window.location.href = Window.urlSite ? Window.urlSite.checkout + "?edit" : '';
        }

        let error = (err) => {
            console.log('error booking slot for cart');
        }

        let body = {
            "timeSlotId": Window.tsdUseSlider && this.utils.isTsdEnabled() ? this.fetchTimeslotIdFromSlider() : this._slotSelected,
        }


        if(Window.tsdUseSlider && this.utils.isTsdEnabled() && ((_$('.balloon-timeslot').length && _$('.balloon-timeslot').hasClass('not-available')) || (_$('.component-SliderTimeslotTsd.active').length && _$('.component-SliderTimeslotTsd.active').hasClass('not-available')))){
            this.showModalTsdUnavailableAlert();
        }else{
            this.storeService.reserveTimeSlotOnCart(success, error, body);
        }
    }

    manageInfoStore(checkout?: boolean) {
        let info: any = {};
        let storeInfo;
        if(checkout){
            info.nStore = Window.selectedAddress.nStoresFound;
            storeInfo = Window.pointOfService;
        }else{
            if(this.localStorage.getFromStorage('step2Flow') == 'loadStep2'){
                info.nStore = this.localStorage.getFromStorage('nStore');
                storeInfo = this.localStorage.getFromStorage('storeSelected');
            }else if(this.localStorage.getFromStorage('step2Flow') == 'modifyStep2'){
                info.nStore = Window.selectedAddress.nStoresFound;
                storeInfo = Window.pointOfService;
            }
        }
        
        if (storeInfo) {
            info.latitude = storeInfo.geoPoint.latitude;
            info.longitude = storeInfo.geoPoint.longitude;
        }
        return info;
    }
    /**
     * Checks value
     * @param type
     */
    public checkValue(event, type: string) {
        this.setValue(event, type);
        this.utils.hideShowFormError(type, this[type]);
    }

    /**
     * Sets value
     * @param event
     * @param type
     */
    public setValue(event, type) {
        this[type] = event.value;
    }
    
    /**
     * CTA "Scegli dopo"
     */
    chosenStore(serviceType?: string, toCart?: boolean, addAddress?: boolean, chooseLater?: boolean, checkPdvName?: boolean) {
        this.utils.showLoader(_$('#modal-onboarding').find('.btn-conferma-pdv button'), true, LOADER_POSITION.BUTTON);
        let success = (res) => {
            if (res) {
                //viene fatto un cambio pagina, non togliere il loader
                //this.utils.showLoader(_$('.js-button-scegli-dopo'), false, LOADER_POSITION.BUTTON);
                this.localStorage.setItem('selectedAddressOrderAndCollect', JSON.stringify(selectedAddressSAP));
                let params = Window.location.search;
                let queryParams = new URLSearchParams(params);
                if(queryParams && queryParams.get('cb')){
                    Window.location.href = decodeURIComponent(queryParams.get('cb'));
                }else if(this.bookingSlotState == BOOKING_SLOT_STATE.TO_CART) {
                    if(checkPdvName){
                        if(this._serviceType == "ORDER_AND_COLLECT"){
                            Window.location.href = Window.urlSite.home;
                        }else{
                            if(Window.pointOfService && Window.pointOfService.name == this._storeSelected.name){
                                Window.location.reload();
                            }else{
                                Window.location.href = Window.urlSite.home;
                            }
                        }
                    }else{
                        Window.location.href = Window.urlSite.detailCart + '?updatedCart=true';
                    }
                } else if(this.bookingSlotState == BOOKING_SLOT_STATE.MODIFY_ADDRESS || (Window.interactionCondition === 'REQUIRE_SERVICE_CHOICE' && !Window.typeOfService)) {
                    Window.location.reload();
                } else if(chooseLater) {
                    let htmlAvailability;
                    let htmlAvailabilityMenuMobile;
                    let firstAvailableTimeSlot = window['pointOfService'].firstAvailableTimeSlot;
                    if (firstAvailableTimeSlot) {
                        //caso in cui non si sceglie una nuova prenotazione da modale slot-prenotato-scadenza
                        htmlAvailability = `<div class="availability-label"><i class="icon-calendar"></i> Disponibilità:</div><b class="hour"> dal ${('0' + this.utils.getDayFromTimestamp(firstAvailableTimeSlot.date).getDate().toString()).slice(-2)}/${('0' + (this.utils.getDayFromTimestamp(firstAvailableTimeSlot.date).getMonth() + 1)).toString().slice(-2)} ore ${firstAvailableTimeSlot.startTime.formattedHour}</b><button type="button" class="manage js-change-modal uk-button uk-button-link" onclick="OnboardingManager.changeModal('timeslot', '', this)">Prenota</button>`;
                        htmlAvailabilityMenuMobile = `<div class="availability-content uk-flex uk-flex-column">
                                <div class="availability-label">Disponibilità:</div>
                                <div class="uk-flex uk-flex-middle">
                                    <i class="icon-alert uk-border-circle warning uk-hidden"></i>
                                    <div class="hour"> dal ${('0' + this.utils.getDayFromTimestamp(firstAvailableTimeSlot.date).getDate().toString()).slice(-2)}/${('0' + (this.utils.getDayFromTimestamp(firstAvailableTimeSlot.date).getMonth() + 1)).toString().slice(-2)} ore ${firstAvailableTimeSlot.startTime.formattedHour}</div>
                                </div>
                            </div>`;
                    } else {
                        //caso in cui non si annulla una nuova prenotazione da modale slot-expired
                        //OLD htmlAvailability = `<i class="icon-alert uk-border-circle warning uk-hidden"></i><div class="availability-label">Disponibilità:</div><b class="hour">Esaurita</b><button type="button" class="js-change-modal uk-button uk-button-primary uk-button-small uk-animation-shake" onclick="OnboardingManager.changeModal('timeslot', '', this)"><span>Prenota</span></button>`;
                        htmlAvailability = _$('.js-availability-esaurita-html');
                        htmlAvailabilityMenuMobile = `<div class="availability-content uk-flex uk-flex-column">
                                    <div class="availability-label">Disponibilità:</div>
                                    <div class="uk-flex uk-flex-middle">
                                        <i class="icon-alert uk-border-circle warning uk-hidden"></i>
                                        <div class="hour">Esaurita</div>
                                    </div>
                                </div>`;
                        _$('.component-PreHeader').find(htmlAvailability).removeClass('uk-hidden');
                    }

                    _$('.component-PreHeader').find('.availability').html(htmlAvailability);
                    _$('#menu-mobile').find('.availability-content').html(htmlAvailabilityMenuMobile);
                }else{
                    Window.location.href = Window.urlSite.home;
                }
            }
        };
        let error = (err) => {
            console.log(err);
            this.utils.showLoader(_$('#modal-onboarding').find('.btn-conferma-pdv button'), false, LOADER_POSITION.BUTTON);
        };
        const infoStore = this.manageInfoStore();
        
        let selectedAddressSAP;
        let notCompleted;

        if(this.localStorage.getFromStorage('step2Flow') == 'loadStep2'){
            selectedAddressSAP = this.localStorage.getFromStorage('selectedAddress_SAP_Format') ? this.localStorage.getFromStorage('selectedAddress_SAP_Format') : '';
            notCompleted = this.localStorage.getFromStorage('selectedAddress') ? this.localStorage.getFromStorage('selectedAddress').notCompleted : '';
        }else if(this.localStorage.getFromStorage('step2Flow') == 'modifyStep2'){
            selectedAddressSAP = Window.selectedAddress.completeAddress;
            notCompleted = Window.selectedAddress.notCompleted;
        }

        const deliveryArea = this._storeSelected ? this._storeSelected.selectedDeliveryId : Window.deliveryArea;
        
        if(notCompleted){
            selectedAddressSAP['notCompleted'] = notCompleted;
        }
        let params = {
            pointOfServiceId: this._storeSelected ? this._storeSelected.name : Window.pointOfService.name,
            becommerce: this._storeSelected ? this._storeSelected.becommerce : Window.pointOfService.becommerce, // il backend di riferimento del punto vendita
            typeOfService: this._serviceType ? this._serviceType : Window.typeOfService, // tipo di servizio, valori possibili: ORDER_AND_COLLECT o HOME_DELIVERY
            deliveryAddress: this._addressDeliverySelected ? this._addressDeliverySelected : this._currentDeliveryFormattedAddress,
            completeAddress: selectedAddressSAP,
            latitudine: infoStore.latitude,
            longitudine: infoStore.longitude,
            nStoresFound: infoStore.nStore
        };
        
        if(this._serviceType == 'HOME_DELIVERY'){
            params['deliveryArea'] = deliveryArea ? deliveryArea : Window.deliveryArea;
        }

        this.storeService.setChosenStore(success, error, params);
    }

    chosenStoreScadenza(serviceType?: string, toCart?: boolean, addAddress?: boolean, chooseLater?: boolean){
        this.utils.showLoader(_$('.js-button-scegli-dopo'), true, LOADER_POSITION.BUTTON);
        let success = (res) => {
            if (res) {
                if(chooseLater) {
                    if(_$('body').hasClass('page-CheckoutNew')){
                        this.newBookingSlotPrenotatoScadenza();
                    }else{
                        let htmlAvailability;
                        let htmlAvailabilityMenuMobile;
                        let firstAvailableTimeSlot = window['pointOfService'].firstAvailableTimeSlot;
                        if (firstAvailableTimeSlot) {
                            //caso in cui non si sceglie una nuova prenotazione da modale slot-prenotato-scadenza
                            htmlAvailability = `<div class="availability-label"><i class="icon-calendar"></i> Disponibilità:</div><b class="hour"> dal ${('0' + this.utils.getDayFromTimestamp(firstAvailableTimeSlot.date).getDate().toString()).slice(-2)}/${('0' + (this.utils.getDayFromTimestamp(firstAvailableTimeSlot.date).getMonth() + 1)).toString().slice(-2)} ore ${firstAvailableTimeSlot.startTime.formattedHour}</b><button type="button" class="manage js-change-modal uk-button uk-button-link" onclick="OnboardingManager.changeModal('timeslot', '', this)">Prenota</button>`;
                            htmlAvailabilityMenuMobile = `<div class="availability-content uk-flex uk-flex-column">
                                    <div class="availability-label">Disponibilità:</div>
                                    <div class="uk-flex uk-flex-middle">
                                        <i class="icon-alert uk-border-circle warning uk-hidden"></i>
                                        <div class="hour"> dal ${('0' + this.utils.getDayFromTimestamp(firstAvailableTimeSlot.date).getDate().toString()).slice(-2)}/${('0' + (this.utils.getDayFromTimestamp(firstAvailableTimeSlot.date).getMonth() + 1)).toString().slice(-2)} ore ${firstAvailableTimeSlot.startTime.formattedHour}</div>
                                    </div>
                                </div>`;
                        } else {
                            //caso in cui non si annulla una nuova prenotazione da modale slot-expired
                            htmlAvailability = _$('.js-availability-esaurita-html');
                            htmlAvailabilityMenuMobile = `<div class="availability-content uk-flex uk-flex-column">
                                    <div class="availability-label">Disponibilità:</div>
                                    <div class="uk-flex uk-flex-middle">
                                        <i class="icon-alert uk-border-circle warning uk-hidden"></i>
                                        <div class="hour">Esaurita</div>
                                    </div>
                                </div>`;
                            _$('.component-PreHeader').find(htmlAvailability).removeClass('uk-hidden');
                        }

                        _$('.component-PreHeader').find('.availability').html(htmlAvailability);
                        _$('#menu-mobile').find('.availability-content').html(htmlAvailabilityMenuMobile);
                    }
                    
                }else{
                    Window.location.href = Window.urlSite.home;
                }
            }
        };

        let error = (err) => {
            console.log(err);
            this.utils.showLoader(_$('.js-button-scegli-dopo'), false, LOADER_POSITION.BUTTON);
            if(Window.location.pathname == Window.urlSite.checkoutRecognized){
                this.newBookingSlotPrenotatoScadenza();
            }
        };

        let infoStore: any = {}
        infoStore.nStore = Window.selectedAddress && Window.selectedAddress.nStoresFound ? Window.selectedAddress.nStoresFound : '' ;
        infoStore.latitude = Window.pointOfService && Window.pointOfService.geoPoint && Window.pointOfService.geoPoint.latitude ? Window.pointOfService.geoPoint.latitude : '';
        infoStore.longitude = Window.pointOfService && Window.pointOfService.geoPoint && Window.pointOfService.geoPoint.longitude ? Window.pointOfService.geoPoint.longitude : '';

        const deliveryArea = Window.deliveryArea ? Window.deliveryArea : '';

        let selectedAddressSAP = Window.selectedAddress && Window.selectedAddress.completeAddress ? Window.selectedAddress.completeAddress : '';
        let notCompleted = Window.selectedAddress && Window.selectedAddress.notCompleted ? Window.selectedAddress.notCompleted : '';

        if(notCompleted){
            selectedAddressSAP['notCompleted'] = notCompleted;
        }

        let params = {
            pointOfServiceId: Window.pointOfService && Window.pointOfService.name ? Window.pointOfService.name : '',
            becommerce: Window.pointOfService && Window.pointOfService.becommerce ? Window.pointOfService.becommerce : '', // il backend di riferimento del punto vendita
            typeOfService: Window.typeOfService, // tipo di servizio, valori possibili: ORDER_AND_COLLECT o HOME_DELIVERY
            deliveryAddress: this._currentDeliveryFormattedAddress,
            completeAddress: selectedAddressSAP,
            latitudine: infoStore.latitude,
            longitudine: infoStore.longitude,
            nStoresFound: infoStore.nStore,
            missingCart: true
        };
        
        if(Window.typeOfService == 'HOME_DELIVERY'){
            params['deliveryArea'] = deliveryArea ? deliveryArea : Window.deliveryArea;
        }

        this.storeService.setChosenStore(success, error, params);
    }


    allowButtonExtAddress(id: string): void {
        const formButton = _$('[nkButton="' + id + '"]')[0];
        if (
            this.utils.isValidEmail(this.email) &&
            this.utils.hasOnlyLetters(this.name) &&
            this.utils.hasOnlyLetters(this.surname)
        )
            formButton.removeAttribute('disabled');
        else !formButton.hasAttribute('disabled') && formButton.setAttribute('disabled', 'true');
    }

    /**
     * Extends addresses
     */
    extendAddresses(text, addressLoadedOnStep0) {
        this.utils.showLoader(_$('button.send-request'), true, LOADER_POSITION.BUTTON);

        const location = {
            lat: addressLoadedOnStep0.latitude,
            lng: addressLoadedOnStep0.longitude
        }        
        
        const typeOfService = text;
        const selectedAddress = addressLoadedOnStep0;
        const line2 = selectedAddress.line2 ? selectedAddress.line2 : '';
        const town = selectedAddress.town ? selectedAddress.town : '';
        const postalCode = selectedAddress.postalCode ? selectedAddress.postalCode : ''
        const district = selectedAddress.district ? selectedAddress.district : '';
        const partial = selectedAddress.notCompleted ? true : false;
        const countryIso = selectedAddress.country && selectedAddress.country.isocode ? selectedAddress.country.isocode : '';
        const countryName = selectedAddress.country && selectedAddress.country.name ? selectedAddress.country.name : '';
        const line1 = selectedAddress.line1 ? selectedAddress.line1 : '';
        const stringService = typeOfService == 'HOME_DELIVERY' ? 'di consegna' : 'di ritiro';

        let success = (res) => {
            this.utils.showLoader(_$('button.send-request'), false, LOADER_POSITION.BUTTON);
            _Uikit.notification({
                message: '<div style="text-align: center"><span style="font-size: 16px"><b>La tua richiesta è stata inviata!</b></span><br><br><span style="font-size: 14px; font-weight: 500">Ti contatteremo non appena il servizio '+stringService+' verrà esteso anche nella tua zona!</span></div>',
                status: 'primary',
                pos: 'bottom-right',
                timeout: 7000
            });
        };
        let error = (e) => {
            this.utils.showLoader(_$('button.send-request'), false, LOADER_POSITION.BUTTON);
        };
        let body = {
            country: {
                isocode: countryIso,
                name: countryName
            },
            email: this.email,
            firstName: this.name,
            formattedAddress: selectedAddress.formatted_address,
            lastName: this.surname,
            line1: line1 ? line1 : '',
            line2: line2 ? line2 : '',
            postalCode: postalCode ? postalCode : '',
            town: town ? town : '',
            partial: partial,
            typeOfService: typeOfService,
            district: district ? district : '',
            geoPoint: {
                latitude: location.lat,
                longitude: location.lng,
            },
            gRecaptchaResponse: 'XXXX',
        };
        this.storeService.extendAddresses(success, error, body);
    }

    setExtendAddressInfo(email, firstName, lastName){
        this.email = email;
        this.name = firstName;
        this.surname = lastName;
    }

    showBackupPdvModal() {
        // this.utils.enableAllGoogleInput();
        let backupPos = this._storeSelected.backupPos;
        _$('#modalSlotTerminati .text-box .subtitle').html('ci dispiace ma il negozio <b>' + this._storeSelected.storeType + '</b> al momento ha esaurito le disponibilità per le consegne.');
        _$('#modalSlotTerminati .text-box .desc.address').html('<b>' + backupPos.storeType + '</b> <br>' + backupPos.address.formattedAddress);
        if (backupPos.firstAvailableTimeSlot) {
            const day = new Date(backupPos.firstAvailableTimeSlot.date).getDate();
            const month = new Date(backupPos.firstAvailableTimeSlot.date).getMonth() + 1;
            const hour = backupPos.firstAvailableTimeSlot.startTime.formattedHour;
            const deliveryNote = `Consegna: dal ${day}/${month} dalle ${hour}`;
            _$('#modalSlotTerminati .text-box .uk-badge').html(deliveryNote);
        }
        _Uikit.modal('#modalSlotTerminati').show();
    }

    choosePdvBackupNew() {
        this.localStorage.setItem('storeSelected', JSON.stringify(this._storeSelected.backupPos));
        const substitutionPos = this._storeSelected;
        this._storeSelected = substitutionPos.backupPos;
        this._storeSelected.selectedDeliveryId = substitutionPos.backupPointOfServiceDeliveryId ? substitutionPos.backupPointOfServiceDeliveryId : null;
        this.chosenStore();
    }

    declinePdvBackupNew() {
        this.localStorage.setItem('storeSelected', JSON.stringify(this._storeSelected));
        _$('.list-card-store .active .delivery-note').html('al momento nessuna disponibilità');
        this.utils.showLoader(_$('#modal-onboarding').find('.btn-conferma-pdv button'), false);
        _$('#modal-onboarding').find('.btn-conferma-pdv button').prop('disabled', false);
    }

    choosePdvBackup(onload?: boolean) {
        if(onload){
            this._storeSelected = Window.pointOfService ? Window.pointOfService : '';
            this._serviceType = Window.typeOfService;
        }else{
            this._storeSelected = this.localStorage.getFromStorage('storeSelected')
                ? this.localStorage.getFromStorage('storeSelected')
                : '';
        }
        if(this._storeSelected.backupPos) {
            this._storeSelected.backupPos.selectedDeliveryId = this._storeSelected.backupPointOfServiceDeliveryId ? this._storeSelected.backupPointOfServiceDeliveryId : null;
        }
        this.localStorage.setItem('storeSelected', JSON.stringify(this._storeSelected.backupPos));
        this.chosenStore();
    }

    declinePdvBackup() {
        this._storeSelected = this.localStorage.getFromStorage('storeSelected')
            ? this.localStorage.getFromStorage('storeSelected')
            : '';
        delete this._storeSelected.backupPos;
        const newTextBadge = 'al momento nessuna disponibilità';
        _$('.list-card-store .active .delivery-note').html(newTextBadge);
        this.localStorage.setItem('storeSelected', JSON.stringify(this._storeSelected));
    }


    /**
     * Modals Slot Prenotato Scadenza - Nuova Prenotazione
     * @param element
     */
    newBookingSlotPrenotatoScadenza(element?) {
        if(_$('body').hasClass('page-CheckoutNew')){
            _$('.section-giorno-orario').find('.edit').trigger('click');
        }else{
            this.utils.showLoader(_$(element), true, LOADER_POSITION.BUTTON);
            Window.typeOfService === 'HOME_DELIVERY'
                ? this.modifyStep2('ricevi')
                : this.modifyStep2('ritira');
        }
        
    }

    /* 
    * Applica la redirect alla HP di un pdv non migrato alla nuova piattaforma
    *
    *  */
    redirectToOldPdvHp(){
        let storeSelected = this.localStorage.getFromStorage('storeSelected') ? this.localStorage.getFromStorage('storeSelected') : '';
        let success = (res) => {
            if (res.data) {
                if (res.data.url) {
                    window.location.href = res.data.url;
                } else if (res.data.data) {
                    if (res.data.data.url) {
                        window.location.href = res.data.data.url;
                    }
                }
            } else if (res.url) {
                window.location.href = res.url;
            } else {
                window.location.href = res;
            }
        }

        let error = (err) => {

        }
        let body = {
            pointOfServiceId: storeSelected.name,
            typeOfService: this.localStorage.getFromStorage('typeOfServiceRedirectOldPdv') ? this.localStorage.getFromStorage('typeOfServiceRedirectOldPdv') : 'HOME_DELIVERY'
        }

        this.storeService.getOldPdv(success, error, body);
    }
    
    /**
     * Controlla se il pdv è stato migrato
     * TRUE: pdv migrato e continua il flusso
     * FALSE: pdv non migrato e apertura homepage del pdv non migrato
     * nel caso di pdv in logica di canale verrà passato visto che lo storeSelected non è aggiornato
     */
    checkPdvMigrato(channelLogicStore?): boolean{
        let selectedStore;
        if(channelLogicStore){
            selectedStore = channelLogicStore;
        }else{
            selectedStore = this.localStorage.getFromStorage('storeSelected') ? this.localStorage.getFromStorage('storeSelected') : Window.pointOfService;
        }
        
        let newPlatform = selectedStore.newPlatform;
        let newPlatformDate = selectedStore.newPlatformSwitchDate;
        let newPlatformSwitchDate = new Date(newPlatformDate);
        let today = new Date();
        return newPlatform && newPlatformSwitchDate <= today
    }

    /**
     * Controlla se deve essere effettuato uno switch di piattaforma (SAP -> Eurecart e viceversa)
     * 
     */
    checkSwitchPiattaforma(): boolean{
        let newSelectedStore = this.localStorage.getFromStorage('storeSelected') ? this.localStorage.getFromStorage('storeSelected') : Window.pointOfService;
        let currentSelectedStore = Window.pointOfService;
        return newSelectedStore.becommerce != currentSelectedStore.becommerce;
    }

    /* Cancella l'ECACCESS */
    cleanEcaccess(){
        let success = (res) => {
            Window.location.href = Window.urlSite.entry;
        }

        let error = (err) => {
            console.log(err);
        }

        let body = {}

        this.storeService.cleanEcaccess(success, error, body);
    }
    /** 
     * Controlla se esiste un carrello con prodotti: true se esiste
     * 
    */
    checkCartPresence(): boolean{
        if(_$('.container-cart-full').length && _$('.container-cart-empty').length){
            if(_$('.container-cart-full').hasClass('uk-hidden')){
                return false;
            }else{
                return true;
            }
        }else{
            if(Window.cart){
                if(Window.cart.totalUnitCount){
                    return true
                }else{
                    return false;
                }
            }else{
                return false;
            }
        }
    }

    manageAccordionTimeslotInfo(accordionDiv, timeslot){
        let accordionSelected = _$(accordionDiv).find('.component-AccordionTimeslotInfo');
        
        if(accordionSelected.length){
            if(this._serviceType == "HOME_DELIVERY"){
                _$(accordionSelected).find('.home-delivery').removeClass('uk-hidden');
                _$(accordionSelected).find('.order-and-collect').addClass('uk-hidden');
            }else if(this._serviceType == "ORDER_AND_COLLECT"){
                _$(accordionSelected).find('.home-delivery').addClass('uk-hidden');
                _$(accordionSelected).find('.order-and-collect').removeClass('uk-hidden');
            }
        }
    }

    manageAccordion2(accordionDiv, deliveryThresholdsAttr, serviceSurchargeThresholdsAttr){
        let accordionSelected = _$(accordionDiv).find('.component-AccordionTimeslotInfo');
        _$(accordionSelected).find('.accordion-second').removeClass('uk-hidden');
        
        function valueToString(val: number): string{
            return val.toFixed(2).toString().replace('.', ',');
        }

        function valueToStringProgressBar(val: number): string{
            if(val > 0){
                return valueToString(val) + '&euro;';
            }else{
                return 'Gratuita';
            }
        }
        
        if(deliveryThresholdsAttr && decodeURI(deliveryThresholdsAttr) && JSON.parse(decodeURI(deliveryThresholdsAttr)) && JSON.parse(decodeURI(deliveryThresholdsAttr)).length){
            let deliveryThresholds = JSON.parse(decodeURI(deliveryThresholdsAttr));
            if(deliveryThresholds.length > 1){
                _$(accordionSelected).find('.checkpoints').html('');
                for(let i = 0; i < deliveryThresholds.length; i++){
                    _$(accordionSelected).find('.checkpoints').append(`
                    <div class="checkpoint active current">
                        <div class="uk-badge"></div>
                        <div class="label">
                            <b>${deliveryThresholds[i].deliveryPrice && deliveryThresholds[i].deliveryPrice.value >= 0 ? valueToStringProgressBar(deliveryThresholds[i].deliveryPrice.value) : null}</b>
                            <span>spesa di <b>${deliveryThresholds[i].deliveryPrice && deliveryThresholds[i].minimumCartValue.value >= 0 ? valueToString(deliveryThresholds[i].minimumCartValue.value) : null}&euro;</b></span>
                        </div>
                    </div>`);
                }

                _$(accordionSelected).find('.accordion-second .single-cost').addClass('uk-hidden');
                _$(accordionSelected).find('.progress-bar').removeClass('uk-hidden');
            }else{
                _$(accordionSelected).find('.accordion-second .single-cost.ritiro').addClass('uk-hidden');
                _$(accordionSelected).find('.accordion-second .single-cost.consegna').removeClass('uk-hidden');
                _$(accordionSelected).find('.progress-bar').addClass('uk-hidden');
            }

            if(deliveryThresholds[0] && deliveryThresholds[0].deliveryPrice && deliveryThresholds[0].deliveryPrice.value >= 0){
                this.accordionSingleCostHD = valueToString(deliveryThresholds[0].deliveryPrice.value) + '€';
                _$('#modal-timeslot .component-AccordionTimeslotInfo').find('.consegna .costo-consegna').html(this.accordionSingleCostHD);
            }

            if(deliveryThresholds[0] && deliveryThresholds[0].minimumCartValue && deliveryThresholds[0].minimumCartValue.value >= 0){
                this.accordionMinimoSpesaHD = valueToString(deliveryThresholds[0].minimumCartValue.value) + '€';
                _$('#modal-timeslot .component-AccordionTimeslotInfo').find('.consegna .minimo-spesa').html(this.accordionMinimoSpesaHD);
            }

        }else if(serviceSurchargeThresholdsAttr && decodeURI(serviceSurchargeThresholdsAttr) && JSON.parse(decodeURI(serviceSurchargeThresholdsAttr)) && JSON.parse(decodeURI(serviceSurchargeThresholdsAttr)).length){
            let serviceSurchargeThresholds = JSON.parse(decodeURI(serviceSurchargeThresholdsAttr));
            
            if(serviceSurchargeThresholds.length > 1){
                _$(accordionSelected).find('.checkpoints').html('');
                for(let i = 0; i < serviceSurchargeThresholds.length; i++){
                    _$(accordionSelected).find('.checkpoints').append(`
                    <div class="checkpoint active current">
                        <div class="uk-badge"></div>
                        <div class="label">
                            <b>${serviceSurchargeThresholds[i].servicePrice && serviceSurchargeThresholds[i].servicePrice.value >= 0 ? valueToStringProgressBar(serviceSurchargeThresholds[i].servicePrice.value) : null}</b>
                            <span>spesa di <b><br>${serviceSurchargeThresholds[i].servicePrice && serviceSurchargeThresholds[i].minimumCartValue.value >= 0 ? valueToString(serviceSurchargeThresholds[i].minimumCartValue.value) : null}&euro;</b></span>
                        </div>
                    </div>`);
                }

                _$(accordionSelected).find('.accordion-second .single-cost').addClass('uk-hidden');
                _$(accordionSelected).find('.progress-bar').removeClass('uk-hidden');
            }else{
                _$(accordionSelected).find('.accordion-second .single-cost.consegna').addClass('uk-hidden');
                _$(accordionSelected).find('.accordion-second .single-cost.ritiro').removeClass('uk-hidden');
                _$(accordionSelected).find('.progress-bar').addClass('uk-hidden');
            }

            if(serviceSurchargeThresholds[0] && serviceSurchargeThresholds[0].servicePrice && serviceSurchargeThresholds[0].servicePrice.value >= 0){
                this.accordionSingleCostOC = valueToString(serviceSurchargeThresholds[0].servicePrice.value) +'€';
                _$('#modal-timeslot .component-AccordionTimeslotInfo').find('.ritiro .costo-consegna').html(this.accordionSingleCostOC);
            }

            if(serviceSurchargeThresholds[0] && serviceSurchargeThresholds[0].minimumCartValue && serviceSurchargeThresholds[0].minimumCartValue.value >= 0){
                this.accordionMinimoSpesaOC = valueToString(serviceSurchargeThresholds[0].minimumCartValue.value) +'€';
                _$('#modal-timeslot .component-AccordionTimeslotInfo').find('.ritiro .minimo-spesa').html(this.accordionMinimoSpesaOC);
            }
        }
        
    }
    
    requireTimeslotSelection(store): boolean{
        if(store && store.becommerce && 
            (['eurecart-pac', 'eurecart-sic', 'eurecart-ccn', 'eurecart-cad'].indexOf(store.becommerce) < 0 ||
            ['eurecart-pac', 'eurecart-sic', 'eurecart-ccn', 'eurecart-cad'].indexOf(store.becommerce) > -1 && Window.typeOfService
            )){
            return true;
        }else{
            return false;
        }
    }

    /* Controllo timeslot al vai alla cassa per i locker */
    checkTimeslotsOnGoToCheckout(): Promise<object>{
        return new Promise((resolve, reject) => {
            
            let result = {};
            
            //controllo se c'è lo store da AEM altrimenti sfrutto quello nel localstorage
            //il pdv sul window dovrebbe esserci sempre nel caso di modifica step2
            if (Window.pointOfService) {
                this._storeSelected = Window.pointOfService;
            } else {
                this._storeSelected = ''
            }

            let success = (res) => {
                if (res && res.data && res.data.results) {
                    if(res.data.results.length){
                        result['timeslots'] = true;
                    }
                    resolve(result)
                }else{
                    result['timeslots'] = false;
                    resolve(result);
                }
            };

            let error = (err) => {
                result['timeslots'] = false;
                if(err && err.response && err.response.data && err.response.data.message && this.lockerUtils.parseLockerErrorMessageAddToCart(err.response.data.message)){
                    let jsonError = this.lockerUtils.parseLockerErrorMessageAddToCart(err.response.data.message);
                    if(jsonError != null && jsonError['invalidUnitObjects'] != null && jsonError['invalidUnitObjects'].length){
                        result['invalidUnitObjects'] = jsonError['invalidUnitObjects'];
                    }
                    resolve(result);
                }else if(this.lockerUtils.checkTooManyUnitsPresence(err)){
                    let jsonError = this.lockerUtils.parseLockerErrorMessageAddToCart(err.response.data.message);
                    if(jsonError != null && jsonError['errorMessage'] != null && jsonError['errorMessage'].includes('ConadLockerTooManyUnitsError')){
                        result['ConadLockerTooManyUnitsError'] = jsonError['errorMessage']
                    }
                    
                    resolve(result);
                }else{
                    resolve(result);
                }
            };

            let currentTime = _moment().valueOf();
            
            let params = {
                dateUTC: currentTime,
                pointOfServiceId: this._storeSelected?.name,
                becommerce: this._storeSelected?.becommerce, // il backend di riferimento del punto vendita
                typeOfService: 'ORDER_AND_COLLECT'
            };
        
            this.storeService.getAvailableTimeSlot(success, error, params);
        });
    }

    manageDeleteLockerEntriesLoadStep2(serviceType){
        if(this.lockerOversizeProducts && this.lockerOversizeProducts.length){
            this.utils.showLoader('', true);
            let entriesToDelete = [];
            for(let i = 0; i < this.lockerOversizeProducts.length; i++){
                entriesToDelete.push(this.lockerOversizeProducts[i].entryId);
            }
            
            let success = (res) => {
                _Uikit.modal('#modalLockerOversizeDimensions').hide();
                this.utils.showLoader('', false);
                this.loadStep2(serviceType);
            }

            let error = (err) => {
                _Uikit.modal('#modalLockerOversizeDimensions').hide();
                this.utils.showLoader('', false);
                this.loadStep2(serviceType);
            }

            let body = {
                "clean": "true",
                "entriesToDelete": entriesToDelete.toString().replace('[', '').replace(']', '')
            }

            this.cartService.addRemoveItemToCart(success, error, body);
        }else{
            _Uikit.modal('#modalLockerOversizeDimensions').hide();
        }
    }

    manageDeleteLockerEntriesModifyStep2(serviceType){
        if(this.lockerOversizeProducts && this.lockerOversizeProducts.length){
            this.utils.showLoader('', true);
            let entriesToDelete = [];
            for(let i = 0; i < this.lockerOversizeProducts.length; i++){
                entriesToDelete.push(this.lockerOversizeProducts[i].entryId);
            }
            
            let success = (res) => {
                _Uikit.modal('#modalLockerOversizeDimensions').hide();
                this.utils.showLoader('', false);
                this.modifyStep2(serviceType);
            }

            let error = (err) => {
                _Uikit.modal('#modalLockerOversizeDimensions').hide();
                this.utils.showLoader('', false);
                this.modifyStep2(serviceType);
            }

            let body = {
                "clean": "true",
                "entriesToDelete": entriesToDelete.toString().replace('[', '').replace(']', '')
            }

            this.cartService.addRemoveItemToCart(success, error, body);
        }else{
            _Uikit.modal('#modalLockerOversizeDimensions').hide();
        }
    }

    fetchFirstAvailableTimeslots(typeOfService, storeIds, deliveryArea): Promise<object>{
        return new Promise((resolve, reject) => {
            let success = (res) => {
                resolve(res.data);
            }
    
            let error = (err) => {
                /* let x = {"data":{"006958":{},"060014":{},"010114":{"firstAvailableTimeSlot":{"available":true,"date":1678320000000,"endTime":{"timestamp":1678359600000,"formattedHour":"12:00","hour":12,"minute":0},"extension":30,"reservationLength":30,"slotId":"OC_010114_20230309_021_39063","startTime":{"timestamp":1678352400000,"formattedHour":"10:00","hour":10,"minute":0},"serviceSurchargeThresholds":[],"isFull":false}},"009967":{"firstAvailableTimeSlot":{"available":true,"date":1678320000000,"endTime":{"timestamp":1678359600000,"formattedHour":"12:00","hour":12,"minute":0},"extension":30,"reservationLength":30,"slotId":"OC_009967_20230309_021_39061","startTime":{"timestamp":1678352400000,"formattedHour":"10:00","hour":10,"minute":0},"serviceSurchargeThresholds":[],"isFull":false}},"010115":{"firstAvailableTimeSlot":{"available":true,"date":1678320000000,"endTime":{"timestamp":1678359600000,"formattedHour":"12:00","hour":12,"minute":0},"extension":30,"reservationLength":30,"slotId":"OC_010115_20230309_021_39062","startTime":{"timestamp":1678352400000,"formattedHour":"10:00","hour":10,"minute":0},"serviceSurchargeThresholds":[],"isFull":false}},"009843":{"firstAvailableTimeSlot":{"available":true,"date":1678320000000,"endTime":{"timestamp":1678359600000,"formattedHour":"12:00","hour":12,"minute":0},"extension":30,"reservationLength":30,"slotId":"OC_009843_20230309_021_9843","startTime":{"timestamp":1678352400000,"formattedHour":"10:00","hour":10,"minute":0},"serviceSurchargeThresholds":[],"isFull":false}}}}
                resolve(x.data); */
                reject(err);
            }

            let storeMap = storeIds.map((id, index) => {
                return Object.assign({}, {
                    id,
                    deliveryArea: deliveryArea[index]
                });
            });
    
            let body = {
                stores: storeMap,
                typeOfService: typeOfService
            }
    
            this.storeService.getFirstAvailableTimeslot(success, error, body);
        })
        
    }

    preselectTimeslot(availability){
        let preselected = false;
        let dayIndex;
        availability.forEach((element, index) => {
            if(element.timeSlots && element.timeSlots.length){
                element.timeSlots.forEach(timeslot => {
                    if(Window.selectedTimeslot && Window.selectedTimeslot.slotId && Window.selectedTimeslot.slotId && timeslot.slotId != null
                            && Window.selectedTimeslot.slotId == timeslot.slotId && timeslot.available){
                                preselected = true;
                                dayIndex = index;
                    }
                })
            }
        });
        if(Window.selectedTimeslot && Window.selectedTimeslot.slotId){
            if(preselected){
                this.createHourSlotsModalOnboarding(dayIndex);
                this.setCostModalOnboarding(_$('#modal-timeslot').find(`.chip-hour[slotid=${Window.selectedTimeslot.slotId}]`));
            }
        }
        
    }

    checkTimeslotContinuativo(timeslot, day): Object{
        let timeslotText = {}
        let timeslotStart = timeslot.startTime;
        let timeslotEnd = timeslot.endTime;
        let startTimeTimestamp = timeslot.startTime.date;
        let endTimeTimestamp = timeslot.endTime.date;
        let startTimeDate = new Date(startTimeTimestamp);
        let endTimeDate = new Date(endTimeTimestamp);
        let todayDate = new Date(Date.now());
        let cardDayDate = new Date(day.date);
        if(sameDay(startTimeDate, cardDayDate) && sameDay(endTimeDate, cardDayDate)){
            timeslotText['startTimeText'] = timeslotStart.formattedHour;
            timeslotText['endTimeText'] = timeslotEnd.formattedHour;
        }else{
            let startTimeWithDay = timeslotStart.formattedHour+ '&nbsp;<span class="date-continuativo"> del ' + startTimeDate.getDate() +' '+ months[startTimeDate.getMonth()] + '</span>';
            let endTimeWithDay = timeslotEnd.formattedHour + '&nbsp;<span class="date-continuativo"> del ' + endTimeDate.getDate() +' '+ months[endTimeDate.getMonth()] + '</span>';
            let startTimeCardDay = sameDay(startTimeDate, cardDayDate);
            let endTimeCardDay = sameDay(endTimeDate, cardDayDate);
            if(!startTimeCardDay){
                timeslotText['startTimeText'] = startTimeWithDay;
            }else{
                timeslotText['startTimeText'] = timeslotStart.formattedHour;
            }
            if(!endTimeCardDay){
                timeslotText['endTimeText'] = endTimeWithDay;
            }else{
                timeslotText['endTimeText'] = timeslotEnd.formattedHour;
            }
        }
        return timeslotText;

        function sameDay(d1, d2) {
            return d1.getFullYear() === d2.getFullYear() &&
              d1.getMonth() === d2.getMonth() &&
              d1.getDate() === d2.getDate();
        }
    }

    bookingSlotCheckoutText(timeslot): string{
        let text = '';
        let startDate = new Date(timeslot.startTime.date);
        let endDate = new Date(timeslot.endTime.date)
        if(!sameDay(startDate, endDate)){
            text = `${("0" + startDate.getDate()).slice(-2)}/${("0" + (startDate.getMonth() + 1)).slice(-2)} - ${timeslot.startTime.formattedHour} al
                        ${("0" + endDate.getDate()).slice(-2)}/${("0" + (endDate.getMonth() + 1)).slice(-2)} - ${timeslot.endTime.formattedHour}`;
            return text;
        }else{
            let date = new Date(timeslot.date);
            let month = date.toLocaleString('it-IT', { month: 'long' });
            month = month.charAt(0).toUpperCase() + month.slice(1);
            text = `${date.getDate()} ${month} ore ${timeslot.startTime.formattedHour} - ${timeslot.endTime.formattedHour}`;
            return text;
        }

        function sameDay(d1, d2) {
            return d1.getFullYear() === d2.getFullYear() &&
              d1.getMonth() === d2.getMonth() &&
              d1.getDate() === d2.getDate();
        }
    }

    checkCartEcAccess(){
        if(Window.typeOfService && !Window.cartId){
            console.log('cart null with typeofservice');
            this.chosenStoreScadenza(Window.typeOfService, false, false, true);
        }
    }

    /* precheck sull'esistenza del selectedTimeslot */
    preselectTimeslotTsd(timeslotResponse){
        if(timeslotResponse != null){
            timeslotResponse.forEach((value, index) => {
                let dayIndex = index;
                let deliveryBandMap = value.deliveryBandMap;
                let types = Object.keys(deliveryBandMap);

                types.forEach((value) => {
                    let timeSlots = deliveryBandMap[value].timeSlots;
                    let deliveryBandType = value;
                    timeSlots.forEach((value) => {
                        if(Window.selectedTimeslot.slotId == value.slotId){
                            if(Window.tsdUseSlider){
                                this.createCardOpzioniConsegna(dayIndex);
                                this.createHourSlotsSlider(_$(`.component-CardOpzioneConsegna[data-band-id=${deliveryBandType}]`).find('.band-type'), true);
                                this.setCostModalOnboarding(_$(window).width() >= 1024 ? JSON.parse(_$('.component-SliderTimeslotTsd.active').attr('data-timeslot')) : JSON.parse(_$('.component-SliderTimeslotTsd.active').attr('data-timeslot')), true);
                            }else{
                                this.createCardOpzioniConsegna(dayIndex);
                                this.createHourSlotsSlider(_$(`.component-CardOpzioneConsegna[data-band-id=${deliveryBandType}]`).find('.band-type'))
                                this.setCostModalOnboarding(_$(`.chip-hour[slotid=${value.slotId}]`));
                            }
                        }
                    })
                })
            })
        }
    }

    showModalTsdUnavailableAlert(){
        _$('.component-ModalTimeslot .alert-fascia-non-disponibile').addClass('unavailable-timeslot-selected');
        if(_$(window).width() >= 1024){
            _$('.balloon-timeslot').addClass('shake-timeslot').on('animationend', () => {
                _$('.balloon-timeslot.shake-timeslot').removeClass('shake-timeslot');
            });
        }else{
            _$('.component-SliderTimeslotTsd.active').addClass('shake-timeslot').on('animationend', () => {
                _$('.component-SliderTimeslotTsd.shake-timeslot').removeClass('shake-timeslot');
            });
        }
        this.utils.showLoader(_$('.js-button-conferma, #modal-timeslot .js-button-conferma-timeslot'), false, LOADER_POSITION.BUTTON);;
    }

    fetchTimeslotIdFromSlider(): string{
        let timeslotId = '';
        
        timeslotId = _$('.component-SliderTimeslotTsd.active').length ? _$('.component-SliderTimeslotTsd.active').attr('data-timeslot-id') : ''

        return timeslotId;
    }
}
