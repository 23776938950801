///////////////////////////////////
/////////////////////////////////// CSS
///////////////////////////////////
import './base.css'; // BASE CSS TO ALIGN BROWSER
import './app.scss'; // OLD APP SCSS
import './tailwind.css'; // NEW TAILWIND CSS

///////////////////////////////////
/////////////////////////////////// JS
///////////////////////////////////

// OLD FE CODE
import './main';
import { initPage } from './binding';

// API
import { OpenAPI } from './api/index';
OpenAPI.BASE = '';

// ALPINE
import Alpine from 'alpinejs';
window.Alpine = Alpine;

// cfamily
import './cfamily';

// stores
import './stores/user';
import './stores/cart';
import './stores/dictionary';
import './stores/page';

// components
// | apline templates
import './repository/apps/conad-ecommerce/alpine-templates/ap200-card-order/script';
import './repository/apps/conad-ecommerce/alpine-templates/ap203-like-button/script';
// | structure
import './repository/apps/conad-ecommerce/components/structure/page/script';
import './repository/apps/conad-ecommerce/components/structure/detail-product/script';
// | content
import './repository/apps/conad-ecommerce/components/content/8-0-profile/script';
import './repository/apps/conad-ecommerce/components/content/8-1-orders/script';
import './repository/apps/conad-ecommerce/components/content/8-2-coupons/script';
import './repository/apps/conad-ecommerce/components/content/8-3-wishlists/script';
import './repository/apps/conad-ecommerce/components/content/8-4-assistance/script';
// | dynamic
import './repository/apps/conad-ecommerce/components/dynamic/product-card/script';
import './repository/apps/conad-ecommerce/components/dynamic/30-0-product-card-mini/script';
// | internal
import './repository/apps/conad-ecommerce/components/internal/11-0-radio-select/script';
import './repository/apps/conad-ecommerce/components/internal/11-1-personal-area-menu/script';
import './repository/apps/conad-ecommerce/components/internal/11-3-textbox/script';
import './repository/apps/conad-ecommerce/components/internal/11-4-toasting-box/script';
import './repository/apps/conad-ecommerce/components/internal/11-5-manage-wishlists/script';
import './repository/apps/conad-ecommerce/components/internal/11-7-preview-orders/script';
import './repository/apps/conad-ecommerce/components/internal/11-8-preview-wishlists/script';
import './repository/apps/conad-ecommerce/components/internal/11-9-preview-coupons/script';
import './repository/apps/conad-ecommerce/components/internal/11-10-user-menu/script';
// | popups
import './repository/apps/conad-ecommerce/components/popup/12-1-display-order-states/script';
import './repository/apps/conad-ecommerce/components/popup/12-2-question/script';
import './repository/apps/conad-ecommerce/components/popup/12-3-message-with-image/script';
import './repository/apps/conad-ecommerce/components/popup/12-7-wishlists-add-product/script';
import './repository/apps/conad-ecommerce/components/popup/12-8-wishlists-name/script';
import './repository/apps/conad-ecommerce/components/popup/12-9-wishlists-add-to-cart-with-errors/script';
// | templates
import './repository/apps/conad-ecommerce/templates/et900-popup-base/script';

// some implementation
window.cStepLoader = async (name: string) => {
    let reqUrl = `/popups/${name}/_jcr_content/root/empty.html`;
    if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
        reqUrl = '/content/conad-ecommerce/it/it/default' + reqUrl;
        reqUrl += '?' + new URLSearchParams({
            wcmmode: 'disabled'
        }).toString();
    }
    const resp = await fetch(reqUrl);
    const html = await resp.text();
    const parser = new DOMParser();
    const parsedDoc = parser.parseFromString(html, 'text/html');
    const el = parsedDoc.body.querySelector(`[x-cflowelement=${name}]`);
    const container = document.getElementById('pm-container');
    container.appendChild(el);
};

// DEV
if (process.env.NODE_ENV === 'development') {
    (async () => {
        const start = new Date();
        console.log('[ECC] Starting dev-utils...');
        const devUtils = await import('./libs/dev-utils');
        await devUtils.default();
        console.log('[ECC] 🔧 Variables loaded');
        console.log('[ECC] 🚂 Initializing OLD FE Page... ', new Date().getTime() - start.getTime());
        initPage();
        console.log('[ECC] 🚞 Initializing Alpine...', new Date().getTime() - start.getTime());
        Alpine.start();
        console.log('[ECC] 🚀 Init Complete', new Date().getTime() - start.getTime());
    })();
} else {
    const start = new Date();
    console.log('[ECC] 🚂 Initializing OLD FE Page... ', new Date().getTime() - start.getTime());
    initPage();
    console.log('[ECC] 🚞 Initializing Alpine...', new Date().getTime() - start.getTime());
    Alpine.start();
    console.log('[ECC] 🚀 Init Complete', new Date().getTime() - start.getTime());
}
