import Alpine from 'alpinejs';
import { Splide, Options } from '@splidejs/splide';
import { DEFAULT_WISHLIST_NAME, deleteWishlist, getWishlist, getWishlistNames } from '../../../../../../libs/wishlists-utils';
import { formatC, getQueryParamFromUrl, i18n } from '../../../../../../libs/base-utils';
import { FlowStore } from 'src/cfamily/types';
import { sendAnalytics } from '../../../../../../libs/tracking-utils';
import { BASE_ECOMMERCE_DATA } from '../../../../../../constants';

const SPLIDE_WISH_ID = '#splide-wishlists';
const SPLIDE_WISH_OPT: Options = {
    drag: 'free',
    arrows: false,
    pagination: false,
    autoWidth: true,
};

Alpine.data('ec11x5xManageWishlists', () => {
    let splideWish;

    return {
        initialized: false as boolean,
        existentWishlistsNamesWithDim: new Map() as Map<string, number>,
        selectedWishlist: DEFAULT_WISHLIST_NAME as string,
        init() {
            this.selectedWishlist = getQueryParamFromUrl('pre-selected-wishlist', DEFAULT_WISHLIST_NAME);
            this._loadExistentWishlistsNames();
        },
        // consts
        DEFAULT_WISHLIST_NAME,
        // utils
        i18n,
        formatC,
        getWishlist,
        // internal functions
        async _loadExistentWishlistsNames() {
            this.existentWishlistsNamesWithDim = new Map();
            (await getWishlistNames(true))?.forEach(async (wishName) => {
                const wishDim = (await getWishlist(wishName))?.entries?.size;
                this.existentWishlistsNamesWithDim.set(wishName, wishDim);
            });
            this.$nextTick(() => {
                if (splideWish) splideWish.destroy();
                splideWish = new Splide(SPLIDE_WISH_ID, SPLIDE_WISH_OPT).mount();
            });
            this.initialized = true;
        },
        // main component functions
        async selectWishlist(wishlistName: string) {
            this.selectedWishlist = wishlistName;
            sendAnalytics((() => {
                let ecommerceData = BASE_ECOMMERCE_DATA.CLICK_PA_WISHLISTS_CHANGE_LIST;
                ecommerceData.funnel.listaVisualizzata = getQueryParamFromUrl('pre-selected-wishlist', DEFAULT_WISHLIST_NAME);
                ecommerceData.funnel.listaSelezionata = this.selectedWishlist;
                return ecommerceData;
            })());
            this.$refs.input.dispatchEvent(new CustomEvent('change', { bubbles: true })); // trigger onchange
        },
        async createWishlistAction() {
            if (!this.$store.user || !this.$store.user.utenzaId) return;
            sendAnalytics((() => {
                let ecommerceData = BASE_ECOMMERCE_DATA.CLICK_PA_WISHLISTS_MANAGE;
                ecommerceData.funnel.stepFunnel = 'Nuova Lista';
                ecommerceData.funnel.listaVisualizzata = getQueryParamFromUrl('pre-selected-wishlist', DEFAULT_WISHLIST_NAME);
                return ecommerceData;
            })());
            let flowOutputData: any = await window.cFlowManager.startAsyncFlow({
                name: 'wishlists-create-flow',
                steps: [
                    { name: 'wishlists-create' },
                    { name: 'wishlists-confirm-create' },
                ],
                initialData: {
                }
            });
            if (flowOutputData?.result?.state === 'confirmed') {
                this._loadExistentWishlistsNames();
            }
        },
        async deleteWishlistAction() {
            if (!this.$store.user || !this.$store.user.utenzaId) return;
            if (!this.selectedWishlist) return;
            sendAnalytics((() => {
                let ecommerceData = BASE_ECOMMERCE_DATA.CLICK_PA_WISHLISTS_MANAGE;
                ecommerceData.funnel.stepFunnel = 'Elimina Lista';
                ecommerceData.funnel.listaVisualizzata = getQueryParamFromUrl('pre-selected-wishlist', DEFAULT_WISHLIST_NAME);
                return ecommerceData;
            })());
            let indexOfDeletingWish: number = (await getWishlistNames(true)).indexOf(this.selectedWishlist);
            let flowOutputData: any = await window.cFlowManager.startAsyncFlow({
                name: 'wishlists-create-flow',
                steps: [
                    {
                        name: 'wishlists-delete',
                        toContinue: async () => {
                            await deleteWishlist(this.selectedWishlist);
                            const flowStore = (await <FlowStore>window.Alpine.store('cFlow'));
                            flowStore.data.result = { state: 'confirmed' };
                            window.cFlowManager.next('wishlists-confirm-delete');
                        },
                        toGoBack: async () => {
                            const flowStore = (await <FlowStore>window.Alpine.store('cFlow'));
                            flowStore.data.result = { state: 'cancelled' };
                            window.cFlowManager.complete();
                        }
                    },
                    { name: 'wishlists-confirm-delete' },
                ],
                initialData: {
                    formatLabels: {
                        textArgs: [this.selectedWishlist]
                    }
                }
            });
            if (flowOutputData?.result?.state === 'confirmed') {
                this._loadExistentWishlistsNames();
                let wishNames: string[] = (await getWishlistNames(true));
                if (indexOfDeletingWish && indexOfDeletingWish != -1 && wishNames[indexOfDeletingWish - 1])
                    this.selectWishlist(wishNames[indexOfDeletingWish - 1]);
            }
        },
        async renameWishlistAction() {
            if (!this.$store.user || !this.$store.user.utenzaId) return;
            if (!this.selectedWishlist) return;
            sendAnalytics((() => {
                let ecommerceData = BASE_ECOMMERCE_DATA.CLICK_PA_WISHLISTS_MANAGE;
                ecommerceData.funnel.stepFunnel = 'Modifica Lista';
                ecommerceData.funnel.listaVisualizzata = getQueryParamFromUrl('pre-selected-wishlist', DEFAULT_WISHLIST_NAME);
                return ecommerceData;
            })());
            let flowOutputData: any = await window.cFlowManager.startAsyncFlow({
                name: 'wishlists-create-flow',
                steps: [
                    { name: 'wishlists-rename' },
                    { name: 'wishlists-confirm-rename' },
                ],
                initialData: {
                    wishlistName: this.selectedWishlist
                }
            });
            if (flowOutputData?.result?.state === 'confirmed') {
                this._loadExistentWishlistsNames();
            }
        },
        async heartEvent(eventDetail: Record<string, any>) {
            this._loadExistentWishlistsNames();
        },
    }
});
