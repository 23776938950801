import Alpine from 'alpinejs';
import * as WishlistUtils from '../../../../../libs/wishlists-utils';
import { notUndefinedAndTrue } from '../../../../../libs/base-utils';
import { _Uikit } from '../../../../../main'; // old-fe import

Alpine.data('ap203xLikeButton', () => ({
    initialized: false as boolean,
    isWishlistProduct: false as boolean,
    init() {
        if (!this.$store.user || !this.$store.user.utenzaId) return;
        this._checkWishlistProduct();
    },
    // utils
    notUndefinedAndTrue,
    // internal functions
    async _checkWishlistProduct() {
        if (!this.$store.user || !this.$store.user.utenzaId) return;
        this.isWishlistProduct = (await WishlistUtils.isWishListProduct(this.productData?.code));
        this.initialized = true;
    },
    // main component functions
    async favoriteButtonClickAction() {
        if (!this.$store.user || !this.$store.user.utenzaId) {
            _Uikit.offcanvas('#modalReservedFunctionality').show(); // old-fe call
            return;
        }

        if (!this.productData?.code) return;
        let flowOutputData: any = await window.cFlowManager.startAsyncFlow({
            name: 'wishlists-add-product-flow',
            steps: [
                { name: 'wishlists-add-product' },
                { name: 'wishlists-create-and-add-product' },
                { name: 'wishlists-confirm-create-and-add' },
            ],
            initialData: {
                productData: this.productData
            }
        });

        if (flowOutputData?.result?.state === 'confirmed') {
            this.$dispatch('action-on-favorite', { productCode: this.productData?.code });
        }
    },
    async sameProductHeartEvent(eventDetail: Record<string, any>) {
        if (!this.$store.user || !this.$store.user.utenzaId) return;
        if (eventDetail?.productCode === this.productData?.code)
            this._checkWishlistProduct();
    }
}));