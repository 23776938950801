import { LOADER_POSITION, Utils } from '../../services/utils/utils';
import { _$, _Uikit, Window } from '../../main';
import { CartService } from 'src/services/cart-service/cart-service';
import { DetailCart } from '../../pages/detail-cart/detail-cart';
import { Analytics } from 'src/services/analytics/analytics';
import { LocalStorage } from 'src/services/local-storage/local-storage';
import { LockerUtils } from '../../services/utils/locker-utils';

export class ProductCardLastView {

    scaleMap = {
        "MG": "mg",
        "CG": "cg",
        "GR": "g",
        "HG": "hg",
        "KG": "kg"
    }

    constructor(
        private _utils: Utils,
        private cartService: CartService,
        private _detailCart: DetailCart,
        private _analytics: Analytics,
        private _localStorage: LocalStorage,
        private _lockerUtils: LockerUtils
    ) {
    }

    nkOnInit() {
    }

    /* Preso da product card */
    toggleFeedbackOverlay(e: object) {
        let card;
        if (_$(e).parents('.component-ProductCardLastView').length) {
            card = _$(e).closest('.component-ProductCardLastView');
        } else if (_$(e).closest('.component-CardSuggest').length) {
            card = _$(e).closest('.component-CardSuggest');
        }
        let oldFeedback = card.find('.feedback-overlay-pcard');
        let newFeedback = card.find('.feedback-overlay-pcard').clone();
        oldFeedback.before(newFeedback);
        oldFeedback.remove();
        newFeedback.css('display', 'block');
        setTimeout(function() {
            newFeedback.css('display', 'none');
        }, 3000);
    }

    /* Sia per card suggest che per card last view */
    addToCart(e: object) {
        let productCard;
        if (_$(e).closest('.component-ProductCardLastView').length) {
            productCard = _$(e).closest('.component-ProductCardLastView');
        } else if (_$(e).closest('.component-CardSuggest').length) {
            productCard = _$(e).closest('.component-CardSuggest');
        }

        let dataProduct = JSON.parse(productCard.attr('data-product'));
        let productType;
        if (dataProduct.increment) {
            productType = 'incremental';
        } else if (dataProduct.variations) {
            productType = 'variable';
        } else if (dataProduct.volume) {
            productType = 'heavy';
        } else {
            productType = 'base';
        }
        this.callAddRemoveToCartNew(e, 'add', productType);
    }

    /* Sia per card suggest che per card last view */
    callAddRemoveToCartNew(e, type: string, productType: string) {
        let productCard;
        if (_$(e).closest('.component-ProductCardLastView').length) {
            productCard = _$(e).closest('.component-ProductCardLastView');
        } else if (_$(e).closest('.component-CardSuggest').length) {
            productCard = _$(e).closest('.component-CardSuggest');
        }
        this._utils.showLoader(_$(e), true, LOADER_POSITION.BUTTON);
        let success = (res) => {
            this._utils.showLoader(_$(e), false, LOADER_POSITION.BUTTON);
            let response = res.data;
            this._localStorage.setItem('flagFirstAddToCart', this._utils.checkFirstAddToCartFlag(response.data));
            this.toggleFeedbackOverlay(e);
            let statusCode = response.data.modifications.cartModifications[0].statusCode;

            if(statusCode == "success"){
                _$(productCard).find('.add-cart').prop('disabled', false);
                let prezzo;
                switch (productType) {
                    case 'variable':
                        let dataQuantity;
                        let dataEntryNumber;
                        if (productCard.attr('data-quantity')) {
                            dataQuantity = JSON.parse(productCard.attr('data-quantity'));
                            dataEntryNumber = JSON.parse(productCard.attr('data-entryNumber'));
                            prezzo = response.data.modifications.cartModifications[0].entry.totalPrice.value / response.data.modifications.cartModifications[0].quantity;
                        } else {
                            dataQuantity = {};
                            dataEntryNumber = {};
                            prezzo = response.data.modifications.cartModifications[0].entry.totalPrice.value;
                        }
                        dataEntryNumber[dataProduct.variations.minWeight] = response.data.modifications.cartModifications[0].entry.entryNumber;
                        dataQuantity[dataProduct.variations.minWeight] = response.data.modifications.cartModifications[0].entry.quantity;
                        productCard.attr('data-quantity', JSON.stringify(dataQuantity));
                        productCard.attr('data-entryNumber', JSON.stringify(dataEntryNumber));
                        productCard.find('.add-cart .uk-badge').html(dataQuantity[dataProduct.variations.minWeight]);
                        productCard.find('.add-cart .uk-badge').removeClass('uk-hidden');
                        this.sendAnalytics('aggiungiProdotto', e, dataProduct.variations.minWeight,prezzo);

                        let currentPriceVar = 0;
                        let totalQty = 0;
                        let entries = response.data.cart.entries;
                        for(let entry in entries){
                            if(entries[entry].productCode == JSON.parse(productCard.attr('data-product')).code){
                                currentPriceVar += entries[entry].totalPrice.value;
                                totalQty += entries[entry].quantity * entries[entry].scale;
                            }
                        }

                        let quantityVar;

                        if(dataProduct.variations && dataProduct.variations.unitOfMeasure == "KG"){
                            quantityVar = (totalQty < 1 ? this._utils.parseScaleKgToGr(totalQty * 1000).toString().replace('.',',') : totalQty).toString()+ ' ' + (totalQty < 1 ? this.scaleMap["GR"] : this.scaleMap["KG"])
                        }else if(dataProduct.variations && dataProduct.variations.unitOfMeasure == "GR"){
                            quantityVar = (totalQty >= 1000 ? (totalQty/1000).toString().replace('.',',') : totalQty).toString()+ ' ' + (totalQty >= 1000 ? this.scaleMap["KG"] : this.scaleMap["GR"]);
                        }else{
                            quantityVar = (totalQty >= 1000 ? (totalQty/1000).toString().replace('.',',') : totalQty).toString()+ ' ' + (totalQty >= 1000 ? this.scaleMap["KG"] : this.scaleMap["GR"])
                        }

                        this._utils.updateAllCardsPicker(dataProduct.code, productCard.find('.add-cart .uk-badge').html(), true, '€ '+prezzo, false, true, quantityVar, '€ '+currentPriceVar.toFixed(2));
                        break;
                    case 'incremental':
                        this.toggleFeedbackOverlay(e);
                        let scale = response.data.modifications.cartModifications[0].entry.scale;
                        let incrementoMinimo = dataProduct.increment.minWeight;
                        let incrementoUnitario =dataProduct.increment.increment;
                        let totalPrice = response.data.modifications.cartModifications[0].entry.totalPrice.value;
                        if ((scale - incrementoMinimo) > 0) {
                            prezzo = totalPrice / (scale / incrementoUnitario);
                        } else {
                            prezzo = totalPrice;
                        }
                        productCard.attr('data-scale', response.data.modifications.cartModifications[0].entry.scale);
                        productCard.attr('data-entryNumber', response.data.modifications.cartModifications[0].entry.entryNumber);
                        if(dataProduct.increment && dataProduct.increment.unitOfMeasure == "KG"){
                            productCard.find('.add-cart .uk-badge').html(response.data.modifications.cartModifications[0].entry.scale < 1 ? (this._utils.parseScaleKgToGr(response.data.modifications.cartModifications[0].entry.scale * 1000) + 'g').replace('.', ',') : response.data.modifications.cartModifications[0].entry.scale + 'kg');
                        }else if(dataProduct.increment && dataProduct.increment.unitOfMeasure == "GR"){
                            productCard.find('.add-cart .uk-badge').html(response.data.modifications.cartModifications[0].entry.scale > 999 ? (response.data.modifications.cartModifications[0].entry.scale / 1000 + 'kg').replace('.', ',') : response.data.modifications.cartModifications[0].entry.scale + 'g');
                        }else{
                            productCard.find('.add-cart .uk-badge').html(response.data.modifications.cartModifications[0].entry.scale > 999 ? (response.data.modifications.cartModifications[0].entry.scale / 1000 + 'kg').replace('.', ',') : response.data.modifications.cartModifications[0].entry.scale + 'g');
                        }


                        productCard.find('.add-cart .uk-badge').removeClass('uk-hidden');
                        this.sendAnalytics('aggiungiProdotto', e, response.data.modifications.cartModifications[0].entry.scale, prezzo);
                        this._utils.updateAllCardsPicker(dataProduct.code, productCard.find('.add-cart .uk-badge').html(), true, '€ '+prezzo);
                        break;
                    case 'heavy':
                        this.toggleFeedbackOverlay(e);
                        productCard.attr('data-quantity', response.data.modifications.cartModifications[0].quantity);
                        productCard.attr('data-entryNumber', response.data.modifications.cartModifications[0].entry.entryNumber);
                        Window.volume.cartVolume += dataProduct.volume;
                        prezzo = response.data.modifications.cartModifications[0].entry.totalPrice.value;
    
                        if (response.data.modifications.cartModifications[0].quantity == 1) {
                            /* this.lsfTrack(e,'cart'); */
                        }
                        productCard.find('.add-cart .uk-badge').html(response.data.modifications.cartModifications[0].quantity);
                        productCard.find('.add-cart .uk-badge').removeClass('uk-hidden');
                        this.sendAnalytics('aggiungiProdotto', e);
                        this._utils.updateAllCardsPicker(dataProduct.code, productCard.find('.add-cart .uk-badge').html(), true, '€ '+prezzo);
                        break;
                    case 'base':
                        this.toggleFeedbackOverlay(e);
                        productCard.attr('data-entryNumber', response.data.modifications.cartModifications[0].entry.entryNumber);
                        productCard.attr('data-quantity', response.data.modifications.cartModifications[0].quantity);
                        prezzo = response.data.modifications.cartModifications[0].entry.totalPrice.value;
                        if (response.data.modifications.cartModifications[0].quantity == 1) {
                            /* this.lsfTrack(e,'cart'); */
                        }
                        productCard.find('.add-cart .uk-badge').html(response.data.modifications.cartModifications[0].quantity);
                        productCard.find('.add-cart .uk-badge').removeClass('uk-hidden');
                        this.sendAnalytics('aggiungiProdotto', e);
                        this._utils.updateAllCardsPicker(dataProduct.code, productCard.find('.add-cart .uk-badge').html(), true, '€ '+prezzo);
                }
    
                if (type == 'add' && response.data.modifications.cartModifications[0].quantity == 1 &&
                    response.data.modifications.cartModifications[0].quantityAdded == 1) {
                    /* this.lsfTrack(e,'cart'); */
                }
                this._utils.updateCartBtn(response.data.cart.subTotal.value, response.data.cart.totalUnitCount);
                this._utils.updateProductsOnPage(response.data.cart.entries);
                
                if(_$('body').hasClass('page-DetailCart')){
                    this._detailCart.reloadCart();
                }
            }else if(statusCode =="lowStock" || statusCode =="noStock"){
                this._utils.manageLowStock(dataProduct.code, true);
            }
        };

        let error = (err) => {
            this._utils.showLoader(_$(e), false, LOADER_POSITION.BUTTON);

            console.log('error no update', err.response);
            if (err.response.data.code == 'BACKEND:OUT_OF_STOCK_ERROR') {
                _$(productCard).find('.add-cart').removeClass('uk-hidden').prop('disabled', true);
                _$(productCard).find('.alert-out-of-stock').removeClass('uk-hidden');
            }else if(this._lockerUtils.parseLockerErrorMessageAddToCart(err.response.data.message) != null){
                let jsonError = this._lockerUtils.parseLockerErrorMessageAddToCart(err.response.data.message);
                if(jsonError['maxPiecesLk'] != null){
                    this._lockerUtils.manageLockerContingencyModal(jsonError['maxPiecesLk'], true, false);
                    _$(productCard).find('.add-cart').removeClass('uk-hidden').prop('disabled', true);
                }else if(jsonError['maxKgLk'] != null){
                    this._lockerUtils.manageLockerContingencyModal(jsonError['maxKgLk'], false, true);
                    _$(productCard).find('.add-cart').removeClass('uk-hidden').prop('disabled', true);
                }
            }
        };
        let body;

        let dataProduct = JSON.parse(productCard.attr('data-product'));

        switch (productType) {
            case 'variable':
                if (productCard.attr('data-quantity') && JSON.parse(productCard.attr('data-quantity'))[dataProduct.variations.minWeight]) {
                    let dataQuantity = JSON.parse(productCard.attr('data-quantity'))[dataProduct.variations.minWeight];
                    let entryNumber = JSON.parse(productCard.attr('data-entryNumber'))[dataProduct.variations.minWeight];

                    body = {
                        'productId': dataProduct.code,
                        'qty': dataQuantity + 1,
                        'scale': dataProduct.variations.minWeight,
                        'entryNumber': entryNumber
                    };

                } else {
                    body = {
                        'productId': dataProduct.code,
                        'qty': 1,
                        'scale': dataProduct.variations.minWeight
                    };

                }

                body['weight'] = body['scale'];
                break;

            case 'incremental':

                if (productCard.attr('data-scale') && parseFloat(productCard.attr('data-scale')) > 0) {
                    if (type == 'add') {
                        body = {
                            'productId': dataProduct.code,
                            'qty': 1,
                            'scale': parseFloat(productCard.attr('data-scale')) + dataProduct.increment.increment,
                            'entryNumber': productCard.attr('data-entryNumber')
                        };
                    }
                } else {
                    body = {
                        'productId': dataProduct.code,
                        'qty': 1,
                        'scale': dataProduct.increment.minWeight
                    };
                }

                body['weight'] = body['scale'];
                break;

            case 'heavy':
                if (productCard.attr('data-quantity') == '0' || productCard.attr('data-quantity') == undefined) {
                    body = {
                        'productId': dataProduct.code,
                        'qty': 1
                    };
                } else {
                    body = {
                        'productId': dataProduct.code,
                        'qty': parseInt(productCard.attr('data-quantity')) + 1,
                        'entryNumber': productCard.attr('data-entryNumber')
                    };
                }

                if(dataProduct.netQuantityUm && dataProduct.netQuantityUm.toUpperCase() != "PZ"){
                    body['weight'] = dataProduct.netQuantity ? dataProduct.netQuantity : 0;
                }
                break;
            case 'base':
                if (productCard.attr('data-quantity') == '0' || productCard.attr('data-quantity') == undefined) {
                    body = {
                        'productId': dataProduct.code,
                        'qty': 1
                    };
                } else {
                    body = {
                        'productId': dataProduct.code,
                        'qty': parseInt(productCard.attr('data-quantity')) + 1,
                        'entryNumber': productCard.attr('data-entryNumber')
                    };
                }
                if(dataProduct.netQuantityUm && dataProduct.netQuantityUm.toUpperCase() != "PZ"){
                    body['weight'] = dataProduct.netQuantity ? dataProduct.netQuantity : 0;
                }
                break;
        }

        this.cartService.addRemoveItemToCart(success, error, body);
    }


    async managePreferiteProductSendAnalytics (e) {
        try {
            //await this._utils.managePreferiteProduct(e, 'card-last');
            this.sendAnalytics('prodottoPreferito', e);
        } catch (error) {
            console.log(error);
        }
    }

    async sendAnalytics(eventType: string, e: object, variante?, prezzo?){
        try {
            let analyticsData;
            let funnelDict;
            let myEvent = eventType;
            let page = _$('body').attr('nkpage').toString();
            let productCard;

            if (page == 'DetailCart') {
                switch (myEvent){
                    case 'aggiungiProdotto':
                        myEvent = 'aggiungiDaCarosello';
                        break;
                    case 'rimuoviProdotto':
                        myEvent = 'rimuoviDaCarosello';
                        break;
                    case 'prodottoPreferito':
                        myEvent = 'preferitoDaCarosello';
                        break;
                    case 'dettaglioProdotto':
                        myEvent = 'dettaglioDaCarosello';
                        break;
                }
            }
    
            if (_$(e).closest('.component-ProductCardLastView').length) {
                productCard = _$(e).closest('.component-ProductCardLastView');
            } else if (_$(e).closest('.component-CardSuggest').length) {
                productCard = _$(e).closest('.component-CardSuggest');
            }

            analyticsData = {
                event: myEvent,
                prodotto: (await this._analytics.getProductData(productCard, false, myEvent, variante, prezzo)),
            }
    
            if (page == 'DetailProduct' || page == 'DetailCart') {
                analyticsData.prodotto['carosello'] = _$(e).closest('.section-carousel').find('.section-title').html();
            } else if (page == 'Homepage') {
                if(_$(e).closest('.component-StorytellingA, .component-StorytellingB').length) {
                    analyticsData.prodotto['carosello'] = "Editoriale"
                } else {
                    if (_$(e).closest('.section-carousel').length) {
                        if (_$(e).closest('.section-carousel').find('.section-title').length) {
                            analyticsData.prodotto['carosello'] = _$(e).closest('.section-carousel').find('.section-title').html();
                        } else {
                            analyticsData.prodotto['carosello'] = _$(e).closest('.section-carousel').find('.title').html();
                        }
                    } else {
                        analyticsData.prodotto['carosello'] = _$(e).closest('.section').find('.section-title').html();
                    }
                }
            }
            
            switch (myEvent) {
                case 'aggiungiDaCarosello':
                    //4.2.15
                    funnelDict = 'FUNNEL_DATA_CART_PRODUCT_CARD_ADD_TO_CART';
                    analyticsData['IDpromozioni'] = this._analytics.getIDpromozioni();
                    break;
                case 'rimuoviDaCarosello':
                    //4.2.16
                    funnelDict = 'FUNNEL_DATA_CART_PRODUCT_CARD_REMOVE_FROM_CART';
                    analyticsData['IDpromozioni'] = this._analytics.getIDpromozioni();
                    break;
                case 'preferitoDaCarosello':
                    //4.2.17
                    funnelDict = _$(e).hasClass('active') ? 'FUNNEL_DATA_CART_PRODUCT_CARD_ADD_FAVOURITE' : 'FUNNEL_DATA_CART_PRODUCT_CARD_REMOVE_FAVOURITE';
                    analyticsData['IDpromozioni'] = this._analytics.getIDpromozioni();
                    break;
                case 'dettaglioDaCarosello':
                    //4.2.18
                    funnelDict = 'FUNNEL_DATA_CART_PRODUCT_CARD_CLICK_PRODUCT';
                    analyticsData['IDpromozioni'] = this._analytics.getIDpromozioni();
                    break;
                case 'aggiungiProdotto':
                    if (page == 'DetailProduct') {
                        funnelDict = 'FUNNEL_DATA_DET_PROD_ADD_TO_CART';
                    } else if (page == 'Homepage') {
                        funnelDict = 'FUNNEL_DATA_HOME_ADD_TO_CART';
                    } else {
                        funnelDict = window.location.pathname == window['urlSite'].search ? 'FUNNEL_DATA_PRODUCT_CARD_ADD_TO_CART_SEARCH' : 'FUNNEL_DATA_PRODUCT_CARD_ADD_TO_CART_CHOOSE';
                    }
                    break;
                case 'rimuoviProdotto':
                    if (page == 'DetailProduct') {
                        funnelDict = 'FUNNEL_DATA_DET_PROD_REMOVE_FROM_CART';
                    } else if (page == 'Homepage') {
                        funnelDict = 'FUNNEL_DATA_HOME_REMOVE_FROM_CART';
                    } else {
                        funnelDict = 'FUNNEL_DATA_PRODUCT_CARD_REMOVE_FROM_CART';
                    }
                    break;
                case 'prodottoPreferito':
                    if (page == 'DetailProduct') {
                        funnelDict = _$(e).hasClass('active') ? 'FUNNEL_DATA_DET_PROD_ADD_FAVOURITE' : 'FUNNEL_DATA_DET_PROD_REMOVE_FAVOURITE';
                    } else if (page == 'Homepage') {
                        funnelDict = _$(e).hasClass('active') ? 'FUNNEL_DATA_HOME_ADD_FAVOUR' : 'FUNNEL_DATA_HOME_REMOVE_FAVOUR';
                    } else {
                        if (window.location.pathname == window['urlSite'].search) {
                            funnelDict = _$(e).hasClass('active') ? 'FUNNEL_DATA_PRODUCT_CARD_ADD_FAVOURITE_SEARCH' : 'FUNNEL_DATA_PRODUCT_CARD_REMOVE_FAVOURITE_SEARCH';
                        } else {
                            funnelDict = _$(e).hasClass('active') ? 'FUNNEL_DATA_PRODUCT_CARD_ADD_FAVOURITE_CHOOSE' : 'FUNNEL_DATA_PRODUCT_CARD_REMOVE_FAVOURITE_CHOOSE';
                        }
                    }
                    break;
                case 'dettaglioProdotto':
                    if (page == 'DetailProduct') {
                        funnelDict = 'FUNNEL_DATA_DET_PROD_CLICK_PRODUCT';
                    } else if (page == 'Homepage') {
                        funnelDict = 'FUNNEL_DATA_HOME_CLICK_PRODUCT';
                    } else {
                        funnelDict = window.location.pathname == window['urlSite'].search ? 'FUNNEL_DATA_PRODUCT_CARD_CLICK_PRODUCT_SEARCH' : 'FUNNEL_DATA_PRODUCT_CARD_CLICK_PRODUCT_CHOOSE';
                    }
                    break;
            }
    
            this._analytics.sendAnalytics(analyticsData, funnelDict);
        } catch (error) {
            console.log(eventType + ' : ' + error);
        }
    }
}