import { LOADER_POSITION, Utils } from "../../../src/services/utils/utils";
import { LsfService } from "../../../src/services/lsf-service/lsf-service";
import { CartService } from "../../../src/services/cart-service/cart-service";
import { LocalStorage } from "../../../src/services/local-storage/local-storage";
import { _$, _Uikit, Window, _zoom } from "../../main";
import Swiper, { SwiperOptions } from 'swiper';
import { GoogleUtils } from '../../services/utils/google-utils';
import { LockerUtils } from '../../services/utils/locker-utils';
import { Analytics } from '../../services/analytics/analytics';

export class DetailProduct {

    _preparationNoteSelected: string = 'Categorie';
    _preparationNoteDefault: string = 'Categorie';
    _currentPrice = 0;
    public _cartScale = 0;
    scaleMap = {
        "MG": "mg",
        "CG": "cg",
        "GR": "g",
        "HG": "hg",
        "KG": "kg"
    }
    /* Traccia l'ultimo prodotto aggiunto per gestire le note di preparazione */
    _lastModifiedItem = {};

    swiperDetailProduct = new Swiper('.swiper-product-detail-image', {
        pagination: {
            el: '.pagination-product',
            clickable: true,
        },
        slidesPerView: 1,
        updateOnWindowResize: true,
        freeMode: false,
        breakpointsInverse: true,
        observer: true,
        resizeObserver: true,
        observeParents: true,
        observeSlideChildren: true,
        breakpoints: {
            320: {
                slidesPerView: 1
            },
            992: {
                slidesPerView: 5,
                watchSlidesVisibility: true,
                spaceBetween: 3,
                //@ts-ignore //TODO: verificare swiper pagination
                pagination: false,
                navigation: {
                    nextEl: '.next-product',
                    prevEl: '.prev-product',
                }
            },
            1200: {
                slidesPerView: 7,
                watchSlidesVisibility: true,
                navigation: {
                    nextEl: '.next-product',
                    prevEl: '.prev-product',
                }
            }
        },
        on: {
            click: (e) => {
                const _this = this.swiperDetailProduct;
                /* _$('#previewImageContainer').prepend('<div class="lds-ring lds-ring-1 orange uk-position-center" uk-spinner="ratio: 1.4"></div>'); */
                let img = _$(_this.clickedSlide).find('img')[0];
                this._utils.initZoom('NZoomContainer', 'NZoomImg', img);

                _$(_this.slides).removeClass('active');
                _$(_this.clickedSlide).addClass('active');

                let nSlideText = _$('.pagination-product .swiper-pagination-bullet-active');
                let nSlide = nSlideText.length ? parseInt(nSlideText.attr('aria-label').substring(nSlideText.attr('aria-label').length - 2)) : 1;

                let slide = _$('.swiper-product-detail-image-modal .swiper-slide').eq(nSlide - 1);
                _$('.swiper-product-detail-image-modal .swiper-slide.active').removeClass('active');
                _$(slide).addClass('active');

                this.sendAnalytics('vediFotoProdotto', null);
            },
            //@ts-ignore
            doubleClick: (e) => {
                const _this = this.swiperDetailProduct;
                /* _$('#previewImageContainer').append('<div class="lds-ring lds-ring-1 orange uk-position-center" uk-spinner="ratio: 1.4"></div>'); */
                let img = _$(_this.clickedSlide).find('img')[0];
                this._utils.initZoom('NZoomContainer', 'NZoomImg', img);

                _$(_this.slides).removeClass('active');
                _$(_this.clickedSlide).addClass('active');
            },
            init: function () { },
            update: function () {
                const _this = this;
                _this.pagination.update()
            }
        }
    }
    );

    swiperDetailProductModal = new Swiper('.swiper-product-detail-image-modal', {
        slidesPerView: 'auto',
        updateOnWindowResize: true,
        freeMode: false,
        breakpointsInverse: true,
        observer: true,
        //@ts-ignore
        resizeObserver: true,
        observeParents: true,
        observeSlideChildren: true,
        centerInsufficientSlides: true,
        navigation: {
            nextEl: '.next-product',
            prevEl: '.prev-product',
        },
        on: {
            click: (e) => {
                const _this = this.swiperDetailProductModal;
                /* _$('#previewImageContainerModal div:first').prepend('<div class="lds-ring lds-ring-1 orange uk-position-center" uk-spinner="ratio: 1.4"></div>'); */
                let img = _$(_this.clickedSlide).find('img')[0];
                this._utils.initZoom('NZoomContainerMobile', 'NZoomImgMobile', img);

                _$(_this.slides).removeClass('active');
                _$(_this.clickedSlide).addClass('active');
            },
            //@ts-ignore
            doubleClick: (e) => {
                const _this = this.swiperDetailProduct;
                /* _$('#previewImageContainerModal div:first').append('<div class="lds-ring lds-ring-1 orange uk-position-center" uk-spinner="ratio: 1.4"></div>'); */
                let img = _$(_this.clickedSlide).find('img')[0];
                this._utils.initZoom('NZoomContainerMobile', 'NZoomImgMobile', img);

                _$(_this.slides).removeClass('active');
                _$(_this.clickedSlide).addClass('active');
            },
            init: function () { },
            update: function () {
                const _this = this;
                _this.pagination.update();
            }
        }
    }
    );

    swiperSuggestedProductsOptions: SwiperOptions = {
        slidesPerView: 1.01,
        spaceBetween: 15,
        freeMode: true,
        scrollbar: {
            el: '.scrollbar-suggested',
            draggable: true,
            hide: false,
        },
        /* navigation: {
            nextEl: '.next-suggested',
            prevEl: '.prev-suggested',
        }, */
        pagination: {
            el: '.pagination-suggested',
            type: 'custom',
            renderCustom: function (swiper, current, total) {
                let slideView;
                if (_$(window).width() < 768) {
                    slideView = swiper.params.breakpoints['375'].slidesPerView;
                } else if (_$(window).width() < 1024) {
                    slideView = swiper.params.breakpoints['768'].slidesPerView;
                } else if (_$(window).width() < 1440) {
                    slideView = swiper.params.breakpoints['1024'].slidesPerView;
                } else {
                    slideView = swiper.params.breakpoints['1440'].slidesPerView;
                }
                return Math.ceil((current + slideView - 1) / slideView) + ' di ' + Math.ceil((total + slideView - 1) / slideView);
            }
        },
        breakpointsInverse: true,
        breakpoints: {
            375: {
                slidesPerView: 1.21,
                spaceBetween: 15
            },
            768: {
                slidesPerView: 3.1,
                spaceBetween: 15,
                /* slidesPerGroup: 3 */
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 16,
                /* slidesPerGroup: 4 */
            },
            1440: {
                slidesPerView: 4,
                spaceBetween: 24,
                /* slidesPerGroup: 4 */
            }
        },
        on: {
            transitionStart: () => {
                this._utils.draggedByScrollbar = true;
            },
            transitionEnd: () => {
                this._utils.draggedByScrollbar = false;
            }
        }
    }
    swiperSuggestedProducts = new Swiper('.swiper-suggested-products', this.swiperSuggestedProductsOptions);

    swiperPerfectMatchOptions: SwiperOptions = {
        slidesPerView: 1.01,
        spaceBetween: 15,
        freeMode: true,
        scrollbar: {
            el: '.scrollbar-perfect',
            draggable: true,
            hide: false,
        },
        navigation: {
            nextEl: '.next-perfect',
            prevEl: '.prev-perfect',
        },
        breakpointsInverse: true,
        breakpoints: {
            375: {
                slidesPerView: 1.21,
                spaceBetween: 15
            },
            768: {
                slidesPerView: 3.1,
                spaceBetween: 15
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 16
            },
            1440: {
                slidesPerView: 4,
                spaceBetween: 24
            }
        },
        on: {
            transitionStart: () => {
                this._utils.draggedByScrollbar = true;
            },
            transitionEnd: () => {
                this._utils.draggedByScrollbar = false;
            }
        }
    }
    swiperPerfectMatch = new Swiper('.swiper-perfect-match', this.swiperPerfectMatchOptions);

    swiperLastViewsOptions: SwiperOptions = {
        slidesPerView: 1.01,
        spaceBetween: 15,
        freeMode: true,
        scrollbar: {
            el: '.scrollbar-last',
            draggable: true,
            hide: false,
        },
        /* navigation: {
            nextEl: '.next-last',
            prevEl: '.prev-last',
        }, */
        pagination: {
            el: '.pagination-last',
            type: 'custom',
            renderCustom: function (swiper, current, total) {
                let slideView;
                if (_$(window).width() < 768) {
                    slideView = swiper.params.breakpoints['375'].slidesPerView;
                } else if (_$(window).width() < 1024) {
                    slideView = swiper.params.breakpoints['768'].slidesPerView;
                } else if (_$(window).width() < 1440) {
                    slideView = swiper.params.breakpoints['1024'].slidesPerView;
                } else {
                    slideView = swiper.params.breakpoints['1440'].slidesPerView;
                }
                return Math.ceil((current + slideView - 1) / slideView) + ' di ' + Math.ceil((total + slideView - 1) / slideView);
            }
        },
        breakpointsInverse: true,
        breakpoints: {
            375: {
                slidesPerView: 1.21,
                spaceBetween: 15
            },
            768: {
                slidesPerView: 2.4,
                spaceBetween: 15,
                /* slidesPerGroup: 2 */
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 16,
                /*  slidesPerGroup: 3 */
            },
            1440: {
                slidesPerView: 3,
                spaceBetween: 24,
                /* slidesPerGroup: 3 */
            }
        },
        on: {
            transitionStart: () => {
                this._utils.draggedByScrollbar = true;
            },
            transitionEnd: () => {
                this._utils.draggedByScrollbar = false;
            }
        }
    }
    swiperLastViews = new Swiper('.swiper-last-views', this.swiperLastViewsOptions);

    swiperNxMOptions: SwiperOptions = {
        slidesPerView: 1.01,
        spaceBetween: 15,
        freeMode: true,
        scrollbar: {
            el: '.scrollbar-nxm',
            draggable: true,
            hide: false,
        },
        /* navigation: {
            nextEl: '.next-suggested',
            prevEl: '.prev-suggested',
        }, */
        pagination: {
            el: '.pagination-nxm',
            type: 'custom',
            renderCustom: function (swiper, current, total) {
                let slideView;
                if (_$(window).width() < 768) {
                    slideView = swiper.params.breakpoints['375'].slidesPerView;
                } else if (_$(window).width() < 1024) {
                    slideView = swiper.params.breakpoints['768'].slidesPerView;
                } else if (_$(window).width() < 1440) {
                    slideView = swiper.params.breakpoints['1024'].slidesPerView;
                } else {
                    slideView = swiper.params.breakpoints['1440'].slidesPerView;
                }
                return Math.ceil((current + slideView - 1) / slideView) + ' di ' + Math.ceil((total + slideView - 1) / slideView);
            }
        },
        breakpointsInverse: true,
        breakpoints: {
            375: {
                slidesPerView: 1.21,
                spaceBetween: 15
            },
            768: {
                slidesPerView: 3.1,
                spaceBetween: 15,
                /* slidesPerGroup: 3 */
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 16,
                /* slidesPerGroup: 4 */
            },
            1440: {
                slidesPerView: 4,
                spaceBetween: 24,
                /* slidesPerGroup: 4 */
            }
        },
        on: {
            transitionStart: () => {
                this._utils.draggedByScrollbar = true;
            },
            transitionEnd: () => {
                this._utils.draggedByScrollbar = false;
            }
        }
    }
    swiperNxM = new Swiper('.swiper-nxm', this.swiperNxMOptions);

    constructor(
        private _utils: Utils,
        private _lsfService: LsfService,
        private _localStorage: LocalStorage,
        private _cartService: CartService,
        private _google: GoogleUtils,
        private _analytics: Analytics,
        private _lockerUtils: LockerUtils
    ) {
        if (_$(".button-cart-add").length) {
            _$(Window).scroll(() => {
                if (_$(Window).width() <= 992) {
                    var top_of_element = _$(".button-cart-add").offset().top;
                    var bottom_of_element = _$(".button-cart-add").offset().top + _$(".button-cart-add").outerHeight();
                    var bottom_of_screen = _$(window).scrollTop() + _$(window).innerHeight();
                    var top_of_screen = _$(window).scrollTop();

                    if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) {
                        //visible
                        _$('.fixed-button-add-cart').slideUp('fast');
                        _$('#esw-fab').removeClass('move');
                    } else {
                        //not visible
                        _$('.fixed-button-add-cart').slideDown('fast');
                        _$('#esw-fab').addClass('move');
                    }

                }
            });
        }
    }

    nkOnInit() {

        if (!Window.displayedProduct) {
            const stringDataProduct = _$('.main-content')?.attr('data-product');
            if (stringDataProduct) {
                try {
                    let dataProduct: any = JSON.parse(stringDataProduct);
                    if (dataProduct) {
                        Window.displayedProduct = dataProduct;
                    } else {
                        console.warn('invalid data-product attribute')
                    }
                } catch (e) {
                    console.warn('error while parsing the data-product attribute')
                }
            }
        }
        _$('#preparationNotesBox').addClass('uk-hidden');
        this.checkProductOnCart();
        setTimeout(() => {
            this._utils.initZoom('NZoomContainer', 'NZoomImg');
        }, 500);

        _Uikit.util.on('#modalProductImages', 'show', () => {
            this._utils.initZoom('NZoomContainerMobile', 'NZoomImgMobile');
        });

        if (_$('.product-preparation-notes').find('.filter-title').attr('data-default-title')) {
            this.setPreparationNotesTitle(_$('.product-preparation-notes').find('.filter-title').attr('data-default-title'));
        }

        if (Window.displayedProduct && Window.displayedProduct.entries && Window.displayedProduct.entries.length == 1 && Window.displayedProduct.entries[0].preparationNote) {

            this.setPreparationNotesSelected(Window.displayedProduct.entries[0].preparationNote);
        }

        /* if(_$('#preparationNotesBox').length){
            _$('#preparationNotesBox .filter-dropdown').on('show', () => {
                this.preloadPrepNote();
            });
        } */
        //queste inizializzazione probabilmente non sono necessarie
        this.swiperDetailProduct;
        this.swiperSuggestedProducts;
        this.swiperPerfectMatch;
        this.swiperLastViews;
        this.sendAnalytics('pageview', null);
        _$('.product-data-container .product-other-info').find('ul').on('show', () => { setTimeout(() => { this.sendAnalytics('clickAccordion', null); }, 100); })

        for (let i = 0; i < _$('.next-suggested').length; i++) {
            _$('.next-suggested')[i].setAttribute('onclick', 'DetailProduct.mySlideNext("suggested",this)');
            _$('.prev-suggested')[i].setAttribute('onclick', 'DetailProduct.mySlidePrev("suggested",this)');
        }
        for (let i = 0; i < _$('.next-last').length; i++) {
            _$('.next-last')[i].setAttribute('onclick', 'DetailProduct.mySlideNext("last",this)');
            _$('.prev-last')[i].setAttribute('onclick', 'DetailProduct.mySlidePrev("last",this)');
        }
        for (let i = 0; i < _$('.next-nxm').length; i++) {
            _$('.next-nxm')[i].setAttribute('onclick', 'DetailProduct.mySlideNext("nxm",this)');
            _$('.prev-nxm')[i].setAttribute('onclick', 'DetailProduct.mySlidePrev("nxm",this)');
        }

        _Uikit.util.on(window, 'load', () => {
            let queryString = window.location.search;

            if (queryString.indexOf("scrollCaroselloNxM") > -1) {
                this.scrollToElement();
            }
        });

    }

    async managePreferiteProductSendAnalytics(e) {
        try {
            //await this._utils.managePreferiteProduct(e, 'detail');
            this.sendAnalytics('prodottoPreferito', e);
        } catch (error) {
            console.log(error);
        }
    }

    setPreparationNotesTitle(value: string) {
        this._preparationNoteSelected = value;
        this._preparationNoteDefault = value;
    }

    setPreparationNotesSelected(value: string) {
        this._preparationNoteSelected = value;

        if (_$('#preparationNotesBox').find('input').siblings('span').length) {
            _$('#preparationNotesBox').find('input').siblings('span').each((index) => {
                if (_$('#preparationNotesBox').find('input').siblings('span').eq(index) != null &&
                    _$('#preparationNotesBox').find('input').siblings('span').eq(index).html() != null &&
                    _$('#preparationNotesBox').find('input').siblings('span').eq(index).html().toLowerCase() != null &&
                    _$('#preparationNotesBox').find('input').siblings('span').eq(index).html().toLowerCase() == value.toLowerCase()) {
                    _$('#preparationNotesBox').find('input').eq(index).prop('checked', true);
                }
            });
        }
    }

    getPreparationNotes() {
        var radios = document.getElementsByName('preparationNotes');
        for (var i = 0, length = radios.length; i < length; i++) {
            //@ts-ignore
            if (radios[i].checked) {
                let textValue = _$(radios[i]).next().hasClass('free-note') ? _$(radios[i]).next().val().toString() : _$(radios[i]).next().text();
                //Call API PreparationNotes
                _Uikit.dropdown('.filter-dropdown').hide();
                this._preparationNoteSelected = textValue;
                break;
            }
        }

        /* Se già presente a carrello bisogna chiamare il manage cart */

        if (Window._cartEntryNumber !== undefined) {
            let productType;
            if (Window.displayedProduct.increment) {
                productType = 'incremental';
            } else if (Window.displayedProduct.variations) {
                productType = 'variable';
            } else {
                productType = 'base';
            }
            if (productType == 'variable') {
                this.callAddRemoveToCartNew(_$('.page-DetailProduct'), 'preparationNotes', productType, this._utils.pdpLastModifiedItem['scale']);
            } else if (productType == 'incremental') {
                this.callAddRemoveToCartNew(_$('.page-DetailProduct'), 'preparationNotes', productType);
            }

        } else {
            console.log('non a carrello');
        }
    }

    deletePreparationNote() {
        if (Window._cartEntryNumber !== undefined) {
            let productType;
            if (Window.displayedProduct.increment) {
                productType = 'incremental';
            } else if (Window.displayedProduct.variations) {
                productType = 'variable';
            } else {
                productType = 'base';
            }

            this._preparationNoteSelected = '';
            if (productType == 'variable') {
                Window._cartPrepNote[this._utils.pdpLastModifiedItem['scale']] = this._preparationNoteSelected;
                this.callAddRemoveToCartNew(_$('.page-DetailProduct'), 'preparationNotes', productType, this._utils.pdpLastModifiedItem['scale']);
            } else if (productType == 'incremental') {
                Window._cartPrepNote = this._preparationNoteSelected;
                this.callAddRemoveToCartNew(_$('.page-DetailProduct'), 'preparationNotes', productType);
            }
        } else {
            console.log('non a carrello');
        }
    }

    /* preloadPrepNote(){
        console.log(_$('#preparationNotesBox').find('.single-choice-radio input'));

        _$('#preparationNotesBox').find('.single-choice-radio').find('input span').each((value) => {
            console.log(value);
        });
    } */

    updateValuePreparationNotes(e: object) {
        var selectedFilters = _$(e).find(":radio").filter(':checked').length;
        if (!_$(e).find(":radio").filter(':checked').parent().find('.free-note').length) {
            if (_$(e).attr("class") && _$(e).attr("class").indexOf('filter') != -1) {
                if (selectedFilters > 0) {
                    _$(e).addClass("enabled-filter");
                    this.enableSingleFilterButtons(e);
                } else {
                    _$(e).removeClass("enabled-filter");
                    this.disableSingleFilterButtons(e);
                }
            } else {
                if (selectedFilters > 0) {
                    _$(e).find(".filter").addClass("enabled-filter");
                    this.enableSingleFilterButtons(e);
                } else {
                    _$(e).find(".filter").removeClass("enabled-filter");
                    this.disableSingleFilterButtons(e);
                }
            }
        }
    }

    resetSingleFilter(e: object) {
        _$(e).parent().parent().find(":radio").filter(":checked").prop("checked", false);
        _$(e).parent().parent().find(".free-note").val('');
        _$(e).parent().parent().find(".free-note").addClass('blank');
        this.disableSingleFilterButtons(_$(e).parent());
        _Uikit.dropdown('.filter-dropdown').hide(0);
        this._preparationNoteSelected = '';

        if (Window._cartEntryNumber !== undefined) {
            let productType;
            if (Window.displayedProduct.increment) {
                productType = 'incremental';
            } else if (Window.displayedProduct.variations) {
                productType = 'variable';
            } else {
                productType = 'base';
            }
            this.callAddRemoveToCartNew(_$('.page-DetailProduct'), 'preparationNotes', productType, this._utils.pdpLastModifiedItem['scale']);
        }

    }

    enableSingleFilterButtons(e: object) {
        _$(e).find(".single-filter-reset").prop('disabled', false);
        _$(e).find(".single-filter-apply").prop('disabled', false);
    }

    disableSingleFilterButtons(e: object) {
        _$(e).find(".single-filter-reset").prop('disabled', true);
        _$(e).find(".single-filter-apply").prop('disabled', true);
    }

    addCartFixed(e) {
        var offset = (_$(window).height() - _$('#button-cart-add').height()) / 2 - _$('#button-cart-add').height();
        _Uikit.scroll('#fixed-button-add-cart', { offset: offset }).scrollTo('#button-cart-add');
        _$('.button-cart-add').click();
    }

    addCart(e) {
        /* Peso incrementale */
        if (Window.displayedProduct.increment) {
            this.callAddRemoveToCartNew(e, 'add', 'incremental', undefined, this.scaleMap[Window.displayedProduct.increment.unitOfMeasure]);
        }
        /* Peso variabile */
        else if (Window.displayedProduct.variations) {
            this.showVarWeight(e);
        }
        /* Pesante */
        else if (Window.displayedProduct.volume) {
            this.callAddRemoveToCartNew(e, 'add', 'heavy');
        } else {
            this.callAddRemoveToCartNew(e, 'add', 'base');
        }
    }

    showAddRemoveMenu() {
        _$('.product-button-add').find('.add-product').addClass("uk-hidden");
        _$('.product-button-add').find('.add-quantity').removeClass("uk-hidden");
    }

    hideAddRemoveMenu() {
        _$('.product-button-add').find('.add-product').removeClass("uk-hidden");
        _$('.product-button-add').find('.add-quantity').addClass("uk-hidden");
    }

    showFeedbackOverlay(e: object) {
        /* Animazione */
        let clone = _$(e).closest('.page-DetailProduct').find('.feedback-overlay-detail-product:visible').clone(true);
        _$(e).closest('.page-DetailProduct').find('.feedback-overlay-detail-product:visible').before(clone);
        _$(e).closest('.page-DetailProduct').find('.feedback-overlay-detail-product:visible').last().remove();
        let latestFeedback = _$(e).closest('.page-DetailProduct').find('.feedback-overlay-detail-product');
        latestFeedback.css('display', 'flex');
        /* Nasconde l'overlay dopo 4.8s */
        setTimeout(function () {
            latestFeedback.css('display', 'none');
        }, 4800);
    }

    removeToCart(e: object) {
        this.hideAlert(_$('.alert-promo'));
        /* Peso incrementale */
        if (Window.displayedProduct.increment) {
            this.callAddRemoveToCartNew(e, 'remove', 'incremental', undefined, Window.displayedProduct.increment.unitOfMeasure);
        }
        /* Peso variabile */
        else if (Window.displayedProduct.variations) {
            this.showVarWeightRemove(e);
        }
        /* Pesante/normale? */
        else if (Window.displayedProduct.volume) {
            this.callAddRemoveToCartNew(e, 'remove', 'heavy');
        } else {
            this.callAddRemoveToCartNew(e, 'remove', 'base');
        }
    }

    /* 
    type: add/remove
    productType: variable/incremental/heavy
    size: pezzature
    unit: unità di misura
     */
    callAddRemoveToCartNew(e: object, type: string, productType: string, size?: number, unit?: string) {

        this._utils.showLoader(_$('.page-DetailProduct').find('.product-button-add .button-cart-add'), true, LOADER_POSITION.ADD_TO_CART);
        this._utils.showLoader(_$('.page-DetailProduct').find('.product-button-add .add-quantity'), true, LOADER_POSITION.ADD_TO_CART);
        _$('.page-DetailProduct').find('.product-button-add .total .quantity, .product-button-add .total .price').addClass("uk-hidden");
        let promoAlertNeeded;
        let contingency;
        let activePromo;
        let success = (res) => {
            //this._utils.showLoader(_$('.page-DetailProduct').find('.product-button-add .total'), false, LOADER_POSITION.ADD_TO_CART);
            this._utils.showLoader(_$('.page-DetailProduct').find('.product-button-add .button-cart-add'), false, LOADER_POSITION.ADD_TO_CART);
            this._utils.showLoader(_$('.page-DetailProduct').find('.product-button-add .add-quantity'), false, LOADER_POSITION.ADD_TO_CART);
            let resource = res.data;
            this._localStorage.setItem('flagFirstAddToCart', this._utils.checkFirstAddToCartFlag(resource.data));
            this._utils.updateCartBtn(resource.data.cart.subTotal.value, resource.data.cart.totalUnitCount);
            let statusCode = resource.data.modifications.cartModifications[0].statusCode;
            if (statusCode == "success") {
                _$('.page-DetailProduct').find('.product-button-add .total .quantity, .product-button-add .total .price').removeClass("uk-hidden");
                _$('.product-button-add .add-quantity-button:last-child').prop('disabled', false);
                this._utils.manageLowStock(Window.displayedProduct.code, false);
                let prezzo;
                switch (productType) {
                    case 'variable':
                        if (resource.data.modifications.cartModifications[0].quantity < 1) {
                            let removedEntryNumber = Window._cartEntryNumber[size];
                            this._utils.updateRemovedProductOnPage('variable', Window.displayedProduct.code, size.toString());
                            delete Window._cartEntryNumber[size];
                            delete Window._cartQuantity[size];
                            delete Window._cartPrepNote[size];
                            /* Carrello svuotato - nessuna pezzatura */
                            if (Object.keys(Window._cartQuantity).length < 1 && type == 'remove') {
                                this.hideAddRemoveMenu();
                                this.hideAlert(_$('.alert-promo'));
                                _$('.product-button-add').find('.quantity').html('0 ' + this.scaleMap[Window.displayedProduct.variations.unitOfMeasure]);
                                _$('.product-button-add').find('.price').html('0,00€');
                                Window._cartQuantity = {};
                                this._utils.updateAllCardsPicker(Window.displayedProduct.code, _$('.product-button-add').find('.quantity').html(), false, _$('.product-button-add').find('.price').html());
                            } else {
                                this._currentPrice = 0;
                                let totalQty = 0;
                                let entries = resource.data.cart.entries;
                                for (let entry in entries) {
                                    if (entries[entry].productCode == Window.displayedProduct.code) {
                                        this._currentPrice += entries[entry].totalPrice.value;
                                        totalQty += entries[entry].quantity * 1000 * entries[entry].scale;
                                    }
                                }
                                totalQty = totalQty / 1000;
                                if (Window.displayedProduct.variations && Window.displayedProduct.variations.unitOfMeasure == "KG") {
                                    _$('.product-button-add').find('.quantity').html((totalQty < 1 ? this._utils.parseScaleKgToGr(totalQty * 1000) : parseFloat((totalQty).toFixed(4))).toString() + ' ' + (totalQty < 1 ? this.scaleMap["GR"] : this.scaleMap["KG"]));
                                } else if (Window.displayedProduct.variations && Window.displayedProduct.variations.unitOfMeasure == "GR") {
                                    _$('.product-button-add').find('.quantity').html((totalQty >= 1000 ? totalQty / 1000 : totalQty).toString() + ' ' + (totalQty >= 1000 ? this.scaleMap["KG"] : this.scaleMap["GR"]));
                                } else {
                                    _$('.product-button-add').find('.quantity').html((totalQty >= 1000 ? totalQty / 1000 : totalQty).toString() + ' ' + (totalQty >= 1000 ? this.scaleMap["KG"] : this.scaleMap["GR"]));
                                }

                                _$('.product-button-add').find('.price').html('€ ' + this._currentPrice.toFixed(2).toString().replace('.', ','));

                                if (Window.displayedProduct.variations.unitWeight) {
                                    let unitWeight;
                                    if (Window.displayedProduct.variations.unitOfMeasure == "KG") {
                                        unitWeight = Window.displayedProduct.variations.unitWeight / 1000;
                                    } else {
                                        unitWeight = Window.displayedProduct.variations.unitWeight;
                                    }
                                    let pz = Math.floor(totalQty / unitWeight);
                                    this.showAlertInfo(`La quantità aggiunta corrisponde a ca. ${pz} pezzi`);
                                }
                                this._utils.updateAllCardsPicker(Window.displayedProduct.code, _$('.product-button-add').find('.quantity').html(), true, _$('.product-button-add').find('.price').html(), true, false, 0);
                            }
                            this._utils.updateEntryNumbers(_$(e).closest('body'), JSON.stringify(resource.data.cart.entries));
                            this._utils.variableProductPreparationNotes();
                            prezzo = 0;
                        } else {
                            this._currentPrice = 0;
                            let totalQty = 0;
                            let entries = resource.data.cart.entries;
                            if (!Window._cartEntryNumber) {
                                Window._cartEntryNumber = {}
                                Window._cartEntryNumber[size] = resource.data.modifications.cartModifications[0].entry.entryNumber;
                            } else {
                                Window._cartEntryNumber[size] = resource.data.modifications.cartModifications[0].entry.entryNumber;
                            }
                            Window._cartQuantity[size] = resource.data.modifications.cartModifications[0].quantity;
                            for (let entry in entries) {
                                if (entries[entry].productCode == Window.displayedProduct.code) {
                                    this._currentPrice += entries[entry].totalPrice.value;
                                    totalQty += entries[entry].quantity * 1000 * entries[entry].scale;
                                }
                            }
                            totalQty = totalQty / 1000;
                            if (resource.data.modifications.cartModifications[0].entry.preparationNote) {
                                Window._cartPrepNote[size] = resource.data.modifications.cartModifications[0].entry.preparationNote;
                            }

                            if (Window.displayedProduct.variations.unitWeight) {
                                let unitWeight;
                                if (Window.displayedProduct.variations.unitOfMeasure == "KG") {
                                    unitWeight = Window.displayedProduct.variations.unitWeight / 1000;
                                } else {
                                    unitWeight = Window.displayedProduct.variations.unitWeight;
                                }

                                let pz = Math.floor(totalQty / unitWeight);
                                this.showAlertInfo(`La quantità aggiunta corrisponde a ca. ${pz} pezzi`);
                            }

                            if (Window.displayedProduct.variations && Window.displayedProduct.variations.unitOfMeasure == "KG") {
                                _$('.product-button-add').find('.quantity').html((totalQty < 1 ? this._utils.parseScaleKgToGr(totalQty * 1000) : parseFloat((totalQty).toFixed(4))).toString() + ' ' + (totalQty < 1 ? this.scaleMap["GR"] : this.scaleMap["KG"]));
                            } else if (Window.displayedProduct.variations && Window.displayedProduct.variations.unitOfMeasure == "GR") {
                                _$('.product-button-add').find('.quantity').html((totalQty >= 1000 ? totalQty / 1000 : totalQty).toString() + ' ' + (totalQty >= 1000 ? this.scaleMap["KG"] : this.scaleMap["GR"]));
                            } else {
                                _$('.product-button-add').find('.quantity').html((totalQty >= 1000 ? totalQty / 1000 : totalQty).toString() + ' ' + (totalQty >= 1000 ? this.scaleMap["KG"] : this.scaleMap["GR"]));
                            }

                            _$('.product-button-add').find('.price').html('€ ' + this._currentPrice.toFixed(2).toString().replace('.', ',') + '€');
                            this.showAddRemoveMenu();
                            this._utils.pdpLastModifiedItem['entryNumber'] = resource.data.modifications.cartModifications[0].entry.entryNumber;
                            this._utils.pdpLastModifiedItem['qty'] = resource.data.modifications.cartModifications[0].quantity;
                            this._utils.pdpLastModifiedItem['productId'] = resource.data.modifications.cartModifications[0].entry.productCode;
                            this._utils.pdpLastModifiedItem['scale'] = resource.data.modifications.cartModifications[0].entry.scale;
                            this._utils.variableProductPreparationNotes();
                            prezzo = resource.data.modifications.cartModifications[0].entry.totalPrice.value / resource.data.modifications.cartModifications[0].quantity;
                            this._utils.updateAllCardsPicker(Window.displayedProduct.code, _$('.product-button-add').find('.quantity').html(), true, _$('.product-button-add').find('.price').html(), true, false, Window._cartQuantity[Window.displayedProduct.variations.minWeight]);
                        }
                        if (type == 'add') {
                            this.sendAnalytics('aggiungiProdotto', e, size, prezzo);
                        } else if (type == 'remove') {
                            this.sendAnalytics('rimuoviProdotto', e, size, prezzo);
                        }
                        break;

                    case 'incremental':
                        if (resource.data.modifications.cartModifications[0].quantity < 1) {
                            this.hideAddRemoveMenu();
                            Window._cartQuantity = 0;
                            Window._cartScale = undefined;
                            Window._cartEntryNumber = undefined;
                            Window._cartPrepNote = '';
                            this._utils.updateEntryNumbers(_$('body'), JSON.stringify(resource.data.cart.entries));
                            this.hideAlert(_$('.alert-promo'));

                            _$('#preparationNotesBox').addClass('uk-hidden');
                            _$('.product-preparation-notes').find('.filter').prop('disabled', true);
                            _$('.product-preparation-notes').find('.filter').addClass('uk-disabled showAlert');

                            if (type == 'add') {
                                this.sendAnalytics('aggiungiProdotto', e, undefined, 0);
                            } else if (type == 'remove') {
                                this.sendAnalytics('rimuoviProdotto', e, undefined, 0);
                            }
                            this._utils.updateRemovedProductOnPage('incremental', Window.displayedProduct.code);
                            this._utils.updateAllCardsPicker(Window.displayedProduct.code, _$('.product-button-add').find('.quantity').html(), false, _$('.product-button-add').find('.price').html());
                        } else {
                            let scale = resource.data.modifications.cartModifications[0].entry.scale;
                            let incrementoMinimo = Window.displayedProduct.increment.minWeight;
                            let incrementoUnitario = Window.displayedProduct.increment.increment;
                            let totalPrice = resource.data.modifications.cartModifications[0].entry.totalPrice.value;
                            if ((scale - incrementoMinimo) > 0) {
                                prezzo = totalPrice / (scale / incrementoUnitario);
                            } else {
                                prezzo = totalPrice;
                            }
                            this._currentPrice = resource.data.modifications.cartModifications[0].entry.totalPrice.value;
                            Window._cartQuantity = resource.data.modifications.cartModifications[0].quantity;
                            Window._cartScale = resource.data.modifications.cartModifications[0].entry.scale;
                            Window._cartEntryNumber = resource.data.modifications.cartModifications[0].entry.entryNumber;

                            if (Window.displayedProduct.increment && Window.displayedProduct.increment.unitOfMeasure == "KG") {
                                _$('.product-button-add').find('.quantity').html(Window._cartScale < 1 ? (this._utils.parseScaleKgToGr(Window._cartScale * 1000)).toString().replace('.', ',') + ' g' : Window._cartScale + ' kg');
                            } else if (Window.displayedProduct.increment && Window.displayedProduct.increment.unitOfMeasure == "GR") {
                                _$('.product-button-add').find('.quantity').html(Window._cartScale >= 1000 ? (Window._cartScale / 1000).toString().replace('.', ',') + ' kg' : Window._cartScale + ' g');
                            } else {
                                _$('.product-button-add').find('.quantity').html(Window._cartScale >= 1000 ? (Window._cartScale / 1000).toString().replace('.', ',') + ' kg' : Window._cartScale + ' g');
                            }


                            _$('.product-button-add').find('.price').html(this._currentPrice.toFixed(2).toString().replace('.', ',') + '€');
                            this.showAddRemoveMenu();
                            if (Window.displayedProduct.increment.unitWeight) {
                                let unitWeight;
                                if (Window.displayedProduct.increment.unitOfMeasure == "KG") {
                                    unitWeight = Window.displayedProduct.increment.unitWeight / 1000;
                                } else {
                                    unitWeight = Window.displayedProduct.increment.unitWeight;
                                }

                                let pz = Math.floor(Window._cartScale / unitWeight);
                                this.showAlertInfo(`La quantità aggiunta corrisponde a ca. ${pz} pezzi`);
                            }

                            if (resource.data.modifications.cartModifications[0].entry.preparationNote) {
                                Window._cartPrepNote = resource.data.modifications.cartModifications[0].entry.preparationNote;
                            }
                            _$('#preparationNotesBox').removeClass('uk-hidden');
                            _$('.product-preparation-notes').find('.filter').prop('disabled', false);
                            _$('.product-preparation-notes').find('.filter').removeClass('uk-disabled showAlert');

                            if (type == 'add') {
                                this.sendAnalytics('aggiungiProdotto', e, Window._cartScale, prezzo);
                            } else if (type == 'remove') {
                                this.sendAnalytics('rimuoviProdotto', e, Window._cartScale, prezzo);
                            }
                            this._utils.updateAllCardsPicker(Window.displayedProduct.code, _$('.product-button-add').find('.quantity').html(), true, _$('.product-button-add').find('.price').html());
                        }
                        break;

                    case 'base':
                        if (resource.data.modifications.cartModifications[0].quantity < 1) {
                            this.hideAddRemoveMenu();
                            Window._cartQuantity = 0;
                            Window._cartEntryNumber = undefined;
                            this._utils.updateEntryNumbers(_$(e).closest('body'), JSON.stringify(resource.data.cart.entries));
                            this._utils.updateRemovedProductOnPage('base', Window.displayedProduct.code);
                            this._utils.updateAllCardsPicker(Window.displayedProduct.code, _$('.product-button-add').find('.quantity').html(), false, _$('.product-button-add').find('.price').html());
                        } else {
                            this.showAddRemoveMenu();
                            this._currentPrice = resource.data.modifications.cartModifications[0].entry.totalPrice.value;
                            Window._cartQuantity = resource.data.modifications.cartModifications[0].quantity;
                            Window._cartEntryNumber = resource.data.modifications.cartModifications[0].entry.entryNumber;
                            _$('.product-button-add').find('.quantity').html(Window._cartQuantity);
                            _$('.product-button-add').find('.price').html(this._currentPrice.toFixed(2).toString().replace('.', ',') + '€');
                            this._utils.updateAllCardsPicker(Window.displayedProduct.code, _$('.product-button-add').find('.quantity').html(), true, _$('.product-button-add').find('.price').html());
                        }

                        promoAlertNeeded = false;
                        contingency = false;
                        activePromo = {};
                        if (Window.displayedProduct.promo) {
                            let promoArray = Window.displayedProduct.promo;
                            promoArray.forEach((index, element) => {
                                if ((promoArray[element].itOrderSap || promoArray[element].itOrderEurecart) && !contingency) {
                                    contingency = true;
                                    promoAlertNeeded = true;
                                    activePromo = promoArray[element];
                                } else if (['PRODUCT_PARTNER_FIXED_DISCOUNT',
                                    'PRODUCT_PARTNER_MIX_FIXED_DISCOUNT',
                                    'PRODUCT_N_FIXED_DISCOUNT',
                                    'PRODUCT_ADDITIONAL_PERCENTAGE_DISCOUNT'].indexOf(promoArray[element].promoType) != -1 && !promoAlertNeeded) {
                                    promoAlertNeeded = true;
                                    activePromo = promoArray[element];
                                }
                            })
                        }

                        if (promoAlertNeeded) {
                            if (resource.data.modifications.cartModifications[0].quantity == 0) {
                                this.hideAlertWarning();
                                _$('.alert-contingency-product').addClass('uk-hidden');
                            } else {
                                if (contingency) {
                                    this.manageContingency(activePromo, resource.data.modifications.cartModifications[0].quantity,
                                        resource.data.modifications.cartModifications[0].entry.basePrice ? resource.data.modifications.cartModifications[0].entry.basePrice.value : 0);
                                } else {
                                    let promoQuantity = activePromo.promoType == 'PRODUCT_PARTNER_MIX_FIXED_DISCOUNT' ?
                                        this._utils.checkNxMProducts(resource.data.cart, activePromo.partnerProducts, Window.displayedProduct.code)
                                        : resource.data.modifications.cartModifications[0].quantity;
                                    this.showPromoAlert(activePromo, promoQuantity,
                                        resource.data.modifications.cartModifications[0].entry.basePrice ? resource.data.modifications.cartModifications[0].entry.basePrice.value : 0);
                                }
                            }
                        }
                        if (type == 'add') {
                            this.sendAnalytics('aggiungiProdotto', e);
                        } else if (type == 'remove') {
                            this.sendAnalytics('rimuoviProdotto', e);
                        }
                        break;
                    case 'heavy':
                        if (resource.data.modifications.cartModifications[0].quantity < 1) {
                            this.hideAddRemoveMenu();
                            Window._cartQuantity = 0;
                            Window._cartEntryNumber = undefined;
                            this._utils.updateEntryNumbers(_$(e).closest('body'), JSON.stringify(resource.data.cart.entries));
                            this._utils.updateRemovedProductOnPage('heavy', Window.displayedProduct.code);
                            this._utils.updateAllCardsPicker(Window.displayedProduct.code, _$('.product-button-add').find('.quantity').html(), false, _$('.product-button-add').find('.price').html());
                        } else {
                            this.showAddRemoveMenu();
                            this._currentPrice = resource.data.modifications.cartModifications[0].entry.totalPrice.value;
                            Window._cartQuantity = resource.data.modifications.cartModifications[0].quantity;
                            Window._cartEntryNumber = resource.data.modifications.cartModifications[0].entry.entryNumber;
                            _$('.product-button-add').find('.quantity').html(Window._cartQuantity);
                            _$('.product-button-add').find('.price').html(this._currentPrice.toFixed(2).toString().replace('.', ',') + '€');
                            this._utils.updateAllCardsPicker(Window.displayedProduct.code, _$('.product-button-add').find('.quantity').html(), true, _$('.product-button-add').find('.price').html());
                        }

                        if (type == 'remove') {
                            Window.volume.cartVolume -= Window.displayedProduct.volume;
                        } else {
                            Window.volume.cartVolume += Window.displayedProduct.volume;
                        }
                        promoAlertNeeded = false;
                        contingency = false;
                        activePromo = {};

                        if (Window.displayedProduct.promo) {
                            let promoArray = Window.displayedProduct.promo;
                            promoArray.forEach((index, element) => {
                                if ((promoArray[element].itOrderSap || promoArray[element].itOrderEurecart) && !contingency) {
                                    contingency = true;
                                    promoAlertNeeded = true;
                                    activePromo = promoArray[element];
                                } else if (['PRODUCT_PARTNER_FIXED_DISCOUNT',
                                    'PRODUCT_PARTNER_MIX_FIXED_DISCOUNT',
                                    'PRODUCT_N_FIXED_DISCOUNT',
                                    'PRODUCT_ADDITIONAL_PERCENTAGE_DISCOUNT'].indexOf(promoArray[element].promoType) != -1 && !promoAlertNeeded) {
                                    promoAlertNeeded = true;
                                    activePromo = promoArray[element];
                                }
                            })
                        }

                        if (promoAlertNeeded) {
                            if (resource.data.modifications.cartModifications[0].quantity == 0) {
                                this.hideAlertWarning();
                                _$('.alert-contingency-product').addClass('uk-hidden');
                            } else {
                                if (contingency) {
                                    this.manageContingency(activePromo, resource.data.modifications.cartModifications[0].quantity,
                                        resource.data.modifications.cartModifications[0].entry.basePrice ? resource.data.modifications.cartModifications[0].entry.basePrice.value : 0);
                                } else {
                                    let promoQuantity = activePromo.promoType == 'PRODUCT_PARTNER_MIX_FIXED_DISCOUNT' ?
                                        this._utils.checkNxMProducts(resource.data.cart, activePromo.partnerProducts, Window.displayedProduct.code)
                                        : resource.data.modifications.cartModifications[0].quantity;

                                    this.showPromoAlert(activePromo, promoQuantity,
                                        resource.data.modifications.cartModifications[0].entry.basePrice ? resource.data.modifications.cartModifications[0].entry.basePrice.value : 0);
                                }
                            }
                        }

                        this.checkHeavyItem();
                        if (type == 'add') {
                            this.sendAnalytics('aggiungiProdotto', e);
                        } else if (type == 'remove') {
                            this.sendAnalytics('rimuoviProdotto', e);
                        }
                        break;
                }

                if (type == 'add' && resource.data.modifications.cartModifications[0].quantity == 1 &&
                    resource.data.modifications.cartModifications[0].quantityAdded == 1) {
                    this.lsfTrack('cart');
                }

                if (type != 'preparationNotes') {
                    this.showFeedbackOverlay(_$('.feedback-overlay-detail-product'));
                }

                this._utils.updateCartBtn(resource.data.cart.subTotal.value, resource.data.cart.totalUnitCount);
                this._utils.updateProductsOnPage(resource.data.cart.entries);
            } else if (statusCode == "lowStock" || statusCode == "noStock") {
                this._utils.manageLowStock(Window.displayedProduct.code, true);
            }
            if (type == 'preparationNotes') {
                this.sendAnalytics('applicaNotePreparazione', _$('body'));
            }

        }

        let error = (err) => {
            this._utils.showLoader(_$('.page-DetailProduct').find('.product-button-add .button-cart-add'), false, LOADER_POSITION.ADD_TO_CART);
            this._utils.showLoader(_$('.page-DetailProduct').find('.product-button-add .add-quantity'), false, LOADER_POSITION.ADD_TO_CART);

            console.log('error no update', err.response);
            if (err.response.data.code == 'BACKEND:OUT_OF_STOCK_ERROR') {
                _$('.add-quantity').addClass('uk-hidden');
                _$('#button-cart-add').removeClass('uk-hidden').prop('disabled', true);
                _$('#fixed-button-add-cart').removeClass('uk-hidden').prop('disabled', true);
                _$('.page-DetailProduct .product-header').find('.alert-out-of-stock').removeClass('uk-hidden');
            } else if (this._lockerUtils.parseLockerErrorMessageAddToCart(err.response.data.message) != null) {
                _$('.page-DetailProduct').find('.product-button-add .total .quantity, .product-button-add .total .price').removeClass("uk-hidden");
                let jsonError = this._lockerUtils.parseLockerErrorMessageAddToCart(err.response.data.message);
                if (jsonError['maxPiecesLk'] != null) {
                    this._lockerUtils.manageLockerContingencyModal(jsonError['maxPiecesLk'], true, false);
                    if (jsonError['maxPiecesLk'] == 0) {
                        _$('#button-cart-add').removeClass('uk-hidden').prop('disabled', true);
                        _$('#fixed-button-add-cart').removeClass('uk-hidden').prop('disabled', true);
                    } else if (jsonError['maxPiecesLk'] > 0) {
                        _$('.product-button-add .add-quantity-button:last-child').prop('disabled', true);
                    }
                } else if (jsonError['maxKgLk'] != null) {
                    this._lockerUtils.manageLockerContingencyModal(jsonError['maxKgLk'], false, true);
                    if (productType != 'variable') {
                        if (jsonError['maxKgLk'] == 0) {
                            _$('#button-cart-add').removeClass('uk-hidden').prop('disabled', true);
                            _$('#fixed-button-add-cart').removeClass('uk-hidden').prop('disabled', true);
                        } else if (jsonError['maxKgLk'] > 0) {
                            _$('.product-button-add .add-quantity-button:last-child').prop('disabled', true);
                        }
                    }
                }
            }
        }

        let body;

        switch (productType) {
            case 'variable':
                if (type == 'preparationNotes') {

                    body = {
                        "productId": this._utils.pdpLastModifiedItem['productId'],
                        "entryNumber": this._utils.pdpLastModifiedItem['entryNumber'],
                        "qty": this._utils.pdpLastModifiedItem['qty'],
                        "scale": this._utils.pdpLastModifiedItem['scale']
                    }

                    if (this._preparationNoteSelected != this._preparationNoteDefault) {
                        body["preparationNote"] = this._preparationNoteSelected;
                    }

                } else {
                    if (Window._cartQuantity && Window._cartQuantity[size] != undefined) {

                        body = {
                            "productId": Window.displayedProduct.code,
                            "qty": type.indexOf("remove") > -1 ? Window._cartQuantity[size] - 1 : Window._cartQuantity[size] + 1,
                            "scale": size,
                            "entryNumber": Window._cartEntryNumber[size]
                        }

                        if (Window._cartPrepNote && Window._cartPrepNote[size]) {
                            body["preparationNote"] = Window._cartPrepNote[size];
                        }
                    }
                    else {
                        body = {
                            "productId": Window.displayedProduct.code,
                            "qty": 1,
                            "scale": size
                        }
                    }
                }

                body["weight"] = body["scale"];
                break;
            case 'incremental':
                if (type == "preparationNotes") {
                    body = {
                        "productId": Window.displayedProduct.code,
                        "qty": 1,
                        "scale": Window._cartScale,
                        "entryNumber": Window._cartEntryNumber
                    }

                    if (this._preparationNoteSelected != this._preparationNoteDefault) {
                        body["preparationNote"] = this._preparationNoteSelected;
                    }

                } else {
                    if (Window._cartScale && Window._cartScale > 0) {
                        if (type == 'add') {
                            body = {
                                "productId": Window.displayedProduct.code,
                                "qty": 1,
                                "scale": Window._cartScale + parseFloat(Window.displayedProduct.increment.increment),
                                "entryNumber": Window._cartEntryNumber
                            }
                        } else if (Window._cartScale - parseFloat(Window.displayedProduct.increment.increment) < Window.displayedProduct.increment.minWeight) {
                            body = {
                                "productId": Window.displayedProduct.code,
                                "qty": 0,
                                "scale": Window.displayedProduct.increment.minWeight,
                                "entryNumber": Window._cartEntryNumber
                            }
                        } else {
                            body = {
                                "productId": Window.displayedProduct.code,
                                "qty": 1,
                                "scale": Window._cartScale - parseFloat(Window.displayedProduct.increment.increment),
                                "entryNumber": Window._cartEntryNumber
                            }
                        }
                        if (this._preparationNoteSelected != this._preparationNoteDefault) {
                            body["preparationNote"] = this._preparationNoteSelected;
                        }

                    } else {
                        body = {
                            "productId": Window.displayedProduct.code,
                            "qty": 1,
                            "scale": Window.displayedProduct.increment.minWeight
                        }
                    }

                }

                body["weight"] = body["scale"];

                break;
            case 'base':
                if (type == "preparationNotes") {
                    body = {
                        "productId": Window.displayedProduct.code,
                        "qty": Window._cartQuantity,
                        "entryNumber": Window._cartEntryNumber
                    }
                } else {
                    if (Window._cartQuantity > 0) {
                        body = {
                            "productId": Window.displayedProduct.code,
                            "qty": type == 'remove' ? Window._cartQuantity - 1 : Window._cartQuantity + 1,
                            "entryNumber": Window._cartEntryNumber
                        }
                    } else {
                        body = {
                            "productId": Window.displayedProduct.code,
                            "qty": 1
                        }
                    }
                }
                if (Window.displayedProduct.netQuantityUm && Window.displayedProduct.netQuantityUm.toUpperCase() != "PZ") {
                    body["weight"] = Window.displayedProduct.netQuantity ? Window.displayedProduct.netQuantity : 0;
                }
                break;

            case 'heavy':
                if (type == "preparationNotes") {
                    body = {
                        "productId": Window.displayedProduct.code,
                        "qty": Window._cartQuantity,
                        "entryNumber": Window._cartEntryNumber
                    }
                } else {
                    if (Window._cartQuantity > 0) {
                        body = {
                            "productId": Window.displayedProduct.code,
                            "qty": type == 'remove' ? Window._cartQuantity - 1 : Window._cartQuantity + 1,
                            "entryNumber": Window._cartEntryNumber
                        }
                    } else {
                        body = {
                            "productId": Window.displayedProduct.code,
                            "qty": 1
                        }
                    }
                }
                if (Window.displayedProduct.netQuantityUm && Window.displayedProduct.netQuantityUm.toUpperCase() != "PZ") {
                    body["weight"] = Window.displayedProduct.netQuantity ? Window.displayedProduct.netQuantity : 0;
                }

                break;
        }

        this._cartService.addRemoveItemToCart(success, error, body);
    }

    manageContingency(promo: object, currentQuantity?: number, basePrice?: number) {
        if (promo['itOrderSap']) {
            this.hideAlertWarning();
            this.showAlertContingency(promo['itOrderSap']);
            _$('.product-header').find('.add-quantity .total .price').prepend(`<div class="price-old">€ ${(currentQuantity * basePrice).toFixed(2).toString().replace('.', ',')}</div>`);
        } else if (promo['itOrderEurecart']) {
            this.hideAlertWarning();
            this.showAlertContingency(promo['itOrderEurecart']);
        }
    }

    showPromoAlert(promo: object, currentQuantity?: number, basePrice?: number) {
        let promoType = promo['promoType'];
        let quantityThreshold = promo['quantityThreshold'] ? promo['quantityThreshold'] : '';
        let buyX, getY, missingForPromo;
        switch (promoType) {
            case 'PRODUCT_PARTNER_MIX_FIXED_DISCOUNT':
                buyX = promo['buyX'];
                getY = promo['getY'];
                missingForPromo = (buyX + getY) - currentQuantity % (buyX + getY);
                if (currentQuantity == 0) {
                    this.hideAlert(_$('.alert-promo'));
                } else if (currentQuantity > 0 && missingForPromo == (buyX + getY)) {
                    this.showAlertInfo(`Hai applicato la promo ${buyX + getY}x${buyX} Mix che ti fa ottenere gratis uno di questi prodotti`);
                } else if (missingForPromo > 1) {
                    this.showAlertInfo(`Aggiungi ` + missingForPromo + ` prodotti ${buyX + getY}x${buyX} Mix per applicare la promozione`);
                } else if (missingForPromo == 1) {
                    this.showAlertInfo(`Aggiungi un altro prodotto ${buyX + getY}x${buyX} Mix per applicare la promozione`);
                }
                break;
            case 'PRODUCT_PARTNER_FIXED_DISCOUNT':
                buyX = promo['buyX'];
                getY = promo['getY'];
                missingForPromo = (buyX + getY) - currentQuantity % (buyX + getY);
                if (currentQuantity == 0) {
                    this.hideAlert(_$('.alert-promo'));
                } else if (currentQuantity > 0 && missingForPromo == (buyX + getY)) {
                    this.showAlertInfo(`L'offerta è stata applicata al tuo carrello!`);
                    _$('.product-header').find('.add-quantity .total .price').prepend(`<div class="price-old">€ ${(basePrice * currentQuantity).toFixed(2).toString().replace('.', ',')}</div>`);
                } else if (missingForPromo > 1) {
                    this.showAlertInfo(`Aggiungi altri ` + missingForPromo + ` pezzi per applicare l'offerta!`);
                } else if (missingForPromo == 1) {
                    this.showAlertInfo(`Aggiungi un altro pezzo per applicare l'offerta!`);
                }
                break;
            case 'PRODUCT_N_FIXED_DISCOUNT':
                if (currentQuantity == 0) {
                    this.hideAlert(_$('.alert-promo'));
                } else if (currentQuantity > 0 && currentQuantity >= quantityThreshold) {
                    this.showAlertInfo(`L'offerta è stata applicata al tuo carrello!`)
                    _$('.product-header').find('.add-quantity .total .price').prepend(`<div class="price-old">€ ${(basePrice * currentQuantity).toFixed(2).toString().replace('.', ',')}</div>`);
                } else if (quantityThreshold - currentQuantity % quantityThreshold > 1) {
                    this.showAlertInfo(`Aggiungi altri ${quantityThreshold - currentQuantity % quantityThreshold} pezzi per applicare l'offerta!`);
                } else if (quantityThreshold - currentQuantity % quantityThreshold == 1) {
                    this.showAlertInfo(`Aggiungi un altro pezzo per applicare l'offerta!`);
                }
                break;
            case 'PRODUCT_ADDITIONAL_PERCENTAGE_DISCOUNT':
                if (currentQuantity == 0) {
                    this.hideAlert(_$('.alert-promo'));
                } else if (currentQuantity >= quantityThreshold) {
                    this.showAlertInfo(`L'offerta è stata applicata al tuo carrello!`)
                    _$('.product-header').find('.add-quantity .total .price').prepend(`<div class="price-old">€ ${(basePrice * currentQuantity).toFixed(2).toString().replace('.', ',')}</div>`);
                } else if (quantityThreshold - currentQuantity > 1) {
                    this.showAlertInfo(`Aggiungi altri ${quantityThreshold - currentQuantity % quantityThreshold} pezzi per applicare l'offerta!`);
                } else if (quantityThreshold - currentQuantity == 1) {
                    this.showAlertInfo(`Aggiungi un altro pezzo per applicare l'offerta!`);
                }
                break;
        }
    }

    showAlertInfo(text: string) {
        _$('.alert-promo p b').html(text);
        _$('.alert-promo').removeClass('uk-hidden');
    }

    hideAlert(e: object) {
        _$(e).closest('.uk-alert').addClass('uk-hidden');
    }

    hideAlertWarning() {
        _$('.alert-heavy-product').addClass('uk-hidden');
    }

    showAlertContingency(text: string) {
        _$('.alert-contingency-product p span.pezzi-contingency').html(text);
        _$('.alert-contingency-product').removeClass('uk-hidden');
    }

    showHeavyWarning(classShow: string, classHide: string) {
        _$('.alert-heavy-product').removeClass('uk-hidden');
        _$(classHide).addClass('uk-hidden');
        _$(classShow).removeClass('uk-hidden');
    }

    /* Controllo per mostrare gli alert dei prodotti pesanti */
    checkHeavyItem() {
        if (Window.displayedProduct.volume + Window.volume.cartVolume > Window.volume.maxVolume) {
            this.hideAlertWarning();
            _$('.alert-contingency-product').addClass('uk-hidden');
            this.showHeavyWarning('.troppi-prodotti-pdp', '.limite-prodotti-pdp');
            _$('.troppi-prodotti-pdp').parent().find('.icon-weight').removeClass('uk-hidden');
            _$('.troppi-prodotti-pdp').parent().find('.icon-alert').addClass('uk-hidden');
            _$('.product-button-add .add-quantity-button:last-child').prop('disabled', true);
            _$('#button-cart-add').prop('disabled', true);
        } else if (Window.volume.cartVolume >= Window.volume.maxVolumeNoSurcharge) {
            this.hideAlertWarning();
            _$('.alert-contingency-product').addClass('uk-hidden');
            this.showHeavyWarning('.limite-prodotti-pdp', '.troppi-prodotti-pdp');
            _$('.limite-prodotti-pdp').parent().find('.icon-alert').removeClass('uk-hidden');
            _$('.limite-prodotti-pdp').parent().find('.icon-weight').addClass('uk-hidden');
            _$('.product-button-add .add-quantity-button:last-child').prop('disabled', false);
            _$('#button-cart-add').prop('disabled', false);
        } else {
            this.hideAlertWarning();
            _$('.product-button-add .add-quantity-button:last-child').prop('disabled', false);
            _$('#button-cart-add').prop('disabled', false);
        }
    }

    /* Controlla se il prodotto in dettaglio sia già presente a carrello */
    checkProductOnCart() {
        if (Window.displayedProduct) {
            let productType;
            if (Window.displayedProduct.increment) {
                productType = 'incremental';
            } else if (Window.displayedProduct.variations) {
                productType = 'variable';
            } else if (Window.displayedProduct.volume) {
                productType = 'heavy';
            } else {
                productType = 'base';
            }
            switch (productType) {
                case 'variable':
                    Window._cartQuantity = {};
                    Window._cartEntryNumber = {};
                    Window._cartPrepNote = {};
                    if (Window.displayedProduct.entries) {
                        let entries = Window.displayedProduct.entries;
                        let totalQty = 0;

                        if (entries.length >= 2) {
                            _$('.product-preparation-notes').find('.filter').prop('disabled', true);
                            _$('.product-preparation-notes').find('.filter').addClass('uk-disabled showAlert');
                            _$('.alert-variable-product').removeClass('uk-hidden');
                        } else {
                            _$('.product-preparation-notes').find('.filter').prop('disabled', false);
                            _$('.product-preparation-notes').find('.filter').removeClass('uk-disabled showAlert');
                            _$('.alert-variable-product').addClass('uk-hidden');
                        }

                        for (let entry in entries) {
                            Window._cartQuantity[entries[entry].scale] = entries[entry].qty;
                            Window._cartEntryNumber[entries[entry].scale] = entries[entry].entryNumber;
                            this._currentPrice += entries[entry].totalPrice.value;
                            totalQty += entries[entry].qty * 1000 * entries[entry].scale;

                            /* impostazione nota di prep */
                            if (entries[entry].preparationNote) {
                                Window._cartPrepNote[entries[entry].scale] = entries[entry].preparationNote;
                            }
                        }

                        totalQty = totalQty / 1000;

                        if (Window.displayedProduct.variations && Window.displayedProduct.variations.unitOfMeasure == "KG") {
                            _$('.product-button-add').find('.quantity').html((totalQty < 1 ? this._utils.parseScaleKgToGr(totalQty * 1000) : parseFloat((totalQty).toFixed(4))).toString() + ' ' + (totalQty < 1 ? 'g' : 'kg'));
                        } else if (Window.displayedProduct.variations && Window.displayedProduct.variations.unitOfMeasure == "GR") {
                            _$('.product-button-add').find('.quantity').html((totalQty >= 1000 ? totalQty / 1000 : totalQty).toString() + ' ' + (totalQty >= 1000 ? 'kg' : 'g'));
                        } else {
                            _$('.product-button-add').find('.quantity').html((totalQty >= 1000 ? totalQty / 1000 : totalQty).toString() + ' ' + (totalQty >= 1000 ? 'kg' : 'g'));
                        }

                        _$('.product-button-add').find('.price').html('€ ' + this._currentPrice.toFixed(2).toString());
                        this.showAddRemoveMenu();
                        this._utils.variableProductPreparationNotes();
                    }
                    break;

                case 'incremental':
                    if (!Window.displayedProduct.entries) {
                        Window._cartQuantity = 0;
                        Window._cartScale = 0;
                        Window._cartPrepNote = '';
                        this.hideAddRemoveMenu();
                        _$('#preparationNotesBox').addClass('uk-hidden');
                        _$('.product-preparation-notes').find('.filter').prop('disabled', true);
                        _$('.product-preparation-notes').find('.filter').addClass('uk-disabled showAlert');
                        _$('.alert-variable-product').addClass('uk-hidden');
                    } else {
                        Window._cartQuantity = Window.displayedProduct.entries[0].qty;
                        Window._cartScale = Window.displayedProduct.entries[0].scale;
                        Window._cartEntryNumber = Window.displayedProduct.entries[0].entryNumber;
                        Window._cartPrepNote = Window.displayedProduct.entries[0].preparationNote ? Window.displayedProduct.entries[0].preparationNote : '';
                        if (Window.displayedProduct.increment && Window.displayedProduct.increment.unitOfMeasure == "KG") {
                            _$('.product-button-add').find('.quantity').html(Window._cartScale < 1 ? (this._utils.parseScaleKgToGr(Window._cartScale * 1000)).toString() + ' g' : Window._cartScale + ' kg');
                        } else if (Window.displayedProduct.increment && Window.displayedProduct.increment.unitOfMeasure == "GR") {
                            _$('.product-button-add').find('.quantity').html(Window._cartScale >= 1000 ? Window._cartScale / 1000 + ' kg' : Window._cartScale + ' g');
                        } else {
                            _$('.product-button-add').find('.quantity').html(Window._cartScale >= 1000 ? Window._cartScale / 1000 + ' kg' : Window._cartScale + ' g');
                        }
                        _$('.product-button-add').find('.price').html(Window.displayedProduct.entries[0].totalPrice.value.toFixed(2).toString().replace('.', ',') + '€');
                        this.showAddRemoveMenu();
                        _$('#preparationNotesBox').removeClass('uk-hidden');
                        _$('.product-preparation-notes').find('.filter').prop('disabled', false);
                        _$('.product-preparation-notes').find('.filter').removeClass('uk-disabled showAlert');
                        _$('.alert-variable-product').addClass('uk-hidden');
                    }
                    break;

                case 'base':
                    if (!Window.displayedProduct.entries) {
                        Window._cartQuantity = 0;
                        this.hideAddRemoveMenu();
                    } else {
                        Window._cartQuantity = Window.displayedProduct.entries[0].qty;
                        Window._cartScale = Window.displayedProduct.entries[0].scale;
                        Window._cartEntryNumber = Window.displayedProduct.entries[0].entryNumber;
                        _$('.product-button-add').find('.quantity').html(Window._cartQuantity);
                        _$('.product-button-add').find('.price').html(Window.displayedProduct.entries[0].totalPrice.value.toFixed(2).toString().replace('.', ',') + '€');
                        this.showAddRemoveMenu();
                        if (Window.displayedProduct.promo) {
                            if (Window.displayedProduct.promo.length > 0) {
                                let promoAlertNeeded = false;
                                let contingency = false;
                                let activePromo = {};
                                if (Window.displayedProduct.promo) {
                                    let promoArray = Window.displayedProduct.promo;
                                    promoArray.forEach((index, element) => {
                                        if (promoArray[element].itOrderSap && !contingency) {
                                            contingency = true;
                                            promoAlertNeeded = true;
                                            activePromo = promoArray[element];
                                        } else if (['PRODUCT_PARTNER_FIXED_DISCOUNT',
                                            'PRODUCT_PARTNER_MIX_FIXED_DISCOUNT',
                                            'PRODUCT_N_FIXED_DISCOUNT',
                                            'PRODUCT_ADDITIONAL_PERCENTAGE_DISCOUNT'].indexOf(promoArray[element].promoType) != -1 && !promoAlertNeeded) {
                                            promoAlertNeeded = true;
                                            activePromo = promoArray[element];
                                        }
                                    });

                                    if (contingency) {
                                        if (Window.displayedProduct.entries[0].qty > 0) {
                                            _$('.product-header').find('.add-quantity .total .price').prepend(`<div class="price-old">€ ${(activePromo['basePrice'] * Window.displayedProduct.entries[0].qty).toFixed(2).toString().replace('.', ',')}</div>`);
                                        }
                                    } else if (promoAlertNeeded) {
                                        let missingForPromo = (activePromo['buyX'] + activePromo['getY']) - Window.displayedProduct.entries[0].qty % (activePromo['buyX'] + activePromo['getY']);
                                        if (Window.displayedProduct.entries[0].qty > 0 && missingForPromo == (activePromo['buyX'] + activePromo['getY'])) {
                                            if (activePromo['promoType'] != 'PRODUCT_PARTNER_MIX_FIXED_DISCOUNT') {
                                                _$('.product-header').find('.add-quantity .total .price').prepend(`<div class="price-old">€ ${(activePromo['basePrice'] * Window.displayedProduct.entries[0].qty).toFixed(2).toString().replace('.', ',')}</div>`);
                                            }
                                        }

                                    }
                                }
                            }
                        }
                    }
                    break;
                case 'heavy':
                    if (!Window.displayedProduct.entries) {
                        Window._cartQuantity = 0;
                        this.hideAddRemoveMenu();
                        this.checkHeavyItem();
                    } else {
                        Window._cartQuantity = Window.displayedProduct.entries[0].qty;
                        Window._cartScale = Window.displayedProduct.entries[0].scale;
                        Window._cartEntryNumber = Window.displayedProduct.entries[0].entryNumber;
                        _$('.product-button-add').find('.price').html(Window.displayedProduct.entries[0].totalPrice.value.toFixed(2).toString().replace('.', ',') + '€');
                        _$('.product-button-add').find('.quantity').html(Window._cartQuantity);
                        this.showAddRemoveMenu();
                        this.checkHeavyItem();
                        if (Window.displayedProduct.promo) {
                            if (Window.displayedProduct.promo.length > 0) {
                                let promoAlertNeeded = false;
                                let contingency = false;
                                let activePromo = {};
                                if (Window.displayedProduct.promo) {
                                    let promoArray = Window.displayedProduct.promo;
                                    promoArray.forEach((index, element) => {
                                        if (promoArray[element].itOrderSap && !contingency) {
                                            contingency = true;
                                            promoAlertNeeded = true;
                                            activePromo = promoArray[element];
                                        } else if (['PRODUCT_PARTNER_FIXED_DISCOUNT',
                                            'PRODUCT_PARTNER_MIX_FIXED_DISCOUNT',
                                            'PRODUCT_N_FIXED_DISCOUNT',
                                            'PRODUCT_ADDITIONAL_PERCENTAGE_DISCOUNT'].indexOf(promoArray[element].promoType) != -1 && !promoAlertNeeded) {
                                            promoAlertNeeded = true;
                                            activePromo = promoArray[element];
                                        }
                                    });

                                    if (contingency) {
                                        if (Window.displayedProduct.entries[0].qty > 0) {
                                            _$('.product-header').find('.add-quantity .total .price').prepend(`<div class="price-old">€ ${(activePromo['basePrice'] * Window.displayedProduct.entries[0].qty).toFixed(2).toString().replace('.', ',')}</div>`);
                                        }
                                    } else if (promoAlertNeeded) {
                                        let missingForPromo = (activePromo['buyX'] + activePromo['getY']) - Window.displayedProduct.entries[0].qty % (activePromo['buyX'] + activePromo['getY']);
                                        if (Window.displayedProduct.entries[0].qty > 0 && missingForPromo == (activePromo['buyX'] + activePromo['getY'])) {
                                            if (activePromo['promoType'] != 'PRODUCT_PARTNER_MIX_FIXED_DISCOUNT') {
                                                _$('.product-header').find('.add-quantity .total .price').prepend(`<div class="price-old">€ ${(activePromo['basePrice'] * Window.displayedProduct.entries[0].qty).toFixed(2).toString().replace('.', ',')}</div>`);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    break;
            }
        }
    }

    showVarWeight(e: object) {
        this.animateShowMenu(e);
        _$(e).parents(".product-button-add").find(".var-weight-menu-header-title").html("Aggiungi quantità");
        _$(e).parents(".product-button-add").find(".weight-list").removeClass("remove-selections");
        _$(e).parents(".product-button-add").find(".weight-list .uk-tag").removeClass("disabled");
        _$(e).parents(".product-button-add").find(".weight-list .uk-tag").removeClass("active");
    }

    showVarWeightRemove(e: object) {
        var menuContainer = _$(e).parents(".product-button-add");
        menuContainer.find(".var-weight-menu-header-title").html("Elimina quantità");
        menuContainer.find(".weight-list").addClass("remove-selections");
        menuContainer.find(".var-weight-menu").removeClass("uk-hidden");
        let scales = Object.keys(Window._cartQuantity);
        menuContainer.find(".weight-list .uk-tag").removeClass('disabled');
        let scalesFloat = [];
        /* Nel caso in cui i data-scale da SAP siano ad una cifra decimale, ci vorrà un controllo */
        for (let i = 0; i < scales.length; i++) {
            scalesFloat[i] = parseFloat(scales[i]);
        }

        for (let i = 0; i < menuContainer.find(".weight-list .uk-tag").length; i++) {

            if (scalesFloat.indexOf(parseFloat(menuContainer.find(".weight-list .uk-tag")[i].getAttribute('data-scale'))) < 0) {
                _$(menuContainer.find(".weight-list .uk-tag")[i]).addClass('disabled');
            }
        }
    }

    animateShowMenu(e: object) {
        var menuContainer = _$(e).parents(".product-button-add");
        menuContainer.find(".var-weight-menu").removeClass("uk-hidden");
        /* Reflow del documento per via della rimozione dell'uk-hidden */
        const reflow = menuContainer.height;
        var menu = menuContainer.find(".var-weight-menu");
        const listener = () => {
            menu.removeClass("uk-animation-slide-bottom");
            menu.removeClass("uk-animation-fast");
            menu.off('animationend', listener);
        };
        menu.on("animationend", listener);
        menu.removeClass("uk-animation-reverse");
        menu.addClass("uk-animation-slide-bottom");
        menu.addClass("uk-animation-fast");
    }

    hideVarWeight(e: object) {
        this.animateHideMenu(e);
    }

    animateHideMenu(e: object) {
        var menuContainer = _$(e).parents(".product-button-add");
        var menu = menuContainer.find(".var-weight-menu");
        const listener = () => {
            menu.removeClass("uk-animation-slide-bottom");
            menu.removeClass("uk-animation-reverse");
            menu.removeClass("uk-animation-fast");
            menu.addClass("uk-hidden");
            menu.off('animationend', listener);
        };
        menu.on("animationend", listener);
        menu.addClass("uk-animation-slide-bottom");
        menu.addClass("uk-animation-fast");
        menu.addClass("uk-animation-reverse");
    }

    handleClick(e: object) {
        var menu = _$(e).parents(".product-button-add");
        /* Menù aperto per cancellare le selezioni precedenti */
        if (menu.find(".weight-list").hasClass("remove-selections")) {
            this.callAddRemoveToCartNew(e, 'remove', 'variable', parseFloat(_$(e).attr('data-scale')), this.scaleMap[Window.displayedProduct.variations.unitOfMeasure]);
            this.hideVarWeight(e);
        }
        else {
            this.hideVarWeight(e);
            menu.find(".add-quantity").removeClass("uk-hidden");
            menu.find(".add-product").addClass("uk-hidden");
            this.callAddRemoveToCartNew(e, 'add', 'variable', parseFloat(_$(e).attr('data-scale')), this.scaleMap[Window.displayedProduct.variations.unitOfMeasure]);
        }
    }

    getImageZoom(e) {
        let nSlideText = _$('.pagination-product .swiper-pagination-bullet-active');
        let nSlide = nSlideText.length ? parseInt(nSlideText.attr('aria-label').substring(nSlideText.attr('aria-label').length - 2)) : 1;
        _Uikit.modal('#modalProductImages').show();
        this.setFirstImageZoom(nSlide);
        _Uikit.util.on('#modalProductImages', 'show', () => {
            this.swiperDetailProductModal;
        });
    }

    setFirstImageZoom(nSlide: number) {
        let slide = _$('.swiper-product-detail-image-modal .swiper-slide').eq(nSlide - 1);
        let img = slide.find('img')[0];
        _$('#modalProductImages').addClass('loading');
        //TO DO
        setTimeout(() => {
            this._utils.initZoom('NZoomContainerMobile', 'NZoomImgMobile', img);
            _$('#modalProductImages').removeClass('loading');
        }, 500);
    }

    setCarouselPosition(e) {
        let positionText = _$(e).siblings('.preview-image-container-modal').find('.main-swiper .swiper-slide.active img').attr('id');
        let nSlide = parseInt(positionText.substring(positionText.length - 1)) - 1;
        this.swiperDetailProduct.slideTo(nSlide, 0);
    }

    freeNoteStyle(e: object) {
        //@ts-ignore
        if (_$(e).val().length > 0) {
            _$(e).removeClass('blank');
            this.enableSingleFilterButtons(_$(e).closest('.product-preparation-notes'));
        } else {
            _$(e).addClass('blank');
            this.disableSingleFilterButtons(_$(e).closest('.product-preparation-notes'));
        }
    }

    checkRadio(e: object) {
        _$(e).parent().find('.free-note').trigger('focus');
        _$(e).parent().find('.uk-radio').prop('checked', true);
        //@ts-ignore
        if (_$(e).parent().find('.free-note').val().length < 1) {
            this.disableSingleFilterButtons(_$(e).parents('.product-preparation-notes'));
        }
    }

    lsfTrack(action: string) {
        let productCode = Window.displayedProduct.code;
        let searchQuery = Window.displayedProduct.query;
        this._localStorage.setItem('searchQuery', JSON.stringify(searchQuery));
        this._utils.callLsfTrack(action, productCode, searchQuery);
    }

    async sendAnalytics(eventType, e, variante?, prezzo?) {
        try {
            let analyticsData;
            let banner;
            if (_$('.component-BannerShopInShop').length) {
                banner = {
                    brand: JSON.parse(_$('.main-content').attr('data-product'))['marchio'],
                    campagna: _$('.component-BannerShopInShop .banner-title').text().trim(),
                    formato: 'Banner partner',
                    landing: _$('.component-BannerShopInShop .link').attr('href'),
                    posizioneSlide: 0
                }
            }

            switch (eventType) {
                case 'pageview':
                    //3.1.1
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            nomeFunnel: 'Scheda prodotto',
                            stepFunnel: 'Visualizzazione scheda'
                        },
                        prodotto: (await this._analytics.getProductData(_$('body .main-content'), true, '', variante, prezzo)),
                    }
                    if (banner) {
                        analyticsData['sponsor'] = [banner];
                    }
                    break;
                case 'aggiungiProdotto':
                    //3.1.2
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            nomeFunnel: 'Scheda prodotto',
                            stepFunnel: 'Visualizzazione scheda',
                            creazioneCarrello: ''
                        },
                        prodotto: (await this._analytics.getProductData(_$('body .main-content'), true, eventType, variante, prezzo)),
                    }
                    break;
                case 'rimuoviProdotto':
                    //3.1.3
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            nomeFunnel: 'Scheda prodotto',
                            stepFunnel: 'Visualizzazione scheda'
                        },
                        prodotto: (await this._analytics.getProductData(_$('body .main-content'), true, eventType, variante, prezzo)),
                    }
                    break;
                case 'prodottoPreferito':
                    //3.1.4
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            nomeFunnel: 'Scheda prodotto',
                            stepFunnel: 'Visualizzazione scheda'
                        },
                        prodotto: (await this._analytics.getProductData(_$('body .main-content'), true, eventType, variante, prezzo)),
                    }
                    break;
                case 'vediFotoProdotto':
                    //3.2.1
                    if (_$('body').attr('nkPage') == 'DetailCart') {
                        return;
                    }
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            stepFunnel: 'Visualizzazione foto slideshow'
                        },
                        prodotto: (await this._analytics.getProductData(_$('body .main-content'), true, '', variante, prezzo)),
                    }
                    analyticsData['prodotto']['slideshow'] = {
                        posizione: _$('.swiper-slide.active').length ? _$('.swiper-slide.active img').attr('id').substr(3) : '',
                        video: _$('.swiper-slide.active').hasClass('slide-video') ? 'SI' : 'NO',
                        fullscreen: 'NO'
                    }
                    break;
                case 'fullscreenFotoProdotto':
                    //3.2.2
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            nomeFunnel: 'Scheda prodotto',
                            stepFunnel: 'Fullscreen slideshow'
                        },
                        prodotto: (await this._analytics.getProductData(_$('body .main-content'), true, '', variante, prezzo)),
                    }
                    analyticsData['prodotto']['slideshow'] = {
                        posizione: _$('.swiper-slide.active').length ? _$('.swiper-slide.active img').attr('id').substr(3) : '',
                        video: _$('.swiper-slide.active').hasClass('slide-video') ? 'SI' : 'NO',
                        fullscreen: 'NO'
                    }
                    break;
                case 'clickAccordion':
                    //3.3.1
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            nomeFunnel: 'Scheda prodotto',
                            stepFunnel: 'Click su accordion'
                        },
                        prodotto: (await this._analytics.getProductData(_$('body .main-content'), true, '', variante, prezzo)),
                    }
                    analyticsData['prodotto']['accordion'] = {
                        voce: _$('.product-data-container .product-other-info').find('.uk-open .uk-accordion-title').text().toString().trim(),
                    }
                    break;
                case 'applicaNotePreparazione':
                    //3.4.1
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            nomeFunnel: 'Scheda prodotto',
                            stepFunnel: 'Applica nota di preparazione'
                        },
                        prodotto: (await this._analytics.getProductData(_$('body .main-content'), true, '', variante, prezzo)),
                    }

                    analyticsData.prodotto['notePreparazione']['nota'] = window['_cartPrepNote'][Object.keys(window['_cartPrepNote'])[0]]
                    break;
                case 'resetNotePreparazione':
                    //3.4.2
                    analyticsData = {
                        event: eventType,
                        funnel: {
                            nomeFunnel: 'Scheda prodotto',
                            stepFunnel: 'Resetta nota di preparazione'
                        },
                        prodotto: (await this._analytics.getProductData(_$('body .main-content'), true, '', variante, prezzo)),
                    }

                    analyticsData.prodotto['notePreparazione'] = window['_cartPrepNote'][Object.keys(window['_cartPrepNote'])[0]]
                    break;
            }
            if (_$(e).closest('.component-BannerShopInShop').attr('data-promo')) {
                let dataPromo = JSON.parse(_$(e).closest('.component-BannerShopInShop').attr('data-promo'));
                this._analytics.sendAnalytics(analyticsData, '', false, variante, dataPromo)
            } else {
                if (eventType == 'pageview' && _$('.component-BannerShopInShop').length && _$('.component-BannerShopInShop').attr('data-promo')) {
                    let dataPromo = JSON.parse(_$('.component-BannerShopInShop').attr('data-promo'));
                    this._analytics.sendAnalytics(analyticsData, '', false, variante, dataPromo)
                } else {
                    this._analytics.sendAnalytics(analyticsData, '', false, variante)
                }
            }
        } catch (error) {
            console.log(eventType + ' : ' + error);
        }
    }

    mySlideNext(swiperType, item) {
        switch (swiperType) {
            case "suggested":
                this._utils.mySlideNext(this.swiperSuggestedProductsOptions, _$(item).closest('.swiper-suggested-products')[0].swiper);
                break;
            case "last":
                this._utils.mySlideNext(this.swiperLastViewsOptions, _$(item).closest('.swiper-last-views')[0].swiper);
                break;
            case "nxm":
                this._utils.mySlideNext(this.swiperNxMOptions, _$(item).closest('.swiper-nxm')[0].swiper);
                break;
        }
    }

    mySlidePrev(swiperType, item) {
        switch (swiperType) {
            case "suggested":
                this._utils.mySlidePrev(this.swiperSuggestedProductsOptions, _$(item).closest('.swiper-suggested-products')[0].swiper);
                break;
            case "last":
                this._utils.mySlidePrev(this.swiperLastViewsOptions, _$(item).closest('.swiper-last-views')[0].swiper);
                break;
            case "nxm":
                this._utils.mySlidePrev(this.swiperNxMOptions, _$(item).closest('.swiper-nxm')[0].swiper);
                break;
        }
    }

    scrollToElement() {
        let id = "carosello-nxm";
        let headerHeight = _$(".component-header").height();

        if (_$("#" + id).length) {
            _$('html, body').animate({
                scrollTop: _$("#" + id).offset().top - headerHeight
            }, 2000);
        }
    }
}
