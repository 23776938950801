import Alpine from 'alpinejs';
import { formatC, i18n, renderHrefWithAdditionalParams } from '../../../../../../libs/base-utils';
import { sendAnalytics } from '../../../../../../libs/tracking-utils';
import { BASE_ECOMMERCE_DATA } from '../../../../../../constants';
import { logoutFlow } from '../../../../../../libs/common-flows';

Alpine.data('ec11x10xUserMenu', () => ({
    initialized: false,
    opened: false,
    init() {
        this.initialized = true;
    },
    // consts
    // utils
    i18n,
    formatC,
    // internal functions
    // main component functions
    async logout() {
        sendAnalytics((() => {
            let ecommerceData = BASE_ECOMMERCE_DATA.CLICK_USER_MENU_ITEM;
            ecommerceData.funnel.stepFunnel = 'Esci';
            return ecommerceData;
        })());

        logoutFlow(this);
    },
    actionUserMenuIcon() {
        this.opened = !this.opened;

        sendAnalytics((() => {
            let ecommerceData = BASE_ECOMMERCE_DATA.CLICK_USER_MENU;
            return ecommerceData;
        })());
    },
    // only analytics functions
    trackItemMenuClick(itemLabel: string) {
        sendAnalytics((() => {
            let ecommerceData = BASE_ECOMMERCE_DATA.CLICK_USER_MENU_ITEM;
            ecommerceData.funnel.stepFunnel = itemLabel;
            return ecommerceData;
        })());
    }
}));