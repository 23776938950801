import Alpine from 'alpinejs';
import { formatC, i18n} from '../../../../../../libs/base-utils';

Alpine.data('ec30x0xProductCardMini', () => ({
    productData: {} as {},
    init() {
        this.productData = JSON.parse(this.$el.dataset?.product);
        if (!this.productData) {
            console.warn('no data-product attribute found')
            return;
        }
    },
    // consts
    // utils
    formatC,
    i18n,
    // internal functions
    // main component functions
}));
